import {CardContent, CardHeader, Step, StepLabel, Stepper, Typography} from "@mui/material";
import {Children} from "react";
import {FadeInBox, SlideCard} from "../Util/styledComponents";


type Params = {
    children: React.ReactNode,
    sections: string[],
    pos: number
}


export default function CardPages({children, sections, pos}:Params) {
    return (
        <FadeInBox $visible={pos >= 0}>
            <Stepper activeStep={pos} sx={{position: "absolute", top: "0px", left: "50%", transform: "translateX(-50%)", width: "500px", maxWidth: "96%"}}>
                {sections.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {Children.map(children, (child, index) =>
                <SlideCard $active={pos === index ? true : pos < index ? "pre" : "post"}>
                    <CardHeader title={<Typography variant={"h4"} sx={{margin: "auto"}}>{sections[index]}</Typography>}/>
                    <CardContent>{child}</CardContent>
                </SlideCard>
            )}
        </FadeInBox>
    );
}
