import {ButtonBase, Icon, Stack, Typography} from "@mui/material";
import {CheckCircleOutline, Download} from "@mui/icons-material";
import {executeCallable, storage, uploadFiles} from "placementt-core";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import styled from "styled-components";
import {Popup} from "../../../../Components/Popup";
import Form from "../../../../Components/Form";
import FileDropzone from "../../../../Components/FileDropzone/FileDropzone";
import {getDownloadURL, ref as storageRef} from "firebase/storage";
import {imageToPdf} from "../../../../Util/util";

export type UploadProviderDbsCheckParams = {
    visible?: boolean,
    onComplete?: (e: {
        uploadType: "file"|"form",
        dbsCheck: {
            dbsCheck: any[];
        } | {
            [key: string]: string;
        }[],
        pId?: string,
        placementListingId?: string,
        providerId: string,
    }) => void,
    back?: () => void,
    providerId: string,
    pId?: string,
    uploaded?: boolean,
    uploadType?: "file"|"form",
    placementListingId?: string,
    dbsCheckData?: {
        [key: string]: unknown;
    }[],
    noSubmitButton?: boolean,
    type?: "inset"|"popup"
    uploadedType?: "file"|"form",
}

export const FileUploaded = styled(Stack)`
  height: 100px;
  width: 300px;
  border: 2px grey solid;
  border-radius: 4px;
  position: relative;
  display: flex ;
  align-items: center ;
  justify-content: center;
  transition: background-color 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
`;

const UploadProviderDbsCheck = forwardRef(({uploaded, placementListingId, uploadedType, noSubmitButton, visible=true, onComplete, back, providerId, pId/* , dbsCheckData*/, type="popup"}: UploadProviderDbsCheckParams, ref) => {
    const [fileUploaded, setFileUploaded] = useState<boolean>(uploaded || false);
    const [uploadedFile, setUploadedFile] = useState<string>();


    useEffect(() => {
        if (!uploadedType) return;

        getDownloadURL(storageRef(storage, `dbsChecks/${placementListingId || pId}.pdf`)).then(setUploadedFile).catch(() => console.log("No insurance."));
    }, []);

    const formRef = useRef();

    useImperativeHandle(ref, () => ({
        submit() {
            formRef.current && (formRef.current as {submitForm: () => void}).submitForm();
        },
    }));

    useEffect(() => {
        setFileUploaded(uploaded || false);
    }, [uploaded]);

    const uploadDbsCheck = async (e: {dbsCheck: any[]}|{[key:string]: string}[]) => {
        if (!fileUploaded && !Array.isArray(e)) {
            const splitName = e.dbsCheck[0].name.split(".");
            const suffix = splitName[splitName.length - 1];

            await uploadFiles(suffix === "pdf" ? e.dbsCheck[0] : await imageToPdf(e.dbsCheck[0]), `dbsChecks/${placementListingId || pId}.pdf`, "application/pdf");
        }
        await executeCallable("dbsCheck-add", {
            uploadType: "file",
            dbsCheck: e,
            pId: pId,
            placementListingId: placementListingId,
            providerId: providerId})
            .then(() => {
                onComplete && onComplete({
                    uploadType: "file",
                    dbsCheck: e,
                    pId: pId,
                    placementListingId: placementListingId,
                    providerId: providerId,
                });
            });
        setFileUploaded(true);
    };

    const DbsCheckForm =
    <>
        {fileUploaded ?
            <Stack alignItems={"center"}>
                <Typography>DBS check uploaded</Typography>
                <Icon><CheckCircleOutline/></Icon>
            </Stack> :
            <Stack spacing={0}>
                <Form key={"raForm"} ref={formRef} button={!noSubmitButton} onSubmit={(e) => uploadDbsCheck(e as {dbsCheck: any[]})} submitText={"Submit"}>
                    <Stack alignItems={"center"}>
                        <Stack direction={"row"} justifyContent={"center"}>
                            {Boolean(uploadedFile) &&
                                <ButtonBase onClick={() => window.open(uploadedFile, "_blank")} sx={{height: "100px", p: 2, display: "flex", alignItems: "center", justifyContent: "space-around", borderRadius: "4px", flexDirection: "column", border: "1px grey solid", minWidth: "max-content"}}>
                                    <Typography>Download</Typography>
                                    <Icon sx={{marginTop: "0 !important"}}><Download/></Icon>
                                </ButtonBase>}
                            <FileDropzone name={"dbsCheck"} required accept={"application/pdf, image/png, image/jpeg"} label={`${uploadedFile ? "Reu" : "U"}pload DBS check`} />
                        </Stack>
                    </Stack>
                </Form>
            </Stack>}
    </>;

    if (type === "popup") {
        return (
            <Popup open={visible} fullWidth title={"Upload a DBS check"} onClose={() => back && back()}>
                <Typography variant='subtitle1' textAlign={"center"} mb={2}>This institute has requested that you submit a DBS check for this placement. You can either upload an existing DBS check or fill out our prebuilt assessment.</Typography>
                {DbsCheckForm}
            </Popup>
        );
    }

    return (DbsCheckForm);
});

export default UploadProviderDbsCheck;
