import {useEffect, useState} from "react";
import Page from "../../Components/Page";
import InfoPageHeader from "../../Components/Public/InfoPageHeader";
import {Blog, PRIMARY_COLOUR, camelCaseToNormal, capitaliseWords, readingTime, storage} from "placementt-core";
import {CardContent, Chip, Divider, Grid, Skeleton, Stack, Typography} from "@mui/material";
import {getDownloadURL, ref} from "firebase/storage";
import {orderBy} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import PublicTemplate from "../../Components/Public/PublicTemplate";
import {ClickableCard} from "../../Util/styledComponents";
import ProfileLogo from "../../Components/Public/ProfileLogo";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";


const blogLoadingPlaceholder: {[key:string]: Blog} = {
    [0]: {},
    [1]: {},
    [2]: {},
    [3]: {},
    [4]: {},
    [5]: {},
};

export default function Blogs() {
    const [blogs, setBlogs] = useState<{blogs: {[key:string]: Blog&{hero?:string}}, authorImages: {[key:string]: string}}>({blogs: blogLoadingPlaceholder, authorImages: {}});
    const navigate = useNavigate();
    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        const getBlogs = async () => {
            const docs = await firebaseQuery.getDocsWhere(["blogs"], orderBy("uploaded")) as {[key:string]: Blog&{hero :string}};

            const arrayWithImages = await Promise.all(Object.entries(docs).map(async ([k, b]) => {
                if (b.author && !blogs.authorImages[b.author]) {
                    const authorImage = await getDownloadURL(ref(storage, `/profilePics/${b?.author}.png`));
                    setBlogs((prev) => ({blogs: prev.blogs, authorImages: {...prev.authorImages, [b.author || ""]: authorImage}}));
                }
                const heroImage = await getDownloadURL(ref(storage, `/blogs/${k}.png`));
                return [k, {...b, hero: heroImage}];
            }));

            setBlogs((b) => ({blogs: Object.fromEntries(arrayWithImages), authorImages: b.authorImages}));
        };

        getBlogs();
    }, []);


    return (
        <Page
            metaTitle="Placementt | Blogs"
            metaDesc="View blog articles on all things related to work experience placements.">
            <InfoPageHeader scaleBackground title="Blogs" subtext="View blog articles on all things related to work experience placements." background={PRIMARY_COLOUR}/>
            <PublicTemplate>
                <Grid container pb={10}>
                    {Object.keys(blogs.blogs).length ? Object.entries(blogs.blogs).map(([k, b]) =>
                        <Grid item xs={12} sm={6} md={4} sx={{pointerEvents: b.title ? "all" : "none !important"}} >
                            <ClickableCard onClick={() => navigate(k)} sx={{cursor: "pointer"}}>
                                {b.hero ? <img src={b.hero} style={{margin: -16, width: "calc(100% + 32px)", marginBottom: "10px"}}/> : <Skeleton variant="rectangular" height={224} />}
                                <CardContent sx={{paddingTop: 1, width: "100%"}}>
                                    {b.hero ? <Chip label={capitaliseWords(b?.category)} color={b?.category === "placementt" ? "primary" : b?.category}/> : <Skeleton variant="rounded" width={"40%"} height={24} sx={{marginBottom: 1}}/>}
                                    {b.hero ? <Typography mt={2} variant="h5">{b.title}</Typography> : <Skeleton variant="rounded" width={"80%"} height={32} />}
                                    <Divider sx={{marginTop: 2, marginBottom: 2}}/>
                                    <Stack direction = "row" alignItems={"center"} sx={{width: "100%"}}>
                                        {b.hero && b.author ? <ProfileLogo src={blogs.authorImages[b.author]}/> : <Skeleton variant="circular" width={50} height={50} />}
                                        <Stack spacing={0} sx={{opacity: 0.75, flex: 1}}>
                                            {b.hero ? <Typography>By {capitaliseWords(camelCaseToNormal(b?.author))}</Typography> : <Skeleton variant="rounded" width={"80%"} height={22} sx={{marginBottom: 1}}/>}
                                            {b?.hero ? <Typography>Reading time: {readingTime(b?.summary + JSON.stringify(b?.body))}</Typography> : <Skeleton variant="rounded" width={"80%"} height={22} />}
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </ClickableCard>
                        </Grid>
                    ) : <Typography m={"auto"} variant="h6" mt={5}>Looks like we have't got any articles yet. Check back in a few days!</Typography>}
                </Grid>
            </PublicTemplate>
        </Page>
    );
}
