import {Grid, Stack, Typography, styled, MenuItem, Box, CardContent, IconButton, Button} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import {UserContext} from "../../App";
import {AnalyticsItem, PRIMARY_COLOUR, SECONDARY_COLOUR, UserData, capitalise, editNestedObject, executeCallable, getItemInNestedObject, getUniqueId, sampleTargets} from "placementt-core";
import {ArrowForwardRounded, EditRounded, ExpandMoreRounded, RemoveRounded, SubdirectoryArrowRightRounded} from "@mui/icons-material";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";
import IconButtonPop from "../../Components/IconButtonPop";
import Dropdown from "../../Components/FormComponents/Dropdown";
import CircularProgressbar from "../../Components/CircularProgressbar";
import LinearProgressbar from "../../Components/LinearProgressBar";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {where} from "firebase/firestore";
import {useCheckMobileScreen} from "../../Util/util";
import {Popup} from "../../Components/Popup";
import Page from "../../Components/Page";


export default function Analytics({type="page"}:{type?:"card"|"page"}) {
    const {params} = useParams();
    const isMobile = useCheckMobileScreen();
    const [analyticsPopupOpen, setAnalyticsPopupOpen] = useState(false);


    const user = useContext(UserContext) as UserData;
    const [analytics, setAnalytics] = useState<AnalyticsItem|undefined>(user.analytics);

    if (type === "card") {
        if (!analytics) {
            return (<DefaultAnalyticsCard uid={user.id}/>);
        }
        return (<AnalyticsCard analytics={analytics}/>);
    }

    return (
        <Page
            title="Analytics"
            metaTitle="Placementt | Analytics"
            metaDesc="Placementt - Your placement analytics."
            titleSecondary={<Stack direction={"row"} justifyContent={"space-between"} flex={1} width={"100%"} ml={isMobile ? 0 : "20px"} mt={isMobile ? "20px" : 0}>
                <Stack spacing={0} alignSelf={"center"}>
                    <Typography sx={{width: "max-content"}}><div style={{display: "inline-block", marginRight: "10px", width: "30px", height: "10px", borderRadius: "10px", background: SECONDARY_COLOUR}}></div>Total</Typography>
                    <Typography sx={{width: "max-content"}}><div style={{display: "inline-block", marginRight: "10px", width: "30px", height: "10px", borderRadius: "10px", background: PRIMARY_COLOUR}}></div>Completed</Typography>
                </Stack>
                {isMobile ? <IconButtonPop title="Edit analytics" onClick={() => setAnalyticsPopupOpen(true)}><EditRounded/></IconButtonPop> : <Button onClick={() => setAnalyticsPopupOpen(true)}>Edit analytics</Button>}
            </Stack>}>
            <Popup open={analyticsPopupOpen} onClose={() => setAnalyticsPopupOpen(false)}>
                <AnalyticsSetUp onComplete={(a:AnalyticsItem) => {
                    setAnalyticsPopupOpen(false);
                    setAnalytics(a);
                }}/>
            </Popup>
            {analytics ? <AnalyticsViews analytics={analytics} path={params}/> : <DefaultAnalyticsCard uid={user.id}/>}
        </Page>
    );
}

function DefaultAnalyticsCard({uid}:{uid:string}) {
    const [total, setTotal] = useState<number>(0);
    const [complete, setComplete] = useState<number>(0);
    const [inProgress, setInProgress] = useState<number>(0);

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        firebaseQuery.getCount(["placements"], [where("uid", "==", uid)]).then(setTotal);
        firebaseQuery.getCount(["placements"], [where("uid", "==", uid), where("completed", "==", true)]).then(setComplete);
        firebaseQuery.getCount(["placements"], [where("uid", "==", uid), where("inProgress", "==", true)]).then(setInProgress);
    }, []);

    return (
        <CardContent>
            <Stack width={"100%"} direction={"row"} alignItems={"center"} justifyContent={"space-around"}>
                <Stack direction={"row"} alignItems={"center"}>
                    <CircularProgressbar size={120} label="Placements" text={total?.toString()} primary={1}/>
                    <CircularProgressbar label="Completed" text={complete?.toString()} primary={complete/total}/>
                    <CircularProgressbar label="In progress" text={inProgress?.toString()} primary={inProgress/total}/>
                </Stack>
            </Stack>
        </CardContent>
    );
}
function AnalyticsCard({analytics}:{analytics:AnalyticsItem}) {
    const navigate = useNavigate();

    return (
        <CardContent>
            <Grid container>
                <Grid xs={12} md={9} item container>
                    <Grid item xs={12} sm={3} display={"flex"} justifyContent={"center"}>
                        <CircularProgressbar size={120} label={analytics.name} text={`${analytics.completion?.total} / ${analytics.total}`} secondary={(analytics.completion?.total || 0)/(analytics.total || 0)} primary={(analytics.completion?.completed || 0)/(analytics.total || 0)}/>
                    </Grid>
                    {analytics.subtypes && Object.entries(analytics.subtypes).sort(([, a], [, b]) => (b.completion?.total||0)-(a.completion?.total||0)).slice(0, 3).map(([k, subtype]) => <Grid item xs={12} sm={3} display={"flex"} justifyContent={"center"} alignItems={"center"}><CircularProgressbar key={k} label={subtype.name} text={`${subtype.completion?.total}/${subtype.total}`} primary={(subtype.completion?.completed || 0)/(subtype.total || 0)} secondary={(subtype.completion?.total || 0)/(subtype.total || 0)}/></Grid>)}
                </Grid>
                <Grid item xs={12} md={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <IconButton disableRipple title={"View"} onClick={() => navigate("../analytics")}><><Typography variant="h6" color={"grey"} mr={2}>View all</Typography><ArrowForwardRounded/></></IconButton>
                </Grid>
            </Grid>
        </CardContent>
    );
}


export function AnalyticsSetUp({onComplete}:{onComplete: (e:any) => void}) {
    const user = useContext(UserContext) as UserData;
    const [analytics, setAnalytics] = useState(user.analytics || sampleTargets);
    const [units, setUnits] = useState(user.units);

    const onSubmit = async () => {
        if (!units) {
            throw new Error("Please fill in all required fields.");
        }
        if (user.userType === "Students" ) {
            await executeCallable("userManagement-uploadAnalytics", {...{analytics, units}});
            onComplete(analytics);
        }
    };

    const onChange = (path:string[], value?:unknown) => {
        setAnalytics((a) => editNestedObject(path, a as unknown as {[key:string]: unknown}, value) as unknown as AnalyticsItem);
    };

    return (
        <Box mt={2} id={"Wrapper"}>
            <Dropdown id="count-anallytics-dropdown" label={"Count analytics by"} required onChange={(e) => setUnits(e.target.value)} value={units}>
                <MenuItem value={"days"}>Days</MenuItem>
                <MenuItem value={"weeks"}>Weeks</MenuItem>
                <MenuItem value={"placements"}>Placements</MenuItem>
            </Dropdown>
            <Form onSubmit={onSubmit}>
                <AnalyticsSubItem item={analytics} {...{onChange, units}}/>
            </Form>
        </Box>
    );
}

// Recursive component that displays top level type and allows
// addition of subtypes, which themselves are the same component.
// On change of amount, need to call an onChange function all the way to the top.


type AnalyticsSubItemParams = {
    onChange: (path: string[], value?:unknown) => void,
    item: AnalyticsItem,
    sx?: {[key:string]: unknown},
    id?: string,
    units?: string
}

function AnalyticsSubItem({sx, item, onChange, id="", units}:AnalyticsSubItemParams) {
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (item.subtypes) {
            const names = Object.entries(item.subtypes)?.reduce((acc, [, item]) => {
                item.name && acc.push(item.name);
                return acc;
            }, [] as string[]);

            if (new Set(names).size !== names.length) {
                setError("Cannot duplicate names within a subtype.");
                return;
            }

            if (Object.entries(item.subtypes)?.reduce((acc, [, item]) => acc + (item.total || 0), 0) > (item.total || 0)) {
                setError("Subtypes must add to less than or equal to the parent.");
                return;
            }
        }

        setError(undefined);
    }, [item]);

    // Render top level item with name. Render all lower levels as AnalyticsSubItems.
    // Make top level invalid if doesn't add properly
    // onChange should call onChange all the way up to the top, to change all higher values.
    // Memoise to prevent rerenders? Only rerender if item changes
    // onChange and onDelete should contain path, which is added to recursively on the way up

    return (

        <Stack {...{sx}}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <InputGroup value={item.name} label={item.name === "Total" ? "" : "Name"} required={item.name !== "Total"} disabled={item.name === "Total"} onChange={(e:ChangeEvent<HTMLInputElement>) => onChange(["name"], e.target.value)} error={Boolean(error)} errorText={error}/>
                <Stack direction={"row"}>
                    <InputGroup placeholder={capitalise(units)} min="0" style={{minWidth: "65px"}} required label={capitalise(units)} type={"number"} onChange={(e:ChangeEvent<HTMLInputElement>) => onChange(["total"], parseInt(e.target.value) || 0)} error={Boolean(error)} errorText={"\n"} value={item.total}/>
                    <Stack direction={"row"} spacing={1}>
                        <IconButtonPop title="Subtype" onClick={() => onChange(["subtypes"], {...item.subtypes, [id+getUniqueId(item.subtypes || {})]: {name: ""}})}>
                            <SubdirectoryArrowRightRounded/>
                        </IconButtonPop>
                        {item.name !== "Total" && <IconButtonPop responsive={false} title="Remove" onClick={() => onChange([], undefined)}>
                            <RemoveRounded/>
                        </IconButtonPop>}
                    </Stack>
                </Stack>
            </Stack>
            {(Object.keys(item.subtypes || {}).length > 0) && <Stack paddingLeft={"5%"}>
                {Object.entries(item.subtypes || {}).map(([id, st]) => <AnalyticsSubItem {...{id, units}} item={st} onChange={(p, value) => onChange(["subtypes", id, ...p], value)}/>)}
            </Stack>}
        </Stack>

    );
}

const BreadCrumbLinks = styled("p")`

    >span {
        color: rgba(0,0,0,0.6);
        transition: all 250ms ease-in-out;
        cursor: pointer;

        :hover {
            text-decoration: underline;
        }
    }

    > span.primary{
        pointer-events: none;
        color: rgba(0,0,0,1);
    }
`;

function AnalyticsViews({analytics, path}:{analytics: AnalyticsItem, path?: string}) {
    const [item, setItem] = useState<AnalyticsItem>(analytics);
    const [mPath, setMPath] = useState<string[]>();

    useEffect(() => {
        if (analytics && item !== analytics) {
            setItem(analytics);
        }
    }, [analytics]);

    useEffect(() => {
        if (!path || path === "create") {
            setItem(analytics);
            return;
        }
        setMPath(path.split("-").flatMap((item) => ["subtypes", item]));
    }, []);

    useEffect(() => {
        if (!mPath) {
            setItem(analytics);
            return;
        }
        const newAnalytics = getItemInNestedObject([...mPath], analytics);
        console.log(newAnalytics);
        setItem(newAnalytics);
    }, [mPath]);

    return (
        <Grid container>
            {mPath && <Grid item xs={12} pl={3}>
                <BreadCrumbLinks aria-label="analytics breadcrumbs">
                    {[<span onClick={() => setMPath(undefined)}>Total</span>, " / "]}
                    {mPath.filter((x) => x !== "subtypes").slice(0, -1).map((i, index) => [<span onClick={() => setMPath((m) => m ? m.slice(0, index*2+2) : [])}>{
                        (getItemInNestedObject(mPath.slice(0, index*2+2), analytics) as AnalyticsItem).name
                    }</span>, " / "])}
                    <span className="primary">{item.name}</span>
                </BreadCrumbLinks>
            </Grid>}
            <Grid item xs={12} md={3} display={"flex"} justifyContent={"center"} paddingBottom={3}>
                <CircularProgressbar label={item.name} size={150} primary={(item.completion?.completed || 0) / (item?.total || 0)} secondary={(item.completion?.total || 0) / (item.total || 0)} text={`${item.completion?.total} / ${item.total}`}/>
            </Grid>
            {item.subtypes && Object.keys(item.subtypes).length > 0 &&
                <Grid item container xs={12} md={9}>
                    {
                        Object.entries(item.subtypes).map(([key, subtype]) =>
                            <Grid item xs={6} md={3} alignSelf={"center"} display={"flex"} justifyContent={"center"} flexDirection={"column"} position={"relative"}>
                                <CircularProgressbar size={125} label={subtype.name} primary={(subtype.completion?.completed || 0) / (subtype.total || 0)} secondary={(subtype.completion?.total || 0) / (subtype.total || 0)} text={`${subtype.completion?.total} / ${subtype.total}`}/>
                                {(subtype.subtypes && Object.keys(subtype.subtypes).length > 0) && <Box position="absolute" left={"50%"} bottom={"-70px"} sx={{transform: "translateX(calc(-50% + 8px))"}}>
                                    <IconButtonPop title="View" sx={{marginTop: "15px"}} onClick={() => setMPath((m) => {
                                        const a = [...(m || []), "subtypes", key];
                                        console.log("m", m);
                                        console.log("a", a);
                                        return a;
                                    })}><ExpandMoreRounded sx={{width: "40px", height: "40px"}}/></IconButtonPop>
                                </Box>}
                            </Grid>)}

                </Grid>
            }


            {item.time && Object.keys(item.time).length > 0 && <Grid item container xs={12} md={10} alignSelf={"center"} display={"flex"} height={"max-content"}>
                <Grid item xs={12}>
                    <Typography variant="h6">Time-based targets</Typography>
                </Grid>
                {Object.entries(item.time).map(([key, item]) =>
                    <Grid item xs={12} md={6} height={"max-content"}>
                        <LinearProgressbar key={key} primary={item.completion?.completed} secondary={item.completion?.total} primaryText={item.completion?.completed?.toString()} secondaryText={item.completion?.total?.toString()} totalText={item.total?.toString()} label={item.date}/>
                    </Grid>)}
            </Grid>}
        </Grid>
    );
}
