import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import {Box, Card} from "@mui/material";
import {styled} from "@mui/system";
import {useEffect, useState} from "react";


const DrawerHolder = styled(Box)`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    position: absolute;
    top: 10px;
    right: 0;
    transform:  ${(props) => props.open ? 0 : "translateX(100.5%)"};
    transition:all 0.4s ease;
`;

const Tab = styled(Card)`
    height: max-content;
    left: 0;
    position: absolute;
    transform: translateX(-118px) rotate(-90deg);
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    top: 110px;
    background-color: white;
`;

export default function Drawer({active=false, width="30%", onToggle, children, label}) {
    const [isOpen, setIsOpen] = useState(active);

    useEffect(() => {
        setIsOpen(active);
    }, [active]);

    return (
        <DrawerHolder open={isOpen} width={width} height={"calc(100% - 20px)"} sx={{display: "flex", flex: 1}}>
            <div style={{position: "relative", display: "flex", width: "100%", height: "100%"}}>
                <Tab onClick={() => onToggle(!isOpen)}>{label} &emsp; {isOpen ? <ArrowDownward/> : <ArrowUpward/>}</Tab>
                <Card sx={{width: "100%", height: "100%", zIndex: 1}}>
                    {children}
                </Card>
            </div>
        </DrawerHolder>
    );
}
