import {AccessibleForward, ArrowForward, ArrowForwardRounded, CheckCircleOutline, CheckroomOutlined, Close, Delete, DirectionsBike, DirectionsCar, Edit, Email, ErrorOutline, ExpandLessOutlined, ExpandMoreOutlined, Kitchen, MedicalServices, PermContactCalendarOutlined, RemoveRedEye, UploadFile} from "@mui/icons-material";
import {Stack, Typography, List, ListItem, Button, Card as MuiCard, CardContent, Grid, CardHeader, Chip, Divider, ListItemText, ListItemSecondaryAction, Table, TableBody, TableRow, Snackbar, Box, Alert, ListItemButton} from "@mui/material";
import {OnboardingDocs, PRIMARY_COLOUR, PlacementQuestions, Reminder, StudentPlacementData, UserData, WorkflowStage, camelCaseToNormal, capitaliseWords, convertDate, decodeGeoHash, executeCallable, getFormsFromId, reformatDateTime, useGetIndividualPlacementForPlacementPage, useOnboardingPopup} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {ChangeEvent, useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {OrganisationContext, UserContext} from "../../App";
import DeletePopup from "../../Components/DeletePopup";
import InputGroup from "../../Components/FormComponents/InputGroup";
import IconButtonPop from "../../Components/IconButtonPop";
import {InfoTableCell} from "../../Util/styledComponents";
import {RenderPlacementFlags} from "../../Util/visualUtil";
import {Popup} from "../../Components/Popup";
import Map from "../../Components/Map";
import ProposePlacement, {EditPlacementPopup} from "./ProposePlacement";
import Page, {PageTitle} from "../../Components/Page";
import Spreadsheet from "../../Components/Spreadsheet_alter";
import {CellBase} from "react-spreadsheet";
import DailyPlacementLog from "../../Components/DailyPlacementLog";
import Form, {FormButton} from "../../Components/Form";
import UploadProviderInsurance from "../Public/ProviderDetails/UploadProviderInsurance";
import UploadProviderRiskAssessment from "../Public/ProviderDetails/UploadProviderRiskAssessment/UploadProviderRiskAssessment";
import {LoadingButton} from "../../Components/LoadingButton";
import UploadProviderDbsCheck from "../Public/ProviderDetails/UploadProviderDbsCheck/UploadProviderDbsCheck";
import CustomFormPage from "../../Components/CustomFormPage";
import Files from "./Files";
import AddOnboardingDocsPopup from "../Providers/Popups/AddOnboardingDocsPopup";
import Card from "../../Components/Card";
import SlateEditor from "../../Components/Slate";
import {VisitList} from "./Visits";
import SkillsViewer from "./SkillsViewer";
import {Timestamp, where} from "firebase/firestore";
import FilterList from "../../Components/FilterList";

/* const verificationColourCodes = {
    'Unverified': 'error',
    'Verifying': 'warning',
    'Verified': 'success'
}*/

export default function ViewPlacement() {
    const {pid} = useParams();
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const org = useContext(OrganisationContext);

    const [editPlacementPopup, setEditPlacementPopup] = useState(false);
    const [deletePlacementPopup, setDeletePlacementPopup] = useState(false);
    const [studentRequestCopied, setStudentRequestCopied] = useState(false);

    const firebaseQuery = new FirebaseQuery();

    console.log("View placmeent!!");

    if (!pid) {
        return (
            <Stack height={"100%"}>
                <PageTitle back={"/institutes/placements"}>View placement</PageTitle>
                <Typography>Link broken, please go back to the homepage.</Typography>
            </Stack>
        );
    }


    const {placement, wStage, student, workflow, editable, institute, addOnboardingDocsPopup, setAddOnboardingDocsPopup,
        dismissOnboardingPopup, setEliPopupOpen, uploadProviderDocPopup, setExternalDocPopupOpen, setRejectExternalDocPopup, onFlagClick,
        setUploadInsurance, setUploadProviderDocPopup, setUploadRA, setUploadDBS, onboardingStatus, setSkipStagePopup,
        onboardingPopup, setViewExternalLinkPopup, setOnboardingPopup, setRejectELIPopup, eliURL, riskAssessmentURL,
        dbsCheckURL, setExternalLinkCopied, skipStagePopup, snackbar, setSnackbar, cohort, disableEmail, rejectELIPopup,
        eliPopupOpen, rejectExternalDocPopup, externalDocPopupOpen, viewExternalLinkPopup, externalLinkCopied,
        uploadInsurance, uploadRA, uploadDBS, editStage, sendEmail, canEdit, approveELI, rejectELI, approveProviderDoc,
        rejectProviderDoc, manuallyConfigureProvider, setDismissOnboardingPopup, shareStudentRequestPopup, setShareStudentRequestPopup} = useGetIndividualPlacementForPlacementPage({user: user, placementId: pid, organisation: org});


    if (!placement) {
        return (null);
    }

    // console.log("wStage", wStage)
    if (Object.keys(placement).length === 1) {
        return (
            <Stack height={"100%"}>
                <PageTitle back={"/institutes/placements"}>View placement</PageTitle>
                <Typography>Placement not found.</Typography>
            </Stack>
        );
    }

    if (!wStage) {
        return (null);
    }

    if (user.userType === "Students" && placement.status === 1) {
        return (<ProposePlacement {...{placement}} />);
    }

    if (!workflow ||!pid) return null;

    return (
        <Page
            title={placement.name}
            back={"/institutes/placements"}
            metaTitle={`Placementt | ${placement.name}`}
            metaDesc="View your placement">
            <Box sx={{position: "absolute", background: "linear-gradient(#7920F540, #7920F500)", height: "150px", width: "100vw", left: "0px", top: "-40px", zIndex: 0, pointerEvents: "none"}}>

            </Box>
            <Grid container mt={0}>
                <Grid item lg={8} md={12} maxWidth={"100%"} pr={2}>
                    <Stack>
                        <Stack sx={{alignSelf: "end", width: "max-content"}} spacing={1} direction={"row"} alignItems={"center"}>
                            <Chip color={placement.statusType === "withdrawn" ? "error" : "primary"} label={placement.statusType === "withdrawn" ? "Withdrawn" : wStage.name} sx={{zIndex: 1}}/>
                            {wStage.userType === "Parent" && !student?.details.parentEmail && <ErrorOutline color={"error"} />}
                            {editable && <IconButtonPop responsive={false} title={"Edit placement"} onClick={() => setEditPlacementPopup(true)}><Edit /></IconButtonPop>}
                            {user.userGroup !== "readOnly" && <IconButtonPop responsive={false} title={"Delete placement"} onClick={() => setDeletePlacementPopup(true)}><Delete /></IconButtonPop>}
                        </Stack>
                        <RenderPlacementFlags placement={placement} workflow={workflow} onClick={onFlagClick}/>
                        {/* user.userType === "Staff" && placement.insurance === "Verifying" && <Alert severity='info' action={<Button size={'small'} color={'inherit'} onClick={() => setEliPopupOpen(true)}>View</Button>}>Verify Employer's liability insurance.</Alert>*/}
                        <Table size="small" sx={{maxWidth: "550px", zIndex: 1}}>
                            <TableBody>
                                {user.userType === "Staff" && user.oId === placement.oId && <TableRow sx={{height: "50px"}}>
                                    <InfoTableCell>Student</InfoTableCell>
                                    <InfoTableCell>{student ?`${student.details.forename} ${student.details.surname}` : "Student deleted"}</InfoTableCell>
                                    {student && <InfoTableCell><IconButtonPop title={"View user"} onClick={() => navigate(`/institutes/users/${placement.uid}`)}><ArrowForwardRounded/></IconButtonPop></InfoTableCell>}
                                </TableRow>}
                                <TableRow sx={{height: "50px"}}>
                                    <InfoTableCell>Job title</InfoTableCell>
                                    <InfoTableCell colSpan={2}>{placement.title}</InfoTableCell>
                                </TableRow>
                                <TableRow sx={{height: "50px"}}>
                                    <InfoTableCell>Dates</InfoTableCell>
                                    <InfoTableCell colSpan={2}>{`${convertDate(placement.startDate, "visual")} - ${convertDate(placement.endDate, "visual")}`}</InfoTableCell>
                                </TableRow>
                                {user.userType === "Staff" && user.product === "institutes" && <TableRow sx={{height: "50px"}}>
                                    <InfoTableCell>Provider emailed</InfoTableCell>
                                    <InfoTableCell>{reformatDateTime(placement.providerEmailed) || "Not yet emailed"}</InfoTableCell>
                                    <InfoTableCell>{(user.userType === "Staff" && placement.status !== 11 && placement.providerEmailed) && <IconButtonPop disabled={disableEmail.provider} onClick={() => sendEmail("provider")} title={"Resend provider email"}><Email /></IconButtonPop>}</InfoTableCell>
                                </TableRow>}
                                {Boolean(user.userType === "Staff" && user.product === "institutes" && workflow.find((obj) => obj.userType === "Parent")) && student && <TableRow sx={{height: "50px"}}>
                                    <InfoTableCell>Parent emailed</InfoTableCell>
                                    <InfoTableCell>{student.details.parentEmail ? reformatDateTime(placement.parentEmailed) || "Not yet emailed" : <Typography color={"#C71F1F"}>Awaiting email address</Typography>}</InfoTableCell>
                                    <InfoTableCell>{(user.userType === "Staff" && placement.status !== 11 && placement.parentEmailed) && <IconButtonPop disabled={disableEmail.parent} onClick={() => sendEmail("parent")} title={"Resend parent email"}><Email /></IconButtonPop>}</InfoTableCell>
                                </TableRow>}
                                {user.product === "institutes" && user.userType === "Staff" && <TableRow sx={{height: "50px"}}>
                                    <InfoTableCell>Insurance</InfoTableCell>
                                    <InfoTableCell>{
                                        placement.providerId && institute?.verifiedProviders?.includes(placement.providerId) ? "Verified" :
                                            (placement.providerId && institute?.awaitingProviderInsurance?.includes(placement.providerId)) ? "Awaiting upload" :
                                                placement.providerId && !institute?.verifiedProviders?.includes(placement.providerId) ? "Awaiting review" :
                                                    "Awaiting upload"
                                    }</InfoTableCell>
                                    <InfoTableCell>
                                        {
                                            placement.providerId && placement.insurance && (institute?.verifiedProviders?.includes(placement.providerId) ||
                                        (placement.providerId && !institute?.verifiedProviders?.includes(placement.providerId))) &&
                                        !(placement.providerId && institute?.awaitingProviderInsurance?.includes(placement.providerId)) ?
                                                <IconButtonPop title={"View insurance"} onClick={() => onFlagClick("noInsurance")}><RemoveRedEye/></IconButtonPop> :
                                                (user.userGroup !== "readOnly" ? <IconButtonPop title="Upload manually" onClick={() => placement.providerId ? setUploadInsurance(true) : setUploadProviderDocPopup("insurance")}><UploadFile/></IconButtonPop> : <></>)
                                        }
                                    </InfoTableCell>
                                </TableRow>}
                                {user.userType === "Staff" && workflow.find((node) => node.riskAssessment) && <TableRow sx={{height: "50px"}}>
                                    <InfoTableCell>Risk assessment</InfoTableCell>
                                    <InfoTableCell>{
                                        placement.riskAssessment === true ? "Verified" :
                                            placement.riskAssessment ? "Awaiting review" :
                                                "Awaiting upload"
                                    }</InfoTableCell>
                                    <InfoTableCell>
                                        {
                                            placement.riskAssessment ?
                                                <IconButtonPop title={"View risk assessment"} onClick={() => onFlagClick("noRiskAssessment")}><RemoveRedEye/></IconButtonPop> :
                                                (user.userGroup !== "readOnly" ? <IconButtonPop title="Upload manually" onClick={() => placement.providerId ? setUploadRA(true) : setUploadProviderDocPopup("riskAssessment")}><UploadFile/></IconButtonPop> : <></>)

                                        }
                                    </InfoTableCell>
                                </TableRow>}
                                {user.userType === "Staff" && workflow.find((node) => node.dbsCheck) && <TableRow sx={{height: "50px"}}>
                                    <InfoTableCell>DBS check</InfoTableCell>
                                    <InfoTableCell>{
                                        placement.dbsCheck === true ? "Verified" :
                                            placement.dbsCheck ? "Awaiting review" :
                                                "Awaiting upload"
                                    }</InfoTableCell>
                                    <InfoTableCell>
                                        {
                                            placement.dbsCheck ?
                                                <IconButtonPop title={"View DBS check"} onClick={() => onFlagClick("noDbsCheck")}><RemoveRedEye/></IconButtonPop> :
                                                (user.userGroup !== "readOnly" ? <IconButtonPop title="Upload manually" onClick={() => placement.providerId ? setUploadDBS(true) : setUploadProviderDocPopup("DbsCheck")}><UploadFile/></IconButtonPop> : <></>)

                                        }
                                    </InfoTableCell>
                                </TableRow>}
                                {user.product === "providers" && !["withdrawn", "requested"].includes(placement.statusType) && user.oId === placement.providerId && placement.onboarding !== false &&
                                    <TableRow sx={{height: "50px"}}>
                                        {onboardingStatus === "Add onboarding documents" ||
                                            <><InfoTableCell>Onboarding</InfoTableCell>
                                                <InfoTableCell>{onboardingStatus}</InfoTableCell>
                                                <InfoTableCell>
                                                    {onboardingStatus === "Onboarding sent" ? (user.userGroup !== "readOnly" ? <LoadingButton onClick={async () => await executeCallable("placement-sendOnboardingReminder", {placementId: pid})} text="Send onboarding reminder"/> : <></>) : <Button onClick={() => setOnboardingPopup(true)}>
                                                        {onboardingStatus === "Complete onboarding" ? "Complete" : "Review"}
                                                    </Button>}
                                                </InfoTableCell></>}
                                    </TableRow>}
                                {user.product !== "providers" && placement.onboarding && placement.onboarding.completed.submitted &&
                                    <TableRow>
                                        <InfoTableCell>Onboarding</InfoTableCell>
                                        <InfoTableCell><Button onClick={() => setOnboardingPopup(true)}>View onboarding</Button></InfoTableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                        {user.userType === "Staff" && user.product === "institutes" && !wStage.checkpoint && wStage.userType !== "Staff" && <Alert severity="info" action={
                            <Stack direction={"row"}>
                                {["Provider", "Parent"].includes(wStage.userType || "") && <Button color="inherit" onClick={() => setViewExternalLinkPopup(true)}>View {(wStage.userType || "").toLowerCase()} link</Button>}
                                <Button color="inherit" onClick={() => setSkipStagePopup(true)}>Skip stage</Button>
                            </Stack>}>Currently waiting for information from the {(wStage.userType || "").toLowerCase()}.</Alert>}

                        <Divider sx={{marginBottom: "5px"}} />
                        {canEdit && <PlacementActionPanel stage={wStage} placement={placement} editPlacementStage={editStage} pId={pid} />}
                    </Stack>
                    {user.product === "providers" && student && <StudentInfo student={student}/>}
                    {new Date() > new Date(placement.startDate) && <DailyPlacementLog card {...{placement}} userType={user.userType.toLowerCase() as "students"|"staff"} access={user.userType.toLowerCase() === "staff" ? ["staff", "provider", "students"] : undefined}/>}
                    {institute?.package === "institutes-three" && <SkillsViewer type="placements" id={pid} skillsTargetsValues={placement.skillsTargetsValues}/>}
                    {user.userType === "Students" && placement.questions && <ProviderGuidance guidance={placement.questions}/>}
                    <ProviderAddress {...{placement}}/>
                    {user.userType === "Staff" && placement.questions && <ProviderGuidance guidance={placement.questions}/>}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Stack>
                        {user.userType === "Staff" && <Card title={"Notes"} subtitle="Only visible to your staff">
                            <SlateEditor height="400px" initialValue={placement?.notes?.[user.product as "institutes"|"providers"]} onSave={(text) => firebaseQuery.update(["placements", pid], {[`notes.${user.product}`]: text})}/>
                        </Card>}
                        <PlacementReminders placement={placement} pid={pid}/>
                        {institute?.package === "institutes-three" && <VisitList pId={pid}/>}
                        {(student && student.product === "institutes" || user.product === "providers") && <Workflow {...{placement, workflow, wStage}}/>}
                    </Stack>
                </Grid>
            </Grid>
            <DeletePopup
                open={deletePlacementPopup}
                onClose={() => {
                    setDeletePlacementPopup(false);
                }}
                title={"this placement"}
                onDelete={() => firebaseQuery.delete(["placements", pid]).then(() => {
                    navigate(`/${user.product}/home`);
                })}>
                <Typography>This cannot be undone.</Typography>
            </DeletePopup>
            {placement.statusType === "requested" && <Popup title={"Request student consent"} onClose={() => setShareStudentRequestPopup(false)} open={shareStudentRequestPopup}>
                <Typography>We have sent the student an email requesting that they accept this placement. This will allow you to send onboarding, request feedback and share specific placement details.</Typography>
                <Typography>The student has not yet accepted this placement. Share the link below with them so they can link the placement to their account.</Typography>
                <Stack direction={"row"} spacing={0} alignItems={"center"}>
                    <InputGroup sx={{color: `${PRIMARY_COLOUR} !important`, pointerEvents: "none"}} value={`https://placementt.co.uk/placementRequest/${pid}`}/>
                    <Button sx={{height: "40px !important", borderRadius: "0px 4px 4px 0px", marginLeft: "-10px", background: "white !important"}} onClick={() => {
                        navigator.clipboard.writeText(`https://placementt.co.uk/placementRequest/${pid}`);
                        setStudentRequestCopied(true);
                    }}>{studentRequestCopied ? "Coped!" : "Copy"}</Button>
                </Stack>
            </Popup>}
            {editable && student && <EditPlacementPopup open={editPlacementPopup}
                onClose={() => {
                    setEditPlacementPopup(false);
                }}
                initialData={placement}
                viewingUser={user}
                student={student}
                cohort={cohort} />}
            {user.userType === "Staff" && <>
                <Popup
                    open={eliPopupOpen}
                    onClose={() => setEliPopupOpen(false)}
                    maxWidth={false}
                    cardSx={{maxWidth: "700px", width: "calc(100vw - 10px)"}}
                    title={"Employer's Liability Insurance"}
                    actions={
                        placement.providerId && institute?.verifiedProviders?.includes(placement.providerId) ?
                            <Alert severity='info' action={<Button size='small' color='inherit' onClick={() => setRejectELIPopup(true)}>Reject</Button>}>
                            Your institute has previously verified this insurance.
                            </Alert> :
                            <LoadingButton width='max-content'>
                                <Button variant='contained' onClick={approveELI}>Accept</Button>
                                <Button color='error' onClick={async () => {
                                    setRejectELIPopup(true); return;
                                }}>Reject</Button>
                            </LoadingButton>
                    }>
                    <object data={eliURL} type="application/pdf" width="100%" height="500px">
                        <p>Your web browser doesn't have a PDF plugin.
                        Instead you can <a href={eliURL}>click here to
                        download the PDF file.</a></p>
                    </object>
                </Popup>
                <Popup title={"Reject insurance"} open={rejectELIPopup} onClose={() => setRejectELIPopup(false)}>
                    <Typography>Rejecting this employers liability insurance will require them to reupload. You can check back here once it is checked.</Typography>
                    <Form onSubmit={async (e) => rejectELI(e as {reason:string})}>
                        <InputGroup label="Rejection reason" name={"reason"} required/>
                    </Form>
                </Popup>
                <Popup
                    open={Boolean(externalDocPopupOpen)}
                    onClose={() => setExternalDocPopupOpen(false)}
                    maxWidth={false}
                    cardSx={{maxWidth: "700px", width: "calc(100vw - 10px)"}}
                    title={externalDocPopupOpen === "riskAssessment" ? "Risk assessment" : "DBS check"}
                    actions={
                        placement[externalDocPopupOpen as "riskAssessment" | "dbsCheck"] === true ?
                            <Alert severity='info' action={<Button size='small' color='inherit' onClick={() => setRejectExternalDocPopup(externalDocPopupOpen)}>Reject</Button>}>
                            Your institute has previously verified this {externalDocPopupOpen === "riskAssessment" ? "risk assessment" : "DBS check"}.
                            </Alert> :
                            <LoadingButton width='max-content'>
                                <Button variant='contained' onClick={() => externalDocPopupOpen && approveProviderDoc(externalDocPopupOpen)}>Accept</Button>
                                <Button color='error' onClick={async () => {
                                    setRejectExternalDocPopup(externalDocPopupOpen); return;
                                }}>Reject</Button>
                            </LoadingButton>
                    }>
                    {placement[(externalDocPopupOpen + "Type") as "riskAssessmentType" | "dbsCheckType"] === "file" || externalDocPopupOpen === "dbsCheck" ? <object data={externalDocPopupOpen === "riskAssessment" ? riskAssessmentURL : dbsCheckURL} type="application/pdf" width="100%" height="500px">
                        <p>Your web browser doesn't have a PDF plugin.
                        Instead you can <a href={externalDocPopupOpen === "riskAssessment" ? riskAssessmentURL : dbsCheckURL}>click here to
                        download the PDF file.</a></p>
                    </object> : <Spreadsheet readOnly colWidth={"200px"} initialData={placement[(externalDocPopupOpen + "Data") as "riskAssessmentData" | "dbsCheckData"]?.map((row) =>
                        ([{value: row.Risk}, {value: row.Likelihood}, {value: row.Severity}, {value: row["Overall risk"]}, {value: row["Mitigating action"]}])) as CellBase<any>[][]} defaultCols={["Risk", "Likelihood", "Severity", "Overall risk", "Mitigating action"]}/>}
                </Popup>
                <Popup title={`Reject ${externalDocPopupOpen === "riskAssessment" ? "risk assessment" : "DBS check"}`} open={(Boolean(rejectExternalDocPopup))} onClose={() => setRejectExternalDocPopup(false)}>
                    <Typography>Rejecting this {externalDocPopupOpen === "riskAssessment" ? "risk assessment" : "DBS check"} will require them to reupload. You can check back here once it is checked.</Typography>
                    <Form onSubmit={async (e) => externalDocPopupOpen && rejectProviderDoc(e as {reason:string}, externalDocPopupOpen)}>
                        <InputGroup label="Rejection reason" name={"reason"} required/>
                    </Form>
                </Popup>
            </>}
            {user.userType === "Staff" &&
            <>
                {wStage.userType && ["Provider", "Parent"].includes(wStage.userType || "") && <Popup title={`View ${wStage.userType.toLowerCase()} review link`} key={"externalLinkPopup"} open={viewExternalLinkPopup} onClose={() => setViewExternalLinkPopup(false)}>
                    <Typography>Some external organisations will have firewalls that prevent our emails getting through. If this happens, you can share the below link with the {wStage.userType.toLowerCase()} directly.</Typography>
                    <Stack direction={"row"} spacing={0} alignItems={"center"}>
                        <InputGroup sx={{color: `${PRIMARY_COLOUR} !important`, pointerEvents: "none"}} value={`https://placementt.co.uk/review/${user.oId}/${placement.id}/${placement[`${wStage.userType.toLowerCase()}Key` as keyof typeof placement] as string}`}/>
                        <Button sx={{height: "40px !important", borderRadius: "0px 4px 4px 0px", marginLeft: "-10px", background: "white !important"}} onClick={() => {
                            if (!wStage.userType) return;
                            navigator.clipboard.writeText(`https://placementt.co.uk/review/${user.oId}/${placement.id}/${placement[`${wStage.userType.toLowerCase()}Key` as keyof typeof placement] as string}`);
                            setExternalLinkCopied(true);
                        }}>{externalLinkCopied ? "Coped!" : "Copy"}</Button>
                    </Stack>
                </Popup>}
                <Popup title={"Skip placement stage"} key={"skipPlacementPopup"} open={skipStagePopup} onClose={() => setSkipStagePopup(false)}>
                    <Stack spacing={2}>
                        <Alert severity="warning">Warning: skipping this stage could result in missing records from the required user. This can include specific placement details or important documentation.</Alert>
                        <LoadingButton>
                            {wStage.buttons && wStage.buttons.map((button) => {
                                return <Button onClick={() => editStage(button.id)} variant={button.required ? "contained" : "outlined"}>{wStage.buttons?.length === 1 ? "Skip stage" : button.name || "Submit"}</Button>;
                            })}
                        </LoadingButton>
                    </Stack>
                </Popup>
                <Popup title={"Upload provider document"} open={Boolean(uploadProviderDocPopup)} onClose={() => setUploadProviderDocPopup(undefined)}>
                    <Stack>
                        <Typography>This provider has not yet uploaded any information. Before uploading, check that all provider information is correct to your knowledge. Once you are happy, press continue to prepare this placement for document submission.</Typography>
                        <Divider/>
                        <LoadingButton text="Prepare for submission" onClick={async () => await manuallyConfigureProvider()}/>
                    </Stack>
                </Popup>
                {placement.providerId && <UploadProviderInsurance back={() => setUploadInsurance(false)} pId={pid} visible={uploadInsurance} onComplete={async () => approveELI().then(() => setUploadInsurance(false))} providerId={placement.providerId} type="popup"/>}
                {placement.providerId && <UploadProviderRiskAssessment uploadedType={placement.riskAssessmentType} back={() => setUploadRA(false)} pId={pid} visible={uploadRA} placementListingId={placement.placementId} providerId={placement.providerId} onComplete={async () => approveProviderDoc("riskAssessment").then(() => setUploadInsurance(false))} type="popup"/>}
                {placement.providerId && <UploadProviderDbsCheck uploadedType={placement.dbsCheckType} back={() => setUploadDBS(false)} pId={pid} visible={uploadDBS} placementListingId={placement.placementId} providerId={placement.providerId} onComplete={async () => approveProviderDoc("dbsCheck").then(() => setUploadInsurance(false))} type="popup"/>}
            </>}
            {placement.onboarding === null && <AddOnboardingDocsPopup dismiss={dismissOnboardingPopup} onClose={() => {
                setAddOnboardingDocsPopup(false);
                setDismissOnboardingPopup(false);
            }} placement={(addOnboardingDocsPopup || dismissOnboardingPopup) ? placement : undefined} onComplete={() => {
                setAddOnboardingDocsPopup(false);
                setDismissOnboardingPopup(false);
            }}/>}

            {(user.userType === "Students" || user.product === "providers") && placement.onboarding && placement.providerId && <OnboardingPopup providerId={placement.providerId} onboarding={placement.onboarding} placementId={pid} open={onboardingPopup} onClose={() => setOnboardingPopup(false)}/>}
            <Snackbar
                open={snackbar.open}
                onClose={() => setSnackbar((s) => ({...s, open: false}))}
                message={snackbar.message}
                autoHideDuration={6000}
                action={<IconButtonPop
                    title={"Close"}
                    responsive={false}
                    onClick={() => setSnackbar((s) => ({...s, open: false}))}
                >
                    <Close fontSize="small" color='inherit'/>
                </IconButtonPop>}
            />
        </Page>
    );
}

type Params = {
    external?: boolean,
    stage: WorkflowStage&{files: {[fileId: string]: {name: string, url: string}}},
    placement: {importedProviderForms?: boolean, [key:string]: unknown},
    pId: string,
    editPlacementStage: (nextStageId: number) => Promise<void>,
    toggleForm?: (e: unknown) => void
}

export function PlacementActionPanel({external, stage, placement, pId, editPlacementStage, toggleForm = () => null}:Params) {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [placementData, setPlacementData] = useState<{importedProviderForms?: boolean, [key:string]: unknown}>();
    const firebaseQuery = new FirebaseQuery();

    const user = external ? undefined : useContext(UserContext);

    useEffect(() => {
        setPlacementData((p) => ({...p, ...placement}));
    }, [placement]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!placementData) return;

        const toComplete = [];
        stage.files && toComplete.push(...Object.keys(stage.files).map((file) => {
            return `file_${stage.id}_${file}`;
        }));
        stage.formDetails && toComplete.push(...Object.entries(stage.formDetails).map(([id]) => `form_${stage.id}_${id}`));

        setSubmitDisabled(!toComplete.map((item) => {
            return (item in placementData) ? true : false;
        }).every((element) => element));
    }, [stage, placementData]);

    const viewFile = async (fileId: string, file:{name: string, url: string}) => {
        if (!placementData) return;
        window.open(file.url);
        if (!(`file_${stage.id}_${fileId}` in placementData)) {
            // If external, call cloud function. Otherwise just updateDoc
            if (external) {
                await executeCallable("placement-updateFileView", {pId: pId, stage: stage.id, id: fileId});
            } else {
                await firebaseQuery.update(["placements", pId], {[`file_${stage.id}_${fileId}`]: true});
            }
            setPlacementData((p) => ({...p, [`file_${stage.id}_${fileId}`]: true}));
        }
    };

    const renderFormLinks = (element: {[key:string]: unknown}) => {
        if (!placementData) return;

        if (external) {
            return (
                (`form_${stage?.id}_${element.id}` in placementData) ?
                    [<IconButtonPop responsive={false} title={"Form completed"} disabled>
                        <CheckCircleOutline color={"success"} />
                    </IconButtonPop>,
                    <IconButtonPop responsive={false} title={"View form"} onClick={() => toggleForm({schema: element, destination: ["placements", pId],
                        name: `form_${stage.id}_${element.id}`, formData: placementData[`form_${stage.id}_${element.id}` as keyof typeof placementData]})}><Edit /></IconButtonPop>] :
                    <Button onClick={() => toggleForm({schema: element, destination: ["placements", pId], name: `form_${stage.id}_${element.id}`})}>Complete</Button>
            );
        }

        return (
            (`form_${stage.id}_${element.id}` in placementData) ?
                [<IconButtonPop responsive={false} title={"Form completed"} disabled>
                    <CheckCircleOutline color={"success"} />
                </IconButtonPop>,
                <IconButtonPop responsive={false} title={"View form"} onClick={() => {
                    navigate("/institutes/form", {state: {schema: element, destination: ["placements", pId], name: `form_${stage.id}_${element.id}`, formData: placementData[`form_${stage.id}_${element.id}` as keyof typeof placementData]}});
                }}><Edit /></IconButtonPop>] :
                <Button onClick={() => {
                    navigate("/institutes/form", {state: {schema: element, destination: ["placements", pId], name: `form_${stage.id}_${element.id}`}});
                }}>Complete</Button>
        );
    };

    const onButtonClick = async (button:{id: number}) => {
        await editPlacementStage(button.id);
    };

    if (!placementData) return null;

    return (
        <MuiCard sx={external ? {boxShadow: "none"} : {borderLeft: "10px #7920F5 solid"}}>
            {Boolean(external) || <CardHeader title="Action required"/>}
            <CardContent>
                {Boolean(external) || <Typography variant="subtitle1">Review this placement to move it on to the next workflow stage.</Typography>}
                <Stack spacing={2} marginTop={"20px"} alignItems={"center"} justifyContent={"space-between"}>
                    {stage?.formDetails && <List sx={{width: "100%"}}>
                        {Object.entries(stage.formDetails).map(([, element]) => {
                            return (
                                <ListItem
                                    sx={{border: "1px solid lightgrey", marginBottom: "2px", borderRadius: "5px"}}
                                    secondaryAction={renderFormLinks(element)}>
                                    <ListItemText primary={element.name} sx={{marginRight: "35px"}} />
                                </ListItem>
                            );
                        })}
                    </List>}
                    {stage.files && <List sx={{width: "100%"}}>
                        {Object.entries(stage.files)?.map(([fileId, file]) => {
                            return (
                                <ListItem
                                    sx={{border: "1px solid lightgrey", marginBottom: "2px", borderRadius: "5px"}}>
                                    <ListItemText primary={file.name} sx={{marginRight: "35px"}} />
                                    <ListItemSecondaryAction>
                                        {(`file_${stage.id}_${fileId}` in placementData) ? [<IconButtonPop responsive={false} title={"File viewed"} disabled><CheckCircleOutline color={"success"} /></IconButtonPop>, <IconButtonPop responsive={false} title={"View file"} onClick={() => {
                                            viewFile(fileId, file);
                                        }}><RemoveRedEye /></IconButtonPop>] : <Button onClick={() => {
                                            viewFile(fileId, file);
                                        }}>View</Button>}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>}
                    {placementData.importedProviderForms && <Alert color='info'>We have imported your previously completed forms for this institute, so you don't have to fill them out again.</Alert>}
                    {user && user.userGroup === "readOnly" ? <Typography color={PRIMARY_COLOUR}>Read only users cannot progress placements.</Typography> : <LoadingButton>
                        {stage.buttons && stage.buttons.map((button) => {
                            return <Button onClick={() => onButtonClick(button)} disabled={button.required && submitDisabled} variant={button.required ? "contained" : "outlined"}>{button.name || "Submit"}</Button>;
                        })}
                    </LoadingButton>}
                </Stack>
            </CardContent>
        </MuiCard>
    );
}

type WorkflowParams = {
    placement: StudentPlacementData,
    workflow: WorkflowStage[],
    wStage: WorkflowStage
}

function Workflow({placement, workflow, wStage}:WorkflowParams) {
    const [forms, setForms] = useState<{[k: string]: {[key:string]: unknown}}>();
    const [maxHeight, setMaxHeight] = useState("sm");

    const navigate = useNavigate();
    const scrollRef = useRef<HTMLDivElement>(null);

    const completedFormTest = new RegExp(/form_([0-9]+)_(.*)\w+/g);
    const completedForms = Object.fromEntries(Object.entries(placement).filter(([k]) => completedFormTest.test(k)));

    useEffect(() => {
        const allForms = workflow.reduce((acc, item) =>
            item.forms ? (new Set([...acc, ...item.forms]) as unknown as string[]) : acc
        , [] as string[]);

        getFormsFromId(["forms"], allForms).then((details) => {
            setForms(details);
        });
    }, [placement.oId, workflow]);

    useEffect(() => {
        if (!scrollRef.current) return;
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }, [maxHeight]);

    return (
        <MuiCard>
            <CardHeader title={"Workflow"} />
            <CardContent ref={scrollRef} sx={{maxHeight: maxHeight === "sm" ? "400px" : "max-content"}}>
                <IconButtonPop title={""} responsive={false} onClick={() => setMaxHeight((m) => m === "sm" ? "lg" : "sm")} sx={{position: "absolute", zIndex: 1, bottom: "5px", left: "50%", transform: "translate(-50%)"}}>
                    {maxHeight === "sm" ? <ExpandMoreOutlined/> : <ExpandLessOutlined/>}
                </IconButtonPop>
                <Stack spacing={0}>
                    {placement.leadTimes.sort((a, b) => parseInt(a.split("_")[1]) - parseInt(b.split("_")[1])).map((leadTime) => {
                        const time = leadTime.split("_")[1];
                        const stageId = leadTime.split("_")[0];
                        if (stageId === "0") {
                            return null;
                        }
                        const formattedDate = reformatDateTime(time);
                        const stage = workflow.find((x) => x.id === parseInt(stageId));

                        return (
                            <Box sx={{position: "relative", padding: "10px 0 15px 30px"}}>
                                <Box sx={{background: "#000099", position: "absolute", top: 0, left: 0, height: "100%", width: "5px"}} />
                                <Box sx={{background: "#000099", borderRadius: "50%", position: "absolute", top: "15px", left: "-5px", height: "15px", width: "15px"}} />

                                <Box sx={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
                                    <Typography>{stage?.name}</Typography>
                                    <Typography sx={{color: "#777"}}>{formattedDate}</Typography>
                                </Box>
                                <List>
                                    {forms && Object.entries(completedForms).filter(([key]) => key.split("_")[1] === stageId).map(([key]) => {
                                        const keyParsed = key.split("_");
                                        const form = forms[keyParsed[2]];
                                        const workflowStageName = workflow.find((obj) => obj.id === parseInt(keyParsed[1]))?.name;

                                        return (
                                            <ListItem
                                                sx={{border: "1px solid lightgrey", marginBottom: "2px", borderRadius: "5px"}}>
                                                <ListItemText primary={form?.name as string} secondary={workflowStageName} />
                                                <ListItemSecondaryAction sx={{display: "flex", alignItems: "center"}}>
                                                    <IconButtonPop responsive={false} title={"Form completed"} disabled><CheckCircleOutline color={"success"} /></IconButtonPop>
                                                    <IconButtonPop responsive={false} title={"View form"} onClick={() => {
                                                        navigate("/institutes/form", {state: {schema: form, formData: placement[key as keyof typeof placement], redirect: true}});
                                                    }}><RemoveRedEye /></IconButtonPop>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })}

                                </List>
                            </Box>
                        );
                    })}
                    <Box sx={{position: "relative", padding: "10px 0 15px 30px"}}>
                        <Box sx={{background: "#AB3EC1", borderRadius: "0 0 5px 5px", position: "absolute", top: 0, left: 0, height: "100%", width: "5px"}} />
                        <Box sx={{background: "#AB3EC1", borderRadius: "50%", position: "absolute", top: "15px", left: "-5px", height: "15px", width: "15px"}} />

                        <Box sx={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
                            <Typography>{wStage?.name}</Typography>
                            <Typography sx={{color: "#777"}}>Current stage</Typography>
                        </Box>
                    </Box>
                </Stack>
            </CardContent>
        </MuiCard>
    );
}

type ProviderAddressParams = {
    geoHash?: string,
    name: string,
    contactForename: string,
    contactSurname: string,
    providerEmail: string,
    providerPhone: string,
    ["address-line1"]: string,
    ["address-line2"]: string,
    locality: string,
    postal_code: string,
    country: string,
}

export function ProviderAddress({placement}:{placement:ProviderAddressParams}) {
    const location = placement.geoHash && decodeGeoHash(placement.geoHash);

    return (
        <Stack spacing={2} marginTop={"20px"}>
            <Typography variant='h5'>Business details</Typography>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h6'>{placement.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography>{placement["address-line1"]+" · "+placement["address-line2"]}</Typography>
                    <Typography>{placement.locality+" · "+placement.postal_code}</Typography>
                    <Typography>{capitaliseWords(camelCaseToNormal(placement.country))}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography>Contact: {(placement.contactForename || "Undefined")+" "+placement.contactSurname}</Typography>
                    <Typography>Email: {placement.providerEmail || "Undefined"}</Typography>
                    <Typography>Phone: {placement.providerPhone || "Undefined"}</Typography>
                </Grid>
            </Grid>
            {location && <Box sx={{position: "relative", overflow: "hidden"}} height={"300px"} borderRadius={"10px"}>
                <Map lat={location.latitude} lng={location.longitude} zoom={15} points={[[location.latitude, location.longitude]] as any}/>
            </Box>}
            <Divider sx={{marginBottom: "5px"}} />
        </Stack>
    );
}

function StudentInfo({student}:{student: UserData}) {
    return (
        <Stack spacing={2} marginTop={"20px"}>
            <Typography variant='h5'>Student details</Typography>
            <Table size="small">
                <TableRow>
                    <InfoTableCell>Name</InfoTableCell>
                    <InfoTableCell>{student.details.forename} {student.details.surname}</InfoTableCell>
                </TableRow>
                <TableRow>
                    <InfoTableCell>Email</InfoTableCell>
                    <InfoTableCell>{student.email}</InfoTableCell>
                </TableRow>
            </Table>
            <Divider sx={{marginBottom: "5px"}} />
        </Stack>
    );
}

export function ProviderGuidance({guidance}:{guidance:PlacementQuestions}) {
    return (
        <Stack marginTop={"20px"}>
            <Typography variant='h5'>Provider guidance</Typography>
            <Typography variant='h6' color={"primary"}>Description</Typography>
            <Stack direction={"row"} flexWrap={"wrap"}>
                <Stack direction={"row"} sx={{alignItems: "center", minWidth: "50%", marginLeft: "0 !important"}}>
                    <IconButtonPop responsive={false} title='Dress code' sx={{marginRight: "10px"}}><CheckroomOutlined/></IconButtonPop>
                    {guidance.dressCode === "other" ? guidance.dressCodeText || "Not given" : capitaliseWords(camelCaseToNormal(guidance.dressCode))}
                </Stack>
                <Stack direction={"row"} sx={{alignItems: "center", minWidth: "45%", marginLeft: "0 !important"}}>
                    <IconButtonPop responsive={false} title='First day contact' sx={{marginRight: "10px"}}><PermContactCalendarOutlined/></IconButtonPop>
                    {guidance.firstDayContact}
                </Stack>
                {guidance.firstAider && <Stack direction={"row"} sx={{alignItems: "center", minWidth: "45%", marginLeft: "0 !important"}}>
                    <IconButtonPop responsive={false} title='First aider' sx={{marginRight: "10px"}}><MedicalServices/></IconButtonPop>
                    {guidance.firstAider}
                </Stack>}
            </Stack>
            <Typography>{guidance.description}</Typography>
            {guidance.hours && <Typography><strong>Working hours: </strong>{guidance.hours}</Typography>}
            <Typography variant='h6' color={"primary"}>Responsibilities</Typography>
            <Stack direction={"row"} flexWrap={"wrap"}>
                {guidance.responsibilities ? (Array.isArray(guidance.responsibilities) ? guidance.responsibilities : guidance.responsibilities.split(", ")).sort((a, b) => b.length - a.length).map((exp) =>
                    <Typography minWidth={"calc(50% - 20px)"} ml={"0 !important"}>• {exp}</Typography>
                ) : <Typography>No information given.</Typography>}
            </Stack>
            <Typography variant='h6' color={"primary"}>Experience given</Typography>
            <Stack direction={"row"} flexWrap={"wrap"}>
                {guidance.experiencesGiven ? (Array.isArray(guidance.experiencesGiven) ? guidance.experiencesGiven : guidance.experiencesGiven.split(", ")).sort((a, b) => b.length - a.length).map((exp) =>
                    <Typography minWidth={"calc(50% - 20px)"} ml={"0 !important"}>• {exp}</Typography>
                ) : <Typography>No information given.</Typography>}
            </Stack>
            <Typography variant='h6' color={"primary"}>Experience required</Typography>
            <Stack direction={"row"} flexWrap={"wrap"}>
                {guidance.experienceDesired ? (Array.isArray(guidance.experienceDesired) ? guidance.experienceDesired : guidance.experienceDesired.split(", ")).sort((a, b) => b.length - a.length).map((exp) =>
                    <Typography minWidth={"calc(50% - 20px)"} ml={"0 !important"}>• {exp}</Typography>
                ) : <Typography>No information given.</Typography>}
            </Stack>
            <Typography><strong>Required equipment: </strong>{guidance.equipment}</Typography>
            <Typography><strong>Other information: </strong>{guidance.otherAdvice}</Typography>
            <Stack direction={"row"}>
                <strong>Amenities: </strong>
                {guidance.carParking && <IconButtonPop responsive={false} title='Car parking' sx={{color: "gray"}}><DirectionsCar/></IconButtonPop>}
                {guidance.bikeStorage && <IconButtonPop responsive={false} title='Bike storage' sx={{color: "gray"}}><DirectionsBike/></IconButtonPop>}
                {guidance.wheelchairFriendly && <IconButtonPop responsive={false} title='Wheelchair friendly' sx={{color: "gray"}}><AccessibleForward/></IconButtonPop>}
                {guidance.kitchen && <IconButtonPop responsive={false} title='Kitchen' sx={{color: "gray"}}><Kitchen/></IconButtonPop>}
            </Stack>
            {guidance.lunchArrangements && <Typography><strong>Lunch arrangements: </strong>{guidance.lunchArrangements}</Typography>}
            <Divider sx={{marginBottom: "5px"}} />
        </Stack>
    );
}


function OnboardingPopup({onboarding, providerId, placementId, open, onClose}:{onboarding: (
    OnboardingDocs&{
        completed: {
            submitted: boolean,
            submittedDate?: string,
            accepted?: boolean,
            filesViewed?: string[],
            formsCompleted?: {[key: string]: unknown},
            filesUploaded?: {[key: number]: string[]},
        }}
), open: boolean, providerId: string, onClose: () => void, placementId: string}) {
    const user = useContext(UserContext);

    const {addFile, viewFile, setFormComplete, formDetails, viewableFiles, setForm, setRejectPopup, stagesCompleted, mOnboarding, form, submit, acceptOnboarding, rejectOnboarding, rejectPopup, completedSections, fileUploadPopup, setFileUploadPopup} = useOnboardingPopup({user: user, providerId: providerId, placementId: placementId, onClose: onClose, onboarding: onboarding});

    console.log("onboarding", mOnboarding);

    return (
        <Popup {...{open, onClose}} fullWidth title="Review onboarding documents">
            <Form onSubmit={submit} button={false}>
                {mOnboarding?.forms && Object.keys(mOnboarding?.forms).length > 0 &&
                    <>
                        <Typography variant="h6">{mOnboarding ? "Complete these" : "Completed"} forms</Typography>
                        {formDetails && <List>
                            {Object.entries(formDetails).map(([id, form]) =>
                                <ListItem>
                                    <ListItemText primary={form.name}/>
                                    <ListItemSecondaryAction>
                                        {Object.keys(completedSections?.formsCompleted || {}).includes(id) ? <Alert sx={{alignItems: "center"}} action={<Button size="small" color="inherit" onClick={() => setForm({schema: form, disabled: user.product === "providers", ...form} as {name: string, id: string})}>{(user.product === "providers" || mOnboarding.completed.submitted) ? "View" : "Edit"}</Button>}>Completed</Alert> : <Button onClick={() => setForm({schema: form, ...form} as {name: string, id: string})}>Complete</Button>}
                                    </ListItemSecondaryAction>
                                </ListItem>)}
                        </List>}
                    </>}
                {viewableFiles && Object.keys(viewableFiles).length > 0 &&
                    <>
                        <Typography variant="h6">Review{user.product === "providers" ? "ed" : " these"} documents</Typography>
                        {viewableFiles && <List>
                            {Object.entries(viewableFiles).map(([id, file]) =>
                                <ListItem>
                                    <ListItemText primary={file.name}/>
                                    <ListItemSecondaryAction>
                                        {completedSections?.filesViewed?.includes(id) ? <Alert sx={{alignItems: "center"}} action={user.product !== "providers" && <Button size="small" color="inherit" onClick={() => window.open(file.url, "_blank")}>View</Button>}>Viewed</Alert> : <Button onClick={() => viewFile(id, (url) => window.open(url, "_blank"))}>View</Button>}
                                    </ListItemSecondaryAction>
                                </ListItem>)}
                        </List>}
                    </>}
                <Box>
                    {mOnboarding?.requiredFiles && mOnboarding.requiredFiles.length > 0 &&
                        <>
                            <Typography variant="h6">Submit{user.product !== "providers" ? " these" : "ted"} files</Typography>
                            {mOnboarding.requiredFiles && <List>
                                {mOnboarding.requiredFiles.map((file, i) =>
                                    <ListItem>
                                        <ListItemText
                                            primary={file.fileName}
                                            secondary={file.fileType}/>
                                        <ListItemSecondaryAction>
                                            {completedSections?.filesUploaded?.[i] ? <Alert sx={{alignItems: "center"}} action={<Button size="small" color="inherit" onClick={() => user.product !== "providers" ? setFileUploadPopup(i) : completedSections.filesUploaded?.[i].forEach((url) => window.open(url, "_blank"))}>{(user.product === "providers" || mOnboarding.completed.submitted) ? "View" : "Edit"}</Button>}>Uploaded</Alert> : <Button onClick={() => setFileUploadPopup(i)}>Upload</Button>}
                                        </ListItemSecondaryAction>
                                    </ListItem>)}
                            </List>}
                        </>}
                </Box>
                {user.userType === "Students" && (onboarding.completed?.submitted ? <Typography textAlign={"center"} color={"primary"}>Onboarding submitted</Typography> : <FormButton variant='contained' text="Submit" disabled={!stagesCompleted()}/>)}
                {user.product === "providers" && (onboarding.completed?.submitted ? (onboarding.completed.accepted ?
                    <Typography textAlign={"center"} color={"primary"}>Onboarding complete</Typography> :
                    (user.userGroup === "readOnly" ? <Typography color={PRIMARY_COLOUR}>Read-only staff cannot approve onboarding.</Typography> : <LoadingButton>
                        <Button variant='contained' onClick={acceptOnboarding}>Accept</Button>
                        <Button color='error' onClick={async () => {
                            setRejectPopup(true); return;
                        }}>Reject</Button>
                    </LoadingButton>)) : <Typography>Waiting for student to review onboarding.</Typography>
                )}
            </Form>
            <Popup fullWidth open={Boolean(form?.id)} title={form?.name} onClose={() => setForm(undefined)}>
                {form && <CustomFormPage key={form?.name} embedded formData={(onboarding.completed.formsCompleted?.[form.id] as { [key: string]: {[key: string]: unknown}; })?.[form.name]} {...form} noNavigate noUpdate onComplete={async (e) => setFormComplete(e)} disabled={user.product === "providers" || Boolean(mOnboarding.completed?.submitted)}/>}
            </Popup>
            <Popup title={"Reject onboarding"} open={rejectPopup} onClose={() => setRejectPopup(false)}>
                <Typography>Rejecting this onboarding will require the student to recomplete the steps. We'll let you know once it is complete.</Typography>
                <Form onSubmit={async (e) => await rejectOnboarding(e as {reason:string})}>
                    <InputGroup label="Rejection reason" name={"reason"} required/>
                </Form>
            </Popup>
            <Files popup open={Boolean(fileUploadPopup !== false)} onClose={() => setFileUploadPopup(false)} type="user" selectable onSubmitSelection={addFile} defaultSelected={fileUploadPopup !== false ? completedSections?.filesUploaded?.[fileUploadPopup] : undefined}/>
        </Popup>
    );
}


export function PlacementReminders({placement, cohort, pid}:{placement?: StudentPlacementData, cohort?: string, pid?: string}) {
    const [reminders, setReminders] = useState<{[key: string]: Reminder&{placement?: StudentPlacementData, student?: UserData}}>();
    const [addReminderPopup, setAddReminderPopup] = useState<{id: string, reminder: Reminder}|boolean>(false);
    const [dueDate, setDueDate] = useState<string>();
    const [delPopup, setDelPopup] = useState<{id: string, reminder: Reminder}|false>(false);

    const user = useContext(UserContext);
    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();

    useEffect(() => {
        const constraints = [where("collection", "==", "placements"), where("oId", "==", user.oId), where("uid", "==", user.id)];
        if (pid) {
            constraints.push(where("documentId", "==", pid));
        }
        if (cohort) {
            constraints.push(where("cohort", "==", cohort), where("status", "==", "upcoming"));
        }
        firebaseQuery.collectionSnapshot(async (items: {[key: string]: Reminder}) => {
            if (pid) {
                setReminders(sortReminders(items));
                return;
            }
            const remindersWithPlacements = await Promise.all(Object.entries(items).map(async ([key, reminder]) => {
                const placement = await firebaseQuery.getDocData(["placements", reminder.documentId]) as StudentPlacementData;
                const student = await firebaseQuery.getDocData(["users", placement.uid]).catch(() => false) as UserData|false;
                return [key, {...reminder, placement: placement, student: student}];
            }));
            setReminders(sortReminders(Object.fromEntries(remindersWithPlacements)));
        }, ["reminders"], constraints);
    }, []);


    function sortReminders(reminders: { [key: string]: Reminder }): { [key: string]: Reminder } {
        const today = new Date().toISOString().split("T")[0]; // Get today's date in yyyy-mm-dd format

        // Convert the reminders object to an array of [key, Reminder] tuples for sorting
        const reminderEntries = Object.entries(reminders);

        // Separate and sort reminders based on the specified criteria
        const sortedReminders = reminderEntries
            .sort(([, aReminder], [, bReminder]) => {
                const aDate = aReminder.dueDate;
                const bDate = bReminder.dueDate;

                // Sort based on status and dueDate
                if (aReminder.status === "upcoming" && bReminder.status === "upcoming") {
                    if (aDate <= today && bDate <= today) {
                        // Both are overdue, sort by most overdue
                        return aDate.localeCompare(bDate);
                    } else if (aDate <= today) {
                        // Only a is overdue
                        return -1;
                    } else if (bDate <= today) {
                        // Only b is overdue
                        return 1;
                    } else {
                        // Both are future, sort by closest due date
                        return aDate.localeCompare(bDate);
                    }
                } else if (aReminder.status === "dismissed" && bReminder.status === "dismissed") {
                    // Both are dismissed, sort by most recent dismissal
                    return bDate.localeCompare(aDate);
                } else {
                    // Ensure upcoming reminders come before dismissed ones
                    return aReminder.status === "upcoming" ? -1 : 1;
                }
            });

        // Convert the sorted array back to an object
        return Object.fromEntries(sortedReminders);
    }


    const addReminder = async (e: Partial<Reminder>) => {
        if (!placement) {
            throw new Error("Can only create a reminder from the placement page");
        }
        if (dueDate && dueDate <= (convertDate(new Date(), "dbstring") as string)) {
            throw new Error("Cannot create a reminder for today or in the past.");
        }

        const item = {
            oId: user.oId,
            documentId: pid,
            collection: "placements",
            uid: user.id,
            product: user.product,
            cohort: placement.cohort,
            created: Timestamp.fromDate(new Date()),
            status: "upcoming",
            ...e,
        } as Reminder;

        if (typeof addReminderPopup === "object") {
            await firebaseQuery.update(["reminders", addReminderPopup.id], e);
        } else {
            await firebaseQuery.add(["reminders"], item);
        }

        setDueDate(undefined);
        return;
    };

    const onDelete = async () => {
        if (!delPopup) return;

        await firebaseQuery.delete(["reminders", delPopup.id]);

        setDelPopup(false);
    };

    return (
        <>
            <FilterList sx={{height: "500px"}} card loadMoreIcon={"loaded"} title={"Reminders"} data={reminders} noResultsText={pid ? "No reminders" : "No reminders, create one from any placement page"} noSearch actionButton={pid && <Button onClick={() => setAddReminderPopup(true)}>Add reminder</Button>}>
                {Object.entries(reminders || {}).map(([id, reminder]) => <ListItemButton divider id={id} onClick={() => {
                    if (!pid) {
                        navigate(`/institutes/cohorts/placements/${reminder.documentId}`);
                        return;
                    }
                    setDueDate(reminder.dueDate);
                    setAddReminderPopup({id: id, reminder: reminder});
                }}>
                    <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                        <ListItemText
                            primary={<Stack spacing={0}>
                                <Typography fontSize={"1.2em"} pb={1}>{reminder.title}</Typography>
                                {reminder.placement && reminder.student && <Typography>{reminder.student.details.forename} {reminder.student.details.surname} - {reminder.placement.name}</Typography>}
                                <Typography sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: reminder.status === "dismissed" ? "1" : "3",
                                    WebkitBoxOrient: "vertical",
                                }} color={"grey"}>{reminder.description}</Typography>
                            </Stack>}/>
                        <Stack spacing={1}>
                            <Chip sx={{width: "max-content", alignSelf: "end"}} color={reminder.status === "dismissed" ? undefined : reminder.dueDate <= (convertDate(new Date(), "dbstring") as string) ? "error" : "primary"} label={reminder.status === "dismissed" ? "Dismissed" : reminder.dueDate <= (convertDate(new Date(), "dbstring") as string) ? "Reminder due" : `Due ${convertDate(reminder.dueDate, "visual")}`}/>
                            <Stack direction={"row"} sx={{justifyContent: "flex-end"}}>
                                {(reminder.dueDate <= (convertDate(new Date(), "dbstring") as string)) && reminder.status === "upcoming" && <IconButtonPop responsive={false} title="Edit reminder" onClick={(e) => {
                                    e.stopPropagation();
                                    firebaseQuery.update(["reminders", id], {status: "dismissed"});
                                }}><CheckCircleOutline/></IconButtonPop>}
                                {Boolean(pid) && (reminder.dueDate > (convertDate(new Date(), "dbstring") as string)) && <IconButtonPop responsive={false} title="Edit reminder" onClick={() => {
                                    setDueDate(reminder.dueDate);
                                    setAddReminderPopup({id: id, reminder: reminder});
                                }}><Edit/></IconButtonPop>}
                                {Boolean(pid) && <IconButtonPop responsive={false} onClick={async (e) => {
                                    e.stopPropagation();
                                    setDelPopup({id: id, reminder: reminder});
                                }} title="Delete reminder"><Delete/></IconButtonPop>}
                                {Boolean(pid) || <IconButtonPop responsive={false} title="View placement"><ArrowForward/></IconButtonPop>}
                            </Stack>
                        </Stack>
                    </Stack>
                </ListItemButton>)}
            </FilterList>
            {pid && placement && <Popup title={"New reminder"} subtitle="Reminders are personal to your account. We will notify you once they are due." maxWidth={"sm"} fullWidth onClose={() => {
                setDueDate(undefined);
                setAddReminderPopup(false);
            }} open={Boolean(addReminderPopup)}>
                <Form onSubmit={(e) => addReminder(e as Partial<Reminder>)}>
                    <InputGroup required value={typeof addReminderPopup === "object" ? addReminderPopup?.reminder.title : ""} label={"Title"} name={"title"}/>
                    <InputGroup required value={typeof addReminderPopup === "object" ? addReminderPopup?.reminder.description : ""} multiline minRows={4} label={"Description"} name={"description"}/>
                    <Divider textAlign="left">Placement dates: {convertDate(placement.startDate, "visual") as string} - {convertDate(placement.endDate, "visual") as string}</Divider>
                    <InputGroup required label={"Due date"} name={"dueDate"} value={dueDate} onChange={(e:ChangeEvent<HTMLInputElement>) => setDueDate(e.target.value)} type={"date"}/>
                    <Stack direction={"row"} spacing={1} flexWrap={"wrap"} rowGap={1}>
                        <Button variant={dueDate === placement.startDate ? "contained" : "outlined"} onClick={() => setDueDate(placement.startDate)}>On start date</Button>
                        <Button variant={dueDate === convertDate(new Date((convertDate(placement.startDate, "date") as Date).getTime() - 7*86400000), "dbstring") as string ? "contained" : "outlined"} onClick={() => setDueDate(convertDate(new Date((convertDate(placement.startDate, "date") as Date).getTime() - 7*86400000), "dbstring") as string)}>One week before</Button>
                        <Button variant={dueDate === convertDate(new Date((convertDate(placement.startDate, "date") as Date).getTime() - 30*86400000), "dbstring") as string ? "contained" : "outlined"} onClick={() => setDueDate(convertDate(new Date((convertDate(placement.startDate, "date") as Date).getTime() - 30*86400000), "dbstring") as string)}>One month before</Button>
                    </Stack>
                </Form>
            </Popup>}
            <DeletePopup
                open={Boolean(delPopup)}
                onClose={() => setDelPopup(false)}
                title={"reminder"}
                itemName={delPopup ? delPopup.reminder.title : ""}
                // For deleting workflow form or attachment, need to make sure no workflow is using hte attachment. Just add a popup for this as nonessential.
                onDelete={async () => await onDelete()}/>
        </>
    );
}
