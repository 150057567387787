import {Box} from "@mui/material";

export default function ProfileLogo({src}: {src: string}) {
    return (
        <Box
            component = "img"
            width = {50}
            height = {50}
            borderRadius={"50%"}
            sx = {{verticalAlign: "middle"}}
            src = {src}
        />

    );
}
