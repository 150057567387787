import {useContext, useEffect, useState} from "react";
import {Chip, IconButton, MenuItem, Table, TableBody, TableRow} from "@mui/material";
import {OrganisationContext, UserContext} from "../../App";
import {InfoTableCell} from "../../Util/styledComponents";
import {LoadingButton} from "../../Components/LoadingButton";
import {camelCaseToNormal, capitaliseWords, executeCallable, getUserById} from "placementt-core";
import Page from "../../Components/Page";
import Card from "../../Components/Card";
import {Check, Edit} from "@mui/icons-material";
import Dropdown from "../../Components/FormComponents/Dropdown";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import Files from "./Files";
import IconButtonPop from "../../Components/IconButtonPop";
import EditUserPopup from "../../Components/EditUserPopup";


export default function Profile() {
    const [admin, setAdmin] = useState<{email: string}>();
    const [editNotificationFrequency, setEditNotificationFrequency] = useState(false);
    const [notificationFrequency, setNotificationFrequency] = useState<"daily"|"weekly"|"fortnightly"|"none">();
    const [editUserPopup, setEditUserPopup] = useState(false);

    const user = useContext(UserContext);
    const organisation = useContext(OrganisationContext);
    const firebaseQuery = new FirebaseQuery();


    useEffect(() => {
        getUserById(organisation.details.admin).then(setAdmin);
    }, []);

    const updateNotificationFrequency = async () => {
        if (!editNotificationFrequency) {
            setEditNotificationFrequency(true);
            return;
        }

        if (!notificationFrequency) return;

        await firebaseQuery.update(["users", user.id], {
            notificationFrequency: notificationFrequency,
        });

        setEditNotificationFrequency(false);
        setNotificationFrequency(undefined);
    };

    const userGroup = user.groupData?.name || "Admin";

    const sendResetEmail = async () => {
        return await executeCallable("userManagement-sendPasswordResetEmail", {email: user.email}).catch((e) => {
            throw e;
        }).then(() => {
            return {severity: "success", message: "Password reset email sent"};
        });
    };

    if (!admin) {
        return null;
    }

    const updateUser = async (data: {[key:string]: unknown}) => {
        await executeCallable("userManagement-editUser", {userId: user.id, newData: data, userType: user.userType});
        setEditUserPopup(false);
    };

    return (
        <Page
            title="Profile"
            metaTitle="Placementt | Profile"
            metaDesc="View your Placementt account details"
            grid>
            <Card grid xs={12} md={6} title={"Basic details"} secondaryTitle={<IconButtonPop onClick={() => setEditUserPopup(true)} responsive={false} title="Edit"><Edit/></IconButtonPop>}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <InfoTableCell>Name</InfoTableCell>
                            <InfoTableCell>{user.details.forename} {user.details.surname}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>Email</InfoTableCell>
                            <InfoTableCell>{user.email}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>User type</InfoTableCell>
                            <InfoTableCell>{user.userType}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>User group</InfoTableCell>
                            <InfoTableCell>{userGroup}</InfoTableCell>
                        </TableRow>
                        {organisation.details[`${user.userType.toLowerCase()}Fields`]?.map((field: string) => {
                            if (!Object.prototype.hasOwnProperty.call(user.details, field)) {
                                return null;
                            }
                            if (["forename", "surname", "email"].includes(field)) {
                                return null;
                            }
                            return (
                                <TableRow>
                                    <InfoTableCell>{camelCaseToNormal(field)}</InfoTableCell>
                                    <InfoTableCell>{user.details[field as keyof typeof user.details]}</InfoTableCell>
                                    <InfoTableCell></InfoTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <LoadingButton onClick={sendResetEmail} text={"Change password"}/>
            </Card>
            <Card grid xs={12} md={6} title={"Organisation details"}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <InfoTableCell>Name</InfoTableCell>
                            <InfoTableCell>{organisation.details.name}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>Administrator</InfoTableCell>
                            <InfoTableCell>{user.details.forename} {user.details.surname}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>Administrator email</InfoTableCell>
                            <InfoTableCell>{admin.email}</InfoTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
            <EditUserPopup open={editUserPopup} onClose={() => setEditUserPopup(false)} fields={[...["forename", "surname"], ...(organisation.details[`${user.userType.toLowerCase()}Fields`] || [])]} initialData={user} onSubmit={updateUser} />

            {user.userType === "Staff" && <Card grid xs={12} md={6} title={"Notifications"}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <InfoTableCell>Email task notifications</InfoTableCell>
                            <InfoTableCell>{editNotificationFrequency ?
                                <Dropdown label="Frequency" onChange={(e) => setNotificationFrequency(e.target.value as "daily"|"weekly"|"fortnightly"|"none"|undefined)} required sx={{minWidth: 120}}>
                                    <MenuItem value={"daily"}>Daily</MenuItem>
                                    <MenuItem value={"weekly"}>Weekly</MenuItem>
                                    <MenuItem value={"fortnightly"}>Fortnightly</MenuItem>
                                    <MenuItem value={"none"}>None</MenuItem>
                                </Dropdown> :
                                <Chip color="primary" label={capitaliseWords(user.notificationFrequency) || "Weekly"}/>}</InfoTableCell>
                            <InfoTableCell><IconButton onClick={updateNotificationFrequency}>{editNotificationFrequency ? <Check color="success"/> : <Edit/>}</IconButton></InfoTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>}
            {user.userType === "Students" && <Files type="user"/>}
        </Page>

    );
}
