import ListNavBar from "../../../../Components/Navigation/ListNavBar";
import Page from "../../../../Components/Page";
import {CohortData} from "placementt-core";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../../App";
import {where} from "firebase/firestore";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@mui/material";
import Card from "../../../../Components/Card";

export default function ArchivedCohorts({cohorts}: {cohorts: {[key:string]: CohortData}}) {
    return (
        <Page fullHeight noGutters>
            <ListNavBar items={Object.entries(cohorts).map(([k, cohort]) => ({
                label: cohort.name,
                route: k,
                element: <ArchivedCohort key={k} id={k} cohort={cohort}/>,
            }))}/>
        </Page>);
}

function ArchivedCohort({id}: {id: string, cohort: CohortData}) {
    const user = useContext(UserContext);
    const firebaseQuery = new FirebaseQuery();

    const [inWorkflow, setInWorkflow] = useState<number>(0);
    const [complete, setComplete] = useState<number>(0);
    const [users, setUsers] = useState<number>(0);

    useEffect(() => {
        firebaseQuery.getCount("placements", [where("inProgress", "==", true), where("oId", "==", user.oId), where("cohort", "==", id)]).then(setInWorkflow);

        firebaseQuery.getCount("placements", [where("inProgress", "==", false), where("oId", "==", user.oId), where("cohort", "==", id)]).then(setComplete);
        firebaseQuery.getCount("users", [where("product", "==", user.product), where("oId", "==", user.oId), where("cohort", "==", id)]).then(setUsers);
    }, []);

    return (
        <Page grid>
            <Card grid xs={12} md={6} lg={12} title={<Typography variant={"h5"}>Users</Typography>}>
                <List>
                    <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                        <ListItemText primary="Students"/>
                        <ListItemSecondaryAction>
                            {users}
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Card>
            <Card grid xs={12} md={6} lg={12} title={<Typography variant={"h5"}>Placements</Typography>}>
                <List>
                    <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                        <ListItemText primary="In workflow"/>
                        <ListItemSecondaryAction>
                            {inWorkflow}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem sx={{background: "#00000010", borderRadius: "20px"}}>
                        <ListItemText primary="Completed"/>
                        <ListItemSecondaryAction>
                            {complete}
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Card>
        </Page>);
}
