
import {Stack} from "@mui/material";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import React, {JSXElementConstructor, ReactElement, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {UserContext} from "../../App";
import Preloader from "../../Components/Preloader";
import Page from "../../Components/Page";

/*

title            =  Form title
templateOptions  =  Array of template options. Must be the exact name of the template -> "Staff" for "StaffTemplate"
                    Where value is prefix to public template, written 'xTemplate'
formType         =  storage / permission type
*/

// template must be reserve words in forms.

type Params = {
    title: string,
    editable: boolean,
    path: string,
    children: ReactElement<any, string | JSXElementConstructor<any>>
}

export default function FormPage({title, editable, path, children}:Params) {
    const navigate = useNavigate();
    const location = useLocation();
    const firebaseQuery = new FirebaseQuery();

    const [formData, setFormData] = useState<{[key: string]: unknown}>({});

    const {id} = useParams();

    const user = useContext(UserContext);

    useEffect(() => {
        // Can the user edit the page? If cannot manage, can only view, so form is disabled. Need to fine tune this with add, edit, delete. This will be the edit permission
        // Has the application passed formData into this? If so, setFormData with this data.
        if (location?.state?.formData) {
            setFormData(location.state.formData);
        } else {
            // If no formData, did we even mean to pass any? If no id, this is a new form with no data. Return
            if (!id) {
                return;
            }
            // Otherwise, if there is an ID but no form data (first page in session), then get the document and setFormData
            firebaseQuery.getDocData([path, id]).then((formData) => {
                setFormData(formData);
            });
        }
    }, []);

    const onSubmit = async (data: {[key:string]: unknown}) => {
        console.log("Submission", data);
        data.updated = (new Date()).toISOString();
        if (!id) {
            await firebaseQuery.add([path], {...data, oId: user.oId, product: user.product});
            navigateBack();
            return;
        }

        const diff:{[key:string]: unknown} = {};

        Object.entries(data).forEach(([key]) => {
            if (!formData[key] && !data[key]) {
                return;
            }

            if (formData[key] !== data[key]) {
                diff[key] = data[key];
            }
        });

        await firebaseQuery.update([path, id], diff);
        navigateBack();
    };

    const navigateBack = () => {
        console.log("window.history.state", window.history.state);
        if (window.history.state && window?.history?.state?.idx > 0) {
            navigate(-1);
        } else {
            navigate(`/${user.product}/home`, {replace: true}); // the current entry in the history stack will be replaced with the new one with { replace: true }
        }
    };

    if (Object.keys(formData).length === 0 && id) {
        return (
            <Preloader visible />
        );
    }

    return (
        <Page title={title} back={`/${user.product}/home`}>
            <Stack spacing={3} padding={"20px"} width={"100%"}>
                {React.cloneElement(children, {formData: formData, onSubmit: onSubmit, access: editable, id: id})}
            </Stack>
        </Page>
    );
}
