import Page from "../../Components/Page";
import Card from "../../Components/Card";
import {LoadingButton} from "../../Components/LoadingButton";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import InputGroup from "../../Components/FormComponents/InputGroup";
import Form from "../../Components/Form";
import {where} from "firebase/firestore";

export default function Home() {
    const firebaseQuery = new FirebaseQuery();

    async function fetchAboutUsItems() {
        // Instantiate your FirebaseQuery class with the desired collection

        // Use getDocsWhere method to fetch documents where "about" exists
        const result = await firebaseQuery.getDocsWhere(["emailTemplates"], where("public", "==", true));

        // Copy the result as a JSON string to the clipboard
        navigator.clipboard.writeText(JSON.stringify(result))
            .then(() => {
                console.log("Data copied to clipboard");
            })
            .catch((err) => {
                console.error("Failed to copy data to clipboard: ", err);
            });
    }

    const submitAbout = async (e: {about: {[key: string]: {[key: string]: unknown}}}) => {
        const aboutObj = Object.entries(e.about);

        aboutObj.forEach(([key, v]) => {
            firebaseQuery.set(["emailTemplates", key], v);
        });
    };

    return (
        <Page title="Home" grid>
            <Card grid>
                <LoadingButton text="Add active" onClick={fetchAboutUsItems}/>
                <Form onSubmit={(e) => submitAbout(e as {about: {[key: string]: {[key: string]: unknown}}})}>
                    <InputGroup name={"about"}/>
                </Form>
            </Card>
        </Page>
    );
}
