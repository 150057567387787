import PublicTemplate from "../../Components/Public/PublicTemplate";
import IconGridList from "../../Components/Public/IconGridList";
import {Analytics, CampaignRounded, Group, LocationCityRounded, Storage, TextSnippetRounded} from "@mui/icons-material";
import Page from "../../Components/Page";
import {Box, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography} from "@mui/material";
import imageOne from "../../Images/studentAppImages/1.png";
import imageTwo from "../../Images/studentAppImages/2.png";
import imageThree from "../../Images/studentAppImages/3.png";
import imageFour from "../../Images/studentAppImages/4.png";
import googlePlayButton from "../../Images/Google Play Button.png";
import appStoreButton from "../../Images/App Store Button.png";
import {RedOrangePinkGradientText} from "../../Util/visualUtil";
import {PRIMARY_COLOUR} from "placementt-core";
import {useCheckMobileScreen} from "../../Util/util";

export default function StudentInfo() {
    const mobile = useCheckMobileScreen();

    return (
        <Page
            metaTitle="Placementt | Student"
            metaDesc="For students, by students: Placementt - Student allows you to find and manage work experience that progresses your career."
        >
            <PublicTemplate>
                <Stack width={"100%"} mb={"-64px !important"}>
                    <Typography color={"primary"} fontWeight={"bold"}>Placementt for Students</Typography>
                    <Divider/>
                </Stack>
                <Stack spacing={5} borderBottom={"1px solid lightgrey"} pt={mobile ? 8 : 20} pb={10} textAlign={"center"} maxWidth={"1000px"} alignSelf={"center"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant="h2" fontWeight={500} maxWidth={"800px"}><RedOrangePinkGradientText>Take Control</RedOrangePinkGradientText> Of Your Placements</Typography>
                    <Typography variant="h5">Whether you are a secondary school or university student, we've got you covered. Your educator doesn't need to use us for you to gain the benefit. </Typography>
                </Stack>
                <Grid container>
                    <Grid item xs={12} md={3} sm={6}>
                        <Box component={"img"} borderRadius={4} width={"100%"} src={imageOne}/>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                        <Box component={"img"} borderRadius={4} width={"100%"} src={imageTwo}/>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                        <Box component={"img"} borderRadius={4} width={"100%"} src={imageThree}/>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                        <Box component={"img"} borderRadius={4} width={"100%"} src={imageFour}/>
                    </Grid>
                </Grid>
                <IconGridList
                    title="Take control of your career with a platform that helps shape your dream career."
                    titleColour={PRIMARY_COLOUR}
                    items={[
                        {
                            title: "Find placements across a UK-wide database",
                            icon: <LocationCityRounded/>,
                        },
                        {
                            title: "Build your skills and track your progress",
                            icon: <Analytics/>,
                        },
                        {
                            title: "Advertise yourself to employers",
                            icon: <CampaignRounded/>,
                        },
                        {
                            title: "Get improved support on your placements",
                            icon: <TextSnippetRounded/>,
                        },
                    ]}
                />
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Divider/>
                            <Typography variant="h4">Our Independent Placement App</Typography>
                            <Typography>Take control of your placements with our all-in-one app that helps school and university students take control of their placements.</Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon><Storage/></ListItemIcon>
                                    <ListItemText primary={"Log your work experience placements."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Group/></ListItemIcon>
                                    <ListItemText primary={"Keep track of work experience contacts."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Analytics/></ListItemIcon>
                                    <ListItemText primary={"View custom placement analytics based on your course."}/>
                                </ListItem>
                            </List>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} position={"relative"}>
                        <Grid display={"flex"} alignSelf={"center"} m={"auto !important"} flexDirection={"column"} alignItems={"center"} justifyContent={"space-around"}>
                            <Box style={{cursor: "pointer"}} onClick={() => window.open("https://apps.apple.com/gb/app/placementt/id6464076066", "blank")} width={"300px"} component={"img"} src={appStoreButton}/>
                            <Box style={{cursor: "pointer"}} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.placementt.app", "blank")} width={"300px"} component={"img"} src={googlePlayButton}/>
                        </Grid>
                    </Grid>
                </Grid>
            </PublicTemplate>
        </Page>
    );
}
