import {Grid, Typography, Collapse, Alert, Stack} from "@mui/material";
import {capitalise} from "placementt-core";
import {useState, useEffect, ChangeEvent} from "react";
import InputGroup from "./InputGroup";

type Params = {
    label?: string,
    required?: boolean,
    name: string,
    startLabel?: string,
    endLabel?: string,
    start?: string,
    end?: string,
    startMin?: string,
    startMax?: string,
    endMin?: string,
    endMax?: string,
    disabled?: boolean,
    sx?: {[key: string]: unknown},
    onChange?: (v: string, t: "start"|"end") => void
}

export default function DateRangePicker({label, required, name, startLabel="Start date", start, end, endLabel="End date", startMin, startMax, endMin, endMax, disabled=false, sx={}, onChange=() => null}:Params) {
    const [startDate, setStartDate] = useState(start);
    const [endDate, setEndDate] = useState(end);
    const [error, setError] = useState<string>();

    useEffect(() => {
        setStartDate(start);
    }, [start]);

    useEffect(() => {
        setEndDate(end);
    }, [end]);

    const setDateHandler = (value: string, type:"start"|"end") => {
        // Create jsons with state dates in and one for setters
        const sDates = {start: startDate, end: endDate};
        const setters = {start: setStartDate, end: setEndDate};
        // Replace whichever with the value given, no need for decision.
        sDates[type] = value;

        const start = new Date(sDates.start || "undefined");
        const end = new Date(sDates.end || "");

        // If the other value is undefined, just add the date.
        if (!sDates[type === "start" ? "end" : "start"]) {
            setters[type](value);
            onChange(value, type);
            return;
        }

        // Ensure end date is same or after start.
        if (start <= end) {
            setError(undefined);
            setters[type](value);
            onChange(value, type);
            return;
        }
        // If guard clause above not met, give error
        setError("Start date must be before end date.");
        setters[type](undefined);
    };


    return (
        <Stack spacing={2} {...sx}>
            <Typography variant={"subtitle1"}>{label}</Typography>
            <Grid item>
                <Grid container>
                    <Grid item sx={{marginTop: "-10px"}} xs={6}>
                        <InputGroup {...{disabled}} error={error} required={required || endDate} type='date' name={`start${capitalise(name)}`} min={startMin} max={startMax} value={startDate} onChange={(e:ChangeEvent<HTMLInputElement>) => setDateHandler(e.target.value, "start")} label={startLabel}/>
                    </Grid>
                    <Grid item sx={{marginTop: "-10px"}} xs={6}>
                        <InputGroup {...{disabled}} error={error} required={required || startDate} type='date' name={`end${capitalise(name)}`} min={endMin} max={endMax} value={endDate} onChange={(e:ChangeEvent<HTMLInputElement>) => setDateHandler(e.target.value, "end")} label={endLabel}/>
                    </Grid>
                </Grid>
            </Grid>
            <Collapse in={Boolean(error)}>
                <Alert severity='error'>{error}</Alert>
            </Collapse>
        </Stack>
    );
}
