import {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../../../App";
import FadeInBox from "../../../Components/FadeInBox";
import {arrayRemove, arrayUnion, where} from "firebase/firestore";
import FilterList from "../../../Components/FilterList";
import {Button, Checkbox, Chip, Divider, ListItem, ListItemText, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {camelCaseToNormal, capitalise, capitaliseWords, ERRORTEXTCOLOR, OrganisationAddress, useLoadAddresses, useLoadListings, UserData} from "placementt-core";
import {Popup} from "../../../Components/Popup";
import Page from "../../../Components/Page";
import Form from "../../../Components/Form";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import Alert from "../../../Components/Alert";


export default function RequestItemPage() {
    const user = useContext(UserContext);

    const selectAddressTab = user.viewAddresses === "request" && !user.visibleAddresses && !user.requestedVisibleAddresses;
    const selectListingsTab = user.viewPlacementListings === "request" && !user.visibleListings && !user.requestedVisibleListings;

    const [hasAddresses] = useState(selectAddressTab);
    const [hasListings] = useState(selectListingsTab);

    const [page, setPage] = useState(selectAddressTab ? 0 : 1);

    return (
        <Page>
            {hasAddresses && <AddressSelector request visible={page === 0} type="fade" onForward={() => setPage((p) => p+1)}/>}
            {hasListings && <ListingSelector request key={user.requestedVisibleAddresses?.join(",")} visible={page === 1} type="fade" onForward={() => setPage((p) => p+1)} onBack={hasAddresses ? () => setPage((p) => p-1) : undefined}/>}
            <FadeInBox card visible={page === 2}>
                <Typography>Get started</Typography>
            </FadeInBox>
        </Page>
    );
}

export function AddressSelector({type, selectedUserId, selectedUser, visible, onClose, onForward, request}:{selectedUserId?: string, selectedUser?: UserData, type:"popup"|"card"|"fade", visible?:boolean, onClose?: () => void, onForward?: () => void, onBack?: () => void, request?: boolean}) {
    const selectedUserData = selectedUser || useContext(UserContext);

    const firebaseQuery = new FirebaseQuery();
    const [accessType, setAccessType] = useState<"none"|"request"|"all"|"some"|undefined>(selectedUserData?.viewAddresses);

    const user = useContext(UserContext);

    const {addresses, onScrollBottom, loading} = useLoadAddresses(user, 10, undefined, request);

    const isSelectedUser = !selectedUser || (selectedUserId === user.id);

    const onSubmit = async (e: any) => {
        const addedItems = Object.entries(e).filter(([, v]) => v === true).map(([k]) => k);

        if (addedItems.length > 10) throw new Error("You can "+(isSelectedUser ? "request" : "select")+" a maximum of ten addresses. If you require more, consider "+(isSelectedUser ? "requesting" : "granting")+" permission to view all addresses, or contact us.");

        const removedItems = Object.entries(e).filter(([, v]) => v === false).map(([k]) => k);

        addedItems.length && await firebaseQuery.update(["users", selectedUserId || selectedUserData.id], {[selectedUserId ? "visibleAddresses" : "requestedVisibleAddresses"]: arrayUnion(...addedItems)} as Partial<UserData>);
        removedItems.length && await firebaseQuery.update(["users", selectedUserId || selectedUserData.id], {[selectedUserId ? "visibleAddresses" : "requestedVisibleAddresses"]: arrayRemove(...removedItems)} as Partial<UserData>);

        if (selectedUser?.viewAddresses !== accessType) {
            await firebaseQuery.update(["users", selectedUserId || selectedUserData.id], {viewAddresses: accessType});
        }
        onForward && onForward();
    };

    // Only select a maximum of ten addresses.

    const list = <FilterList onScrollBottom={onScrollBottom} loadMoreIcon={loading} card={type === "card"} title="All addresses" data={addresses} grid xs={12} md={8}>
        {Object.entries(addresses).map(([id, address]) =>
            <ListItem key={id} divider id={id}>
                <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                    <Checkbox name={id} defaultChecked={selectedUserData.visibleAddresses?.includes(id)}/>
                    <ListItemText
                        primary={<Stack spacing={0}>
                            <Typography fontSize={"1.2em"} pb={1}>{address.name}</Typography>
                            <Typography color={"grey"}>{address["address-line1"]} | {address.postal_code} | {capitaliseWords(camelCaseToNormal(address.country))}</Typography>
                            <Typography>Associated listings: {address?.listings || 0}</Typography>
                        </Stack>}/>
                    <Stack direction={"row"}>
                        {address.default && <Chip sx={{width: "max-content", alignSelf: "end"}} color="primary" label={"Default"}/>}
                    </Stack>
                </Stack>
            </ListItem>)}
    </FilterList>;


    if (type === "popup" && onClose) {
        return (
            <Popup onClose={onClose} title={`${(!selectedUserId || user.id === selectedUserId) ? "Request" : "Grant"} access to addresses`} open={Boolean(visible)}>
                <Typography>You can {isSelectedUser ? "request" : "select"} a maximum of ten addresses. If you require more, consider {isSelectedUser ? "requesting" : "granting"} permission to view all addresses, or contact us.</Typography>
                <Divider sx={{marginTop: 3}}/>
                {selectedUser && user.id !== selectedUserId && selectedUser?.userGroup !== "admin" &&
                    <>
                        <Stack direction={"row"} mt={2} mb={2} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography>Select access to addresses</Typography>
                            <ToggleButtonGroup
                                color="primary"
                                value={accessType}
                                exclusive
                                onChange={(e, n) => n && setAccessType(n)}>
                                <ToggleButton value={"all"}>All</ToggleButton>
                                <ToggleButton value={"request"}>Select</ToggleButton>
                                <ToggleButton value={"none"}>None</ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                        <Divider/>
                    </>}
                <Form onSubmit={onSubmit}>
                    {accessType === "request" && list}
                </Form>
            </Popup>
        );
    }

    if (type === "fade") {
        return (
            <FadeInBox cardTitle="Select addresses" card visible={Boolean(visible)}>
                <Typography>Select all addresses you are associated with. These will be sent to your admin for review. Once accepted, you can view placements and listings held at those addresses.</Typography>
                <Form onSubmit={onSubmit}>
                    {list}
                </Form>
            </FadeInBox>
        );
    }

    return list;
}


export function ListingSelector({type, visible, onClose, selectedUserId, selectedUser, onForward, onBack, request}:{selectedUserId?: string, selectedUser?: UserData, type:"popup"|"card"|"fade", visible?:boolean, onClose?: () => void, onForward?: () => void, onBack?: () => void, request?: boolean}) {
    const selectedUserData = selectedUser || useContext(UserContext);
    const firebaseQuery = new FirebaseQuery();
    const [accessType, setAccessType] = useState<"none"|"request"|"all"|undefined>(selectedUserData?.viewPlacementListings);

    const [addressSelectorPopup, setAddressSelectorPopup] = useState(false);


    const user = useContext(UserContext);
    const org = useContext(OrganisationContext);
    const addresses = org?.addresses as {[key: string]: OrganisationAddress};
    const isSelectedUser = !selectedUser || (selectedUserId === user.id);

    const constraints = [where("providerId", "==", selectedUserData.oId)];

    const {listings, onScrollBottom, loading, changeQueryConstraints} = useLoadListings(user, constraints, request);

    useEffect(() => {
        changeQueryConstraints(constraints);
    }, [selectedUserData]);

    const onSubmit = async (e: any) => {
        const addedItems = Object.entries(e).filter(([, v]) => v === true).map(([k]) => k);

        if (addedItems.length > 10) throw new Error("You can "+(isSelectedUser ? "request" : "select")+" a maximum of ten listings. If you require more, consider "+(isSelectedUser ? "requesting" : "granting")+" permission to view all listings and limiting access to addresses, or contact us.");

        const removedItems = Object.entries(e).filter(([, v]) => v === false).map(([k]) => k);

        console.log("added items", addedItems);

        await firebaseQuery.update(["users", selectedUserId || selectedUserData.id], {[selectedUserId ? "visibleListings" : "requestedVisibleListings"]: arrayUnion(...addedItems)} as Partial<UserData>);
        await firebaseQuery.update(["users", selectedUserId || selectedUserData.id], {[selectedUserId ? "visibleListings" : "requestedVisibleListings"]: arrayRemove(...removedItems)} as Partial<UserData>);

        if (selectedUser?.viewPlacementListings !== accessType) {
            await firebaseQuery.update(["users", selectedUserId || selectedUserData.id], {viewPlacementListings: accessType});
        }

        onForward && onForward();
    };

    const list = <FilterList onScrollBottom={onScrollBottom} loadMoreIcon={loading} card={type === "card"} title="Listings" data={Object.fromEntries(listings)} grid xs={12} md={8}>
        {listings.map(([id, listing]) => {
            const address = addresses?.[listing.addressId || ""];

            return (
                <ListItem key={id} divider id={id}>
                    <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                        <Checkbox name={id} defaultChecked={selectedUserData.visibleListings?.includes(id)} disabled={selectedUser?.viewAddresses === "request" && !selectedUser?.visibleAddresses?.includes(listing.addressId as string)}/>
                        <ListItemText primary={<Typography><strong style={{fontSize: "1.1em"}}>{listing.title || <span style={{opacity: 0.7}}>No title</span>}</strong><span style={{opacity: 0.75}}> - {address ? `${address?.["address-line1"]} | ${address?.postal_code.toUpperCase()} | ${capitaliseWords(camelCaseToNormal(address?.country))}` : <i style={{color: ERRORTEXTCOLOR}}><strong>Missing address</strong></i>}</span></Typography>}/>
                        <Stack spacing={1} alignItems={"end"}>
                            <Chip color={listing.status === "listed" ? "primary" : undefined} variant={listing.status === "listed" ? "filled" : undefined} label={capitalise(listing.status)}/>
                            {selectedUser?.viewAddresses === "request" && !selectedUser?.visibleAddresses?.includes(listing.addressId as string) && <Alert action={<Button onClick={() => setAddressSelectorPopup(true)}>Grant access</Button>} severity="error">User does not have access to associated address. Grant access to the address first.</Alert>}
                        </Stack>
                    </Stack>
                </ListItem>);
        })}
    </FilterList>;

    if (type === "popup" && onClose) {
        return (
            <Popup onClose={onClose} title={`${isSelectedUser ? "Request" : "Grant"} access to listings`} open={Boolean(visible)}>
                <Typography>You can {isSelectedUser ? "request" : "select"} a maximum of ten listings. If you require more, consider {isSelectedUser ? "requesting" : "granting"} permission to view all addresses or all listings, or contact us.</Typography>
                <Divider sx={{marginTop: 3}}/>
                {!isSelectedUser &&
                    <>
                        <Stack direction={"row"} mt={2} mb={2} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography>Select access to listings</Typography>
                            <ToggleButtonGroup
                                color="primary"
                                value={accessType}
                                exclusive
                                onChange={(e, n) => n && setAccessType(n)}>
                                <ToggleButton value={"all"}>All</ToggleButton>
                                <ToggleButton value={"request"}>Select</ToggleButton>
                                <ToggleButton value={"none"}>None</ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                        <Divider/>
                    </>}
                <Form onSubmit={onSubmit}>
                    {selectedUserData.viewAddresses === "none" && <Alert severity="error">This user has no addresses visible to them. To grant access to listings, you must give access to their associated addresses.</Alert>}
                    {selectedUserData.viewAddresses !== "none" && accessType === "request" && list}
                </Form>
                <AddressSelector {...{selectedUser, selectedUserId, type}} visible={addressSelectorPopup} onClose={() => setAddressSelectorPopup(false)}/>
            </Popup>
        );
    }

    if (type === "fade") {
        return (
            <FadeInBox cardTitle="Select placements" card visible={Boolean(visible)}>
                <Typography>Select all placements you are associated with. These will be sent to your admin for review. Once accepted, you can view placements and applications associated with the listings.</Typography>
                <Form onSubmit={onSubmit} onBackPress={onBack}>
                    {list}
                </Form>
            </FadeInBox>
        );
    }

    return list;
}
