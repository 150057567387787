import {Upload} from "@mui/icons-material";
import {Alert, Button, Collapse, Stack, Typography} from "@mui/material";
import {ChangeEvent, useEffect, useState} from "react";
import {FileDropzoneContainer, FileDropzoneInput} from "../../Util/styledComponents";

type Params = {
    accept?: string,
    onSubmit?: (e: File[]) => void,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    label?: string,
    required?: boolean,
    multiple?: boolean,
    name?: string,
    defUploadText?: string,
}

export default function FileDropzone({accept, onSubmit, onChange, label, required=true, multiple, name, defUploadText=""}: Params) {
    const [uploadText, setUploadText] = useState(label || "Upload a file");
    const [files, setFiles] = useState<File[]>([]);
    const [fileTypeError, setFileTypeError] = useState(false);

    useEffect(() => {
        if (defUploadText) {
            setUploadText(defUploadText);
        }
        if (label) {
            setUploadText(label);
        }
    }, [defUploadText, label]);

    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
        setFileTypeError(false);


        if (!e.target.files || e.target.files.length === 0) {
            setUploadText((label || "Upload a file"));
            return;
        }

        let fFileTypeError = false;
        const nameList = (Array.from(e.target.files).reduce((acc, val) => {
            if (accept && !accept.split(", ").includes(val.type)) {
                fFileTypeError = true;
            }

            if (!acc.includes(val.name)) {
                acc.push(val.name);
            }
            return acc;
        }, [] as Array<string>)).join(", ");

        if (fFileTypeError) {
            setUploadText((label || "Upload a file"));
            setFiles([]);
            setFileTypeError(true);
            return;
        }

        setUploadText(nameList);
        setFiles(Array.from(e.target.files));
        (onChange !== undefined) && onChange(e);
    };

    return (
        <Stack alignItems='center' spacing={1}>
            <FileDropzoneContainer>
                <FileDropzoneInput type='file' required={required ? uploadText===defUploadText ? false : true : false} name={name} accept={accept} multiple={multiple} onChange={handleUpload}/>
                <input name={""} hidden value={files.length > 0 ? "1" : undefined} required={required ? uploadText===defUploadText ? false : true : false}/>
                <Stack alignItems='center' spacing={0}>
                    {uploadText === "Upload a file" && <Upload/>}
                    <Typography variant='body1' textAlign={"center"}>{uploadText}</Typography>
                    <Typography color={"#bbb"}>{uploadText === label && accept && `(${accept.split(", ").map((i) => "."+i.split("/")[1]).join(", ")})`}</Typography>
                </Stack>
            </FileDropzoneContainer>
            {onSubmit && <Button disabled={required ? files.length > 0 ? false : true : false} onClick={() => onSubmit(files)}>Upload</Button>}
            <Collapse in={fileTypeError}>
                <Alert severity='error'>{accept} required.</Alert>
            </Collapse>
        </Stack>
    );
}
