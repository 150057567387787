import {Box, Button, Grid, Stack, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme} from "@mui/material";
import {ReactNode, createRef, useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import Card from "./Card";
import Form, {FormButton, FormButtonGroup} from "./Form";
import {useNavigate} from "react-router-dom";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {UserContext} from "../App";
import {editNestedObject} from "placementt-core";
import {DocumentData} from "firebase/firestore";
import IconButtonPop from "./IconButtonPop";
import {Delete} from "@mui/icons-material";
import {Popup} from "./Popup";
import {LoadingButton} from "./LoadingButton";

type Params = {
    items: {
        label: string,
        element: ReactNode
    }[],
    onChangeStep?: (e?: "back"|"forward", dataToAdd?: {[key: string]: unknown}, stepIdx?: number) => void
    activeStep: number
}

const FadeBox = styled(Box)<{$visible: "pre"|true|"post", $margin?: string, $maxHeight?: number}>`
    pointer-events: ${(props) => props.$visible === true ? "all" : "none"};
    opacity: ${(props) => props.$visible === true ? 1 : 0};
    transition: top 500ms ease-in-out, opacity 300ms ease-in-out;
    width: 500px;
    display: flex;
    max-width: 95%;
    position: absolute;
    max-height: ${(props) => props.$maxHeight ? `${props.$maxHeight - 50}px` : undefined};
    left: calc(50% + 8px);
    top: ${(props) => props.$visible === true ? "30px" : props.$visible === "pre" ? "calc(500px)" : "calc(-70px)"};
    transform: translateX(-50%);
`;

const BottomFade = styled(Box)`
    position: absolute;
    bottom: 40px;
    display: block;

    width: 100%;
    height: 50px;
  
    background-image: linear-gradient(to bottom, 
        rgba(255, 255, 255, 0), 
        rgba(255, 255, 255, 1)
    100%);
`;
const LeftFade = styled(Box)`
    position: absolute;
    left: 15px;
    display: block;
    height: 100%;
    width: 50px;
  
    background-image: linear-gradient(to left, 
        rgba(255, 255, 255, 0), 
        rgba(255, 255, 255, 1)
    100%);
`;

const RightFade = styled(Box)`
    position: absolute;
    right: 0px;
    display: block;
    height: 100%;
    width: 50px;
  
    background-image: linear-gradient(to right, 
        rgba(255, 255, 255, 0), 
        rgba(255, 255, 255, 1)
    100%);
`;


export default function VerticalPaginator({items, activeStep, onChangeStep}:Params) {
    const scrollRefs = useRef<{[key:string]: React.MutableRefObject<any>}>({});
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerHeight, setContainerHeight] = useState<number>();

    const theme = useTheme();
    const aboveMdBreakpoint = useMediaQuery(theme.breakpoints.up("md"));

    useEffect(() => {
        if (!scrollRefs.current) return;

        if (!scrollRefs.current[activeStep]) return;
        scrollRefs.current[activeStep].current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
        });
    }, [activeStep]);

    useEffect(() => {
        if (!containerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            setContainerHeight(containerRef.current?.getBoundingClientRect().height);
        });
        resizeObserver.observe(containerRef.current);
        return () => resizeObserver.disconnect();
    }, []);

    return (
        <Grid container direction={aboveMdBreakpoint ? undefined : "column"} overflow={"hidden"} flex={1} height={"100%"}>
            <Grid item xs={12} md={4} display={"flex"} p={aboveMdBreakpoint ? 5 : 0} alignItems={"center"} flexDirection={"column"} width={0} minWidth={aboveMdBreakpoint ? undefined : "100%"} sx={{overflowX: "clip"}} position={"relative"} flex={aboveMdBreakpoint ? undefined : "0 !important"} height={aboveMdBreakpoint ? "100%" : "min-content"}>
                {!aboveMdBreakpoint && activeStep > 1 && <LeftFade/>}

                <Stepper activeStep={activeStep} orientation={aboveMdBreakpoint ? "vertical" : "horizontal"}
                    sx={aboveMdBreakpoint ? {
                        "& .MuiStepConnector-line": {
                            minHeight: "5vh",
                        },
                        "overflow": "hidden",
                        "paddingBottom": "40px",
                    }: {
                        "width": "100%",
                        "overflow": "hidden",
                    }}>
                    {items.map((item, index) => {
                        scrollRefs.current = Object.fromEntries(items.map(
                            (_, i) => [i, scrollRefs.current[i] ?? createRef()]
                        ));
                        return (<Step ref={scrollRefs?.current[index]} key={index} onClick = {() => onChangeStep && index < activeStep && onChangeStep(undefined, undefined, index)} style = {{cursor: "pointer"}}>
                            <StepLabel>{item.label}</StepLabel>
                        </Step>);
                    })}
                </Stepper>
                {aboveMdBreakpoint ? <BottomFade/> : <RightFade/>}
            </Grid>
            <Grid ref={containerRef} overflow={"hidden"} item xs={12} md={8} position={"relative"} flex={"1 !important"} width={0} minWidth={aboveMdBreakpoint ? undefined : "100%"}>
                {items.map((item, index) => <FadeBox $maxHeight={containerHeight} $visible={activeStep === index ? true : activeStep < index ? "pre" : "post"}>
                    {item.element}
                </FadeBox>)}
            </Grid>
        </Grid>
    );
}

type VerticalPaginatorFormItems = {
    title: string,
    description?: string|ReactNode,
    fields: ReactNode,
    onChangeStep?: () => Promise<void>
}[];

type VerticalPaginatorFormParams = {
    items: VerticalPaginatorFormItems,
    saveFirstItem?: boolean,
    saveText?: string,
    completeText?: string,
    step?: number,
    onChangeStep: (e?: "back"|"forward", dataToAdd?: {[key: string]: unknown}, stepIdx?: number) => void
    onComplete?: () => void|{[key: string]: unknown},
    deleteText?: string,
    onDelete?: () => Promise<boolean>
}

export function VerticalPaginatorForm({items, onDelete, deleteText, saveFirstItem, saveText="Save and Exit", completeText="Finish", step, onChangeStep, onComplete}: VerticalPaginatorFormParams) {
    const [deletePopupActive, setDeletePopupActive] = useState(false);
    const [itemDeleted, setItemDeleted] = useState(false);

    const navigate = useNavigate();

    const buildPaginatorItems = () => {
        return items.map((item, i) => ({
            label: item.title,
            element:
            <Card title={item.title} secondaryTitle={<IconButtonPop title="Delete" onClick={() => setDeletePopupActive(true)} responsive={false}><Delete/></IconButtonPop>} sx={{width: "100%"}}>
                <Form button={false} functionType="sync" key={`form-${i}`}>
                    {typeof item.description === "string" ? <Typography>{item.description}</Typography> : item.description}
                    {item.fields}
                    <Stack direction={"row"} justifyContent={"end"} alignItems={"center"} pt={3}>
                        {i > 0 && <Button onClick={async () => {
                            const dataToAdd = item.onChangeStep && await item.onChangeStep();
                            onChangeStep("back", dataToAdd || undefined);
                        }}>Back</Button>}
                        <FormButtonGroup>
                            {(i > 0 || saveFirstItem) ? <FormButton onClick={async () => {
                                const dataToAdd = item.onChangeStep && await item.onChangeStep();
                                console.log("DATA TO ADD", dataToAdd);
                                onChangeStep(undefined, dataToAdd || undefined);
                            }} text={saveText}/> : null}
                            {(i === items.length - 1 && !onComplete) || <FormButton onClick={async () => {
                                const dataToAdd = item.onChangeStep && await item.onChangeStep();
                                i === items.length - 1 ? onComplete && onComplete() : onChangeStep("forward", dataToAdd || undefined);
                            }} variant="contained" text={i === items.length - 1 ? completeText : "Continue"}/>}
                        </FormButtonGroup>
                    </Stack>
                </Form>
            </Card>})
        );
    };

    if (step === undefined) return <Box>No step</Box>;

    return (
        <>
            <VerticalPaginator activeStep={step} items={buildPaginatorItems()} onChangeStep={onChangeStep}/>
            {onDelete && <Popup title="Delete item" open={deletePopupActive} onClose={() => setDeletePopupActive(false)}>
                <Typography>Are you sure you want to delete this item?</Typography>
                {deleteText && <Typography>{deleteText}</Typography>}
                <LoadingButton text="Delete item" onClick={() => onDelete().then((res) => {
                    if (!res) throw new Error("Error in deletion");
                    setItemDeleted(true);
                    setDeletePopupActive(false);
                    setTimeout(() => {
                        navigate(-1);
                    }, 1000);
                })}/>
            </Popup>}
            <Popup title={"Item deleted"} open={itemDeleted} onClose={() => null}>
                <Typography>You can now leave this page.</Typography>
            </Popup>
        </>
    );
}

type VerticalPlacementFormHookParams = {
    stages: string[],
    initialData?: {[key: string]: unknown},
    id?: string,
    path?: string[]|string,
    dbUploadData?: {[key: string]: unknown},
    onComplete?: (e: {
        id?: string,
        item?: {[key: string]: unknown}
    }) => void,
    additionalData?: (e: {[key: string]: unknown}) => Promise<DocumentData>,
}

export function useVerticalPlacementForm({stages, initialData, id, path, dbUploadData, onComplete, additionalData}: VerticalPlacementFormHookParams) {
    const [step, setStep] = useState<number>();
    const [itemId, setItemId] = useState(id);
    const [item, setItem] = useState(initialData);

    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();
    const user = useContext(UserContext);

    useEffect(() => {
        setItem(initialData);
    }, [initialData]);

    useEffect(() => {
        if (!id) {
            setStep(0);
            return;
        }
        setItemId(id);
        if (!path) return;
        firebaseQuery.getDocData([...(typeof path === "string" ? path.split("/") : path), id]).then(async (doc) => {
            const document = additionalData ? await additionalData(doc) : doc;
            setItem(document);
            console.log("docstage", document.stage);
            const listingPos = document.stage === "complete" ? stages.length-2 : stages.indexOf(document?.stage);
            console.log("Listingpos", listingPos);
            setStep(listingPos >= 0 ? listingPos : 0);
        });
    }, [id]);


    async function changeStep(direction?: "back"|"forward", dataToAdd?: {[key: string]: unknown}, stepIdx?: number) {
        if (stepIdx !== undefined) {
            setStep(stepIdx);
            return;
        }
        const newItemStage = direction ? stages[direction === "forward" ? (step || 0) + 1 : !step ? 0 : step - 1] : stages[(step || 0)];
        console.log("stage: "+ newItemStage);

        if (path) {
            if (!itemId) {
                console.log("Uploading", {...item, ...dbUploadData, product: user.product, oId: user.oId, created: (new Date()).toISOString(), stage: newItemStage, ...dataToAdd});
                const newId = (await firebaseQuery.add([...(typeof path === "string" ? path.split("/") : path)], {...item, ...dbUploadData, product: user.product, oId: user.oId, created: (new Date()).toISOString(), stage: newItemStage, ...dataToAdd})).id;
                console.log("ITEM ADDED", newId);
                setItemId(newId);
            } else {
                console.log("DATA TO UPDATE", dataToAdd);
                await firebaseQuery.update([...(typeof path === "string" ? path.split("/") : path), itemId], {...item, stage: newItemStage, ...dataToAdd});
            }
        }
        setItem((l) => ({...l, stage: newItemStage}));

        if (!direction) {
            navigate(-1);
        }
        setStep((a) => direction === "forward" ? (a || 0)+1 : (a || 0)-1);
    }

    function exitBuilder() {
        onComplete && onComplete({id: itemId, item: item});
    }

    function updateItem(path: string|string[], value: unknown) {
        setItem((i) => editNestedObject((typeof path === "string" ? [path] : path) as string[], i || {}, value) as {[key: string]: unknown});
    }

    return {...{step, setStep, changeStep, exitBuilder, updateItem, item, setItem, itemId}};
}
