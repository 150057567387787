import Page from "../../Components/Page";
import SubNavBar from "../../Components/Navigation/SubNavBar";
import FilterList from "../../Components/FilterList";
import {useContext, useState} from "react";
import {StudentContext} from "./Students";
import {Button, Divider, ListItem, Stack, Typography} from "@mui/material";
import Card from "../../Components/Card";
import {Popup} from "../../Components/Popup";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {Contact, camelCaseToNormal, capitaliseWords} from "placementt-core";
import {UserContext} from "../../App";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";
import Dropdown from "../../Components/FormComponents/Dropdown";
import DeletePopup from "../../Components/DeletePopup";
import IconButtonPop from "../../Components/IconButtonPop";
import {Delete, Edit} from "@mui/icons-material";
import StudentPlacementList from "../../Components/StudentPlacementList";


export default function Placements() {
    return (
        <Page noGutters title="Placements" metaTitle="Placementt | Placements">
            <SubNavBar items={[
                {
                    label: "All placements",
                    route: "placements",
                    element: <Page><StudentPlacementList
                        card={false}
                        sx={{minHeight: "450px", maxHeight: "450px"}}
                        title={""}/></Page>,
                },
                {
                    label: "Contacts",
                    route: "contacts",
                    element: <Contacts/>,
                },
            ]}/>
        </Page>
    );
}

function Contacts() {
    const [addContactPopup, setAddContactPopup] = useState<boolean|string>(false);
    const contacts = useContext(StudentContext).contacts;
    const [deleting, setDeleting] = useState<boolean|string>(false);

    const user = useContext(UserContext);

    const firebaseQuery = new FirebaseQuery();

    const addContact = async (contactForm: Contact) => {
        if (typeof addContactPopup === "string") {
            await firebaseQuery.update(["contacts", addContactPopup], contactForm);
        } else {
            await firebaseQuery.add(["contacts"], {...contactForm, uid: user?.id});
        }
        setAddContactPopup(false);
    };

    const editedContact = contacts?.[typeof addContactPopup === "string" ? addContactPopup : ""];

    return (
        <Page>
            <FilterList title={<Typography variant="h5">All contacts</Typography>} card={false} actionButton={<Button onClick={() => setAddContactPopup(true)}>New contact</Button>} data={contacts}>
                {Object.entries(contacts || {}).map(([id, contact]) =>
                    <ListItem id={id}>
                        <Card sx={{width: "100%"}}>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <Stack spacing={0}>
                                    <Typography variant="h6">{contact.name}</Typography>
                                    <Typography>{contact.contactForename} {contact.contactSurname} | {contact.providerEmail}</Typography>
                                    <Typography color={"grey"}>{contact["address-line1"]} | {contact.postal_code} | {capitaliseWords(camelCaseToNormal(contact.country))}</Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <IconButtonPop responsive={false} title="Edit" onClick={() => setAddContactPopup(id)}><Edit/></IconButtonPop>
                                    <IconButtonPop responsive={false} title="Delete" onClick={() => setDeleting(id)}><Delete/></IconButtonPop>
                                </Stack>
                            </Stack>
                        </Card>
                    </ListItem>
                )}
            </FilterList>
            <Popup title={`${typeof addContactPopup === "string" ? "Edit" : "Add"} contact`} open={Boolean(addContactPopup)} onClose={() => setAddContactPopup(false)}>
                <Form onSubmit={(e) => addContact(e as Contact)} submitText={typeof addContactPopup === "string" ? "Update" : "Create"}>
                    <Typography variant="h6">Basic details</Typography>
                    <InputGroup name='name' label='Business name' value={editedContact?.name}/>
                    <InputGroup name='providerEmail' type='email' label='Email' value={editedContact?.providerEmail}/>
                    <Stack direction={"row"}>
                        <InputGroup name='contactForename' label='Contact forename' containerStyle={{flex: 1, marginRight: 5}} value={editedContact?.contactForename}/>
                        <InputGroup name='contactSurname' label='Contact surname' containerStyle={{flex: 1, marginLeft: 5}} value={editedContact?.contactSurname}/>
                    </Stack>
                    <InputGroup name='providerPhone' type='tel' label='Phone' value={(editedContact?.providerPhone || "").toString()} maxLength = {11}/>
                    <Divider/>
                    <Typography variant="h6">Address</Typography>
                    <InputGroup label="Address line one" name="address-line1" value={editedContact?.["address-line1"]}/>
                    <InputGroup label="Address line two" name="address-line2" value={editedContact?.["address-line2"]}/>
                    <Stack direction={"row"}>
                        <InputGroup label="City" name="locality" value={editedContact?.locality} containerStyle={{flex: 1, marginRight: 5}}/>
                        <InputGroup label="Postcode" name="postal_code" value={editedContact?.postal_code} containerStyle={{flex: 1, marginLeft: 5}}/>
                    </Stack>
                    <Dropdown label="Country" name="country" value={editedContact?.country || "unitedKingdom"}/>
                </Form>
            </Popup>
            <DeletePopup
                open={Boolean(deleting)}
                onClose={() => setDeleting(false)}
                onDelete={async () => {
                    if (typeof deleting !== "string") return;
                    await firebaseQuery.delete(["contacts", deleting]);
                    setDeleting(false);
                    return;
                }}
                title="Delete contact"
                itemName={`${contacts?.[typeof deleting === "string" ? deleting : ""]?.contactForename} ${contacts?.[typeof deleting === "string" ? deleting : ""]?.contactSurname} | ${contacts?.[typeof deleting === "string" ? deleting : ""]?.providerEmail}`}/>
        </Page>
    );
}
