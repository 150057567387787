import {Box, Button, CardContent, Divider, Grid, Stack, TableCell, Typography} from "@mui/material";
import {ReactNode, useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../../App";
import IconButtonPop from "../../Components/IconButtonPop";
import {ArrowForward, CameraAlt, RemoveRedEye, Upload} from "@mui/icons-material";
import {OrganisationAddress, ProviderData, StudentPlacementData, UserData, capitaliseWords, convertDate, storage, uploadFiles} from "placementt-core";
import DeletePopup from "../../Components/DeletePopup";
import {useNavigate} from "react-router-dom";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import Page from "../../Components/Page";
import OnboardingChecklist from "../../Components/OnboardingChecklist";
import Card from "../../Components/Card";
import FileUploaderPopup from "../../Components/FileUploaderPopup";
import {getDownloadURL, ref} from "firebase/storage";
import CircularProgressbar from "../../Components/CircularProgressbar";
import PlacementCalendar from "../../Components/PlacementCalendar";
import Tasks from "../../Components/Tasks";
import {where} from "firebase/firestore";
import Alert from "../../Components/Alert";
import UploadProviderInsurance from "../Public/ProviderDetails/UploadProviderInsurance";
import {Popup} from "../../Components/Popup";
import {LoadingButton} from "../../Components/LoadingButton";
import {PublicProfileBannerAndProfileImage} from "../Public/ProviderPage";

export default function Home() {
    const [deletePopup, setDeletePopup] = useState<{id: string, name: string, type:"address"|"placement"}>();
    const [openApplications, setOpenApplications] = useState<number>();
    const [scheduledPlacements, setScheduledPlacements] = useState<number>();
    const [activePlacements, setActivePlacements] = useState<number>();
    const [staffCount, setStaffCount] = useState<number>();
    const [staffRequestCount, setStaffRequestCount] = useState<number>();
    const [listingCount, setListingCount] = useState<number>();

    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();

    const user = useContext(UserContext);
    const provider = useContext(OrganisationContext).details as ProviderData;
    const addressCount = Object.keys(useContext(OrganisationContext).addresses || {})?.length;

    useEffect(() => {
        firebaseQuery.getCount("applications", [where("providerId", "==", user.oId), where("status", "==", "submitted")]).then(setOpenApplications);
        firebaseQuery.getCount("placements", [where("providerId", "==", user.oId), where("inProgress", "==", true)]).then(setScheduledPlacements);
        firebaseQuery.getCount("placements", [where("providerId", "==", user.oId), where("active", "==", true)]).then(setActivePlacements);
        firebaseQuery.getCount("placementListings", [where("providerId", "==", user.oId)]).then(setListingCount);
        firebaseQuery.getCount("requests", [where("product", "==", user.product), where("oId", "==", user.oId)]).then(setStaffRequestCount);
        firebaseQuery.getCount("users", [where("product", "==", user.product), where("oId", "==", user.oId)]).then((n) => setStaffCount(n-1));
    }, []);

    const placements = useContext(OrganisationContext).placements as {[key: string]: StudentPlacementData};

    const deleteItem = async () => {
        if (!deletePopup) return;
        const path = deletePopup?.type === "address" ? ["addresses", deletePopup?.id] : ["placementListings", deletePopup?.id];
        await firebaseQuery.delete(path);
        setDeletePopup(undefined);
    };

    return (
        <Page title={`Welcome back, ${user.details.forename}!`} metaTitle="Placementt | Home" grid fullHeight>
            <ProfileCard/>
            {provider.mapConsent ?
                <Card title="Overview" grid xs={12} lg={7} md={6}>
                    <Stack direction={"row"} justifyContent={"space-around"} alignItems={"center"}>
                        <CircularProgressbar text={openApplications?.toString()} label={[<Typography textAlign={"center"}>Open applications</Typography>, <IconButtonPop sx={{display: "inline"}} responsive={false} title="Go to open applications" onClick={() => navigate("/providers/placementListings/applicants")}><ArrowForward/></IconButtonPop>]}/>
                        <CircularProgressbar text={scheduledPlacements?.toString()} label={[<Typography textAlign={"center"}>Scheduled placements</Typography>, <IconButtonPop sx={{display: "inline"}} responsive={false} title="Go to scheduled placements" onClick={() => navigate("/providers/placementListings/students")}><ArrowForward/></IconButtonPop>]}/>
                        <CircularProgressbar text={activePlacements?.toString()} label={[<Typography textAlign={"center"}>Active placements</Typography>, <IconButtonPop sx={{display: "inline"}} responsive={false} title="Go to active placements" onClick={() => navigate("/providers/placementListings/students")}><ArrowForward/></IconButtonPop>]}/>
                    </Stack>
                    <Grid container>
                        <Grid item xs={12} md={(staffRequestCount && staffRequestCount > 0) ? 3 : 4}>
                            <TableCell>Listings</TableCell>
                            <TableCell>{listingCount}</TableCell>
                            <TableCell><IconButtonPop responsive={false} onClick={() => navigate("/providers/placementListings/listings/listings")} title="View listings"><ArrowForward/></IconButtonPop></TableCell>
                        </Grid>
                        <Grid item xs={12} md={(staffRequestCount && staffRequestCount > 0) ? 3 : 4}>
                            <TableCell>Addresses</TableCell>
                            <TableCell>{addressCount}</TableCell>
                            <TableCell><IconButtonPop responsive={false} onClick={() => navigate("/providers/organisation/addresses")} title="View addresses"><ArrowForward/></IconButtonPop></TableCell>                        </Grid>
                        <Grid item xs={12} md={(staffRequestCount && staffRequestCount > 0) ? 3 : 4}>
                            <TableCell>Staff</TableCell>
                            <TableCell>{staffCount}</TableCell>
                            <TableCell><IconButtonPop responsive={false} onClick={() => navigate("/providers/organisation/staff/all")} title="View staff"><ArrowForward/></IconButtonPop></TableCell>
                        </Grid>
                        {Boolean(staffRequestCount && staffRequestCount > 0) && <Grid item xs={12} md={3}>
                            <TableCell>Staff requests</TableCell>
                            <TableCell>{staffRequestCount}</TableCell>
                            <TableCell><IconButtonPop responsive={false} title="Go to listings"><ArrowForward/></IconButtonPop></TableCell>
                        </Grid>}
                    </Grid>
                </Card> : <OnboardingChecklist/>}
            <Tasks grid xs={12} md={6}/>
            <Card grid xs={12} md={6} title={"Calendar"}>
                <PlacementCalendar placements={placements}/>
            </Card>
            <DeletePopup id={JSON.stringify(deletePopup)} title={deletePopup?.type || ""} itemName={deletePopup?.name} open={Boolean(deletePopup)} onClose={() => setDeletePopup(undefined)} onDelete={deleteItem}/>
        </Page>
    );
}

export function useInsuranceUploadPopupBanner() {
    const [providerInsurancePopup, setProviderInsurancePopup] = useState(false);
    const user = useContext(UserContext);

    const eliUploadPopup = <UploadProviderInsurance providerId={user.oId} visible={providerInsurancePopup} back={() => setProviderInsurancePopup(false)} type="popup"/>;

    return {eliUploadPopup, setProviderInsurancePopup, providerInsurancePopup};
}


export function usePublishProviderPopup({provider}:{provider?:ProviderData|false}) {
    const [activateProfilePopup, setActivateProfilePopup] = useState(false);
    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);

    const listProvider = async (type: true|"unlisted"|false) => {
        // Check has listings

        // Check has insurance

        // Check has description and images
        firebaseQuery.update(["providers", user.oId], {mapConsent: type});
    };

    const publishOrganisationPopup = provider ? <Popup id="activateProfilePopup" title={"Make your placements public"} open={activateProfilePopup} onClose={() => setActivateProfilePopup(false)}>
        <Stack>
            <Typography>Would you like to make your profile public?</Typography>
            <Typography>You can release this only as a private page to advertise yourself, or list on our public database (currently in development)</Typography>
            {provider.insurance !== true ? <Alert severity="info">Your organisation can only be made public once you have uploaded your employers liability insurance. Once uploaded, come back here to publish your account.</Alert> :<LoadingButton>
                <Button variant="contained" onClick={() => listProvider(true)}>Release on public map</Button>
                <Button onClick={() => listProvider("unlisted")}>Release privately</Button>
                {provider.mapConsent && <Button color="error" onClick={() => listProvider(false)}>Make private</Button>}
            </LoadingButton>}
        </Stack>

    </Popup> : undefined;

    return {publishOrganisationPopup, activateProfilePopup, setActivateProfilePopup};
}


function ProfileCard() {
    const provider = useContext(OrganisationContext);
    const navigate = useNavigate();
    const user = useContext(UserContext);

    const providerDetails = provider.details as ProviderData&{adminData: UserData};
    const {eliUploadPopup, setProviderInsurancePopup} = useInsuranceUploadPopupBanner();

    useEffect(() => {
        if (location.hash.includes("insurance")) {
            setProviderInsurancePopup(true);
        }
    }, [location.hash]);

    const defAddress = Object.values(provider.addresses || {}).find((address) => (address as OrganisationAddress).default) as OrganisationAddress;

    return (
        <Card grid sm={12} lg={5} md={6} contentSx={{padding: 0, height: "max-content"}} sx={{height: "max-content"}}>
            {user.userGroup === "readOnly" ? <PublicProfileBannerAndProfileImage providerId={user.oId}/> : <BannerPic>
                <ProfilePic/>
            </BannerPic>}
            <Box ml={17} mt={2}>
                <Stack p={1} direction={"row"} justifyContent={"space-between"} alignContent={"center"}>
                    <Typography variant="h5">{providerDetails.name}</Typography>
                    <Button size="small" startIcon={<RemoveRedEye/>} onClick={() => navigate(`/provider/${user.oId}`)}>Public page</Button>
                </Stack>
                <Divider sx={{mb: 2}}/>
            </Box>
            <CardContent sx={{pb: "0px !important", mb: 0}}>
                <Stack>
                    <Typography>Primary address: {capitaliseWords(defAddress?.["address-line1"])} | {defAddress?.postal_code.toUpperCase()}</Typography>
                    <Typography>Admin: {providerDetails.adminData.details.forename} {providerDetails.adminData.details.surname} | {providerDetails.adminData.email}</Typography>
                    <br/>
                    {user.userGroup === "admin" && <>
                        <Alert sx={{alignItems: "center"}} action={!providerDetails.insurance && <IconButtonPop onClick={() => setProviderInsurancePopup(true)} responsive={false} title="Upload"><Upload color={providerDetails.insurance === false ? undefined : "primary"}/></IconButtonPop>} severity={providerDetails.insurance ? "info" : providerDetails.insurance === false ? "error" : "primary"}>Employers liability insurance: {providerDetails.insurance === true ? <strong>Valid until {convertDate(providerDetails.insuranceExpiry, "visual") as string}</strong> : providerDetails.insurance === "awaitingReview" ? <strong>In review</strong> : <strong>{providerDetails.insurance === false ? "Expired" : "Awaiting upload"}</strong>}</Alert>
                        {eliUploadPopup}</>}
                    {!provider.details.mapConsent && <Alert severity="warning" sx={{alignItems: "center"}} action={<IconButtonPop onClick={() => navigate(`/provider/${user.oId}#publish`)} responsive={false} title="Publish"><Upload/></IconButtonPop>}>Pages are not currently live. Click to publish.</Alert>}
                </Stack>
            </CardContent>
        </Card>
    );
}

const hoverStyles = {
    "cursor": "pointer",
    "position": "relative",
    "transition": "all 250ms ease",
    "overflowX": "clip",
    "&::after": {
        content: "\"\"",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0)",
        transition: "all 250ms ease",
        pointerEvents: "none",
        borderRadius: "50%",
    },
    "&:hover::after": {
        backgroundColor: "rgba(0,0,0,0.05)",
    },
    "> img, > svg": {
        transition: "all 150ms ease",
    },
    "&:hover > img, &:hover > svg": {
        transform: "scale(1.05)",
    },
};

export function BannerPic({children, publicPage}:{children: ReactNode, publicPage?: boolean}) {
    const [profileUrl, setProfileUrl] = useState<string>();
    const [uploadPopup, setUploadPopup] = useState(false);
    const user = useContext(UserContext);

    useEffect(() => {
        getDownloadURL(ref(storage, `${user.product}/${user.oId}/bannerPic.png`)).then(setProfileUrl).catch(() => null);
    }, []);

    const uploadImage = (e: {file: FileList}) => {
        uploadFiles(e.file[0], [user.product, user.oId, "bannerPic.png"]).then(() => {
            getDownloadURL(ref(storage, `${user.product}/${user.oId}/bannerPic.png`)).then(setProfileUrl);
        });
    };


    return (<>
        <FileUploaderPopup accept="image/png" open={uploadPopup} title="Upload banner image" onClose={() => setUploadPopup(false)} onSubmit={uploadImage}/>
        {profileUrl ?
            <Box onClick={(e) => {
                setUploadPopup(true);
                e.stopPropagation();
            }} height={publicPage ? "15vw" :"75px"} width={"100%"} minHeight={publicPage ? "100px" : undefined} display={"grid"} sx={hoverStyles}>
                <Box height={publicPage ? "15vw" :"75px"} width={"100%"} component={"img"} sx={{objectFit: "contain"}} src={profileUrl} />
            </Box> :

            <Box onClick={(e) => {
                setUploadPopup(true);
                e.stopPropagation();
            }} height={publicPage ? "15vw" :"75px"} width={"100%"} minHeight={publicPage ? "100px" : undefined} display={"grid"} sx={hoverStyles} justifyContent={"center"} bgcolor={"rgb(223, 223, 223)"}>
                <CameraAlt sx={{"aspectRatio": 1, "alignSelf": "center", "height": "40%", "opacity": 0.4, "transition": "all 125ms ease-in-out", "&:hover": {transform: "scale(1.2)"}}}/>
            </Box>}
        {children}
    </>

    );
}


export function ProfilePic({publicPage, path="profilePic"}:{publicPage?: boolean, path?: string}) {
    const [profileUrl, setProfileUrl] = useState<string>();
    const [uploadPopup, setUploadPopup] = useState(false);
    const user = useContext(UserContext);

    useEffect(() => {
        getDownloadURL(ref(storage, `${user.product}/${user.oId}/${path}.png`)).then(setProfileUrl).catch(() => null);
    }, []);

    const uploadImage = async (e: {file: FileList}) => {
        return await uploadFiles(e.file[0], [user.product, user.oId, `${path}.png`]).then(() => {
            getDownloadURL(ref(storage, `${user.product}/${user.oId}/${path}.png`)).then(setProfileUrl);
        });
    };

    return (<Box position={"absolute"} zIndex={100} sx={{...hoverStyles}} width={"100%"} onClick={(e) => e.stopPropagation()}>
        <FileUploaderPopup accept="image/png" open={uploadPopup} title="Upload profile picture" onClose={() => setUploadPopup(false)} onSubmit={uploadImage}/>
        {profileUrl ?
            <Box onClick={(e) => {
                setUploadPopup(true);
                e.stopPropagation();
                e.preventDefault();
            }} width={publicPage ? "12vw" : "100px"} top={publicPage ? "-50px" : undefined} height={publicPage ? "12vw" : "100px"} minWidth={"100px"} minHeight={"100px"} border={"lightgrey 1px solid"} component={"img"} src={profileUrl} display={"flex"} sx={{objectFit: "cover"}} margin={publicPage ? "0 5%" : user.product === "providers" ? "-35px 20px" : undefined} position={user.product === "providers" ? "absolute" : undefined} borderRadius={"50%"} /> :

            <Box onClick={(e) => {
                setUploadPopup(true);
                e.stopPropagation();
                e.preventDefault();
            }} width={publicPage ? "12vw" : "100px"} top={publicPage ? "-50px" : undefined} height={publicPage ? "12vw" : "100px"} minWidth={"100px"} minHeight={"100px"} border={"lightgrey 1px solid"} display={"flex"} alignItems={"center"} justifyContent={"center"} margin={publicPage ? "0 5%" : user.product === "providers" ? "-35px 20px" : undefined} position={user.product === "providers" ? "absolute" : undefined} borderRadius={"50%"} bgcolor={"rgb(193, 193, 193)"}>
                <CameraAlt sx={{"width": "40%", "height": "40%", "opacity": 0.4, "transition": "all 125ms ease-in-out", "&:hover": {transform: "scale(1.2)"}}}/>
            </Box>}
    </Box>

    );
}
