import Page from "../../../Components/Page";


export default function InstituteAnalytics() {
    return (
        <Page
            title="Analytics">

        </Page>
    );
}
