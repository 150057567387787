import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../../App";
import {Timestamp, where} from "firebase/firestore";
import FilterList from "../../Components/FilterList";
import {Button, Chip, Divider, ListItem, ListItemSecondaryAction, ListItemText, MenuItem} from "@mui/material";
import {Popup} from "../../Components/Popup";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";
import {UserSearch} from "./ProposePlacement";
import Dropdown from "../../Components/FormComponents/Dropdown";
import {FileSelectorInput} from "./Files";
import {convertDate, getDateDiff} from "placementt-core";


type Visit = {
    oId: string,
    placementId: string,
    status: "upcoming"|"completed"
    title: string,
    description: string,
    forms?: string[],
    files?: string[],
    date: string,
    created: Timestamp
    designatedStaff: string
};


export function VisitList({pId}:{pId: string}) {
    const [visits, setVisits] = useState<{[key: string]: Visit}>();
    const [addVisitPopup, setAddVisitPopup] = useState(false);

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);

    const today = convertDate(new Date(), "dbstring") as string;

    useEffect(() => {
        const unsubscribe = firebaseQuery.collectionSnapshot(setVisits, "visits", [where("oId", "==", user.oId), where("placementId", "==", pId)]);

        return () => unsubscribe();
    }, [pId]);

    console.log("CISITS", visits);

    return (
        <>
            <FilterList noSearch noResultsText={"No visits booked yet."} data={visits} title={"Visits"} actionButton={<Button onClick={() => setAddVisitPopup(true)}>Schedule visit</Button>}>
                {Object.entries(visits || {}).map(([key, visit]) =>
                    <ListItem key={key} id={key}>
                        <ListItemText
                            primary={visit.title}
                            secondary={visit.description}
                            sx={{marginRight: 6}}/>
                        <ListItemSecondaryAction>
                            {today < visit.date && <Chip label={`In ${getDateDiff(new Date(), convertDate(visit.date, "date") as Date)} days`}/>}
                        </ListItemSecondaryAction>
                    </ListItem>)}
            </FilterList>
            <AddVisitPopup open={addVisitPopup} onClose={() => setAddVisitPopup(false)} pId={pId}/>
        </>
    );
}


function AddVisitPopup({open, onClose, pId}:{open: boolean, onClose: () => void, pId: string}) {
    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);
    const [forms, setForms] = useState<{[key:string]: {name: string}}>({});
    const institute = useContext(OrganisationContext);

    useEffect(() => {
        setForms(institute.forms as {[key:string]: {name: string}});
    }, []);
    const createVisit = async (e: Partial<Visit>) => {
        await firebaseQuery.add(["visits"], {
            ...e,
            oId: user.oId,
            placementId: pId,
            created: Timestamp.fromDate(new Date()),
            status: "upcoming",
        } as Visit);
    };

    return (
        <Popup {...{open, onClose}} maxWidth={"sm"} fullWidth title={"Schedule new visit"}>
            <Form onSubmit={async (e) => await createVisit(e as Partial<Visit>)}>
                <InputGroup required name={"title"} label="Title" placeholder="E.g. Mid-placement check-in"/>
                <InputGroup name={"description"} label="Description" placeholder="A short description for the designated staff member."/>
                <InputGroup required name={"date"} label="Date" type={"date"}/>
                <UserSearch userType="Staff" required name="designatedStaff" label={"Designated staff"}/>
                <Divider/>
                <Dropdown
                    label='Forms'
                    multiple
                    name="forms">
                    {Object.entries(forms).map(([key, e]) => {
                        return (<MenuItem value={key}>{e.name}</MenuItem>);
                    })}
                    <Divider/>
                    <MenuItem onClick={()=>window.open(`/${user.product}/organisation/resources/f`, "_blank")}>
                        {"Create form"}
                    </MenuItem>
                </Dropdown>
                <FileSelectorInput
                    files={undefined}
                    name="files"/>
            </Form>
        </Popup>
    );
}
