import {Edit} from "@mui/icons-material";
import {Alert, Box, Button, Collapse, Stack, Typography} from "@mui/material";
import React, {forwardRef, ReactNode, useEffect, useImperativeHandle, useState} from "react";
import {CellBase, ColumnIndicatorProps, Matrix, Spreadsheet as ReactSpreadsheet} from "react-spreadsheet";
import Form from "./Form";
import InputGroup from "./FormComponents/InputGroup";
import IconButtonPop from "./IconButtonPop";
import {Popup} from "./Popup";
import {arrayEquals, objectsEqual} from "placementt-core";
import styled from "styled-components";


const ColumnHeader = styled("th")`
    width: ${(props:{$width?: string}) => props?.$width || "inherit"};

    :hover {
        > :nth-child(1) {
            opacity: 1;
            width: 36px;
        }
    }

    > :nth-child(1) {
        opacity: 0;
        width: 0;
        overflow-x: clip;
        transition: all ease-in-out 250ms;
    }
`;

const SpreadsheetContainer = styled(Box)`
    max-height: 500px;
    overflow-y: auto;
`;

type Params = {
    defaultCols: string[],
    formatColLabels?: (label:string) => void,
    noNewRows?: boolean,
    noNewColumns?: boolean,
    noSubmitButton?: boolean,
    colWidth?: string,
    onChange?: () => void,
    readOnly?: boolean,
    initialData?: Matrix<CellBase<any>>,
    alert?: {
        severity: "warning" | "error" | "success" | "info";
        msg: string;
    },
    onSubmit?: (users: {[key:string]: unknown}[]) => void,
    customCellComponents?: {[key:string]: ReactNode}
}
const Spreadsheet = forwardRef(({customCellComponents, onSubmit, onChange, readOnly, initialData, noSubmitButton, defaultCols, formatColLabels, noNewColumns, noNewRows, colWidth, alert}:Params, ref) => {
    const [data, setData] = useState<Matrix<CellBase<any>>>(initialData || [[]]);

    const [columnNames, setColumnNames] = useState(defaultCols);
    const [colNamePopup, setColNamePopup] = useState(false);
    const [colNameNumber, setColNameNumber] = useState<number|undefined>(undefined);
    const [colNameInputName, setColNameInputName] = useState<string|undefined>("");


    useImperativeHandle(ref, () => ({
        submit: () => formatAndSubmit(),
    }));

    useEffect(() => {
        onChange && onChange();
    }, [data]);

    function Col({column, label, onSelect, width}:ColumnIndicatorProps&{width?: string}) {
        const editHeader = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setColNameNumber(column);
            setColNameInputName(columnNames ? columnNames[column] : "");
            setColNamePopup(true);
            e.stopPropagation();
        };

        const defaultField = defaultCols ? defaultCols.includes(label as string) : false;

        return (
            <ColumnHeader $width={width} onClick={() => onSelect(column, true)} style={{cursor: "pointer"}}>
                <>
                    {formatColLabels ? formatColLabels(label as string) : label}
                    {!defaultField && <IconButtonPop onClick={editHeader} responsive={false} title="Edit">
                        <Edit fontSize="small"/>
                    </IconButtonPop>}
                </>

            </ColumnHeader>
        );
    }

    const submitLabelForm = (action:"create"|"delete") => {
        if (action === "create") {
            if (!colNameInputName) return;
            if (colNameInputName.trim().length === 0) {
                setColNameInputName("");
                return;
            }
            setColumnNames((c) => {
                if (colNameNumber) {
                    const n = c ? [...c] : [];
                    n[colNameNumber] = colNameInputName;
                    return n;
                }
                return [...(c ? c :[]), colNameInputName];
            });
        }
        if (action === "delete" && colNameNumber) {
            setColumnNames((c) => {
                const n = c ? [...c] : [];
                n.splice(colNameNumber, 1);
                return n;
            });
            setData((d) => d.map((row) => {
                const n = [...row];
                n.splice(colNameNumber, 1);
                return n;
            }));
        }

        setColNamePopup(false);
        setColNameNumber(undefined);
        setColNameInputName("");
    };

    const formatAndSubmit = () => {
        if (!columnNames) return;
        const submissionData = data.map((row) => row.reduce((acc, cell, index) => {
            acc[columnNames[index]?.trim() || ""] = cell ? /^\d+$/.test(cell?.value?.trim()) ? parseInt(cell?.value?.trim()) : cell.value?.trim() : "";
            return acc;
        }, Object.fromEntries(columnNames.map((colName) => [colName, ""])) as {[key:string]:unknown}));

        onSubmit && onSubmit(submissionData);
    };

    console.log("P{RE", data.map((row) => {
        console.log("a");
        return customCellComponents && Object.keys(customCellComponents).length ? columnNames.map((col, index) => {
            if (customCellComponents[col.toLowerCase()]) {
                console.log("cell", customCellComponents[col.toLowerCase()]);
                return customCellComponents[col.toLowerCase()];
            } else {
                return row[index];
            }
        }) : row;
    }));

    return (
        <Stack direction={"column"} spacing={2}>
            <Collapse in={Boolean(alert)}>
                <Alert severity={alert?.severity}>{alert?.msg}</Alert>
            </Collapse>
            <SpreadsheetContainer>
                <MemoisedSpreadsheet key={"spreadsheet"} ColumnIndicator={(e:ColumnIndicatorProps) => <Col {...{...e}} width={colWidth}/>} data={
                    data.map((row) => {
                        console.log("a");
                        return customCellComponents && Object.keys(customCellComponents).length ? columnNames.map((col, index) => {
                            if (customCellComponents[col.toLowerCase()]) {
                                console.log("cell", customCellComponents[col.toLowerCase()]);
                                return customCellComponents[col.toLowerCase()];
                            } else {
                                return row[index];
                            }
                        }) : row.map((cell) => ({...cell, readOnly: readOnly}));
                    }) as any
                } onChange={setData} hideRowIndicators columnLabels={columnNames}/>
            </SpreadsheetContainer>
            {readOnly || <><Typography sx={{alignSelf: "flex-start", color: "#00000050", fontSize: "0.8em", textAlign: "left"}}>Tip: Copy and paste entire tables without manually adding rows. Click the top left cell and paste your dataset.</Typography>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Stack direction={"row"}>
                        {noNewRows || <Button onClick={() => setData((d) => ([...d, [{value: ""}]]))}>Add row</Button>}
                        {noNewColumns || <Button onClick={() => setColNamePopup(true)}>Add column</Button>}
                    </Stack>
                    {(onSubmit && !noSubmitButton) && <Button variant={"contained"} onClick={formatAndSubmit}>Submit</Button>}
                </Stack>
                <Popup open={colNamePopup} onClose={() => setColNamePopup(false)} title={"Enter column name"}>
                    <Form button={false} functionType={"sync"}>
                        <InputGroup name={"name"} label={"Column name"} required onChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setColNameInputName(e.target.value)} value={colNameInputName}/>
                        <Stack direction={"row"} justifyContent={"space-evenly"}>
                            <Button onClick={() => submitLabelForm("create")} variant={"contained"}>{colNameNumber ? "Set" : "Create"}</Button>
                            {colNameNumber && <Button variant={"outlined"} color={"error"} onClick={() => submitLabelForm("delete")}>Remove</Button>}
                        </Stack>
                    </Form>
                </Popup></>}
        </Stack>
    );
});

export default Spreadsheet;

const MemoisedSpreadsheet = React.memo(ReactSpreadsheet, (prevProps, nextProps) => objectsEqual(prevProps.data, nextProps.data) && arrayEquals(prevProps.columnLabels, nextProps.columnLabels));
