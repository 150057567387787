import {useContext, useEffect, useState} from "react";
import Card from "../../Components/Card";
import {OrganisationContext, UserContext} from "../../App";
import {EmailTemplate, PRIMARY_COLOUR, Products, arraysEqual, camelCaseToNormal, emailTemplates, getAccess} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {Timestamp, where} from "firebase/firestore";
import {Button, Checkbox, Divider, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, Stack, Typography} from "@mui/material";
import IconButtonPop from "../../Components/IconButtonPop";
import {Delete, Edit} from "@mui/icons-material";
import DeletePopup from "../../Components/DeletePopup";
import {Popup} from "../../Components/Popup";
import {Descendant} from "slate";
import InputGroup from "../../Components/FormComponents/InputGroup";
import Form from "../../Components/Form";
import Dropdown from "../../Components/FormComponents/Dropdown";
import SlateWithParamsEditor from "../../Components/SlateWithParams";
import FilterList from "../../Components/FilterList";


// Selectable turns this into a list of files that can be selected by the user. Multiple allows multiple selections.

export default function EmailTemplates({popup, open, onClose, onSubmitSelection, md=6, xs=12, selectable, defaultSelected, emailTemplate}:{popup?: boolean, md?: number, xs?: number, open?: boolean, onClose?: () => void, onSubmitSelection?: (e: string[]) => any, emailTemplate?: string, selectable?: boolean|"multiple", defaultSelected?: string[]}) {
    const user = useContext(UserContext);
    const org = useContext(OrganisationContext);


    const [addTemplatePopupOpen, setAddTemplatePopupOpen] = useState<{id?: string, template: Partial<EmailTemplate>}|boolean>(false);
    const [deleteTemplatePopup, setDeleteTemplatePopup] = useState<string|false>(false);

    const [selected, setSelected] = useState<string[]>();

    const [orgEmailTemplates, setOrgEmailTemplates] = useState(org.emailTemplates as {[key: string]: EmailTemplate});

    const {addEmailTemplates, editEmailTemplates, deleteEmailTemplates} = getAccess(user, "addEmailTemplates", "editEmailTemplates", "deleteEmailTemplates");

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        if (location.hash.includes("addEmailTemplate")) {
            setAddTemplatePopupOpen(true);
        }
    }, [location.hash]);

    useEffect(() => {
        if (!defaultSelected) return;
        if (arraysEqual(defaultSelected, selected || [])) return;

        setSelected(defaultSelected);
    }, [defaultSelected, open]);

    useEffect(() => {
        if (user.product !== "admin") return;
        firebaseQuery.getDocsWhere(["emailTemplates"], where("public", "==", true)).then((a) => setOrgEmailTemplates(a as { [k: string]: any; }));
    }, []);

    useEffect(() => {
        setOrgEmailTemplates(org.emailTemplates);
    }, [org.emailTemplates]);


    useEffect(() => {
        if (open) return;
        setSelected(undefined);
    }, [open]);


    const TemplateListComponent = orgEmailTemplates && <>
        <FilterList noSearch card={false} data={orgEmailTemplates}>
            {Object.entries(orgEmailTemplates).filter(([, v]) => emailTemplate ? v.emailTemplate === emailTemplate : true).map(([key, template]) =>
                <ListItemButton divider key={key} id={key} onClick={() => selectable ? null : setAddTemplatePopupOpen({id: key, template: template})}>
                    {selectable && <ListItemIcon>
                        <Checkbox onChange={() => setSelected((s) => {
                            return s?.includes(key) ? s.filter((i) => key !== i) : [...(s || []), key];
                        })} checked={Boolean(selected?.includes(key))} disabled={Boolean(selectable === true && selected?.length && !selected.includes(key))}/>
                    </ListItemIcon>}
                    <ListItemText
                        primary={template.name}
                        secondary={camelCaseToNormal(template.emailTemplate as string)}
                    />
                    <ListItemSecondaryAction>
                        {[
                            editEmailTemplates && <ListItemIcon><IconButtonPop responsive={false} title={"Edit"} onClick={(e) => {
                                e.stopPropagation(); setAddTemplatePopupOpen({id: key, template: template});
                            }}><Edit/></IconButtonPop></ListItemIcon>,
                            deleteEmailTemplates && <ListItemIcon><IconButtonPop title={"Delete"} responsive={false} onClick={(e) => {
                                e.stopPropagation(); setDeleteTemplatePopup(key);
                            }}><Delete/></IconButtonPop></ListItemIcon>,
                        ]}
                    </ListItemSecondaryAction>
                </ListItemButton>
            )}
        </FilterList>
        <EmailTemplatePopup
            open={Boolean(addTemplatePopupOpen)}
            onClose={() => setAddTemplatePopupOpen(false)}
            itemId={typeof addTemplatePopupOpen === "object" ? addTemplatePopupOpen.id : undefined}
            item={typeof addTemplatePopupOpen === "object" ? addTemplatePopupOpen.template : undefined}/>
        <DeletePopup
            title="template"
            itemName={orgEmailTemplates?.[deleteEmailTemplates]?.name}
            open={Boolean(deleteTemplatePopup)}
            onClose={() => setDeleteTemplatePopup(false)}
            onDelete={async () => deleteTemplatePopup ? await firebaseQuery.delete(["emailTemplates", deleteTemplatePopup]) : null}/>
    </>;

    if (popup) {
        return (
            <Popup fullWidth title={selectable ? <Stack><Typography variant="h5">Select template to use for email</Typography><Typography color={PRIMARY_COLOUR} variant="h5">{camelCaseToNormal(emailTemplate)}</Typography></Stack>: "Email templates"} open={Boolean(open)} onClose={() => onClose && onClose()} actions={
                <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                    {addEmailTemplates && user.product !== "admin" && <Button onClick={() => setAddTemplatePopupOpen(emailTemplate ? {id: undefined, template: {emailTemplate: emailTemplate}} : true)}>Add template</Button>}
                    {selectable && <Button variant="contained" onClick={() => selected && onSubmitSelection && onSubmitSelection(selected)}>Submit</Button>}
                </Stack>
            }>
                {TemplateListComponent}
            </Popup>
        );
    }

    return (
        <Card title={"Email templates"} sx={{height: "100%"}} grid {...{xs, md}} secondaryTitle={addEmailTemplates && user.product !== "admin" && <Button onClick={() => setAddTemplatePopupOpen(true)}>Add template</Button>}>
            {TemplateListComponent}
        </Card>);
}


function EmailTemplatePopup({open, onClose, item, itemId}:{open: boolean, onClose: () => void, item?: Partial<EmailTemplate>, itemId?: string}) {
    const [selectedTemplate, setSelectedTemplate] = useState(item?.emailTemplate);
    const [templateData, setTemplateData] = useState<{subject: string, body: Descendant[]}>();
    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);

    type EmailTemplateFormParams = {
        name: string,
        emailTemplate: keyof typeof emailTemplates,
        subject: string,
        content: Descendant[],
        product?: Products
    };

    useEffect(() => {
        if (!selectedTemplate) {
            setTemplateData(undefined);
            return;
        }
        firebaseQuery.getDocData(["emailTemplates", selectedTemplate]).then((item) => {
            setTemplateData({subject: item.subject, body: item.bodyText});
        });
    }, [selectedTemplate]);

    useEffect(() => {
        setSelectedTemplate(item?.emailTemplate);
    }, [item]);

    const submitTemplate = async (e: EmailTemplateFormParams) => {
        console.log("E", e);
        if (!validateParamsInString(JSON.stringify(e.content))) {
            throw new Error("Your email content contains invalid personalisations.");
        }
        if (emailTemplates[e.emailTemplate].button && (!JSON.stringify(e.content).includes("{{button}}") || (JSON.stringify(e.content).match(/{{button}}/g) || []).length > 1)) {
            throw new Error("This email must have one instance of the button element.");
        }

        const emailObject = {
            name: e.name,
            emailTemplate: e.emailTemplate,
            subject: e.subject,
            bodyText: e.content,
            oId: user.oId,
            product: e.product || user.product,
        } as EmailTemplate;

        if (!itemId) {
            if (user.product === "admin") {
                emailObject.public = true;
                await firebaseQuery.set(["emailTemplates", e.name], {...emailObject, oId: undefined, created: Timestamp.fromDate(new Date())});
            } else {
                await firebaseQuery.add(["emailTemplates"], {...emailObject, created: Timestamp.fromDate(new Date())});
            }
        } else {
            if (user.product === "admin") {
                emailObject.oId = undefined as any;
            }
            await firebaseQuery.update(["emailTemplates", itemId], emailObject);
        }
        setTimeout(() => {
            onClose();
        }, 500);
    };

    // Function checks that all typed params are valid
    function validateParamsInString(content: string) {
        if (!selectedTemplate) return false;
        // Regex to match {{text}} pattern with only alphabetic characters
        const regex = /\{\{([a-zA-Z]+)\}\}/g;

        const contentWithoutButton = content.replace(/{{button}}/g, "");


        // Find all matches
        let match;
        while ((match = regex.exec(contentWithoutButton)) !== null) {
            const param = match[1]; // Extract the text within {{}}

            // Check if the param is in the params array
            if (!emailTemplates[selectedTemplate].params.includes(param)) {
                return false; // If any param is not found, return false
            }
        }

        return true; // All params are valid
    }

    return (
        <Popup title={"Create email template"} fullWidth maxWidth={"lg"} open={open} onClose={onClose}>
            <Form onSubmit={async (e) => await submitTemplate(e as EmailTemplateFormParams)}>
                <InputGroup value={user.product === "admin" ? itemId : item?.name} name={"name"} required label={"Name"} placeholder={"Only visible to you"}/>
                <Dropdown value={item?.emailTemplate} label="Email template" required name={"emailTemplate"} onChange={(e) => setSelectedTemplate(e.target.value)}>
                    {Object.keys(emailTemplates).map((templateName) => <MenuItem value={templateName}>{camelCaseToNormal(templateName)}</MenuItem>)}
                </Dropdown>
                {user.product === "admin" && <Dropdown label="Product" required name={"product"}>
                    <MenuItem value={"institutes"}>Institutes</MenuItem>
                    <MenuItem value={"providers"}>Providers</MenuItem>
                </Dropdown>}
                <Divider/>
                <Typography variant="h6">Customise your email content</Typography>
                {selectedTemplate && templateData && <>
                    <InputGroup value={item?.subject || templateData?.subject} name={"subject"} required label={"Subject text"} placeholder={"Email subject"}/>
                    <SlateWithParamsEditor key={selectedTemplate} button={emailTemplates[selectedTemplate].button} initialValue={item?.bodyText || templateData?.body} name="content" params={emailTemplates[selectedTemplate].params}/>
                </>}

            </Form>
        </Popup>
    );
}
