import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import {PurpleCard} from "../Util/styledComponents";
import {StudentPlacementData, getDateDiff, getPlacementsWhere, reformatDate} from "placementt-core";
import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {where} from "firebase/firestore";
import {UserContext} from "../App";
import styled from "styled-components";


export const NextPlacementCard = styled(Card)`
border-left: 8px solid ${(props:{theme:{palette:{primary:{main:string}}}}) => props.theme.palette.primary.main};
transition: all 250ms ease;
cursor: pointer;

&:hover {
filter: brightness(0.95);
}
`;

export default function NextOrActivePlacementCard() {
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const [activePlacement, setActivePlacement] = useState<StudentPlacementData>();
    const [placements, setPlacements] = useState<{[key:string]:StudentPlacementData}>({});

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        firebaseQuery.getDocsWhere(["placements"], [where("active", "==", true), where("uid", "==", user.id)]).then((docs) => {
            setActivePlacement((docs as {[key:string]: StudentPlacementData})?.[Object.keys(docs as {[key:string]: StudentPlacementData})[0]] || undefined);
        });
        getPlacementsWhere({w: where("uid", "==", user.id), oId: user.oId}).then((placements) => setPlacements(placements as {[key:string]: StudentPlacementData} || {}));
    }, []);

    const today = new Date();

    const upcoming = Object.entries(placements).filter(
        ([, v]) => new Date(v.startDate).getTime() > today.getTime()
    )[0];

    return (activePlacement ?
        <Grid item xs={12} sm={6}>
            <PurpleCard onClick={() => navigate(`/${user.product}/placements/${activePlacement.id}`)}>
                <CardContent sx={{display: "flex", flexDirection: "row"}}>
                    <Box sx={{flexGrow: "1"}}>
                        <Typography>
                        Active placement
                        </Typography>
                        <Typography variant="h5">{activePlacement.name}</Typography>
                        <Box paddingLeft="10px" >
                            <Typography variant="subtitle2">
                                {reformatDate(activePlacement.startDate)}{" "}-{" "}
                                {reformatDate(activePlacement.endDate)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{width: "80px", textAlign: "center"}}>
                        <Typography variant="h2" sx={{marginBottom: "-10px"}}>
                            {getDateDiff(today, new Date(activePlacement.endDate))}
                        </Typography>
                        <Typography variant="subtitle2" >
                        days left
                        </Typography>
                    </Box>
                </CardContent>
            </PurpleCard>
        </Grid> :
        upcoming ? (
            <Grid item xs={12} sm={6}>
                <NextPlacementCard onClick={() => navigate(`/${user.product}/placements/${upcoming[0]}`)}>
                    <CardContent
                        sx={{display: "flex", flexDirection: "row"}}>
                        <Box sx={{flexGrow: "1"}}>
                            <Typography>
                                    Next placement
                            </Typography>
                            <Typography variant="h5">{upcoming[1].name}</Typography>
                            <Box sx={{color: "#00000080"}} paddingLeft="10px" >
                                <Typography variant="subtitle2">
                                    {reformatDate(upcoming[1].startDate)}{" "}-{" "}
                                    {reformatDate(upcoming[1].endDate)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{width: "80px", textAlign: "center"}}>
                            <Typography variant="h2" sx={{marginBottom: "-10px"}}>
                                {getDateDiff(today, new Date(upcoming[1].startDate))}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                sx={{color: "#00000080"}}
                            >
                                    days to go
                            </Typography>
                        </Box>
                    </CardContent>
                </NextPlacementCard>
            </Grid>
        ) : null);
}
