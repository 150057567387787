import {Box, Card, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography} from "@mui/material";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";
import {GradientText} from "../../Util/styledComponents";
import {Analytics, CampaignRounded, Person, SchoolRounded, StarRateRounded} from "@mui/icons-material";
import Cookies from "universal-cookie";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {useState} from "react";
import {useCheckMobileScreen} from "../../Util/util";
import {SectorSelector} from "../../Components/SectorSelector";
import {PRIMARY_COLOUR} from "placementt-core";
import Page from "../../Components/Page";


export default function BusinessSignUp() {
    const cookies = new Cookies();
    const isMobile = useCheckMobileScreen();

    const [signedUp, setSignedUp] = useState<boolean>(cookies.get("businessInterest"));

    const submitDetails = async (e:{forename: string, surname: string, email: string}) => {
        const firebaseQuery = new FirebaseQuery();
        await firebaseQuery.add(["businessInterest"], e);
        cookies.set("businessInterest", true);
        setSignedUp(true);
    };

    return (
        <Page
            metaTitle="Placementt | Business - Join our waitlist"
            metaDesc="Become part of a UK-wide database of placements, service students across the country"
            noGutters={isMobile}>
            <Box width={"100%"} paddingTop={"20px"}>
                <Card sx={{border: isMobile ? "none" : undefined, boxShadow: isMobile ? "none" : undefined, width: "600px", maxWidth: "100%", margin: "auto"}}>
                    <Stack p={2} alignItems={"center"}>
                        <GradientText variant="h4">
                        Placementt - Business
                        </GradientText>
                        <Box bgcolor={PRIMARY_COLOUR} sx={{position: "relative", margin: "10px -16px !important", padding: "40px 20px", clipPath: "polygon(0 9%, 100% 0, 100% 100%, 0 90%)"}}>
                            <Typography color={"#fff"} variant='h5' mb={1}>
                            Placementt is a work experience platform allowing businesses, students and educational institutes to find and manage placements.
                            </Typography>
                            <Typography color={"#fff"}>
                            Placementt - Business allows you to effortlessly connect with a pool of eager and talented students, ensuring a perfect match with your placements. Be among the first to join and form meaningful connections with the brightest minds of tomorrow.
                            </Typography>
                        </Box>
                        <List sx={{alignSelf: "start", margin: "0px !important"}}>
                            <Typography variant="h6">Features</Typography>
                            <ListItem>
                                <ListItemIcon><CampaignRounded/></ListItemIcon>
                                <ListItemText primary={"Create engaging placement advertisements."}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><Person/></ListItemIcon>
                                <ListItemText primary={"View and compare student applications."}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><Analytics/></ListItemIcon>
                                <ListItemText primary={"Effortlessly manage your placement students."}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><SchoolRounded/></ListItemIcon>
                                <ListItemText primary={"Headhunt exceptional students for placements or permanent roles."}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><StarRateRounded/></ListItemIcon>
                                <ListItemText primary={"Increase your visibility and reputation among students."}/>
                            </ListItem>
                        </List>

                        {signedUp ?
                            <Typography color={"primary"} textAlign={"center"} variant="h6">Thanks for registering your interest! We will keep you in the loop with Placementt - Business development and will get in touch when we're ready to show off your placements on our site.</Typography> :
                            <><Form sx={{width: "400px", maxWidth: "100%", marginTop: "-10px"}} onSubmit={(e) => submitDetails(e as {forename:string, surname:string, email:string})}>
                                <Typography variant="h5" textAlign={"center"}>Register your interest</Typography>
                                <Grid container sx={{}}>
                                    <Grid item xs={12} md={6}>
                                        <InputGroup required name={"forename"} label={"Forename"}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputGroup required name={"surname"} label={"Surname"}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputGroup required name={"email"} label={"Email"} type={"email"}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputGroup required name={"provider"} label={"Business name"}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SectorSelector/>
                                    </Grid>

                                </Grid>
                            </Form>
                            <Typography color={"grey"} textAlign={"center"}>By signing up, you are agreeing to Placementt contacting you via email. You can unsubscribe any time.</Typography>
                            </>}
                    </Stack>
                </Card>
            </Box>


        </Page>
    );
}
