import {Box, Button, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography} from "@mui/material";
import Page from "../../Components/Page";
import PublicTemplate from "../../Components/Public/PublicTemplate";
import InstitutePriceCalculator from "../../Components/PriceCalculator";
import {AccountTree, Article, CompareArrows, Feed, Group, Hub, Polyline, Public, Search, Share} from "@mui/icons-material";
import {Testimonials} from "./InstituteInfo";
import {GDPRPrivacyInfo} from "./SchoolInfo";
import {BluePurpleRedYellowGradientText} from "../../Util/visualUtil";
import instituteAnalytics from "../../Images/platformImages/instituteAnalytics.png";
import instituteAnalyticsSectors from "../../Images/platformImages/instituteAnalyticsSectors.png";
import sharedResources from "../../Images/platformImages/sharedResources.png";
import multipleSchools from "../../Images/platformImages/multipleSchools.png";
import instituteMap from "../../Images/platformImages/instituteMap.png";
import {useCheckMobileScreen} from "../../Util/util";

export default function TrustInfo() {
    const mobile = useCheckMobileScreen();

    return (
        <Page
            metaTitle="Placementt | Multi Academy Trusts"
            metaDesc=""
        >
            <PublicTemplate>
                <Stack width={"100%"} mb={"-64px !important"}>
                    <Typography color={"primary"} fontWeight={"bold"}>Placementt for Multi-Academy Trusts</Typography>
                    <Divider/>
                </Stack>
                <Stack spacing={5} borderBottom={"1px solid lightgrey"} pt={mobile ? 8 : 20} pb={10} textAlign={"center"} maxWidth={"1000px"} alignSelf={"center"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant="h2" fontWeight={500} maxWidth={"800px"}><BluePurpleRedYellowGradientText>Achieve Synergy</BluePurpleRedYellowGradientText> Across Every School</Typography>
                    <Typography variant="h5">Power your schools with a centralised work experience system. Setup once, share with your schools, and analyse student placements with comprehensive data visualisation.</Typography>
                    <Button href="mailto:tom@placementt.co.uk"><Typography variant="h5"><BluePurpleRedYellowGradientText>Get in touch</BluePurpleRedYellowGradientText></Typography></Button>
                </Stack>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Divider/>
                            <Typography variant="h4">Every School, Centrally Managed</Typography>
                            <Typography>Ease the burden of work experience from your Careers Leads, giving them more time to focus on your students.</Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon><Hub/></ListItemIcon>
                                    <ListItemText primary={"Set up your work experience process once and deploy everywhere."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Group/></ListItemIcon>
                                    <ListItemText primary={"Each school manages only their data, while you have complete oversight."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Group/></ListItemIcon>
                                    <ListItemText primary={"Configure staff accounts with comprehensive access control and permissions."}/>
                                </ListItem>
                            </List>
                            <Typography>Placementt for Trusts allows you to build a robust, standardised work experience process across every school, giving you access to invaluable insight into school performance.</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"}>
                        <Box component={"img"} borderRadius={1} boxShadow={3} width={"100%"} alt={"Placementt for MATs, screenshot showing cohorts at multiple schools."} src={multipleSchools}/>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} position={"relative"}>
                        <Box component={"img"} borderRadius={1} boxShadow={3} width={"100%"} alt={"Placementt for MATs shared resources"} src={sharedResources}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Typography color={"primary"}>Reduce Admin Across Every School</Typography>
                            <Divider/>
                            <Typography variant="h4">Share Resources Across Your Schools</Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon><AccountTree/></ListItemIcon>
                                    <ListItemText primary={"We'll help you build a comprehensive placement processing workflow that can be applied across every school."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Share/></ListItemIcon>
                                    <ListItemText primary={"Share email templates, forms and files, personalised to each school with individual branding."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Article/></ListItemIcon>
                                    <ListItemText primary={"Share custom guidance documents with staff and students, ensuring everyone in your trust has access to quality information."}/>
                                </ListItem>
                            </List>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Typography color={"primary"}>Robust, Customisable Data Analytics</Typography>
                            <Divider/>
                            <Typography variant="h4">Analyse Work Experience Across Every School</Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon><Group/></ListItemIcon>
                                    <ListItemText primary={"Day-to-day oversight goes to your Careers Leads, while you view progress across every school."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CompareArrows/></ListItemIcon>
                                    <ListItemText primary={"Compare schools to one another to identify areas requiring more support."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Feed/></ListItemIcon>
                                    <ListItemText primary={"View placement logs, feedback from employers and destination data, helping you tailor your provision."}/>
                                </ListItem>
                            </List>
                            <Typography></Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} position={"relative"}>
                        <Box component={"img"} borderRadius={1} boxShadow={3} width={"100%"} alt={"Placementt for Educators analytics"} src={instituteAnalytics}/>
                        <Box position={"absolute"} p={1} bgcolor={"#fff"} right={-40} bottom={-40} component={"img"} borderRadius={1} boxShadow={5} height={"50%"} alt={"Placementt for Educators analytics sectors pie chart"} src={instituteAnalyticsSectors}/>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} position={"relative"}>
                        <Box component={"img"} borderRadius={1} boxShadow={3} width={"100%"} alt={"Placementt for Educators map"} src={instituteMap}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Typography color={"primary"}>Your Employer Database</Typography>
                            <Divider/>
                            <Typography variant="h4">Build and Share a Vast Network of Employers</Typography>
                            <Typography></Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon><Polyline/></ListItemIcon>
                                    <ListItemText primary={"Placements from across your schools feed into one centralised database."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Public/></ListItemIcon>
                                    <ListItemText primary={"Students across your trust can browse your own database, containing comprehensive placement information."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Search/></ListItemIcon>
                                    <ListItemText primary={"View analytics on student engagement and placement feedback at a school or whole trust level."}/>
                                </ListItem>
                            </List>
                        </Stack>
                    </Grid>
                </Grid>
                <InstitutePriceCalculator type="mat"/>
            </PublicTemplate>
            <Testimonials/>
            <PublicTemplate>
                <GDPRPrivacyInfo/>
            </PublicTemplate>
        </Page>
    );
}
