import {Button, CardActions, CardContent, CardHeader, Grid, Card as MuiCard, Typography} from "@mui/material";
import {ReactNode, useState} from "react";

type Params = {
    title?: string|ReactNode,
    secondaryTitle?: string|ReactNode,
    children?: ReactNode,
    sx?: {[key:string]: unknown},
    style?: {[key:string]: unknown},
    grid?: boolean,
    xs?: number,
    sm?: number,
    md?: number,
    lg?: number,
    id?: string,
    headerSx?: {[key:string]: unknown},
    contentSx?: {[key:string]: unknown}
    onClick?: () => void,
    expandable?: string,
    subtitle?: string
}

export default function Card({title, subtitle, id, secondaryTitle, children, sx, style, grid, xs, sm, md, lg, headerSx, contentSx, onClick, expandable}:Params) {
    const clickableStyles = {"cursor": onClick ? "pointer" : undefined, "transition": "all 150ms ease-in-out", "&:hover": {filter: "brightness(0.95)"}};

    const [fullHeight, setFullHeight] = useState(false);

    const expandableStyles = expandable ? {
        "maxHeight": fullHeight ? "" : expandable,
        "overflowY": "hidden",
        "&::before": fullHeight ? {} : {
            content: "''",
            position: "absolute",
            zIndex: 1,
            bottom: 0,
            left: 0,
            pointerEvents: "none",
            backgroundImage: "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 90%)",
            width: "100%",
            height: "4em",
        },
    } : {};

    const cardItem =
    <MuiCard id={id} sx={{...sx, ...(onClick ? clickableStyles : {})}} style={style} onClick={onClick}>
        {title && <CardHeader sx={headerSx} title={[title, secondaryTitle]}/>}
        {subtitle && <Typography variant="subtitle2" ml={2} mr={2} mt={0.5} sx={{opacity: 0.7}}>{subtitle}</Typography>}
        <CardContent sx={{height: "100%", position: "relative", ...contentSx, ...expandableStyles}}>
            {children}
        </CardContent>
        {expandable && <CardActions><Button onClick={() => setFullHeight((f) => !f)} color="inherit" variant="text" size="small" sx={{margin: "auto", opacity: 0.7}}>{fullHeight ? "Hide details" : "Show more details"}</Button></CardActions>}
    </MuiCard>;

    if (grid) {
        return (
            <Grid item {...{xs, sm, md, lg}}>
                {cardItem}
            </Grid>
        );
    }
    return (cardItem);
}
