import {Typography} from "@mui/material";
import {ReactNode} from "react";
import Form from "./Form";
import {Popup} from "./Popup";

interface Param {
    open: boolean,
    onClose: () => void,
    error?: string,
    title: string,
    itemName?: string,
    children?: ReactNode
    onDelete: (e:object) => Promise<any>,
    id?: string
}

export default function DeletePopup({open, onClose, title, itemName, children, onDelete, id}:Param) {
    return (
        <Popup key={`${id}Page`} {...{open, onClose}} maxWidth={"sm"} title={`Delete ${title}?`}>
            <Form onSubmit={onDelete} submitText={"Delete"}>
                {itemName && <Typography>Name: {itemName}</Typography>}
                {children}
            </Form>
        </Popup>
    );
}
