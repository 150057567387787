import {ReactNode, useState} from "react";
import {Box, ListItem, ListItemIcon, ListItemText, Stack} from "@mui/material";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import IconButtonPop from "../IconButtonPop";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {PRIMARY_COLOUR, camelCase} from "placementt-core";
import {useCheckMobileScreen} from "../../Util/util";
import NotFound from "../../Pages/Public/NotFound";
import {NAVBARHEIGHT} from "../NavBar/NavBar";
import styled from "styled-components";

const SidebarContainer = styled("div")`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${(props: {$active: boolean, $mobile: boolean}) => props.$mobile ? "100%" : props.$active ? "155px" : "50px"};
    z-index: 200;
    padding-right: 5px;
    bottom: 0;
    -webkit-box-shadow: 0 1px 3px rgb(143, 142, 142);
    -moz-box-shadow: 0 1px 3px rgb(143, 142, 142);
    box-shadow: 0 1px 3px rgb(143, 142, 142);
    transition: width 0.25s ease-in-out;
    height: ${(props: {$active: boolean, $mobile: boolean}) => props.$mobile ? undefined : `calc(100% - ${NAVBARHEIGHT})`};;
    background-color: #ffffff;
`;


const SideBarItemList = styled(Stack)`
    flex-direction: ${(props: {$mobile: boolean}) => props.$mobile ? "row" : "column"};
    margin-top: ${(props: {$mobile: boolean}) => props.$mobile ? 0 : "10px"};
`;

const Spacer = styled(Box)`
    height: ${(props: {$active: boolean, $mobile: boolean}) => props.$mobile ? "60px" : "100px"};;
    min-width: ${(props: {$active: boolean, $mobile: boolean}) => props.$mobile ? "100%" : props.$active ? "155px" : "50px"};
    transition: width 0.25s ease-in-out;
`;


const SideBarItem = styled(ListItem)`
    background-color: ${(props:{$active: boolean, $mobile: boolean}) => (props.$active && !props.$mobile) ? PRIMARY_COLOUR : "none"};
    color: ${(props:{$active: boolean, $mobile: boolean}) => props.$active ? props.$mobile ? PRIMARY_COLOUR : "white" : undefined};
    border-radius: ${(props:{$active: boolean, $mobile: boolean}) => props.$mobile ? undefined : "0 40px 40px 0"};
    overflow: hidden;
    cursor: pointer;
    transition: all 500ms ease;
    display: flex;
    flex-direction: ${(props:{$active: boolean, $mobile: boolean}) => props.$mobile ? "column" : "row"};
    padding: ${(props:{$active: boolean, $mobile: boolean}) => props.$mobile ? "10px 0 0 0" : undefined};

    div {
        color: ${(props:{$active: boolean, $mobile: boolean}) => props.$active ? props.$mobile ? PRIMARY_COLOUR : "white" : undefined};
        padding: ${(props:{$active: boolean, $mobile: boolean}) => props.$mobile ? 0 : undefined};
    }

    span {
        font-size: ${(props:{$active: boolean, $mobile: boolean}) => props.$mobile ? "12px" : undefined};;
    }

    &:hover {
        background-color: ${(props:{$active:boolean, $mobile: boolean}) => props.$active ? undefined : "rgba(0, 0, 0, 0.10)"};
    }`;


export type SideNavBarItems = {
    label ?: string,
    icon ?: ReactNode,
    route: string,
    element: ReactNode,
}[]

type Params = {
    items: SideNavBarItems,
    pathLevel?: number
}

export default function SideNavBar({items, pathLevel=2}:Params) {
    // https://www.notion.so/placementt/SideNavBar-d5c5fd9102904342b5c652f18ded5410
    const navigate = useNavigate();

    const location = useLocation();
    const url = location.pathname.split("/");

    const [active, setActive] = useState(window.innerWidth > 768);

    const isMobile = useCheckMobileScreen();

    return (
        <Box display={"flex"} width={0} minWidth={"100%"} height={`calc(100vh - ${NAVBARHEIGHT})`} flexDirection={isMobile ? "column-reverse" : "row"} overflow={"auto"}>
            <Spacer $active={active} $mobile={isMobile} id="spacer"/>
            <SidebarContainer $active={active} $mobile={isMobile}>
                <SideBarItemList spacing={0} $mobile={isMobile}>
                    {items.filter((i) => i.label).map((item) =>
                        <SideBarItem $active={url[pathLevel] === item.route} $mobile={isMobile} onClick={() => {
                            navigate(item.route);
                        }} key={`${camelCase(item.label)}-label`}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.label} />
                        </SideBarItem>)}
                </SideBarItemList>

                {isMobile || <Box>
                    <IconButtonPop responsive={false} title={active ? "Collapse" : "Expand"} onClick={() => setActive(!active)} sx={{alignSelf: "flex-end", margin: "5px"}}>
                        {active ? <ChevronLeft/> : <ChevronRight/>}
                    </IconButtonPop>
                </Box>}
            </SidebarContainer>
            <Routes>
                {items.map((item) => <Route path={item.route} element={item.element}><Route path={"*"} element={item.element}/></Route>)}
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Box>
    );
}
