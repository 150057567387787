import {PRIMARY_COLOUR} from "placementt-core";
import Page from "../../Components/Page";
import InfoPageHeader from "../../Components/Public/InfoPageHeader";
import Team from "../../Components/Public/Team";


export default function AboutUs() {
    return (
        <Page
            metaTitle="About Us | Placementt"
            metaDesc="Learn more about Placementt, our mission, vision, and the dedicated team working to revolutionize work experience."
        >
            <InfoPageHeader
                title="About Us"
                subtext="Learn more about Placementt, our mission, vision, and the dedicated team working to revolutionize work experience."
                background={PRIMARY_COLOUR}
                scaleBackground
            />
            <Team />
        </Page>
    );
}
