import {OrganisationContext, UserContext} from "../../App";
import SideBarNav, {SideNavBarItems} from "../../Components/Navigation/SideNavBar";
import {Book, BusinessCenter, Dashboard, Palette, Password, People, School} from "@mui/icons-material";
import Blogs from "./Blogs";
import AdminUsers from "./AdminUsers";
import Placements from "./Placements";
import Institute from "./Institute/Institute";
import Home from "./Home";
import BusinessUsers from "./Business/Business";
import {useContext} from "react";
import {Box} from "@mui/material";

export default function Admin() {
    const user = useContext(UserContext);

    const items = [
        {
            label: "Home",
            route: "home",
            icon: <Dashboard/>,
            element: <Home/>,
        },
        {
            label: "Blogs",
            route: "blogs",
            icon: <Book/>,
            element: <Blogs/>,
        },
        {
            label: "Users",
            route: "users",
            icon: <People/>,
            element: <AdminUsers/>,
        },
        {
            label: "Institute",
            route: "institute",
            icon: <School/>,
            element: <Institute/>,
        },
        {
            label: "Businesses",
            route: "business",
            icon: <BusinessCenter/>,
            element: <BusinessUsers/>,
        },
        {
            label: "Placements",
            route: "placements",
            icon: <BusinessCenter/>,
            element: <Placements/>,
        },
        user.product === "admin" && {
            label: "Passwords",
            route: "passwords",
            icon: <Password/>,
            element: <Box>Passwords</Box>,
        },
        {
            label: "Storybook",
            route: "storybook",
            icon: <Palette/>,
            element: <iframe src={"https://placementt.github.io/Placementt-Web/?path=/docs/configure-your-project--docs"} style={{width: "100%"}}/>,
        }].filter((i) => i) as SideNavBarItems;

    return (
        <OrganisationContext.Provider value={{}}>
            <SideBarNav items={items}/>
        </OrganisationContext.Provider>
    );
}

