

import {Circle, Close, PlayArrow} from "@mui/icons-material";
import {Alert, Box, Breadcrumbs, Button, Card, Checkbox, Collapse, Divider, FormControlLabel, IconButton, MenuItem, Snackbar, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import React, {useState, useRef, useContext, ChangeEvent, Dispatch, RefObject, useEffect} from "react";
import Draggable from "react-draggable";
import {OrganisationContext, UserContext} from "../App";
import IconButtonPop from "./IconButtonPop";
import Xarrow, {anchorType} from "react-xarrows";
import InputGroup from "./FormComponents/InputGroup";
import Dropdown from "./FormComponents/Dropdown";
import Form from "./Form";
import FileUploaderPopup from "./FileUploaderPopup";
import {DeleteCardButton} from "../Util/styledComponents";
import workflowStages from "../Images/workflowStages.png";
import workflowButtons from "../Images/workflowButtons.png";
import workflowTutorial from "./../Images/Workflow tutorial.mp4";

import {ApplicantStage, ApplicantWorkflow, ArrowObject, CohortData, defaultInstituteWorkflow, objectsEqual, PRIMARY_COLOUR, useWorkflowEditor, WorkflowStage} from "placementt-core";
import {Popup} from "./Popup";
import styled from "styled-components";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {where} from "firebase/firestore";
import Page from "./Page";
import {Link} from "react-router-dom";

type ConnectionButtonParams = {
    $left?: boolean,
    $right?: boolean,
    $top?: boolean,
    $bottom?: boolean,

}

const NewConnectionButton = styled(IconButtonPop)<ConnectionButtonParams>`
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: all 250ms ease-in-out;

    right: ${(props) => props.$right && "-20px"};
    left: ${(props) => props.$left && "-20px"};
    top: ${(props) => props.$top && "-20px"};
    bottom: ${(props) => props.$bottom && "-20px"};

    top: ${(props) => (props.$left || props.$right) && "50%"};
    transform: ${(props) => (props.$left || props.$right) && "translateY(-50%)"};
    left: ${(props) => (props.$top || props.$bottom) && "50%"};
    transform: ${(props) => (props.$top || props.$bottom) && "translateX(-50%)"};
`;

// Style for the draggable card component, allowing fade in of a delete button on hover.
const DraggableCard = styled(Card)`
    padding: 10px 5px;
    overflow: visible;
    box-shadow: 1px 1px 5px 1px #838383;
    width: 200px;
    position: absolute;

    &:hover ${DeleteCardButton} {
        opacity: 1;
        pointer-events: all;
    }

    &:hover ${NewConnectionButton} {
        opacity: 1;
        pointer-events: all;    
    }
`;

// Tiny div that follows cursor when editing is enabled
const CursorPointer = styled(Box)<{$x: number, $y: number}>`
    position: fixed;
    background-color: green;
    top: ${(props) => props.$y}px;
    left: ${(props) => props.$x}px;
`;

type Params = {
    initialData?: WorkflowStage[]|ApplicantStage[],
    cohortId: string,
    onSubmit: (e: {workflow: WorkflowStage[]|ApplicantStage[], includedFiles?: string[], includedForms?: string[], changeToSimple?: boolean}) => void,
    back?: () => void,
}

export default function WorkflowEditorV4({initialData, cohortId, onSubmit}: Params) {
    const [importWorkflowPopup, setImportWorkflowPopup] = useState(false);
    const [simpleWorkflowPopup, setSimpleWorkflowPopup] = useState(false);

    const [tutorialPopup, setTutorialPopup] = useState(false);
    const [importableItems, setImportableItems] = useState<{[key:string]: CohortData|ApplicantWorkflow}>({});
    const firebaseQuery = new FirebaseQuery();

    const user = useContext(UserContext);
    const org = useContext(OrganisationContext);

    useEffect(() => {
        if (user.product === "institutes") {
            setImportableItems(org.cohorts as {[key:string]: CohortData});
        } else {
            firebaseQuery.getDocsWhere("applicantWorkflows", [where("product", "==", user.product), where("oId", "==", user.oId)]).then((items) => setImportableItems(items as {[key: string]: ApplicantWorkflow} || {}));
        }
    }, []);

    const {filePopupActive, files, uploadFile, addNode, onDelete, arrows, onChange, error, onMoveEnd, addEdgePoint, workflowNodes, newEdgePoint, setTutorialActive, setFilePopupActive,
        submitWorkflow, openPopup, setSnackbar, snackbar, setMousePosFunc, mousePos, tutorialActive, onDeleteArrow, containerRef, setError, setArrows, setWorkflowNodes} = useWorkflowEditor({...{user, initialData, cohortId, onSubmit}, product: user.product, oId: user.oId});

    const switchToSimpleWorkflow = () => {
        submitWorkflow(defaultInstituteWorkflow, false).then(() => {
            onSubmit({workflow: defaultInstituteWorkflow, changeToSimple: true});
        }).catch(() => {
            return;
        });
    };

    return (
        <Page fullHeight>
            <Breadcrumbs sx={{padding: 2}}>
                <Link
                    style={{color: "grey"}}
                    to="../settings"
                >
                    Settings
                </Link>
                <Link
                    style={{color: "grey"}}
                    to="../settings/workflow"
                >
                    Workflow
                </Link>
                <Typography color="text.primary">Custom workflow</Typography>
            </Breadcrumbs>
            <Snackbar
                open={Boolean(snackbar)}
                autoHideDuration={6000}
                onClose={() => setSnackbar(undefined)}
                message={snackbar}
                action={
                    <IconButton
                        size="small"
                        title='Close notification'
                        aria-label="close"
                        color="inherit"
                        onClick={() => setSnackbar(undefined)}>
                        <Close fontSize="small" />
                    </IconButton>
                }
            />
            <div
                onMouseMove={(e) => setMousePosFunc(e)}
                id='workflow-editor'
                style={{position: "relative", display: "flex", flexDirection: "column", height: "100%", flex: 1, paddingBottom: 10}}>

                {/* If editing, render cursor div */ newEdgePoint && mousePos && <CursorPointer id={"pointerArrow"} $x={mousePos.x} $y={mousePos.y}/>}
                {/* Draw arrow between cursor div and start node */ newEdgePoint && mousePos && <WorkflowArrow cursor={true} data={{start: `node_${newEdgePoint}`, end: "pointerArrow", name: false}}/>}

                <Form formRef={containerRef} button={false} onError={setError} onSubmit={() => submitWorkflow().catch(console.log)} sx={{flex: 1, overflow: "auto", position: "relative"}}>
                    {arrows.map((data) => (
                        <WorkflowArrow {...{onChange, data}} onDelete={onDeleteArrow} key={`${data.start}_${data.end}`}/>
                    ))}
                    {workflowNodes && workflowNodes.map((data, id) => <MemoizedWorkflowElement key={data.id} {...{data, onMoveEnd, id, setArrows, onDelete, addEdgePoint, onChange, files, openPopup, newEdgePoint}} />)}
                </Form>
                <Collapse in={Boolean(error)} sx={{marginRight: "10px"}}>
                    <Alert severity="error">{error}</Alert>
                </Collapse>
                <Stack pt={2} direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
                    <Button onClick={() => setSimpleWorkflowPopup(true)}>Return to simple workflow</Button>
                    <Button startIcon={<PlayArrow/>} color="info" variant="contained" onClick={() => setTutorialPopup(true)}>View tutorial</Button>
                    {Object.keys(importableItems).length > 0 && <Button onClick={() => setImportWorkflowPopup(true)}>Import workflow</Button>}
                    <Button onClick={() => addNode()}>New stage</Button>
                    <Button variant="contained" type={"submit"} onClick={() => {
                        containerRef.current && containerRef.current.dispatchEvent(
                            new Event("submit", {bubbles: true, cancelable: true}));
                    }}>Save</Button>
                </Stack>
            </div>
            <Popup title={"Switch to simple workflow builder"} open={simpleWorkflowPopup} onClose={() => setSimpleWorkflowPopup(false)}>
                <Stack alignItems={"center"}>
                    <Typography>By switching, you will lose this current workflow, and will need to rebuild your workflow with the simple workflow builder.</Typography>
                    <Button onClick={switchToSimpleWorkflow}>Switch to simple workflow builder</Button>
                    <Collapse in={Boolean(error)} sx={{marginRight: "10px"}}>
                        <Alert severity="error">{error}</Alert>
                    </Collapse>
                </Stack>
            </Popup>
            <Popup title={"How to use the workflow builder"} maxWidth={"lg"} fullWidth open={tutorialPopup} onClose={() => setTutorialPopup(false)}>
                <video className='videoTag' controls width={"100%"}>
                    <source src={workflowTutorial} type='video/mp4' />
                </video>
            </Popup>

            <Popup open={importWorkflowPopup} onClose={() => setImportWorkflowPopup(false)} title={"Import workflow"}>
                <Typography>{user.product === "institutes" ? "Import a workflow from another cohort." : "Import another workflow."}</Typography>
                <Form functionType="sync" onSubmit={(e) => {
                    setWorkflowNodes(importableItems[e.cohort as string].workflow);
                    setImportWorkflowPopup(false);
                }}>
                    <Dropdown name={user.product === "institutes" ? "cohort" : "workflow"} label={`Select ${user.product === "institutes" ? "cohort" : "workflow"}`} required>
                        {Object.entries(importableItems || {}).map(([id, cohort]) => <MenuItem value={id}>{cohort.name}</MenuItem>)}
                    </Dropdown>
                </Form>
            </Popup>

            <Popup open={tutorialActive} onClose={() => setTutorialActive(false)} title={"Workflow tutorial"}>
                <Stack spacing={2}>
                    <Typography variant='h6'>Create a placement processing pathway with Workflows.</Typography>
                    <Typography>Workflows allow you to create pathways of signoff. Get staff, students, parents/guardians and the work experience provider to complete forms and important files.</Typography>
                    <img src={workflowStages} style={{width: "100%"}} alt={"Example workflow stages."}/>
                    <Typography>Arrows between stages are shown as buttons to the user. You can specify whether the user must complete forms and view files to progress the placement.</Typography>
                    <Typography>With this, you can create complex workflows, such as a stage showing a "High risk" and "Low risk" button that progresses the placement to different stages.</Typography>
                    <Typography>Arrows between stages are shown as buttons to the user.</Typography>
                    <img src={workflowButtons} style={{width: "100%"}} alt={"Example workflow buttons."}/>
                </Stack>
            </Popup>
            {user.product !== "admin" && <FileUploaderPopup accept="application/pdf" message="Upload .pdf files to include in your workflow." onClose={() => setFilePopupActive(false)} open={filePopupActive} onSubmit={uploadFile}/>}
        </Page>
    );
}


export const MemoizedWorkflowElement = React.memo(WorkflowElement, (prevProps, nextProps) => objectsEqual(prevProps.data, nextProps.data) && prevProps.newEdgePoint === nextProps.newEdgePoint && prevProps.files === nextProps.files);

type WorkflowElementParams = {
    id: number,
    data: WorkflowStage|ApplicantStage,
    onMoveEnd: (id: number, ref: HTMLDivElement|undefined) => void,
    onDelete: (id: number) => void,
    addEdgePoint: (destination: number, origin: number) => void,
    onChange: (path: (string|number)[], value:unknown) => void,
    files: {name: string, url: string}[],
    setArrows: Dispatch<React.SetStateAction<ArrowObject[]>>,
    openPopup: () => void,
    newEdgePoint: any,
};

function WorkflowElement({id, data, onMoveEnd, onDelete, addEdgePoint, onChange, files, setArrows, openPopup, newEdgePoint}: WorkflowElementParams) {
    const [forms, setForms] = useState<{[key:string]: {name: string}}>({});
    const firebaseQuery = new FirebaseQuery();

    const user = useContext(UserContext);
    const org = useContext(OrganisationContext);
    const cardRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (user.product === "admin") {
            firebaseQuery.getDocsWhere("forms", [where("oId", "==", user.oId), where("product", "==", user.product)]).then((c) => setForms(c as {[key:string]: {name: string}}));
        } else {
            setForms(org.forms as {[key:string]: {name: string}});
        }
    }, []);

    const handleDeadlineTypeChange = (e: React.MouseEvent<HTMLElement, MouseEvent>, n: unknown) => {
        if ( n === null) {
            return;
        }
        onChange([data.id, "deadlineType"], n);
        onChange([data.id, "deadline"], "");
    };

    useEffect(() => {
        setArrows((a) => a.map((arrow) => {
            if (![arrow.start, arrow.end].includes(data.id)) {
                return arrow;
            }
            const newArrow = {...arrow};
            return newArrow;
        }));
    }, []);

    return (
        <Draggable
            defaultPosition={data.pos}
            handle={".handle"}
            position={data.pos}
            onStop={() => {
                onMoveEnd(id, cardRef.current);
            }}
            onDrag={(e, n) => {
                setArrows((a) => a.map((arrow) => {
                    if (![arrow.start, arrow.end].includes(data.id)) {
                        return arrow;
                    }
                    const newArrow = {...arrow};
                    newArrow.screenX = n.x;
                    return newArrow;
                }));
            }}
        // scale={1}
        >
            <DraggableCard ref={cardRef as RefObject<HTMLDivElement>} id={`node_${data.id}`}>
                {![1, 6, 8, 11].includes(data.id) && <DeleteCardButton responsive={false} title="Delete stage" visible={true} onClick={() => onDelete(data.id)}>x</DeleteCardButton>}
                <div className='handle' style={{padding: "0px 30px 10px 30px"}}>
                    <div style={{background: "lightgrey", height: "4px", width: "100%", borderRadius: "5px", marginBottom: "3px"}}></div>
                    <div style={{background: "lightgrey", height: "4px", width: "100%", borderRadius: "5px"}}></div>
                </div>
                <NewConnectionButton responsive={false} onClick={() => addEdgePoint(data.id, newEdgePoint)} title={"New connection"} $top><Circle fontSize={"small"} color={"primary"}/></NewConnectionButton>
                <NewConnectionButton responsive={false} onClick={() => addEdgePoint(data.id, newEdgePoint)} title={"New connection"} $left><Circle fontSize={"small"} color={"primary"}/></NewConnectionButton>
                <NewConnectionButton responsive={false} onClick={() => addEdgePoint(data.id, newEdgePoint)} title={"New connection"} $right><Circle fontSize={"small"} color={"primary"}/></NewConnectionButton>
                <NewConnectionButton responsive={false} onClick={() => addEdgePoint(data.id, newEdgePoint)} title={"New connection"} $bottom><Circle fontSize={"small"} color={"primary"}/></NewConnectionButton>
                <Stack spacing={1}>
                    <InputGroup disabled={data.checkpoint} required label={"Stage name"} value={data.name} onInputEnd={(e: ChangeEvent<HTMLInputElement>) => onChange([data.id, "name"], e.target.value)}/>
                    {data.checkpoint || <>
                        {user.product === "institutes" && <><Dropdown size={"small"} value={(data as WorkflowStage).userType} label='Action from' required onChange={(e) => onChange([data.id, "userType"], e.target.value)}>
                            <MenuItem value={"Students"}>Student</MenuItem>
                            <MenuItem value={"Staff"}>Staff</MenuItem>
                            <MenuItem value={"Provider"}>Provider</MenuItem>
                            <MenuItem value={"Parent"}>Parent/Guardian</MenuItem>
                        </Dropdown>
                        {(data as WorkflowStage).userType === "Provider" && <FormControlLabel onChange={(_, checked) => {
                            onChange && onChange([data.id, "eli"], checked);
                        }} checked={(data as WorkflowStage).eli} label={"Require Employers liability insurance"} control={<Checkbox/>}/>}
                        {(data as WorkflowStage).userType === "Provider" && <FormControlLabel onChange={(_, checked) => {
                            onChange && onChange([data.id, "riskAssessment"], checked);
                        }} checked={(data as WorkflowStage).riskAssessment} label={"Require risk assessment"} control={<Checkbox/>}/>}</>}
                        <Dropdown
                            label='Forms'
                            multiple
                            value={data.forms}
                            onChange={(e) => onChange([data.id, "forms"], (e.target.value as unknown as unknown[]).filter((x: unknown) => x !== undefined))}>
                            {Object.entries(forms).map(([key, e]) => {
                                return (<MenuItem value={key}>{e.name}</MenuItem>);
                            })}
                            <Divider/>
                            <MenuItem onClick={()=>window.open(`/${user.product}/organisation/resources/f`, "_blank")}>
                                {"Create form"}
                            </MenuItem>
                        </Dropdown>
                        <Dropdown
                            label='Files'
                            multiple
                            value={data.files}
                            onChange={(e) => {
                                onChange([data.id, "files"], (e.target.value as unknown as unknown[]).filter((x) => x !== undefined));
                            }}>
                            {files.map((e) => {
                                return (<MenuItem value={JSON.stringify(e)}>{e.name}</MenuItem>);
                            })}
                            <Divider/>
                            <MenuItem onClick={(e) => {
                                openPopup();
                                e.stopPropagation();
                                e.preventDefault();
                            }}>
                                {"Upload files"}
                            </MenuItem>
                        </Dropdown>
                    </>}
                    {![1, 6, 8, 11].includes(data.id) &&
                        <>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography>Deadline</Typography>
                                <ToggleButtonGroup
                                    value={data.deadlineType || "days" }
                                    exclusive
                                    onChange={handleDeadlineTypeChange}
                                    aria-label="Deadline type"
                                    size={"small"}>
                                    <ToggleButton value="date" aria-label="Date">Date</ToggleButton>
                                    <ToggleButton value="days" aria-label="Days">Days</ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            {data.deadlineType === "date" ? <InputGroup label='Deadline' InputLabelProps={{shrink: true}} type={"date"} value={data.deadline} onInputEnd={(e: ChangeEvent<HTMLInputElement>) => onChange([data.id, "deadline"], e.target.value)}/> :
                                <InputGroup label='Deadline (# days)' InputLabelProps={{shrink: true}} type={"number"} min={0} value={data.deadline} onInputEnd={(e: ChangeEvent<HTMLInputElement>) => onChange([data.id, "deadline"], e.target.value)}/>}
                        </>}                </Stack>
            </DraggableCard>
        </Draggable>
    );
}

type WorkflowArrow = {
    onDelete?: (arrow: ArrowObject) => void,
    onChange?: (path: (string|number)[], value: unknown) => void,
    cursor?: boolean,
    data: ArrowObject
}

function WorkflowArrow({onDelete, onChange, cursor, data}:WorkflowArrow) {
    const [hover, setHover] = useState(false);

    const acceptEndAnchor: anchorType = [{position: "right", offset: {y: 20}}, {position: "top", offset: {x: 20}}, {position: "bottom", offset: {x: 20}}, {position: "left", offset: {y: 20}}];
    const acceptStartAnchor: anchorType = [{position: "right", offset: {y: -20}}, {position: "top", offset: {x: -20}}, {position: "bottom", offset: {x: -20}}, {position: "left", offset: {y: -20}}];
    return (
        <Xarrow
            passProps={{onClick: () => setHover((h) => !h), cursor: "pointer"}}
            labels={
                <Stack spacing={1} onFocus={() => setHover(true)} onBlur={() => setHover(false)} position={"relative"}>
                    {(typeof data.name === "boolean" && data.name === false) || <InputGroup label={"Button name"} required sx={{background: "white", width: "150px"}} value={data.name} onInputEnd={(e: ChangeEvent<HTMLInputElement>) => {
                        onChange && onChange([data.start, "buttons", data.end, "name"], e.target.value);
                    }}/>}
                    {(typeof data.name === "boolean" && data.name === false) || <FormControlLabel onChange={(_, checked) => {
                        onChange && onChange([data.start, "buttons", data.end, "required"], checked);
                    }} checked={data.required} sx={{background: "white", border: "1px solid #bdbdbd", padding: "0px 5px", color: "#000000AA", borderRadius: "16px"}} label={"Forms/Files"} control={<Checkbox sx={{padding: 0}}/>}/>}
                    <DeleteCardButton responsive={false} title="Delete arrow" visible={hover} onClick={() => onDelete && onDelete(data)}>x</DeleteCardButton>
                </Stack>}
            startAnchor={acceptStartAnchor}
            endAnchor={cursor ? "middle" : acceptEndAnchor}
            color={PRIMARY_COLOUR}
            start={cursor ? data.start.toString() : `node_${data.start}`}
            end={cursor ? data.end.toString() : `node_${data.end}`}/>
    );
}

