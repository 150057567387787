import {MouseEvent, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import isHotkey from "is-hotkey";
import {Editable, withReact, useSlate, Slate, ReactEditor} from "slate-react";
import {
    BaseEditor,
    Descendant,
    Editor,
    Transforms,
    createEditor,
} from "slate";
import {withHistory} from "slate-history";
import {Stack, styled, Link, Box} from "@mui/material";
import {AddLink, CheckCircleRounded, FormatBold, FormatItalic, FormatUnderlined, SaveRounded} from "@mui/icons-material";
import IconButtonPop from "./IconButtonPop";
import Preloader from "./Preloader";
import {Popup} from "./Popup";
import Form from "./Form";
import InputGroup from "./FormComponents/InputGroup";

const HOTKEYS = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "code",
};

type Params = {
    initialValue?: Descendant[],
    onChange?: ((value: Descendant[]) => void),
    onSave?: (text: Descendant[]) => Promise<void>,
    readOnly?: boolean,
    name?: string,
    height?: string
}

export default function SlateEditor({initialValue = defaultText, height="100%", onChange, onSave, readOnly, name}: Params) {
    const renderElement = useCallback((props: any) => {
        console.log("PROPS", props);
        return (<Element {...props} />);
    }, []);
    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);
    const [textContent, setTextContent] = useState<Descendant[]>(initialValue);
    const [saving, setSaving] = useState<"saving"|"unsaved"|"saved"|undefined>();
    const [linkPopupOpen, setLinkPopupOpen] = useState(false);

    console.log("IV", initialValue);

    useEffect(() => {
        if (initialValue === textContent) return;
        setTextContent(initialValue);
        Transforms.delete(editor, {
            at: {
                anchor: Editor.start(editor, []),
                focus: Editor.end(editor, []),
            },
        });

        // Removes empty node
        Transforms.removeNodes(editor, {
            at: [0],
        });

        // Insert array of children nodes
        Transforms.insertNodes(
            editor,
            initialValue
        );
    }, [initialValue]);

    useEffect(() => {
        if (saving === "unsaved") {
            window.onbeforeunload = function() {
                return "All unsaved data will be lost. Are you sure?";
            };
            return;
        }
        window.onbeforeunload = null;
    }, [saving]);

    useEffect(() => {
        console.log("TC", textContent);
    }, [textContent]);

    const handleInsertLink = (editor: ReactEditor, text: string, url: string) => {
        let fUrl = url;
        if (!fUrl.includes("https://") && !fUrl.includes("http://")) {
            fUrl = "https://"+fUrl;
        }
        try {
            new URL(fUrl);
        } catch (_) {
            throw new Error("Not formatted correctly.");
        }
        setLinkPopupOpen(false);
        insertLink(editor, text, fUrl); // will be implemented later
    };

    return (
        <Box height={height} display={"flex"} flexDirection={"column"}>
            {name && <input name={name} hidden readOnly value={JSON.stringify(textContent)}/>}
            <Slate editor={editor} value={textContent} onChange={(v) => {
                if (v === textContent) return;
                setSaving("unsaved");
                setTextContent(v);
                onChange && onChange(v);
            }}>
                <Editable
                    {...{renderElement, renderLeaf}}
                    onBlur={() => {
                        if (saving !== "unsaved" || !onSave) return;
                        setSaving("saving");
                        onSave(textContent).then(() => setSaving("saved"));
                    }}
                    readOnly={readOnly}
                    placeholder={readOnly ? "Nothing to see here..." : "Start typing…"}
                    spellCheck
                    style={{overflowY: "auto", overflowX: "hidden", flex: 1, border: "1px solid lightgrey", borderRadius: "5px", paddingLeft: 5}}
                    onKeyDown={(event) => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault();
                                const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS];
                                toggleMark(editor, mark);
                            }
                        }
                    }}
                />
                <Stack direction={"row"} alignItems={"center"}>
                    <Toolbar>
                        <MarkButton format="bold" icon={<FormatBold/>} />
                        <MarkButton format="italic" icon={<FormatItalic/>} />
                        <MarkButton format="underline" icon={<FormatUnderlined/>} />
                        <IconButtonPop sx={{marginTop: "-16px"}} title="Add link" onClick={() => setLinkPopupOpen(true)}><AddLink/></IconButtonPop>
                    </Toolbar>
                    {saving === "unsaved" && onSave && <IconButtonPop responsive={false} onClick={() => {
                        setSaving("saving");
                        onSave(textContent).then(() => setSaving("saved"));
                    }} title={"Save"}><SaveRounded color="primary"/></IconButtonPop>}
                    {saving === "saving" && <Preloader visible/>}
                    {saving === "saved" && <IconButtonPop responsive={false} title="Saved"><CheckCircleRounded color="success"/></IconButtonPop>}
                </Stack>
            </Slate>
            <Popup cardSx={{width: "400px", maxWidth: "90vw"}} open={linkPopupOpen} title={"Insert web link"} onClose={() => setLinkPopupOpen(false)}>
                <Form functionType="sync" submitText="Insert" onSubmit={(e) => handleInsertLink(editor, (e as {text: string, url: string}).text, (e as {text: string, url: string}).url)}>
                    <InputGroup label="Link text" name="text" required placeholder="Click link to view."/>
                    <InputGroup label="Link url" name="url" required placeholder="https://"/>
                </Form>
            </Popup>
        </Box>
    );
}


const insertLink = (editor: ReactEditor, text: string, url: string|null) => {
    if (!url) return;

    const link = {
        type: "link",
        href: url,
        children: [{text}],
    };

    ReactEditor.focus(editor);

    // Insert the new link node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, link);

    const para = {type: "paragraph", children: [{text: ""}]};
    Transforms.insertNodes(editor, para);
};


const toggleMark = (editor: BaseEditor, format: string) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const isMarkActive = (editor: BaseEditor, format: string) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format as keyof typeof marks] === true : false;
};

const Element = ({attributes, element, children}:{attributes: {[key:string]: unknown}, element: {[key:string]: unknown}, children: ReactNode}) => {
    console.log("Atts", attributes, element);
    if (element.type === "link") {
        console.log("LINK", element.href);
        return (<Link target="_blank" rel="noreferrer" href={element.href as string}>
            {children}
        </Link>);
    }
    return (
        <p {...attributes}>
            {children}
        </p>
    );
};


const Leaf = ({attributes, children, leaf}:{attributes: {[key:string]: unknown}, children: ReactNode, leaf: {[key:string]: unknown}}) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};

const MarkButton = ({format, icon}:{format:string, icon:ReactNode}) => {
    const editor = useSlate();
    return (
        <SlateButton
            active={isMarkActive(editor, format)}
            onMouseDown={(event:MouseEvent) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            {icon}
        </SlateButton>
    );
};

const defaultText = [
    {
        type: "paragraph",
        children: [
            {
                text: "",
            },
        ],
    },
];

type SlateButtonProps = {
    active?: boolean,
    reversed?: boolean,
}

export const SlateButton = styled("span")<SlateButtonProps>`
    cursor: pointer;
    color: ${(props) => props.reversed ? props.active ? "white" : "#aaa" : props.active ? "black" : "#ccc"};
`;

export const Toolbar = styled("div")`
    padding-top: 10px;
    bottom: 0;
    flex: 1;

    & > * {
        margin-left: 5px;
        margin-right: 5px;
    }
`;
