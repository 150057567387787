import {Grid, Icon, Stack, Typography} from "@mui/material";
import FadeInBox from "../../../Components/FadeInBox";
import {GradientText} from "../../../Util/styledComponents";
import FileDropzone from "../../../Components/FileDropzone/FileDropzone";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import IconButtonPop from "../../../Components/IconButtonPop";
import {ArrowBack, CheckCircleOutline} from "@mui/icons-material";
import {executeCallable, uploadFiles} from "placementt-core";
import {useEffect, useState} from "react";
import styled from "styled-components";
import Form from "../../../Components/Form";
import {Popup} from "../../../Components/Popup";
import {imageToPdf} from "../../../Util/util";

type Params = {
    visible: boolean,
    onComplete?: () => void,
    back?: () => void,
    providerId: string,
    pId?: string,
    uploaded?: boolean,
    expiry?: string,
    type?: "fade"|"popup"
}

export const FileUploaded = styled(Stack)`
  height: 100px;
  width: 300px;
  border: 2px grey solid;
  border-radius: 4px;
  position: relative;
  display: flex ;
  align-items: center ;
  justify-content: center;
  transition: background-color 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
`;

export default function UploadProviderInsurance({uploaded, expiry, visible, onComplete, back, providerId, pId, type="fade"}: Params) {
    const [fileUploaded, setFileUploaded] = useState<boolean>(uploaded || false);

    useEffect(() => {
        setFileUploaded(uploaded || false);
    }, [uploaded]);

    const uploadELI = async (e: {eli: any[], expiry: string}) => {
        const splitName = e.eli[0].name.split(".");
        const suffix = splitName[splitName.length - 1];

        if (!fileUploaded) {
            await uploadFiles(suffix === "pdf" ? e.eli[0] : await imageToPdf(e.eli[0]), `insurance/${providerId}.pdf`, "application/pdf");
        }
        await executeCallable("insurance-add", {
            pId: pId,
            providerId: providerId,
            expiry: e.expiry})
            .then(() => {
                onComplete && onComplete();
            });
        setFileUploaded(true);
    };

    const InsuranceForm = <Form onSubmit={(e) => uploadELI(e as {eli: any[], expiry: string})} submitText={"Submit"}>

        <Typography variant='subtitle1' textAlign={"center"}>To take on a work experience student, you must have employer's liability insurance. We will not share this beyond the schools that organise placements with you.</Typography>

        <Grid item>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {fileUploaded ?
                        <Stack alignItems='center' spacing={1}>
                            <FileUploaded spacing={1}>
                                <Icon fontSize="large"><CheckCircleOutline fontSize="large" color="success"/></Icon>
                                <Typography>File uploaded</Typography>
                            </FileUploaded>
                        </Stack> :
                        <FileDropzone name={"eli"} required accept={"application/pdf, image/png, image/jpeg"} label={"Upload Employers liability insurance"} />}
                </Grid>
                <Grid item xs={12}>
                    <InputGroup required value={expiry} id="expiry" type={"date"} label="Insurance expiry" name="expiry" />
                </Grid>
            </Grid>
        </Grid>
    </Form>;


    if (type === "fade") {
        return (
            <FadeInBox card visible={visible} cardTitle={[
                back && <IconButtonPop onClick={back} responsive={false} title={"Back"}>
                    <ArrowBack />
                </IconButtonPop>, <GradientText margin={"auto"} variant={"h4"}>Upload insurance</GradientText>]
            }>
                {InsuranceForm}
            </FadeInBox>
        );
    }

    return (
        <Popup open={visible} title={"Upload employers liability insurance"} onClose={() => back && back()}>
            {InsuranceForm}
        </Popup>
    );
}
