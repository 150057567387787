import {Grid, Paper, Typography, Box, Stack} from "@mui/material";
import React, {useState, useEffect} from "react";
import {Popup} from "../Popup";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "placementt-core";
import {orderBy} from "firebase/firestore";

interface TeamMember {
    name: string;
    role: string;
    description: string;
    funFacts: string,
    position: number;
    pictureUrl: string;
}

function Team() {
    const [teamMembers, setTeamMembers] = useState<{[key: string]: TeamMember}>({});
    // const [teamMembersArray, setTeamMembersArray] = useState<TeamMember[]>();
    const [open, setOpen] = useState(false);
    const [selectedTeamMember, setSelectedTeamMember] = useState<TeamMember>();

    const firebaseQuery = new FirebaseQuery();


    useEffect(() => {
        firebaseQuery.getDocsWhere("about", orderBy("position")).then(async (profiles) => {
            const processedData = Object.entries(profiles as {[key: string]: TeamMember}).map(async ([key, value]) => {
                const profilePicUrl = await getDownloadURL(ref(storage, `about/${key}.webp`));

                return [key, {...value, pictureUrl: profilePicUrl}];
            });

            const awaitedProcessedData = await Promise.all(processedData) as [string, TeamMember][];

            const finalTeamMembers = Object.fromEntries(awaitedProcessedData);

            setTeamMembers(finalTeamMembers);
        });
    }, []);

    const handleOpen = (teamMember : TeamMember) => {
        setSelectedTeamMember(teamMember);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedTeamMember(undefined);
    };

    const team = Object.values(teamMembers).map((teamMember) => (
        <Grid item xs={12} sm={6} md={4} lg={3} style={{marginTop: "16px", overflow: "hidden"}}>
            <Paper onClick={() => handleOpen(teamMember)}
                elevation={0}
                sx={{
                    "backgroundColour": "#FFC107",
                    "padding": 2,
                    "cursor": "pointer",
                    "display": "flex",
                    "flexDirection": "column",
                    "alignItems": "center",
                    "borderRadius": 4,
                    "transition": "all 250ms ease-in-out",
                    "&:hover": {
                        transform: "scale(1.05)",
                    },
                }}>
                <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <img
                        src={teamMember.pictureUrl}
                        width={"250px"}
                        height={"250px"}
                        style={{
                            marginBottom: 16,
                            borderRadius: "50%",
                            objectFit: "cover",
                            border: "4px solid #7920f5",
                        }}
                    />
                    <Typography variant="h5" sx={{textAlign: "center", fontWeight: "bold"}}>
                        {teamMember.name}
                    </Typography>
                    <Typography variant="h6" color="textSecondary" sx={{textAlign: "center"}}>
                        {teamMember.role}
                    </Typography>
                    <Typography variant="subtitle2" sx={{opacity: 0.5}}>Learn more</Typography>
                </Box>
            </Paper>
        </Grid>
    ));

    return (
        <Box sx={{flexGrow: 1, padding: 2}}>
            <Grid container spacing={2}>
                {team}
            </Grid>
            {selectedTeamMember && (
                <Popup
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    title={<Typography><Typography variant="h5" display={"inline-flex"}>{selectedTeamMember.name}</Typography>&emsp;{selectedTeamMember.role}</Typography>}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5} sx={{display: "flex", justifyContent: "center"}}>
                            <img
                                src={selectedTeamMember.pictureUrl}
                                height={"200px"}
                                width={"200px"}
                                style={{
                                    marginTop: "16px",
                                    marginBottom: "16px",
                                    borderRadius: "50%",
                                    border: "4px solid #7920f5",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Stack>
                                <Typography variant="body1">
                                    {selectedTeamMember.description}
                                </Typography>
                                <Typography variant="h6" color="textSecondary">
                                    Fun facts about me:
                                </Typography>
                                {selectedTeamMember.funFacts.split(", ").map((item) => <Typography>{item}</Typography>)}
                            </Stack>
                        </Grid>
                    </Grid>
                </Popup>
            )}
        </Box>
    );
}

export default Team;
