import {Box} from "@mui/material";
import {styled} from "@mui/system";
import mapboxGl, {Marker} from "mapbox-gl";
import {PRIMARY_COLOUR} from "placementt-core";
import {useEffect, useRef} from "react";

const MapContainer = styled(Box)`
    background-color: white;
    flex: 1;
    width: 100%;
    height: 100%;
    max-height: 1000px;
`;

mapboxGl.accessToken = "pk.eyJ1IjoidGhvbWFzYmFydG9uMTAwIiwiYSI6ImNrd3dkNjE3MzAyZzcydnExdTZiZ256MXYifQ.B5V75w78TKOYqs8NjeHqUg";

export default function Map({lat=54.7, lng=1, zoom=5, points=[]}) {
    const mapContainer = useRef(null);
    const map = useRef(null);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxGl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center: [lng, lat],
            zoom: zoom,
        });

        console.log("points", points);
        points.length && points.forEach(([markerLat, markerLng]) => {
            new Marker({color: PRIMARY_COLOUR})
                .setLngLat([markerLng, markerLat])
                .addTo(map.current);
        });
    }, []);

    return (
        <MapContainer ref={mapContainer} onRender={() => map.current.resize()}
        />
    );
}
