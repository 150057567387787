import {Box, Divider, List, ListItem, ListItemText, Stack} from "@mui/material";
import {ReactNode} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useCheckMobileScreen} from "../../Util/util";
import styled from "styled-components";
import Page from "../Page";

type ListParams = {
    items: {
            label?: string,
            route: string,
            element: ReactNode
        }[],
    pathLevel?: number
};

const StyledList = styled(List)<{$isMobile:boolean}>`
    width: ${(props) => props.$isMobile ? "100%" : "120px"};
    border-right: 1px solid lightgrey;
`;

export default function ListNavBar({items, pathLevel=4}: ListParams) {
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useCheckMobileScreen();

    const route = location.pathname.split("/")[pathLevel];


    return (
        <Stack width={"100%"} height={"100%"} direction={"row"} flex={1} spacing={0}>
            {(!route || !isMobile) && <StyledList $isMobile={isMobile}>
                {items.filter((i) => i.label).map((item) =>
                    <ListItem onClick={() => navigate(item.route)} button divider sx={{background: route === item.route ? "#dc82ff3b" : "transparent"}}>
                        <ListItemText primary={item.label}/>
                    </ListItem>)}
            </StyledList>}
            {isMobile || <Divider orientation="vertical" sx={{marginLeft: "0px !important"}}/>}
            <Routes>
                {items.map((item) => [<Route path={item.route} element={<Page titleUnderline title={item.label} back={"../"}>{item.element}</Page>}/>,
                    <Route path={item.route+"/*"} element={<Page titleUnderline back={"../"} title={item.label}>{item.element}</Page>}/>])}
                <Route path="*" element={isMobile ? <></> : <Box>Select an item.</Box>}/>
            </Routes>
        </Stack>
    );
}
