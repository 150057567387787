import {ListItem, ListItemText, Stack, Chip, ListItemSecondaryAction, Typography, Button} from "@mui/material";
import {QueryConstraint, where} from "firebase/firestore";
import {defaultStudentWorkflow, StudentPlacementData, UserData, useStudentPlacementList, WorkflowStage} from "placementt-core";
import {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {OrganisationContext, UserContext} from "../App";
import {RenderPlacementFlags} from "../Util/visualUtil";
import FilterList from "./FilterList";
import useWindowDimensions from "../Util/util";

// uid only provided if own placements. Provided on student dashboard

type Params = {
    title: string,
    sx?: {[key:string]: unknown},
    student?: UserData,
    queryConstraint?: QueryConstraint[],
    filters?: {[key:string]: unknown},
    urlRef?: string,
    card?: boolean
}


export default function StudentPlacementList({title, card, sx, student, queryConstraint, filters, urlRef}:Params) {
    const [renderedPlacements, setRenderedPlacements] = useState<(false | JSX.Element)[]>();

    const {width} = useWindowDimensions();
    const navigate = useNavigate();

    const user = useContext(UserContext) as UserData;
    const cohorts = useContext(OrganisationContext)?.cohorts;

    const filterNames: {
        [key: string]: {
            label: string;
            type: string;
            values: {
                [k: string]: any;
            };
            value: unknown;
        }} = {
            reqUserType: {
                label: "Required user",
                type: "dropdown",
                values: {
                    Staff: "Staff",
                    Students: "Student",
                    Provider: "Provider",
                    Parent: "Parent/Guardian",
                },
                value: filters?.["reqUserType"],
            },
        };


    useEffect(() => {
        changeQueryConstraints(Object.entries(filterNames).filter(([, item]) => item.value).map(([name, item]) => where(name, "==", parseInt(item.value as string) || item.value)));
    }, [filters]);


    const {placements, loadMoreIcon, loadMorePlacements, setQuery, changeQueryConstraints} = useStudentPlacementList({...{user, student}, queryConstraint: queryConstraint});

    useEffect(() => {
        const renderPlacements = async () => {
            if (!placements) return;
            setRenderedPlacements((await Promise.all(Object.entries(placements as {[key:string]:StudentPlacementData}).map(async ([key, item]) => {
                const workflow = item.cohort ? cohorts[item.cohort].workflow : defaultStudentWorkflow;
                const status = item.status;

                const wStage = workflow?.find((obj:WorkflowStage) => obj.id === status);

                const viewPlacementLocation = user.product === "students" ? `/${user.product}/placements/${key}` : user.userType === "Staff" ? `/${user.product}/cohorts/placements/${key}` : `../placements/${key}`;

                return (
                    <ListItem button key={key} divider id={key} onClick={() => navigate(viewPlacementLocation)}>
                        <ListItemText
                            primary={<Stack spacing={0}>
                                {width <= 500 && wStage &&
                                    <Stack height={"100%"} direction={"row"} sx={{justifyContent: "space-between"}}>
                                        <Chip label={item.statusType === "withdrawn" ? "Withdrawn" : wStage.name} color={item.statusType === "withdrawn" ? "error" : "primary"} sx={{width: "max-content", margin: "8px"}}/>
                                        <RenderPlacementFlags placement={item} workflow={workflow} icon/></Stack>}
                                <strong>{item.name}</strong>
                                <span>{item["address-line1"]} - {item.locality} - {item.postal_code}</span>

                            </Stack>}
                            secondary={`${item.startDate} - ${item.endDate}`}/>
                        {width > 500 && wStage && <ListItemSecondaryAction sx={{height: "100%"}}>
                            <Stack height={"100%"} direction={"row"} sx={{justifyContent: "space-between"}}>
                                <Chip label={item.statusType === "withdrawn" ? "Withdrawn" : wStage.name} color={item.statusType === "withdrawn" ? "error" : "primary"} sx={{width: "max-content", margin: "8px"}}/>
                                <RenderPlacementFlags placement={item} workflow={workflow} icon/>
                            </Stack>
                        </ListItemSecondaryAction>}
                    </ListItem>
                );
            }))).filter((x) => x));
        };
        renderPlacements();
    }, [placements, width]);

    return (
        <FilterList key={"placementList"} card={card} actionButton={user.userType === "Staff" && <Button onClick={() => navigate(`/${user.product}/create/${student?.id}`)}>Add placement</Button>} {...{title, sx, loadMoreIcon, urlRef}} noResultsText={<Typography sx={{textWrap: "balance"}}>No placements found. <Link to={`/${user.product}/create${user.userType === "Staff" ? `/${student?.id}` : ""}`} style={{textDecoration: "underline"}}>Click here</Link> to create one or click "Create" in the sidebar.</Typography>} onSearch={(e) => {
            setQuery(e);
        }} data={placements} onScrollBottom={() => {
            loadMorePlacements();
        }}>
            {renderedPlacements}
        </FilterList>
    );
}
