import {ApplicantStage, Application, ApplicationHookParams, PRIMARY_COLOUR, PlacementListing, ProviderData, camelCaseToNormal, capitaliseWords, convertDate, useCreateApplicationRenderer} from "placementt-core";
import {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../../App";
import {Popup} from "../../Components/Popup";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Collapse, Grid, Icon, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Stack, Table, TableRow, Typography} from "@mui/material";
import DateRangePickerNew from "../../Components/FormComponents/DateRangePickerNew";
import {LoadingButton} from "../../Components/LoadingButton";
import CustomFormPage from "../../Components/CustomFormPage";
import Files from "./Files";
import Alert from "../../Components/Alert";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";
import {AccessibleForward, Analytics, BusinessCenter, CheckCircleOutline, DirectionsBike, DirectionsCar, ExpandMore, Kitchen, Person} from "@mui/icons-material";
import {InfoTableCell, TitleLogo} from "../../Util/styledComponents";
import IconButtonPop from "../../Components/IconButtonPop";
import logo from "../../Images/logo.svg";
import {Link, useNavigate} from "react-router-dom";
import {isAndroid, isIOS, MobileView} from "react-device-detect";
import {PublicProfileBannerAndProfileImage} from "../Public/ProviderPage";

export function PlacementApplicationPopup({open, onClose, provider, listingId, listing, application, applicationId}: {open: boolean, onClose: () => void, onComplete?: (draft?: boolean) => void, listingId?: string, provider?: {details?: ProviderData, id?: string}, listing?: PlacementListing, application?: Partial<Application>, applicationId?: string}) {
    const user = useContext(UserContext);
    const orgContext = useContext(OrganisationContext);
    const navigate = useNavigate();

    const applicationHook = useCreateApplicationRenderer({...{user, listing, applicationId, orgContext, provider, application}, listingId: (listingId || application?.listingId) as string});

    console.log("APPLICATION", applicationId, applicationHook.fApplication);

    return (
        <>
            <Popup title={`${user.product === "providers" ? "Review" : "Apply for"} this placement`} fullWidth maxWidth={"lg"} open={open} onClose={onClose}>
                <Grid container mt={2}>
                    <Grid item xs={12} md={6}>
                        {(user.product === "providers" && user.oId === application?.providerId) ? <StudentInformation {...{applicationHook}}/> : user.userType === "Students" ? <ProviderInformation provider={{...provider}} listing={applicationHook?.fListing}/> : <Typography>Cannot find relevant information.</Typography>}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {user.id ? <Stack>
                            {applicationHook.fApplication?.status === "draft" && <DraftApplicationForm {...{applicationHook}} onComplete={(draft: boolean) => applicationHook.openSuccessPopup(draft ? "draftSaved" : "submitted")}/>}
                            {applicationHook.fApplication?.status === "submitted" && <Alert severity="primary">Application submitted on {convertDate(applicationHook.fApplication.completedSections?.[1].submitted, "visual") as string}</Alert>}
                            {applicationHook.fApplication.status === "submitted" && <UserInformation {...{applicationHook}}/>}
                            {applicationHook.fApplication.status === "submitted" && (applicationHook.fApplication.reqUserType === user.userType ? <UserStageForm key={"currentForm"} {...{applicationHook}} stage={applicationHook.fApplication.stage} onComplete={(outcome?: boolean) =>applicationHook.openSuccessPopup(outcome ? "outcome" : "stageComplete")}/> : <Alert severity="info">Currently waiting on {applicationHook.fApplication.reqUserType}</Alert>)}
                            {applicationHook.fApplication?.status && ["approved", "rejected"].includes(applicationHook.fApplication?.status) && <Alert severity="success">Application {applicationHook.fApplication?.status}</Alert>}
                        </Stack> :
                            <Stack alignItems={"center"} justifyContent={"space-around"}>
                                <TitleLogo style={{width: "200px", height: "200px", maxWidth: "90%"}} src={logo as any} alt="Placementt main logo, three rings inside each other, each a different shade of purple."/>
                                <Typography color={"primary"} textAlign={"center"} variant="h5">Create an account or login to apply for this placement.</Typography>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <BusinessCenter/>
                                        </ListItemIcon>
                                        <ListItemText primary={"Apply for placements"}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Person/>
                                        </ListItemIcon>
                                        <ListItemText primary={"Manage placement contacts"}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Analytics/>
                                        </ListItemIcon>
                                        <ListItemText primary={"Track progress towards placement targets"}/>
                                    </ListItem>
                                </List>
                                <Stack alignItems={"center"}>
                                    <Button onClick={() => navigate("/register/students", {state: {redirect: window.location.pathname+"/apply"}})} variant="contained"><Typography variant="h6">Create account</Typography></Button>
                                    <Link to={"/login"} state={{redirect: window.location.pathname+"/apply"}}>Or log in instead</Link>
                                </Stack>
                            </Stack>}
                    </Grid>
                </Grid>
            </Popup>
            <Popup open={Boolean(applicationHook.successPopup)} noCloseButton fullWidth onClose={() => {
                applicationHook.setSuccessPopup(undefined);
                onClose();
            }}>
                <Stack spacing={5} alignItems={"center"}>
                    <Typography variant="h4" textAlign={"center"}>{applicationHook.successPopup && applicationHook.successText[applicationHook.successPopup].title}</Typography>
                    <Icon sx={{width: "75px", height: "75px"}}><CheckCircleOutline color="success" sx={{width: "75px", height: "75px"}}/></Icon>
                    <Typography variant="h6" textAlign={"center"}>{applicationHook.successPopup && applicationHook.successText[applicationHook.successPopup].desc}</Typography>
                </Stack>
            </Popup>
        </>
    );
}


function DraftApplicationForm({applicationHook}: {applicationHook: ApplicationHookParams, onComplete?: (draft: boolean) => void}) {
    return (
        <>
            <Typography variant="h4">Apply now</Typography>
            {applicationHook.fListing && <MobileView>
                <Alert severity="info">
                    <Stack>
                        Apply through the app for a better user experience.
                        <Stack direction={"row"}>
                            <Button color="inherit" onClick={() => {
                                const prefix = window.location.href.includes("localhost") ? "exp://192.168.68.107:8081/--/" : "placementt://";
                                window.location.href = applicationHook.fListing ? `${prefix}provider/${applicationHook.fListing?.providerId}/placementListings/${applicationHook.fListing?.id}/apply` : "";
                            }}>Open in app</Button>
                            <Button color="inherit" onClick={() => {
                                if (isIOS) {
                                    window.location.href = "https://apps.apple.com/us/app/placementt/id6464076066";
                                }
                                if (isAndroid) {
                                    return;
                                }
                            }}>Download app</Button>
                        </Stack>
                    </Stack>
                </Alert>
            </MobileView>}
            <DateRangePickerNew noPast disabled={applicationHook.fApplication?.status && applicationHook.fApplication?.status !== "draft"} name="applicationDates" start={applicationHook.fApplication?.startDate} end={applicationHook.fApplication?.endDate} onChange={(dates) => {
                applicationHook.setFApplication((a) => ({...a, startDate: convertDate(dates?.start, "dbstring") as string, endDate: convertDate(dates?.end, "dbstring") as string}));
            }}/>
            <UserStageForm {...{applicationHook}} stage={1}/>
            <Collapse in={applicationHook.draftSaved}>
                <Alert severity="primary">Draft saved. Carry on here, or continue in the applications section of your account.</Alert>
            </Collapse>
            <LoadingButton>
                <Button onClick={async () => applicationHook.onFApply(true)}>Save for later</Button>
                <Button onClick={async () => applicationHook.onFApply()} variant="contained">Submit application</Button>
            </LoadingButton>
        </>
    );
}

function UserInformation({applicationHook}: {applicationHook: ApplicationHookParams}) {
    if (!applicationHook.fListing) return <Typography>Cannot review applications for deleted listings.</Typography>;

    const workflow = applicationHook?.fListing.applicantWorkflow;

    if (!workflow) return null;

    return (
        <>
            {Object.entries(applicationHook.fApplication?.completedSections || {}).filter(([stageId]) => parseInt(stageId) !== applicationHook.fApplication.stage).map(([stageId], id) => {
                const stageConfig = workflow.find((s) => s.id === parseInt(stageId));
                return (<Accordion defaultExpanded={id === Object.keys(applicationHook.fApplication?.completedSections || {}).length - 1} sx={{border: `1px solid ${PRIMARY_COLOUR}`, borderRadius: 4}}>
                    <AccordionSummary expandIcon={<ExpandMore/>}>
                        <Stack mr={4} spacing={0} width={"100%"}>
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography>{stageConfig?.name}</Typography>
                                <Chip color="primary" label={`Submitted by ${stageConfig?.userType === "Students" ? "student" : "staff"}`}/>
                            </Stack>
                            <Typography variant="caption">Review this information completed by the {stageConfig?.userType === "Students" ? "student" : "staff"}.</Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails sx={{boxShadow: "inset 0 5px 5px -5px rgba(0,0,0,0.8)"}}>
                        <UserStageForm {...{applicationHook}} stage={parseInt(stageId)}/>
                    </AccordionDetails>
                </Accordion>);
            })}
        </>
    );
}

function UserStageForm({applicationHook, stage}: {applicationHook: ApplicationHookParams, stage?: number, onComplete?: (outcome?: boolean) => void}) {
    if (!applicationHook.fApplication.stage) return <Typography>No stage</Typography>;

    const [fileUploadPopup, setFileUploadPopup] = useState<false|number>(false);
    const [form, setForm] = useState<{id: string, name: string, readOnly?: boolean, [key:string]: unknown}>();

    const targetStageId = stage || applicationHook.fApplication.stage;
    const [acceptPopup, setAcceptPopup] = useState(false);
    const [rejectPopup, setRejectPopup] = useState(false);

    const [applicantStage, setApplicantStage] = useState<{stage: ApplicantStage, completedSections: {
        submitted?: string | undefined;
        filesViewed?: string[] | undefined;
        formsCompleted?: {
            [key: string]: unknown;
        } | undefined;
        filesUploaded?: {
            [key: number]: string[];
        } | undefined;
    }}>();

    useEffect(() => {
        if (!applicationHook.fListing || !applicationHook.fListing.applicantWorkflowId || !stage) return;

        applicationHook.getCurrentStage(stage).then(setApplicantStage);
    }, [applicationHook.fApplication, stage]);

    const user = useContext(UserContext);

    const isCurrentStage = targetStageId === applicationHook.fApplication.stage;

    console.log("IS CURRENT STAGE", isCurrentStage);

    if (!applicantStage) return <Typography>Not current stage. {targetStageId} | {applicationHook.fApplication.stage}</Typography>;

    return (
        <>
            {applicantStage?.stage.forms && Object.keys(applicantStage?.stage.forms).length > 0 &&
                <>
                    <Typography variant="h6">{isCurrentStage ? "Complete these" : "Completed"} forms</Typography>
                    {applicantStage?.stage.formDetails && <List>
                        {Object.entries(applicantStage?.stage.formDetails).map(([id, form]) =>
                            <ListItem>
                                <ListItemText primary={form.name}/>
                                <ListItemSecondaryAction>
                                    {Object.keys(applicantStage?.completedSections?.formsCompleted || {}).includes(id) ? <Alert sx={{alignItems: "center"}} action={<Button size="small" color="inherit" onClick={() => setForm({schema: form, readOnly: !isCurrentStage, ...form} as {name: string, id: string})}>{isCurrentStage ? "Edit" : "View"}</Button>}>Completed</Alert> : <Button onClick={() => setForm({schema: form, ...form} as {name: string, id: string})}>View</Button>}
                                </ListItemSecondaryAction>
                            </ListItem>)}
                    </List>}
                </>}
            {applicantStage?.stage.viewableFiles && Object.keys(applicantStage.stage.viewableFiles).length > 0 &&
                <>
                    <Typography variant="h6">Review{isCurrentStage ? " these" : "ed"} documents</Typography>
                    {applicantStage.stage.viewableFiles && <List>
                        {Object.entries(applicantStage.stage.viewableFiles).map(([id, file]) =>
                            <ListItem>
                                <ListItemText primary={file.name}/>
                                <ListItemSecondaryAction>
                                    {applicantStage?.completedSections?.filesViewed?.includes(id) ? <Alert sx={{alignItems: "center"}} action={isCurrentStage && <Button size="small" color="inherit" onClick={() => applicationHook.viewFile(id, (url) => window.open(url, "_blank"))}>View</Button>}>Viewed</Alert> : <Button onClick={() => applicationHook.viewFile(id, (url) => window.open(url, "_blank"))}>View</Button>}
                                </ListItemSecondaryAction>
                            </ListItem>)}
                    </List>}
                </>}
            <Box>
                {applicantStage?.stage.requiredFiles && applicantStage.stage.requiredFiles.length > 0 &&
                    <>
                        <Typography variant="h6">Submit{isCurrentStage ? " these" : "ted"} files</Typography>
                        {applicantStage.stage.requiredFiles && <List>
                            {applicantStage.stage.requiredFiles.map((file, i) =>
                                <ListItem>
                                    <ListItemText
                                        primary={file.fileName}
                                        secondary={file.fileType}/>
                                    <ListItemSecondaryAction>
                                        {(applicantStage?.completedSections?.filesUploaded?.[i]?.length || 0) > 0 ? <Alert sx={{alignItems: "center"}} action={<Button size="small" color="inherit" onClick={() => isCurrentStage ? setFileUploadPopup(i) : applicantStage?.completedSections.filesUploaded?.[i].forEach((fileId) => window.open(applicationHook?.uploadedFiles?.[fileId].url, "_blank"))}>{isCurrentStage ? "Edit" : "View"}</Button>}>Uploaded</Alert> : <Button onClick={() => setFileUploadPopup(i)}>Upload</Button>}
                                    </ListItemSecondaryAction>
                                </ListItem>)}
                        </List>}
                    </>}
            </Box>
            {isCurrentStage && applicationHook.fApplication.status === "submitted" &&
            <Stack id="progress" direction={"row"} width={"100%"} justifyContent={"space-around"}>
                {(applicantStage.stage.buttons?.filter((b) => ![11, 12].includes(b.id))?.length || 0) > 0 && <LoadingButton disabled={!applicationHook.currentStageComplete}>
                    {applicantStage.stage.buttons?.filter((b) => ![11, 12].includes(b.id)).map((b) => <Button onClick={() => applicationHook.progressStage(b.id)}>{b.name}</Button>)}
                </LoadingButton>}
                {user.product === "providers" && <Button disabled={!applicationHook.currentStageComplete} variant={rejectPopup ? "contained" : "outlined"} color="error" onClick={() => setRejectPopup(true)}>Reject</Button>}
                {user.product === "providers" && applicantStage.stage.buttons?.find((b) => b.id === 11) && <Button disabled={!applicationHook.currentStageComplete} variant={acceptPopup ? "contained" : "outlined"} color="success" onClick={() => setAcceptPopup(true)}>Accept</Button>}
            </Stack>}
            {isCurrentStage && user.product === "providers" && applicantStage.stage.buttons?.find((b) => b.id === 11) && <Popup title={"Accept application"} open={acceptPopup} onClose={() => setAcceptPopup(false)}>
                <Form submitText="Accept" onSubmit={(e) => applicationHook.progressStage("accept", e as {feedback?: string}).then(() => setAcceptPopup(false))} functionType="async">
                    <Stack>
                        <Typography>Provide some feedback or additional information on this application.</Typography>
                        <InputGroup name="feedback" label={"Application feedback"} placeholder={"Type some constructive feedback or information to be shared with the student."} required minRows={4} multiline/>
                    </Stack>
                </Form>
            </Popup>}
            {isCurrentStage && user.product === "providers" && <Popup title={"Reject application"} open={rejectPopup} onClose={() => setRejectPopup(false)}>
                <Form submitText="Reject" onSubmit={(e) => applicationHook.progressStage("reject", e as {feedback?: string}).then(() => setRejectPopup(false))} functionType="async">
                    <Stack>
                        <Typography>Provide some feedback or additional information on this application.</Typography>
                        <InputGroup name="feedback" label={"Application feedback"} placeholder={"Type some constructive feedback or information to be shared with the student."} required minRows={4} multiline/>
                    </Stack>
                </Form>
            </Popup>}
            <Popup fullWidth open={Boolean(form?.id)} title={form?.name} onClose={() => setForm(undefined)}>
                {form && <CustomFormPage key={form?.name} embedded {...form} formData={Object.values(applicantStage?.completedSections.formsCompleted?.[form.id] || {})[0]} noNavigate noUpdate onComplete={async (e) => {
                    applicationHook.setFormComplete(form.id, e);
                    setFileUploadPopup(false);
                }}/>}
            </Popup>
            <Files popup open={Boolean(fileUploadPopup !== false)} onClose={() => setFileUploadPopup(false)} type="user" selectable onSubmitSelection={(e) => {
                applicationHook.addFile(e, fileUploadPopup as number);
                setFileUploadPopup(false);
            }} defaultSelected={fileUploadPopup !== false ? applicantStage?.completedSections?.filesUploaded?.[fileUploadPopup] : undefined}/>
        </>
    );
}

function StudentInformation({applicationHook}: {applicationHook: ApplicationHookParams}) {
    return (
        <>
            <ListItem sx={{height: "max-content"}}>
                <ListItemText
                    primaryTypographyProps={{fontSize: "22px"}}
                    secondaryTypographyProps={{fontSize: "18px"}}
                    primary={applicationHook.student?.details?.forename + " " + applicationHook.student?.details.surname}
                    secondary={applicationHook.fListing ? `${applicationHook.fListing?.["address-line1"]} | ${applicationHook.fListing?.postal_code?.toUpperCase()} | ${capitaliseWords(camelCaseToNormal(applicationHook.fListing?.country))}` : <Alert severity="error">Placement listing deleted</Alert>}
                />
            </ListItem>
        </>
    );
}

function ProviderInformation({provider, listing}: {provider?: {details?: ProviderData, profile?: string, id?: string}, listing?: PlacementListing|false}) {
    return (
        <>
            <ListItem sx={{height: "max-content"}}>
                <PublicProfileBannerAndProfileImage providerId={provider?.id}/>
                {listing ? <ListItemText
                    primaryTypographyProps={{fontSize: "22px"}}
                    secondaryTypographyProps={{fontSize: "18px"}}
                    primary={provider?.details?.name + " | " + listing?.title}
                    secondary={`${listing?.["address-line1"]} | ${listing?.postal_code?.toUpperCase()} | ${capitaliseWords(camelCaseToNormal(listing?.country))}`}
                /> :
                    <Stack>
                        <Typography>{provider?.details?.name}</Typography>
                        <Alert severity="error">Placement listing deleted</Alert>
                    </Stack>}
            </ListItem>
            {listing && <>
                <Typography>{listing?.questions?.description}</Typography>
                <br/>
                <Typography variant='h6' color={"primary"}>Experience given</Typography>
                <Stack direction={"row"} flexWrap={"wrap"}>
                    {listing?.questions?.experiencesGiven ? (Array.isArray(listing.questions.experiencesGiven) ? listing.questions.experiencesGiven : listing.questions.experiencesGiven.split(", ")).sort((a, b) => b.length - a.length).map((exp) =>
                        <Typography minWidth={"calc(50% - 20px)"} ml={"0 !important"}>• {exp}</Typography>
                    ) : <Typography>No information given.</Typography>}
                </Stack>
                <br/>
                <Typography variant='h6' color={"primary"}>Experience required</Typography>
                <Stack direction={"row"} flexWrap={"wrap"}>
                    {listing?.questions?.experienceDesired ? (Array.isArray(listing.questions.experienceDesired) ? listing.questions.experienceDesired : listing.questions.experienceDesired.split(", ")).sort((a, b) => b.length - a.length).map((exp) =>
                        <Typography minWidth={"calc(50% - 20px)"} ml={"0 !important"}>• {exp}</Typography>
                    ) : <Typography>No information given.</Typography>}
                </Stack>
                <br/>
                <Table size="small">
                    <TableRow>
                        <InfoTableCell width={"200px"}>Dress code</InfoTableCell>
                        <InfoTableCell>{camelCaseToNormal(listing?.questions?.dressCode)}</InfoTableCell>
                    </TableRow>
                    <TableRow>
                        <InfoTableCell>Working location</InfoTableCell>
                        <InfoTableCell>{camelCaseToNormal(listing?.questions?.workingLocation)}</InfoTableCell>
                    </TableRow>
                    <TableRow>
                        <InfoTableCell>Responsibilities</InfoTableCell>
                        <InfoTableCell>{listing?.questions?.responsibilities}</InfoTableCell>
                    </TableRow>
                    <TableRow>
                        <InfoTableCell>Required equipment</InfoTableCell>
                        <InfoTableCell>{listing?.questions?.equipment}</InfoTableCell>
                    </TableRow>
                    <TableRow>
                        <InfoTableCell>Other information</InfoTableCell>
                        <InfoTableCell>{listing?.questions?.otherAdvice}</InfoTableCell>
                    </TableRow>
                </Table>

                <Stack direction={"row"}>
                    <strong>Amenities: </strong>
                    {listing?.questions?.carParking && <IconButtonPop responsive={false} title='Car parking' sx={{color: "gray"}}><DirectionsCar/></IconButtonPop>}
                    {listing?.questions?.bikeStorage && <IconButtonPop responsive={false} title='Bike storage' sx={{color: "gray"}}><DirectionsBike/></IconButtonPop>}
                    {listing?.questions?.wheelchairFriendly && <IconButtonPop responsive={false} title='Wheelchair friendly' sx={{color: "gray"}}><AccessibleForward/></IconButtonPop>}
                    {listing?.questions?.kitchen && <IconButtonPop responsive={false} title='Kitchen' sx={{color: "gray"}}><Kitchen/></IconButtonPop>}
                </Stack>
            </>}
        </>
    );
}
