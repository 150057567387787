import {Alert, Box, Button, Stack, Typography} from "@mui/material";
import {ChangeEvent, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import InputGroup from "../../Components/FormComponents/InputGroup";
import Form from "../../Components/Form";
import Preloader from "../../Components/Preloader";
import {signOut} from "firebase/auth";
import {LoadingButton} from "../../Components/LoadingButton";
import {auth, executeCallable, PRIMARY_COLOUR, Products} from "placementt-core";
import Page from "../../Components/Page";
import FadeInBox from "../../Components/FadeInBox";

export default function ResetPassword() {
    const {uid, type} = useParams();
    const [validUser, setValidUser] = useState<"Expired"|"Not found"|"activate"|"Already active"|undefined>(undefined);
    const [data, setData] = useState<{forename?: string, email?: string, accountType?: Products, activated?: boolean, image?: string, color?: string, name?: string}>({});

    useEffect(() => {
        if (!uid) {
            return;
        }
        signOut(auth);

        executeCallable("checkUserExists", {uid: uid}).then((result) => {
            console.log("result", result);
            if (!result.data?.forename) {
                setData(result.data);
                setValidUser("Already active");
                return;
            }
            setData(result.data);
            setValidUser("activate");
        }).catch(() => {
            setValidUser("Expired");
        });
    }, [uid]);

    const navigate = useNavigate();

    const handleSubmit = async (e: {pOne: string, pTwo: string}) => {
        if (e.pOne !== e.pTwo) {
            throw new Error("Both passwords must match.");
        }

        return await executeCallable("userManagement-resetPassword", {uid: uid, password: e.pOne, type}).catch((e) => {
            throw e;
        }).then(() => {
            return {severity: "success", message: "Password reset.", button: {title: "Login", onClick: () => navigate("/login")}};
        });
    };

    const sendResetEmail = async () => {
        return await executeCallable("userManagement-sendPasswordResetEmail", {email: data.email}).catch((e) => {
            throw e;
        }).then(() => {
            return {severity: "success", message: "Password reset email sent."};
        });
    };


    if (!uid) {
        return (
            <Page fullHeight>
                <FadeInBox visible card cardTitle={"Reset password"}>
                    <Form onSubmit={sendResetEmail}>
                        <Typography>Enter your address to send a password reset email.</Typography>
                        <InputGroup type={"email"} value={data.email} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setData((d) => ({...d, email: e.target.value}));
                        }}/>
                    </Form>
                </FadeInBox>
            </Page>
        );
    }

    if (validUser === undefined) {
        return <Page fullHeight>
            <Preloader visible/>
        </Page>;
    }

    if (validUser === "Expired") {
        return (
            <Page fullHeight>
                <FadeInBox visible card cardTitle={"Reset password"}>
                    <Stack spacing={2} alignItems={"center"}>
                        <Typography>Password link expired.</Typography>
                        <Button onClick={() => navigate("/login")}>Login</Button>
                    </Stack>
                </FadeInBox>
            </Page>
        );
    }

    if (validUser === "Not found") {
        return (
            <Page fullHeight>
                <FadeInBox visible card cardTitle={type === "password" ? "Reset password" : "Activate account"}>
                    <Alert severity='error'>User not found.</Alert>
                </FadeInBox>
            </Page>
        );
    }

    if (validUser === "Already active") {
        return (
            <Page fullHeight>
                <FadeInBox visible card cardTitle={"Reset password"}>
                    <Stack alignItems='center' spacing={2}>
                        <Typography>{type === "activate" ? "Account activation" : "Password reset"} link expired.</Typography>
                        {data && data.activated && <Typography>User already active.</Typography>}
                        {type === "activate" && !(data && data.activated) ?
                            <LoadingButton onClick={sendResetEmail} text={"Resend activation email"}/> :
                            <Button onClick={() => navigate("/login")}>Login</Button>}
                    </Stack>
                </FadeInBox>
            </Page>
        );
    }


    return (
        <Page metaTitle="Placementt | Active account" fullHeight>
            <FadeInBox visible card cardTitle={type === "password" && "Reset password"}>
                <Form onSubmit={(e) => handleSubmit(e as {pOne: string, pTwo: string})}>
                    {data.image && <Box component={"img"} alignSelf={"center"} width={"100px"} src={data.image}/>}
                    {data.name && <Typography variant="h4" textAlign={"center"} color={data.color || PRIMARY_COLOUR}>{data.name}</Typography>}
                    {type === "password" || <Typography variant="h5">Activate your account</Typography>}
                    <Typography>Hi {typeof data === "object" ? data?.forename : undefined}, reset your password below.</Typography>
                    <input readOnly hidden name={"email"} value={typeof data === "object" ? data?.email : undefined}/>
                    <InputGroup name={"pOne"} type={"password"} placeholder={"Password"} autoComplete="new-password"/>
                    <InputGroup name={"pTwo"} type={"password"} placeholder={"Repeat password"} autoComplete="new-password"/>
                    <Typography variant="caption" sx={{opacity: 0.7}}>Passwords must be at least 8 characters in length and contain upper and lower case characters, a number and a special character.</Typography>
                </Form>
            </FadeInBox>
        </Page>
    );
}
