import {useNavigate, useParams} from "react-router-dom";
import {Box, Stack} from "@mui/material";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import {CohortData, UserData, dateToString} from "placementt-core";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import {where} from "firebase/firestore";
import {UserContext} from "../../../App";
import IconButtonPop from "../../../Components/IconButtonPop";
import {KeyboardArrowLeftRounded, KeyboardArrowRightRounded, KeyboardDoubleArrowLeftRounded, KeyboardDoubleArrowRightRounded} from "@mui/icons-material";
import ActivePlacementMap from "../../Shared/ActivePlacementMap";


export default function ActivePlacementsMap({cohort}:{cohort: CohortData|"all"}) {
    const currentDate = new Date();
    const {date = currentDate.getDate(), month = currentDate.getMonth()+1, year = currentDate.getFullYear()} = useParams();

    console.log("Year", year, "Month", month, "Date", date);
    const [dateObj, setDateObj] = useState(new Date(`${year}-${month}-${date}`));
    const navigate = useNavigate();
    const user = useContext(UserContext) as UserData;

    useEffect(() => {
        console.log(dateObj);
        if (!dateObj) return;
        if (dateObj === new Date(`${year}-${month}-${date}`)) return;
        console.log("Date", dateObj);

        const dateAsString = dateToString(dateObj);
        navigate(`${dateAsString.split("-")[2]}/${dateAsString.split("-")[1]}/${dateAsString.split("-")[0]}`);
    }, [dateObj]);

    return (
        <Box display={"flex"} flex={1} position={"relative"}>
            <ActivePlacementMap fullscreen={false} title="Active placements" secondary={
                <Stack direction={"row"} justifyContent={"space-around"} alignItems={"center"}>
                    <Stack direction={"row"} alignItems={"center"} spacing={0}>
                        <IconButtonPop title="Previous week" responsive={false} onClick={() => setDateObj((d) => {
                            const n = new Date(d);
                            n.setDate(n.getDate()-7);
                            return n;
                        })}><KeyboardDoubleArrowLeftRounded/></IconButtonPop>
                        <IconButtonPop title="Previous day" responsive={false} onClick={() => setDateObj((d) => {
                            const n = new Date(d);
                            n.setDate(n.getDate()-1);
                            return n;
                        })}><KeyboardArrowLeftRounded/></IconButtonPop>
                        <InputGroup type={"date"} sx={{width: "max-content"}} onChange={(e:ChangeEvent<HTMLInputElement>) => setDateObj(new Date(e.target.value))} value={dateToString(dateObj)}/>
                        <IconButtonPop title="Next day" responsive={false} onClick={() => setDateObj((d) => {
                            const n = new Date(d);
                            n.setDate(n.getDate()+1);
                            return n;
                        })}><KeyboardArrowRightRounded/></IconButtonPop>
                        <IconButtonPop title="Next week" responsive={false} onClick={() => setDateObj((d) => {
                            const n = new Date(d);
                            n.setDate(n.getDate()+7);
                            return n;
                        })}><KeyboardDoubleArrowRightRounded/></IconButtonPop>
                    </Stack>
                </Stack>
            } query={[{path: "placements", constraints: [where("oId", "==", user.oId), where("activeDates", "array-contains", dateToString(dateObj)), ...(cohort === "all" ? [] : [where("cohort", "==", cohort.id)])]}]}/>
        </Box>
    );
}
