import {Switch} from "@mui/material";
import {useEffect, useState} from "react";

type Params = {
    defaultChecked?: boolean,
    checked?: boolean,
    onCheck?: (name: string, checked: boolean) => void,
    name: string,
    id?: string,
    disabled?: boolean,
    obj?: string
}

function ControlledSwitch({checked, onCheck, name, id, disabled}: Params) {
    const [isChecked, setIsChecked] = useState(checked || false);
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if (checked === undefined) return;
        setIsChecked(checked);
    }, [checked]);

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
            return;
        }
        onCheck && onCheck(name, isChecked);
    }, [isChecked]);

    return (
        <Switch id={id} name={name} disabled={disabled} onClick={() => setIsChecked((c) => !c)} checked={isChecked}/>
    );
}

ControlledSwitch.componentName = "ControlledSwitch";

export default ControlledSwitch;
