import {Accordion, AccordionDetails, AccordionSummary, Box, Collapse, Divider, Grid, MenuItem, Radio, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {Popup} from "../../../Components/Popup";
import Dropdown from "../../../Components/FormComponents/Dropdown";
import {ChangeEvent, useContext, useState} from "react";
import {OrganisationContext} from "../../../App";
import {PRIMARY_COLOUR, ProviderUserGroup, QueryObject, RegistrationRequest, UserData, UserGroupData, camelCaseToNormal, capitalise, executeCallable} from "placementt-core";
import {ToggleFormItem} from "../../../Util/styledComponents";
import ControlledSwitch from "../../../Components/FormComponents/ControlledSwitch/ControlledSwitch";
import {LoadingButton} from "../../../Components/LoadingButton";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import Alert from "../../../Components/Alert";


type Params = {
    open: boolean,
    onClose: () => void,
    userType: "Staff"|"Requests",
    filters?: {[key:string]: unknown},
    users: QueryObject[] | {[key: string]: UserData|RegistrationRequest},
}

export default function ActivateProviderUsersPopup({open, onClose, userType, filters, users}: Params) {
    const org = useContext(OrganisationContext);

    const userGroups = org.userGroups as {[key: string]: UserGroupData};

    const [userGroupType, setUserGroupType] = useState<"select"|"basic"|"advanced"|"readOnly">(Object.keys(userGroups).length ? "select" : "basic");
    const [userGroupDetails, setUserGroupDetails] = useState<Partial<ProviderUserGroup>>({
        createPlacementListing: "any",
        viewPlacementListings: "request",
        viewPlacementGroups: "request",
        viewAddresses: "request",
        addAddresses: true,
        processApplicants: true,
    });
    const [selectedUserGroup, setSelectedUserGroup] = useState<string>();

    const activateUsers = async () => {
        if (userGroupType === "select" && !selectedUserGroup) {
            throw new Error("Select a user group to assign to your users");
        }

        await executeCallable("userManagement-activateUsers", {
            users: users, filters: filters, userType: userType, userGroupType: userGroupType, userGroupData: userGroupType === "select" ? selectedUserGroup : userGroupDetails,
        });
    };

    return (
        <Popup title={"Activate user accounts"} {...{open, onClose}} fullWidth maxWidth={"lg"} actions={<LoadingButton onClick={activateUsers} variant="contained" noFlexGrow text="Activate" successText="Users successfully activated."/>}>
            <Grid container sx={{minHeight: "400px"}}>
                <Grid item sm={12} md={4}>
                    <Typography>Activating these users will enable them to access the platform.</Typography>
                    <br/>
                    <Typography>We will send each user an email requesting they create a password and sign in.</Typography>
                    <Box border={`2px solid ${PRIMARY_COLOUR}`} borderRadius={3} p={2} pl={3} pr={3} m={2} mr={5}>
                        <Typography variant="h6" textAlign={"center"}>Activate {Array.isArray(users) ? (filters && Object.keys(filters).length) ? "all filtered" : "all" : Object.keys(users).length} user{(!Array.isArray(users) && Object.keys(users).length) === 1 ? "" : "s"}</Typography>
                    </Box>
                    {(filters && Object.keys(filters).length > 0) &&
                    <Box border={`2px solid ${PRIMARY_COLOUR}`} borderRadius={3} p={2} pl={3} pr={3} m={2} mr={5}>
                        <Typography variant="h6">Filters</Typography>
                        {Object.entries(filters).map(([k, f]) => <Typography><strong>{capitalise(camelCaseToNormal(k))}: </strong>{f as string}</Typography>)}
                    </Box>}
                </Grid>
                <Grid item sm={12} md={8} borderLeft={"1px solid lightgrey"}>
                    <Typography>Set staff permissions through user groups. You can either select a user group here, or create a new set of permissions for these users.</Typography>
                    <Accordion sx={{boxShadow: "none", borderBottom: "lightgrey 1px solid"}} disableGutters expanded={userGroupType === "select"} onChange={(_, open) => setUserGroupType((n) => open ? "select" : n)}>
                        <AccordionSummary>
                            <Stack direction={"row"} alignItems={"center"}>
                                <Radio checked={userGroupType === "select"}/>
                                <Box>
                                    <Typography variant="h6">Select an existing user group</Typography>
                                    <Typography variant="subtitle2" sx={{opacity: 0.75}}>Assign permissions from an existing user group.</Typography>
                                </Box>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Dropdown label="Select a group" onChange={(e) => setSelectedUserGroup(e.target.value)}>
                                <MenuItem value={"admin"}>Super admin</MenuItem>
                                <Divider/>
                                {Object.entries(userGroups).map(([k, v]) => <MenuItem value={k}>{v.name}</MenuItem>)}
                            </Dropdown>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{boxShadow: "none", borderBottom: "lightgrey 1px solid"}} disableGutters expanded={userGroupType === "basic"} onChange={(_, open) => setUserGroupType((n) => open ? "basic" : n)}>
                        <AccordionSummary>
                            <Stack direction={"row"} alignItems={"center"}>
                                <Radio checked={userGroupType === "basic"}/>
                                <Box>
                                    <Typography variant="h6">Configure users manually (Basic)</Typography>
                                    <Typography variant="subtitle2" sx={{opacity: 0.75}}>This will create a new user group.</Typography>
                                </Box>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack>
                                <InputGroup name="userGroupName" required label={"User group name"} onChange={(e: ChangeEvent<HTMLInputElement>) => setUserGroupDetails((u) => ({...u, name: e.target.value}))}/>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box>
                                        <Typography>Create placement listings</Typography>
                                        {userGroupDetails.createPlacementListing === "template" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can create placements from a template, associated with their addresses.</Typography>}
                                        {userGroupDetails.createPlacementListing === "any" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can create placements from scratch, associated with their addresses.</Typography>}
                                        {userGroupDetails.createPlacementListing === "none" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff cannot create placement listings</Typography>}
                                    </Box>
                                    <ToggleButtonGroup value={userGroupDetails?.createPlacementListing} onChange={(_, v) => v && setUserGroupDetails((u) => ({...u, createPlacementListing: v}))} exclusive color="primary">
                                        <ToggleButton value={"any"}>Yes</ToggleButton>
                                        <ToggleButton sx={{minWidth: "max-content"}} value={"template"}>From template</ToggleButton>
                                        <ToggleButton value={"none"}>No</ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box>
                                        <Typography>View placement listings</Typography>
                                        {userGroupDetails.viewPlacementListings === "all" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can view all placements listings.</Typography>}
                                        {userGroupDetails.viewPlacementListings === "request" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can request to view any placement listings.</Typography>}
                                        {userGroupDetails.viewPlacementListings === "none" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff cannot view any placement listings.</Typography>}
                                    </Box>
                                    <ToggleButtonGroup value={userGroupDetails?.viewPlacementListings} onChange={(_, v) => v && setUserGroupDetails((u) => ({...u, viewPlacementListings: v}))} exclusive color="primary">
                                        <ToggleButton value={"all"}>All</ToggleButton>
                                        <ToggleButton value={"request"}>Request access</ToggleButton>
                                        <ToggleButton value={"none"}>None</ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                                <Collapse in={userGroupDetails.viewPlacementListings === "request" && userGroupDetails.viewAddresses === "all"}>
                                    <Alert severity="primary">Staff will request specific access to view placement listings. Once granted by a Super Admin, they can view all associated applicants and placements.</Alert>
                                </Collapse>
                                <Collapse in={userGroupDetails.viewPlacementListings === "none"}>
                                    <Alert severity="warning">Staff will not be able to view any listings or associated placements students are attending.</Alert>
                                </Collapse>
                                <Divider/>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box>
                                        <Typography>View addresses</Typography>
                                        {userGroupDetails.viewAddresses === "all" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can view all addresses.</Typography>}
                                        {userGroupDetails.viewAddresses === "request" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can request to view any addresses.</Typography>}
                                        {userGroupDetails.viewAddresses === "none" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff cannot view any addresses, or associated placement listings.</Typography>}
                                    </Box>
                                    <ToggleButtonGroup value={userGroupDetails?.viewAddresses} onChange={(_, v) => v && setUserGroupDetails((u) => ({...u, viewAddresses: v}))} exclusive color="primary">
                                        <ToggleButton value={"all"}>All</ToggleButton>
                                        <ToggleButton value={"request"}>Request access</ToggleButton>
                                        <ToggleButton value={"none"}>None</ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                                <Collapse in={userGroupDetails.viewAddresses === "none" && userGroupDetails.viewPlacementListings !== "none"}>
                                    <Alert severity="error">Setting "View addresses" to none will prevent users from seeing any placement listings. If they would like access, they will have to request it directly.</Alert>
                                </Collapse>
                                <Collapse in={userGroupDetails.viewAddresses === "request" && userGroupDetails.viewPlacementListings !== "none"}>
                                    <Alert severity="primary">When a staff member first logs in, we will allow them to select addresses {userGroupDetails.viewPlacementListings === "request" ? "and placement listings " : ""} they would like to view. They can then view associated applications and placements.</Alert>
                                </Collapse>
                                {false && <ToggleFormItem>
                                    <Box>
                                        <Typography>Create placement groups</Typography>
                                        <Typography variant="subtitle2" sx={{opacity: 0.75}}>Allow staff to create groups of placements.</Typography>
                                    </Box>
                                    <ControlledSwitch id={"processApplications"} name={"processApplications"} onCheck={(_, c) => setUserGroupDetails((u) => ({...u, processApplicants: c}))} defaultChecked={false}/>
                                </ToggleFormItem>}
                                {false && <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box>
                                        <Typography>View placement groups</Typography>
                                        {userGroupDetails.viewPlacementGroups === "all" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can view all placements groups.</Typography>}
                                        {userGroupDetails.viewPlacementGroups === "request" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can request to view any placement groups.</Typography>}
                                        {userGroupDetails.viewPlacementGroups === "none" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff cannot view any placement groups.</Typography>}
                                    </Box>
                                    <ToggleButtonGroup value={userGroupDetails?.viewPlacementGroups} onChange={(_, v) => v && setUserGroupDetails((u) => ({...u, viewPlacementGroups: v}))} exclusive color="primary">
                                        <ToggleButton value={"any"}>All</ToggleButton>
                                        <ToggleButton value={"request"}>Request access</ToggleButton>
                                        <ToggleButton value={"none"}>None</ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>}
                                <Divider/>
                                <ToggleFormItem>
                                    <Box>
                                        <Typography>Process student applications</Typography>
                                        <Typography variant="subtitle2" sx={{opacity: 0.75}}>Allow staff to process applications on their placement listings.</Typography>
                                    </Box>
                                    <ControlledSwitch id={"processApplications"} name={"processApplications"} onCheck={(_, c) => setUserGroupDetails((u) => ({...u, processApplicants: c}))} defaultChecked={false}/>
                                </ToggleFormItem>
                                <Divider/>
                                <ToggleFormItem>
                                    <Box>
                                        <Typography>Manage staff accounts</Typography>
                                        <Typography variant="subtitle2" sx={{opacity: 0.75}}>Add, edit and delete all staff accounts, edit permissions and user groups, and grant access to specific addresses and listings.</Typography>
                                    </Box>
                                    <ControlledSwitch id={"manageStaff"} name={"manageStaff"} onCheck={(_, c) => setUserGroupDetails((u) => ({...u, addStaff: c, editStaff: c, editUserGroups: c, deleteStaff: c, addUserGroups: c, deleteUserGroups: c, manageAccessRequests: c, manageRegistrationRequests: c}))} defaultChecked={false}/>
                                </ToggleFormItem>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    {false && <Accordion sx={{boxShadow: "none", borderBottom: "lightgrey 1px solid"}} disableGutters expanded={userGroupType === "advanced"} onChange={(_, open) => setUserGroupType((n) => open ? "advanced" : n)}>
                        <AccordionSummary>
                            <Stack direction={"row"} alignItems={"center"}>
                                <Radio checked={userGroupType === "advanced"}/>
                                <Box>
                                    <Typography variant="h6">Configure users manually (Advanced)</Typography>
                                    <Typography variant="subtitle2" sx={{opacity: 0.75}}>Customise every permission of a new user group.</Typography>
                                </Box>
                                <Typography variant="h6" height={"max-content"}></Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack>
                                <Typography>This will create a new user group.</Typography>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>}
                    <Stack sx={{boxShadow: "none", borderBottom: "lightgrey 1px solid", p: "16px 8px 8px 16px"}} onClick={() => setUserGroupType(() => "readOnly")}>
                        <Stack direction={"row"} alignItems={"center"}>
                            <Radio checked={userGroupType === "readOnly"}/>
                            <Box>
                                <Typography variant="h6">Read-only access</Typography>
                                <Typography variant="subtitle2" sx={{opacity: 0.75}}>A free account for staff to view placement details.</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Popup>
    );
}
