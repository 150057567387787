import {Alert, Button, Collapse, Grid, MenuItem, Stack, styled, Typography} from "@mui/material";
import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Preloader from "../../Components/Preloader";
import {GradientText} from "../../Util/styledComponents";
import {convertDate, executeCallable, PlacementReviewDetails} from "placementt-core";
import Page from "../../Components/Page";
import FadeInBox from "../../Components/FadeInBox";
import ProviderReviewStudentPlacement from "./ProviderDetails/ProviderReviewStudentPlacement";
import ReviewStudentPlacement from "./ProviderDetails/ReviewStudentPlacement";
import Dropdown from "../../Components/FormComponents/Dropdown";
import InputGroup from "../../Components/FormComponents/InputGroup";
import Form from "../../Components/Form";

const DecorativeText = styled("span")`
    text-decoration: underline;
    font-weight: bold;
    color: ${(props) => props.color};
`;


export default function PlacementReview() {
    const {uid, pId, key} = useParams();

    const [showPreloader, setShowPreloader] = useState(false);
    const [error, setError] = useState<{code:number, msg?: string}>();
    const [placement, setPlacement] = useState<PlacementReviewDetails>();
    const [cardPos, setCardPos] = useState<"loading"|"notFound"|"submitted"|"reject"|"expired"|"review"|"newLink"|"completeProviderReview">("loading");
    const [selectedContactType, setSelectedContactType] = useState<string>();

    const navigate = useNavigate();

    const {reject} = useParams();

    // Resend email link
    const resendEmail = async () => {
        setShowPreloader(true);
        const res = await executeCallable("placement-sendPlacementReviewLink", {pId: pId, key: key});
        setError(res.data as {code: number, msg?: string});
        setShowPreloader(false);
    };

    const rejectPlacement = async () => {
        executeCallable("placement-rejectPlacement", {pId: pId, key: key});
        setCardPos("submitted");
    };

    useEffect(() => {
        // Check if placement exists
        setShowPreloader(true);
        executeCallable("placement-getPlacement", {pId: pId, key: key}).then((res) => {
            const data = res.data as {code: 0|1|2|3, msg?: string, details?: PlacementReviewDetails};

            setShowPreloader(false);
            if (data.code === 1) {
                setPlacement(data.details);
                console.log("placement", data.details);
                setError(undefined);
                setTimeout(() => {
                    reject ? setCardPos("reject") : setCardPos("review");
                }, 0);
                return;
            }

            if (data.code === 2) {
                setCardPos("expired");
                setError(undefined);
                return;
            }

            if (data.code === 3) {
                setCardPos("newLink");
                setError(undefined);
                return;
            }

            setCardPos("notFound");
            setError(data);
        });
    }, []);

    const updatePlacementContactType = async (e: {contactType?: string, webpage?: string}) => {
        await executeCallable("placement-updatePlacementViewType", {pId: pId, ...e});
    };

    const renderReviewPages = () => {
        if (!(uid && pId && key && placement)) {
            return null;
        }

        console.log("userType", placement.userType);
        console.log("completed", placement?.providerCompleted?.includes("Completed"));

        if (!placement?.providerCompleted?.includes("Completed") && placement.userType === "provider") {
            return (<ProviderReviewStudentPlacement {...{placement, uid, pId}} placementKey={key} onComplete={(outcome) => setCardPos(outcome)} visible={cardPos === "review"}/>);
        }
        return (<ReviewStudentPlacement {...{placement, uid, pId}} placementKey={key} onComplete={(outcome) => setCardPos(outcome)} visible={cardPos === "review"}/>);
    };

    return (
        <Page fullHeight sx={{overflow: "hidden"}}>
            <FadeInBox card visible={cardPos === "loading"}>
                <Typography variant="h4">Finding placement</Typography>
                <Typography variant='h6'>This won't take long.</Typography>
                <Preloader visible/>
            </FadeInBox>
            <FadeInBox visible={cardPos === "notFound"}>
                <Stack spacing={2} alignItems={"center"}>
                    <Typography variant='h5'>You don't currently have any placements.</Typography>
                    <Typography>If you take work experience students, create an account to advertise and manage student placements.</Typography>
                    <Button onClick={() => navigate("/for-providers")}>Learn more</Button>
                </Stack>
            </FadeInBox>

            <FadeInBox card visible={cardPos === "submitted"}>
                <Stack spacing={2} alignItems={"center"}>
                    <Typography variant='h5'>Thanks for reviewing your placement.</Typography>
                    <Typography>You can view this placement at any time by clicking the link in your emails.</Typography>
                </Stack>
            </FadeInBox>

            <FadeInBox card visible={cardPos === "completeProviderReview"}>
                <Stack spacing={2} alignItems={"center"}>
                    <Typography variant='h5'>Thanks for reviewing your placement.</Typography>
                    <Typography>You can view this placement at any time by clicking the link in your emails.</Typography>
                    <Typography>We will show the details you have provided to future students. Students will contact you directly via email requesting a placement if they are interested.</Typography>
                    <Typography>Prefer to be contacted through a different format? Select below:</Typography>
                    <Form sx={{width: "100%"}} onSubmit={(e) => updatePlacementContactType(e as {contactType: string, webpage?: string})}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Dropdown name="contactType" label="Applicant contact type" onChange={(e) => setSelectedContactType(e.target.value)}>
                                    <MenuItem value="email">Email</MenuItem>
                                    <MenuItem value="website">Website</MenuItem>
                                    <MenuItem value="phone">Phone</MenuItem>
                                    <MenuItem value="post">Post</MenuItem>
                                </Dropdown>
                            </Grid>
                            {selectedContactType === "website" && <Grid item xs={12}>
                                <InputGroup name={"webpage"} label={"Application webpage"} required/>
                            </Grid>}
                        </Grid>
                    </Form>
                </Stack>
            </FadeInBox>

            <FadeInBox card visible={cardPos === "newLink"}>
                <Stack spacing={2} alignItems={"center"}>
                    <Typography variant='h5'>This link is no longer active.</Typography>
                    <Typography>You have been sent a new link to your email which you can use.</Typography>
                    <Typography>Can't find the new email? Resend the link below.</Typography>
                    <Button disabled={Boolean(error) || showPreloader} onClick={resendEmail} variant="contained">Resend verification link</Button>
                    <Preloader visible={showPreloader} />
                    <Collapse in={Boolean(error) && Object.prototype.hasOwnProperty.call(error, "code")}>
                        <Alert severity={error?.code === 1 ? "success" : "error"}>{error?.msg}</Alert>
                    </Collapse>
                </Stack>
            </FadeInBox>

            <FadeInBox card cardTitle={"Placement link expired."} visible={cardPos === "expired"}>
                <Stack spacing={2} alignItems={"center"}>
                    <Typography>This link has expired. Click the button below to send a new one to your email address.</Typography>
                    <Button disabled={Boolean(error) || showPreloader} onClick={resendEmail} variant="contained">Resend verification link</Button>
                    <Preloader visible={showPreloader} />
                    <Collapse in={Boolean(error) && Object.prototype.hasOwnProperty.call(error, "code")}>
                        <Alert severity={error?.code === 1 ? "success" : "error"}>{error?.msg}</Alert>
                    </Collapse>
                </Stack>
            </FadeInBox>
            {placement && <FadeInBox card visible={cardPos === "reject"}>
                <Stack spacing={2} alignItems={"center"}>
                    <GradientText variant={"h4"}>Review placement</GradientText>
                    <Typography variant='h5'><DecorativeText color={"#2b66bf"}>{placement?.studentForename}</DecorativeText> from <DecorativeText color={"#2fbbab"}>{placement?.instituteName}</DecorativeText></Typography>
                    <Typography variant='h6'>{convertDate(placement.startDate, "visual") as string} - {convertDate(placement.endDate, "visual") as string}</Typography>
                    <Typography>Not your placement? We'll flag this with the institute so they can follow up with their student.</Typography>
                    <Stack direction={"row"}>
                        <Button onClick={rejectPlacement}>Reject placement</Button>
                        <Button onClick={() => setCardPos("review")}>Review placement</Button>
                    </Stack>
                </Stack>
            </FadeInBox>}
            {renderReviewPages()}
        </Page>);
}
