import {Box, Button, Divider, Grid, InputAdornment, Stack, Table, TableBody, TableCell, TableRow, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {PRIMARY_COLOUR} from "placementt-core";
import {ChangeEvent, useState} from "react";
import InputGroup from "./FormComponents/InputGroup";
import {InfoTableCell} from "../Util/styledComponents";
import {formatPrice} from "../Util/util";

const costObject = {
    school: {
        platformFee: 90,
        studentCost: 1.5,
        placementCost: 1.5,
    },
    mat: {
        platformFee: 250,
        studentCost: 2.5,
        placementCost: 2.5,
    },
    university: {
        platformFee: 250,
        studentCost: 5,
        placementCost: 5,
    },
};

export default function InstitutePriceCalculator({type}:{type?: "school"|"mat"}) {
    const [orgSize, setOrgSize] = useState<"school"|"mat"|"university">(type || "school");
    const [numAddresses, setNumAddresses] = useState<number>(1);
    const [numStudents, setNumStudents] = useState<number>();
    const [placementsPerStudent, setPlacementsPerStudent] = useState<number>(1);

    const studentTotalPrice = numStudents ? costObject[orgSize].studentCost*numStudents : "---";
    const placementTotalPrice = (numStudents !== undefined && placementsPerStudent !== undefined) ? (placementsPerStudent * numStudents * costObject[orgSize].placementCost) : "---";

    const totalPrice = (typeof studentTotalPrice === "number" && typeof placementTotalPrice === "number") ? costObject[orgSize].platformFee + studentTotalPrice + placementTotalPrice : "---";

    return (
        <Stack width={"100%"}>
            <Grid container>
                <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                    <Stack>
                        <Typography color={PRIMARY_COLOUR}>Transparent Pricing Customised to Your Needs</Typography>
                        <Divider/>
                        <Typography variant="h4">Estimate Your Price</Typography>
                        <Typography>We are transparent about our pricing. We'll only charge you for usage, and tailor specific plans to you, ensuring you get the most out of Placementt.</Typography>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography fontSize={"1.1em"}>I am a...</Typography>
                            <ToggleButtonGroup color="primary" exclusive value={orgSize} onChange={(_, n) => n ? setOrgSize(n) : null}>
                                <ToggleButton value={"school"}>Secondary school</ToggleButton>
                                <ToggleButton value={"mat"}>Multi-academy trust</ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                        {orgSize === "mat" && <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography fontSize={"1.1em"}>Number of schools</Typography>
                            <InputGroup sx={{minWidth: "130px", width: "150px"}} InputProps={{
                                endAdornment: <InputAdornment position="end">school{numAddresses === 1 ? "" : "s"}</InputAdornment>,
                            }} type={"number"} value={numAddresses} min={"0"} onChange={(e:ChangeEvent<HTMLInputElement>) => setNumAddresses(parseInt(e.target.value))}/>
                        </Stack>}
                        <Stack pt={"3.5px"} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack spacing={0}>
                                <Typography fontSize={"1.1em"}>Number of students</Typography>
                                <Typography sx={{opacity: 0.7}} fontSize={14}>How many students do you expect to attend placements?</Typography>
                            </Stack>                            <InputGroup sx={{minWidth: "150px", width: "150px"}} InputProps={{
                                endAdornment: <InputAdornment position="end">student{numStudents === 1 ? "" : "s"}</InputAdornment>,
                            }} type={"number"} value={numStudents} min={"0"} onChange={(e:ChangeEvent<HTMLInputElement>) => (parseInt(e.target.value) >=0) ? setNumStudents(parseInt(e.target.value)) : null}/>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack spacing={0}>
                                <Typography fontSize={"1.1em"}>Placements per student</Typography>
                                <Typography sx={{opacity: 0.7}} fontSize={14}>How many placements will each student complete?</Typography>
                            </Stack>
                            <InputGroup sx={{minWidth: "150px", width: "150px"}} InputProps={{
                                endAdornment: <InputAdornment position="end">placement{placementsPerStudent === 1 ? "" : "s"}</InputAdornment>,
                            }} type={"number"} value={placementsPerStudent} min={"0"} onChange={(e:ChangeEvent<HTMLInputElement>) => (parseInt(e.target.value) >=0) ? setPlacementsPerStudent(parseInt(e.target.value)) : undefined}/>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box borderRadius={1} boxShadow={3} overflow={"hidden"} pb={2}>
                        <Stack pb={4} pt={4} alignItems={"center"} spacing={0} sx={{background: PRIMARY_COLOUR}}>
                            <Typography variant="h2" color={"white !important"} fontWeight={"bold"}><Typography variant="h5" sx={{display: "inline"}}>£</Typography>{typeof totalPrice === "number" && orgSize !== "university" ? formatPrice(totalPrice * 100, true) : "£---"}</Typography>
                            <Typography color={"white !important"}>Estimated yearly cost</Typography>
                        </Stack>
                        <Box height={"30px"} sx={{background: `linear-gradient(${PRIMARY_COLOUR}, white)`}}/>
                        {orgSize !== "university" ? <Stack spacing={1}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <InfoTableCell>Platform fee</InfoTableCell>
                                        <InfoTableCell sx={{textAlign: "end", opacity: 0.7}}>£{costObject[orgSize].platformFee} / year</InfoTableCell>
                                    </TableRow>
                                    <TableRow sx={{position: "absolute"}}>
                                        <InfoTableCell colSpan={2}>
                                            <Typography mt={-3.5} sx={{opacity: 0.7}} fontSize={"0.75rem"}>Access to Placementt for unlimited staff.</Typography>
                                        </InfoTableCell>
                                    </TableRow>
                                    <TableRow><TableCell colSpan={2}/></TableRow>
                                    <TableRow>
                                        <InfoTableCell>Price / student</InfoTableCell>
                                        <InfoTableCell sx={{textAlign: "end", opacity: 0.7}}>£{costObject[orgSize].studentCost} x {numStudents || "---"} = {typeof studentTotalPrice === "number" ? formatPrice(studentTotalPrice * 100) : "£---"}</InfoTableCell>
                                    </TableRow>
                                    <TableRow sx={{position: "absolute"}}>
                                        <InfoTableCell colSpan={2}>
                                            <Typography mt={-3.5} sx={{opacity: 0.7}} fontSize={"0.75rem"}>Price for creating and managing student accounts.</Typography>
                                        </InfoTableCell>
                                    </TableRow>
                                    <TableRow><TableCell colSpan={2}/></TableRow>
                                    <TableRow sx={{marginBotton: 0}}>
                                        <InfoTableCell width={"max-content"} sx={{textWrap: "nowrap"}}>Price / placement</InfoTableCell>
                                        <InfoTableCell sx={{textAlign: "end", opacity: 0.7}}>£{costObject[orgSize].placementCost} x {numStudents || "---"} x {placementsPerStudent} = {typeof placementTotalPrice === "number" ? formatPrice(placementTotalPrice * 100) : "£---"}</InfoTableCell>
                                    </TableRow>
                                    <TableRow sx={{position: "absolute"}}>
                                        <InfoTableCell colSpan={2}>
                                            <Typography mt={-3.5} sx={{opacity: 0.7, pr: 3}} fontSize={"0.75rem"}>Not every student may create a placement, so this figure may be lower.</Typography>
                                        </InfoTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Box width={"100%"} display={"flex"} pt={2}>
                                                <Button sx={{m: "auto"}} href="mailto:tom@placementt.co.uk" color="primary">Get in Touch</Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Stack> : <Typography p={2} fontSize={"18px"} textAlign={"center"} sx={{opacity: 0.8}}>We are currently building Placementt for universities. Get in touch if you are interested in supporting your university by clicking the button below.</Typography>}
                    </Box>
                </Grid>
            </Grid>
        </Stack>
    );
}
