import {ChangeEvent, useContext, useState} from "react";
import Page from "../../../Components/Page";
import VerticalPaginator from "../../../Components/VerticalPaginator";
import {Box, Button, Divider, Grid, MenuItem, Stack, Typography} from "@mui/material";
import Card from "../../../Components/Card";
import {Link, useNavigate, useParams} from "react-router-dom";
import {ApplicantStage, ApplicantWorkflow, PRIMARY_COLOUR, defaultApplicantWorkflow, useApplicantWorkflowEditor} from "placementt-core";
import Form, {FormButton, FormButtonGroup} from "../../../Components/Form";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {OrganisationContext, UserContext} from "../../../App";
import WorkflowEditorV4 from "../../../Components/WorkflowEditorV4";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import Dropdown from "../../../Components/FormComponents/Dropdown";
import IconButtonPop from "../../../Components/IconButtonPop";
import {Delete, HelpOutline} from "@mui/icons-material";
import {Popup} from "../../../Components/Popup";
import ListMaker from "../../../Components/FormComponents/ListMaker";
import FileUploaderPopup from "../../../Components/FileUploaderPopup";
import Files from "../../Shared/Files";

// const addressSteps = ["address", "contacts", "misc", "review", "complete"];

export default function AddApplicantWorkflowPage() {
    const [step, setStep] = useState<number>(0);

    const {id, custom}:{id?: string, custom?: string} = useParams();
    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);
    const org = useContext(OrganisationContext);
    const [filesPopupOpen, setFilesPopupOpen] = useState<number|false>(false);

    const defaultWorkflow:ApplicantWorkflow = {
        name: "",
        product: user.product,
        oId: user.oId,
        workflow: defaultApplicantWorkflow,
        type: "complex",
    };

    const [formInfoPopup, setFormInfoPopup] = useState(false);
    const [fileInfoPopup, setFileInfoPopup] = useState(false);
    const [workflowId, setWorkflowId] = useState(id);
    const forms = org.forms as {[key:string]: {name: string}};

    const [workflow, setWorkflow] = useState<ApplicantWorkflow>(id ? org.applicantWorkflows[id] as ApplicantWorkflow : defaultWorkflow);


    const navigate = useNavigate();

    const createWorkflow = async () => {
        if (!workflow) return;

        if (workflowId) {
            firebaseQuery.update(["applicantWorkflows", workflowId], workflow);
        } else {
            const uploadData = {
                ...workflow,
                oId: user.oId,
                product: user.product,
                type: "complex",
                created: (new Date()).toISOString(),
            } as ApplicantWorkflow;
            const newId = (await firebaseQuery.add(["applicantWorkflows"], uploadData)).id;
            setWorkflowId(newId);
            setWorkflow({...uploadData, id: newId});
        }

        setStep((a) => a+1);
    };

    const submit = async (data?: ApplicantWorkflow) => {
        if (!(data || workflow)) return;

        if (workflowId) {
            await firebaseQuery.update(["applicantWorkflows", workflowId], (data || workflow) as ApplicantWorkflow);
        }

        if (data) {
            navigate(-1);
        } else {
            setStep((a) => a+1);
        }
    };

    const submitCompleteWorkflow = (e: {
        workflow: ApplicantStage[];
        includedFiles: string[];
        includedForms: string[];
    }) => {
        setWorkflow((w) => ({...(w || defaultWorkflow), workflow: e.workflow, files: includedFiles, forms: includedForms}));
        submit({...(workflow || defaultWorkflow), workflow: e.workflow, files: includedFiles, forms: includedForms});
    };

    const {workflowNodes, addNode, onDelete, onChange, includedFiles, includedForms, filePopupActive, setFilePopupActive, uploadFile, submitWorkflow} = useApplicantWorkflowEditor({user: user, onSubmit: submitCompleteWorkflow, initialData: workflow?.workflow, workflowId: workflowId});

    if (workflow?.customWorkflow) {
        navigate("custom");
        return null;
    }

    const onChangeItem = (key: string, value: unknown) => {
        setWorkflow((w) => ({...(w || defaultWorkflow), [key]: value}));
    };

    if (!workflow || !workflowNodes.length) return null;

    return (
        <Page title={`${id ? "Edit" : "Add"} applicant workflow`} back={true} fullHeight>
            <FileUploaderPopup accept="application/pdf" onClose={() => setFilePopupActive(false)} open={filePopupActive} onSubmit={uploadFile}/>

            {custom === "custom" ? <WorkflowEditorV4 initialData={workflow?.workflow} cohortId="a" onSubmit={console.log}/> :
                <VerticalPaginator activeStep={step} items={[
                    {
                        label: "Create your workflow",
                        element: <Card title={"Basic details"}>
                            <Typography>Your applicant workflow dictates how applications are processed. The workflow is separated into stages that can be completed by either staff or students.</Typography>
                            <Form button={false} functionType="sync">
                                <Grid container>
                                    <Grid item xs={12}>
                                        <InputGroup name={"name"} label="Workflow name" required onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeItem("name", e.target.value)} value={workflow?.name}/>
                                    </Grid>
                                </Grid>
                                <br/>
                                <FormButtonGroup>
                                    <FormButton onClick={async () => createWorkflow()} variant="contained" text="Continue"/>
                                </FormButtonGroup>
                            </Form>
                        </Card>,
                    },
                    {
                        label: "Application submission",
                        element: <Card title={"Application submission"} sx={{width: "100%"}}>
                            <Form button={false} functionType="sync" spacing={1}>
                                <Typography>When applying, specify what custom forms the student needs to complete, what files they need to review, and what documents you require them to upload.</Typography>
                                <Divider/>
                                <InputGroup name={"message"} label={"Message"} multiline rows={3} placeholder={"Generic message sent to candidate. To send more information, upload a custom file."} value={workflowNodes.find((n) => n.id === 1)?.message} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange([1, "message"], e.target.value)}/>
                                <Box>
                                    <Typography mt={1}>Select custom forms for the student to complete <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setFormInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>
                                    <Dropdown
                                        label='Forms to complete'
                                        multiple
                                        key={"submissionForms"}
                                        value={workflowNodes.find((n) => n.id === 1)?.forms}
                                        onChange={(e) => onChange([1, "forms"], (e.target.value as unknown as unknown[]).filter((x: unknown) => x !== undefined))}>
                                        {forms && Object.entries(forms).map(([key, e]) => {
                                            return (<MenuItem value={key}>{e.name}</MenuItem>);
                                        })}
                                        <Divider/>
                                        <MenuItem onClick={()=>window.open(`/${user.product}/organisation/resources/f`, "_blank")}>
                                            {"Create form"}
                                        </MenuItem>
                                    </Dropdown>
                                </Box>
                                <Box>
                                    <Typography mt={1}>Select files for the student to review <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setFileInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>
                                    <Stack direction={"row"} alignItems={"stretch"}>
                                        <Box border={`1px solid ${PRIMARY_COLOUR}`} borderRadius={1} display="flex" alignItems="center" pl={1} flex={1}>
                                            {workflowNodes.find((n) => n.id === 1)?.files ? `${workflowNodes.find((n) => n.id === 1)?.files?.length} file${(workflowNodes.find((n) => n.id === 1)?.files as string[])?.length > 1 ? "s" : ""} selected` : "No files selected"}
                                        </Box>
                                        <Button onClick={() => setFilesPopupOpen(1)}>Select files</Button>
                                    </Stack>
                                </Box>
                                <Divider/>
                                <ListMaker noDefault name="requiredFiles" onChange={(items) => onChange([1, "requiredFiles"], items)} items={workflowNodes.find((n) => n.id === 1)?.requiredFiles} label="Required documents for students to upload" multipleInputs>
                                    <Grid container mt={"-16px !important"}>
                                        <Grid item xs={6}>
                                            <InputGroup label={"Document name"} name={"fileName"} placeholder={"One file, such as CV"} required/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Dropdown label="Document type" required name="fileType">
                                                <MenuItem value={"application/pdf"}>PDF</MenuItem>
                                                <MenuItem value={"image/png, image/jpeg"}>Image</MenuItem>
                                                <MenuItem value={"video/mp4, video/ogg"}>Video</MenuItem>
                                                <MenuItem value={"audio/ogg, audio/mpeg"}>Voice note</MenuItem>
                                                <Typography p={2} variant="caption">Need another? <Link to={"mailto:admin@placementt.co.uk"}>Contact us</Link></Typography>
                                            </Dropdown>
                                        </Grid>
                                    </Grid>
                                </ListMaker>
                                <Stack direction={"row"} justifyContent={"end"} alignItems={"center"} pt={3}>
                                    <Button onClick={() => setStep((a) => a-1)}>Back</Button>
                                    <FormButtonGroup>
                                        <FormButton onClick={async () => submitWorkflow()} text="Save and exit"/>
                                        <FormButton onClick={async () => submit()} variant="contained" text="Continue"/>
                                    </FormButtonGroup>
                                </Stack>
                            </Form>
                        </Card>,
                    },
                    ...workflowNodes.filter((node) => ![1, 11, 12].includes(node.id)).map((node, i, a) => ({
                        label: node.name,
                        element: <Card title={node.name} secondaryTitle={i > 0 && <IconButtonPop title="Delete" responsive={false} onClick={() => onDelete(node.id)}><Delete/></IconButtonPop>} sx={{width: "100%"}}>
                            <Form button={false} functionType="sync">
                                <InputGroup name={"name"} required value={node.name} label={"Stage name"} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange([node.id, "name"], e.target.value)}/>
                                <Dropdown size={"small"} value={node.userType} label='Action from' required onChange={(e) => onChange([node.id, "userType"], e.target.value)}>
                                    <MenuItem value={"Students"}>Student</MenuItem>
                                    <MenuItem value={"Staff"}>Staff</MenuItem>
                                </Dropdown>
                                <InputGroup name={"message"} label={"Message"} multiline rows={3} placeholder={"Generic message sent to candidate. To send more information, upload a custom file."} value={workflowNodes.find((n) => n.id === node.id)?.message} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange([node.id, "message"], e.target.value)}/>
                                <Box>
                                    <Typography mt={1}>Select custom forms for {node.userType?.toLowerCase() || "users"} to complete <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setFormInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>
                                    <Dropdown
                                        label='Forms'
                                        multiple
                                        key={"submissionForms"}
                                        value={node.forms}
                                        onChange={(e) => onChange([node.id, "forms"], (e.target.value as unknown as unknown[]).filter((x: unknown) => x !== undefined))}>
                                        {forms && Object.entries(forms).map(([key, e]) => {
                                            return (<MenuItem value={key}>{e.name}</MenuItem>);
                                        })}
                                        <Divider/>
                                        <MenuItem onClick={()=>window.open(`/${user.product}/organisation/resources/f`, "_blank")}>
                                            {"Create form"}
                                        </MenuItem>
                                    </Dropdown>
                                </Box>
                                <Box>
                                    <Typography mt={1}>Select files for {node.userType?.toLowerCase() || "users"} to review <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setFileInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>
                                    <Stack direction={"row"} alignItems={"stretch"}>
                                        <Box border={`1px solid ${PRIMARY_COLOUR}`} borderRadius={1} display="flex" alignItems="center" pl={1} flex={1}>
                                            {workflowNodes.find((n) => n.id === node.id)?.files ? `${workflowNodes.find((n) => n.id === node.id)?.files?.length} file${(workflowNodes.find((n) => n.id === node.id)?.files as string[])?.length > 1 ? "s" : ""} selected` : "No files selected"}
                                        </Box>
                                        <Button onClick={() => setFilesPopupOpen(node.id)}>Select files</Button>
                                    </Stack>
                                </Box>
                                <Divider/>
                                <ListMaker noDefault name="requiredFiles" onChange={(items) => onChange([node.id, "requiredFiles"], items)} items={node.requiredFiles} label="Required documents for students to upload" multipleInputs>
                                    <Grid container mt={"-16px !important"}>
                                        <Grid item xs={6}>
                                            <InputGroup label={"Document name"} name={"fileName"} placeholder={"One file, such as CV"} required/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Dropdown label="Document type" required name="fileType">
                                                <MenuItem value={"application/pdf"}>PDF</MenuItem>
                                                <MenuItem value={"image/png, image/jpeg"}>Image</MenuItem>
                                                <MenuItem value={"video/mp4, video/ogg"}>Video</MenuItem>
                                                <MenuItem value={"audio/ogg, audio/mpeg"}>Voice note</MenuItem>
                                                <Typography p={2} variant="caption">Need another? <Link to={"mailto:admin@placementt.co.uk"}>Contact us</Link></Typography>
                                            </Dropdown>
                                        </Grid>
                                    </Grid>
                                </ListMaker>
                                <Stack direction={"row"} justifyContent={"end"} alignItems={"center"} pt={3}>
                                    <Button onClick={() => setStep((a) => a-1)}>Back</Button>

                                    <FormButtonGroup>
                                        {false && <FormButton sx={{minWidth: "max-content", display: i === a.length - 1 ? "flex" : "none"}} onClick={async () => {
                                            addNode({prevStage: node.id, nextStage: node.buttons?.find((n) => n.name === "Accept")?.id});
                                            setStep((a) => a+1);
                                        }} text="Add stage"/>}
                                        <FormButton onClick={async () => submitWorkflow()} text="Save and exit"/>
                                        <FormButton onClick={async () => submit()} variant="contained" text="Continue"/>
                                    </FormButtonGroup>
                                </Stack>
                            </Form>
                        </Card>,
                    })),
                    {
                        label: "Student acceptance",
                        element: <Card title={"Student acceptance"} sx={{width: "100%"}}>
                            <Form button={false} functionType="sync">
                                <InputGroup name={"message"} label={"Message"} multiline rows={4} placeholder={"Generic message sent to candidate. To send personalised messages, create a form for staff to complete."} required value={workflowNodes.find((n) => n.id === 11)?.message} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange([11, "message"], e.target.value)}/>

                                <Stack direction={"row"} justifyContent={"end"} alignItems={"center"} pt={3}>
                                    <Button onClick={() => setStep((a) => a-1)}>Back</Button>
                                    <FormButtonGroup>
                                        <FormButton onClick={async () => submitWorkflow()} text="Save and exit"/>
                                        <FormButton onClick={async () => submit()} variant="contained" text="Continue"/>
                                    </FormButtonGroup>
                                </Stack>
                            </Form>
                        </Card>,
                    },
                    {
                        label: "Student rejection",
                        element: <Card title={"Student rejection"} sx={{width: "100%"}}>
                            <Form button={false} functionType="sync">
                                <InputGroup name={"message"} label={"Message"} multiline rows={4} placeholder={"Generic message sent to candidate. To send personalised messages, create a form for staff to complete."} required value={workflowNodes.find((n) => n.id === 12)?.message} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange([12, "message"], e.target.value)}/>

                                <Stack direction={"row"} justifyContent={"end"} alignItems={"center"} pt={3}>
                                    <Button onClick={() => setStep((a) => a-1)}>Back</Button>
                                    <FormButtonGroup>
                                        <FormButton onClick={async () => submitWorkflow()} text="Save and exit"/>
                                        <FormButton onClick={async () => submit()} variant="contained" text="Continue"/>
                                    </FormButtonGroup>
                                </Stack>
                            </Form>
                        </Card>,
                    },
                    {
                        label: "Review",
                        element: <Card title={"Review"} sx={{width: "100%"}}>
                            <Typography>Workflow name: {workflow.name}</Typography>
                            <Typography>Applicant details</Typography>

                            <Typography>Acceptance message</Typography>
                            <Typography sx={{
                                background: "#00000010",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                p: 0.7,
                                borderRadius: 2}}>{workflowNodes.find((n) => n.id === 11)?.message}</Typography>
                            <Typography>Rejection message</Typography>
                            <Typography sx={{
                                background: "#00000010",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                p: 0.7,
                                borderRadius: 2}}>{workflowNodes.find((n) => n.id === 12)?.message}</Typography>
                            <Stack direction={"row"} justifyContent={"end"} alignItems={"center"} pt={3}>
                                <Button onClick={() => setStep((a) => a-1)}>Back</Button>
                                <Button onClick={() => submitWorkflow()} variant="contained">Submit</Button>
                            </Stack>
                        </Card>,
                    },
                ]}/>}
            <Popup title={"Creating custom forms"} open={formInfoPopup} onClose={() => setFormInfoPopup(false)}>
                <Stack>
                    <Typography>If you require additional information that we do not already cover, you can generate customised forms through our form builder.</Typography>
                    <Typography>These forms can be assigned to staff, employers or parents, who will be required to complete them before submitting and progressing the placement.</Typography>
                    <Typography>When you first view the form builder, you can view a short tutorial on how to use it.</Typography>
                    <Typography>To create a form, click the dropdown and select to create a form. Otherwise, navigate to Organisation → Resources.</Typography>
                </Stack>
            </Popup>
            <Popup title={"Uploading custom files"} open={fileInfoPopup} onClose={() => setFileInfoPopup(false)}>
                <Stack>
                    <Typography>If you require your employers, staff or parents to view specific forms, you can upload them to the platform and select for your users to review them.</Typography>
                    <Typography>Users will be required to view any forms you select on their stage in order to progress their placement.</Typography>
                    <Typography>To upload a file, click the dropdown and select to upload. Otherwise, navigate to Organisation → Resources.</Typography>
                </Stack>
            </Popup>
            <Files type="org" popup selectable={"multiple"} open={Boolean(filesPopupOpen !== false)} defaultSelected={filesPopupOpen !== false ? workflowNodes.find((n) => n.id === filesPopupOpen)?.files : undefined} onClose={() => setFilesPopupOpen(false)} onSubmitSelection={(files) => {
                filesPopupOpen !== false && onChange([filesPopupOpen, "files"], files);
                setFilesPopupOpen(false);
            }}/>
        </Page>
    );
}
