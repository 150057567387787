import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {signOut} from "firebase/auth";
import {auth, executeCallable} from "placementt-core";
import FadeInBox from "../../Components/FadeInBox";
import Form from "../../Components/Form";
import styled from "styled-components";
import {Typography} from "@mui/material";
import Page from "../../Components/Page";

const ActivationCodeInput = styled("input")`
    
    -moz-appearance: textfield;
    appearance: textfield;
    
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0
    }
    
    width: 100%;
    max-width: 200px;
    font-size: 2.5em !important;
    border-radius: 20px;
    align-self: center;
    outline: none;
    border: 2px rgba(0, 0, 0, 0.5) solid;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    padding: 15px;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    font-family: monospace;
`;


export default function ActivateProduct() {
    const [code, setCode] = useState("");
    const {uid} = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        if (!uid) {
            return;
        }
        signOut(auth);
    }, [uid]);

    const activate = async () => {
        console.log("activationCode", code);
        return await executeCallable("registration-activateProductAccount", {uid: uid, activationCode: parseInt(code)}).then(() => {
            navigate("/login");
        });
    };

    return (
        <Page fullHeight>
            <FadeInBox visible card cardTitle={"Activate account"}>
                <Form onSubmit={activate}>
                    <Typography variant="subtitle1">Enter the code sent to your email address.</Typography>
                    <ActivationCodeInput required id="activationCode" type={"number"} name="activationCode" min={0} value={code} onChange={(e) => e.target.value.length <= 6 ? setCode(e.target.value) : null}/>
                </Form>
            </FadeInBox>
        </Page>

    );
}
