import {Close, ErrorOutline} from "@mui/icons-material";
import {Alert, Button, Stack} from "@mui/material";
import {FlagCodes, InstituteData, StudentPlacementData, UserData, WorkflowStage, flagCodes, getPlacementFlagCodes} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../App";
import IconButtonPop from "../Components/IconButtonPop";
import styled, {keyframes} from "styled-components";

type Params = {
    placement:StudentPlacementData,
    workflow: WorkflowStage[],
    icon?: boolean,
    onClick?:(e:any) => void
}
export const RenderPlacementFlags = ({placement, workflow, icon=false, onClick}:Params) => {
    const [studentData, setStudentData] = useState<UserData>();
    const [flags, setFlags] = useState<FlagCodes[]>();

    const firebaseQuery = new FirebaseQuery();

    const institute = useContext(OrganisationContext)?.details as InstituteData;
    const user = useContext(UserContext) as UserData;

    useEffect(() => {
        const getStudentData = async () => {
            return placement.uid === user.id ? user : await firebaseQuery.getDocData(["users", placement.uid]).catch(() => false) as UserData|false;
        };
        getStudentData().then((data) => {
            if (!data) return;
            setStudentData(data);
        });
    }, []);

    useEffect(() => {
        if (!studentData) return;
        getPlacementFlagCodes({...{placement, institute, user, studentData, workflow}}).then(setFlags);
    }, [studentData]);

    if (!flags) return null;
    return (
        <RenderFlags {...{flags, icon}} onClick={onClick}/>
    );
};

export const RenderFlags = ({flags, icon, onClick}:{flags:FlagCodes[], icon:boolean, onClick?:(e:any, close?: boolean) => void}) => {
    if (flags.length === 0) {
        return null;
    }

    if (icon) {
        const flagSummary = {
            errors: flags.filter((flag) => flagCodes[flag].severity === "error")?.length || 0,
            warnings: flags.filter((flag) => flagCodes[flag].severity === "warning").length || 0,
            info: flags.filter((flag) => flagCodes[flag].severity === "info")?.length || 0,
        };
        let text = "";

        if (flagSummary.errors > 0) {
            text = text + `${flagSummary.errors} error${flagSummary.errors > 1 ? "s" : ""}. `;
        }
        if (flagSummary.warnings > 0) {
            text = text.concat(`${flagSummary.warnings} warning${flagSummary.warnings > 1 ? "s" : ""}.`);
        }

        return (
            <IconButtonPop responsive={false} title={flags.length > 1 ? text : flagCodes[flags[0]].message}>
                <ErrorOutline color={flagSummary.errors > 0 ? "error" : flagSummary.warnings > 0 ? "warning" : "primary"}/>
            </IconButtonPop>
        );
    }

    return (
        <Stack spacing={2}>
            {flags.map((flag:FlagCodes) =>
                <Alert severity={flagCodes[flag].severity} sx={{alignItems: "center"}} action={<>{flagCodes[flag].button ? <Button color="inherit" size="small" onClick={() => onClick && onClick(flag)}>{flagCodes[flag].button}</Button> : null}{flagCodes[flag].onClose ? <IconButtonPop responsive={false} title="Dismiss" onClick={() => onClick && onClick(flag, true)}><Close/></IconButtonPop> : null}</>}>{flagCodes[flag].message}</Alert>)}
        </Stack>
    );
};


const gradient = keyframes`
to {
background-position: 400% 0;
}
`;

export const RedOrangePinkGradientText = styled("span")`
background: linear-gradient(270deg,
hsl(0deg 100% 49%) 0%,
hsl(19deg 100% 49%) 6%,
hsl(29deg 100% 49%) 12%,
hsl(37deg 100% 48%) 19%,
hsl(37deg 99% 50%) 26%,
hsl(8deg 100% 62%) 32%,
hsl(329deg 100% 50%) 39%,
hsl(307deg 100% 47%) 46%,
hsl(306deg 100% 46%) 52%,
hsl(329deg 100% 50%) 59%,
hsl(2deg 100% 63%) 66%,
hsl(32deg 91% 49%) 73%,
hsl(33deg 100% 46%) 80%,
hsl(26deg 100% 47%) 86%,
hsl(18deg 100% 49%) 93%,
hsl(0deg 100% 50%) 100%) 0 0 / 400% 100%;
background-clip: text;
color: transparent;
@media (prefers-reduced-motion: no-preference) {
    animation: ${gradient} 30s linear infinite;
}
`;

export const RedDarkBlueGradientText = styled("span")`
background: linear-gradient(45deg,
    hsl(240deg 100% 20%) 0%,
    hsl(288deg 100% 21%) 8%,
    hsl(315deg 100% 27%) 17%,
    hsl(330deg 100% 35%) 25%,
    hsl(340deg 100% 42%) 33%,
    hsl(348deg 100% 47%) 42%,
    hsl(15deg 100% 50%) 50%,
    hsl(346deg 100% 48%) 58%,
    hsl(336deg 100% 43%) 67%,
    hsl(323deg 100% 36%) 75%,
    hsl(306deg 92% 28%) 83%,
    hsl(273deg 58% 31%) 92%,
    hsl(223deg 86% 25%) 100%) 0 0 / 400% 100%;
background-clip: text;
color: transparent;
@media (prefers-reduced-motion: no-preference) {
    animation: ${gradient} 30s linear infinite;
}
`;

export const RedDarkBlueGradientTextStatic = styled("span")<{start?: number}>`
background: linear-gradient(45deg,
    hsl(240deg 100% 46%) 0%,
    hsl(267deg 100% 48%) 8%,
    hsl(278deg 100% 48%) 17%,
    hsl(287deg 100% 49%) 25%,
    hsl(295deg 100% 50%) 33%,
    hsl(314deg 100% 50%) 42%,
    hsl(328deg 100% 50%) 50%,
    hsl(340deg 100% 50%) 58%,
    hsl(0deg 100% 57%) 67%,
    hsl(14deg 100% 55%) 75%,
    hsl(24deg 100% 52%) 83%,
    hsl(32deg 100% 50%) 92%,
    hsl(37deg 100% 50%) 100%) 0 0 / 400% 100%;
background-clip: text;
color: transparent;
font-weight: bold;
background-position: ${(props) => `${props.start || 0}% 0`};
`;

export const BluePurpleRedYellowGradientText = styled("span")`
background: linear-gradient(45deg,
    hsl(240deg 79% 50%) 0%,
    hsl(303deg 100% 39%) 18%,
    hsl(327deg 100% 50%) 26%,
    hsl(339deg 100% 50%) 33%,
    hsl(20deg 100% 57%) 39%,
    hsl(42deg 100% 50%) 44%,
    hsl(55deg 100% 50%) 50%,
    hsl(42deg 100% 50%) 56%,
    hsl(20deg 100% 57%) 61%,
    hsl(339deg 100% 50%) 67%,
    hsl(327deg 100% 50%) 74%,
    hsl(303deg 100% 39%) 82%,
    hsl(240deg 79% 50%) 100%) 0 0 / 400% 100%;
background-clip: text;
color: transparent;
@media (prefers-reduced-motion: no-preference) {
    animation: ${gradient} 30s linear infinite;
}
`;

