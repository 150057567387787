import {OrganisationContext} from "../../App";
import SubNavBar, {SubNavBarItems} from "../../Components/Navigation/SubNavBar";
import AllUsers from "./Users/All";
import StudentUsers from "./Students/Student";

export default function AdminUsers() {
    const items:SubNavBarItems = [
        {
            label: "All",
            route: "all",
            element: <AllUsers/>,
        },
        {
            label: "Student",
            route: "student",
            element: <StudentUsers/>,
        }];

    return (
        <OrganisationContext.Provider value={{}}>
            <SubNavBar items={items}/>
        </OrganisationContext.Provider>
    );
}

