import {useNavigate, useParams} from "react-router-dom";
import Page from "../../Components/Page";
import Card from "../../Components/Card";
import PlacementListingsList from "./Lists/PlacementListingList";
import {camelCaseToNormal, capitaliseWords, decodeGeoHash, OrganisationAddress, UserData} from "placementt-core";
import {useContext, useEffect, useState} from "react";
import {OrganisationContext} from "../../App";
import {Alert, Box, Button, Stack, Table, TableRow, Typography} from "@mui/material";
import Map from "../../Components/Map";
import {InfoTableCell} from "../../Util/styledComponents";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {Popup} from "../../Components/Popup";
import Form from "../../Components/Form";
import {UserSearch} from "../Shared/ProposePlacement";
import IconButtonPop from "../../Components/IconButtonPop";
import {Delete, Edit} from "@mui/icons-material";
import {LoadingButton} from "../../Components/LoadingButton";


export default function AddressPage() {
    const {addressId} = useParams();

    const [deletePopup, setDeletePopup] = useState(false);

    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();

    const addresses = useContext(OrganisationContext).addresses;

    if (!addressId) {
        return (<Page back grid title="View address">
            <Typography>No address found!</Typography>
        </Page>);
    }

    const address:OrganisationAddress&{listings?: number} = addresses[addressId];

    if (!address) {
        return (<Page back grid title="View address">
            <Typography>Couldn't find address.</Typography>
        </Page>);
    }

    return (
        <Page back grid title="View address" titleSecondary={
            <Stack direction={"row"}>
                <IconButtonPop title="Edit address" onClick={() => navigate("../addAddress/"+addressId)} responsive={false}><Edit/></IconButtonPop>
                <IconButtonPop title="Delete address" onClick={() => setDeletePopup(true)} responsive={false}><Delete/></IconButtonPop>
            </Stack>}>
            <AddressCard {...{address, addressId}}/>
            <Card grid xs={12} md={6}>
                <PlacementListingsList hideFilters type="all" addressId={addressId}/>
            </Card>
            <Popup title="Delete address" open={deletePopup} onClose={() => setDeletePopup(false)}>
                <Stack>
                    <Typography>Permanently delete "{address.name}"?</Typography>
                    {address?.listings === 0 ?
                        <LoadingButton text="Delete" onClick={async () => {
                            await firebaseQuery.delete(["addresses", addressId]).then(() => navigate(-1));
                        }}/> :
                        <>
                            <Alert severity="warning">Cannot delete address associated with {address?.listings} active listings.</Alert>
                            <LoadingButton>
                                <Button onClick={() => {
                                    setDeletePopup(false);
                                }}>Exit</Button>
                                <Button color="error" onClick={async () => {
                                    await firebaseQuery.delete(["addresses", addressId]).then(() => setDeletePopup(false));
                                }}>Delete and delist associated listings.</Button>
                            </LoadingButton>
                        </>}
                </Stack>
            </Popup>
        </Page>
    );
}


export function AddressCard({address, addressId}:{address: OrganisationAddress, addressId: string}) {
    const location = address.geoHash && decodeGeoHash(address.geoHash);

    const [responsiblePerson, setResponsiblePerson] = useState<UserData>();
    const [editResponsiblePersonPopup, setEditResponsiblePersonPopup] = useState(false);

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        firebaseQuery.getDocData(["users", address.primaryContactId || ""]).then((user) => setResponsiblePerson(user as UserData));
    }, [address]);

    return (
        <Card grid xs={12} md={6} title={"Address details"}>
            <Stack>
                <Typography>{capitaliseWords(address["address-line1"])+" · "+capitaliseWords(address["address-line2"])} · {capitaliseWords(address.locality)+" · "+address.postal_code.toUpperCase()} · {capitaliseWords(camelCaseToNormal(address.country))}</Typography>
                <Typography>Location: {address.geoHash}</Typography>
                <Table>
                    <TableRow>
                        <InfoTableCell>Responsible person</InfoTableCell>
                        <InfoTableCell>{responsiblePerson?.details.forename} {responsiblePerson?.details.surname}</InfoTableCell>
                        <InfoTableCell><Button onClick={() => setEditResponsiblePersonPopup(true)}>Edit</Button></InfoTableCell>
                    </TableRow>
                </Table>
                {location && <Box sx={{position: "relative", overflow: "hidden"}} height={"300px"} borderRadius={"10px"}>
                    <Map lat={location.latitude} lng={location.longitude} zoom={15} points={[[location.latitude, location.longitude]]}/>
                </Box>}
            </Stack>
            <Popup title={"Select new responsible person"} open={editResponsiblePersonPopup} onClose={() => setEditResponsiblePersonPopup(false)}>
                <Typography mb={2}>This user will receive emails relating to the address.</Typography>
                <Form onSubmit={async (e) => firebaseQuery.update(["addresses", addressId], {primaryContactId: e.primaryContactId} as Partial<OrganisationAddress>)}>
                    <UserSearch required defaultUid={address.primaryContactId} name={"primaryContactId"} userType="Staff" label="Responsible person"/>
                </Form>
            </Popup>
        </Card>
    );
}
