import {Button, ButtonPropsColorOverrides, IconButton, Tooltip, tooltipClasses} from "@mui/material";
import styled from "styled-components";
import {useCheckMobileScreen} from "../Util/util";
import {OverridableStringUnion} from "@mui/types";

type LightToolTipObj = ({className, ...props}: { className?: string, title:string, children:React.ReactElement, [key:string]:unknown }) => any

export const LightTooltip = styled<LightToolTipObj>(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[2],
        fontSize: 15,
    },
}));

type Params = {
    title:string,
    responsive?: boolean,
    children:React.ReactElement|string,
    onClick?:(e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    disabled?:boolean,
    sx?:{[key:string]: unknown},
    size?: string,
    color?: OverridableStringUnion<"inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning", ButtonPropsColorOverrides>,
    edge?: false | "end" | "start",
    variant?: "contained"|"outlined",
    [key: string]: unknown,
};


export default function IconButtonPop(props:Params) {
    const isMobile = useCheckMobileScreen();

    const {title, responsive=true, children, onClick, edge, variant="outlined", color, sx} = props;

    if (!isMobile && responsive) {
        return (<Button disabled={!onClick || props.disabled} variant={variant} color={color} onClick={onClick} {...Object.fromEntries(Object.entries(props).filter(([k]) => k !== "title"))} aria-label={title}>
            {props.title}
        </Button>);
    }

    const iconColor = color === "inherit" ? "inherit" : undefined;

    return (
        <LightTooltip title={title}>
            <div style={{display: "inline-block", height: "max-content", width: "max-content", alignSelf: "center", ...sx}}>
                <IconButton edge={edge} disableRipple={!onClick} {...Object.fromEntries(Object.entries(props).filter(([k]) => k !== "title"))} color={iconColor} aria-label={title}>
                    {children}
                </IconButton>
            </div>
        </LightTooltip>
    );
}
