import {Chip, Grid} from "@mui/material";
import Card from "../../Components/Card";
import SubNavBar, {SubNavBarItems} from "../../Components/Navigation/SubNavBar";
import Page from "../../Components/Page";
import FilterTable from "../../Components/FilterTable";
import {Address, InstitutePlacementListingUpload, PlacementListing, QueryObject, camelCaseToNormal, capitaliseWords, countryList, useInstitutePlacementListingHandler, usePlacementListingPaginator} from "placementt-core";
import DeletePopup from "../../Components/DeletePopup";
import {Popup} from "../../Components/Popup";
import SpreadsheetUploader from "../../Components/SpreadsheetUploader";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../App";
import PlacementMap from "../Shared/PlacementMap";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";
import Dropdown from "../../Components/FormComponents/Dropdown";
import {PlacementListingDetailsPopup} from "../Institutes/Staff/Placements";


export default function Placements() {
    const items:SubNavBarItems = [
        {
            label: "Outreach",
            route: "outreach",
            element: <PlacementOutreach/>,
        },
        {
            label: "List view",
            route: "list",
            element: <PlacementList/>,
        },
        {
            label: "Map view",
            route: "map",
            element: <PlacementsMap/>,
        }];

    return (
        <SubNavBar items={items}/>
    );
}

function PlacementOutreach() {
    const scrapePlacements = async (e: Address) => {
        console.log("address", JSON.stringify({
            query: Object.values(e).map((v) => v).join(","),
        }));
        return await fetch("https://placementt-api.vercel.app/api/potentialproviders/getproviders", {
            method: "POST",
            body: JSON.stringify({
                query: Object.values(e).map((v) => v).join(","),
            }),
            headers: {"Content-Type": "application/json"}});
    };

    return (
        <Page title="Student Users" grid>
            <Card grid xs={12} md={4} title={"Scrape businesses in area"}>
                <Form onSubmit={(e) => scrapePlacements(e as Address)}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <InputGroup required id="address-line1" label="Address line one" name="address-line1"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputGroup id="address-line2" label="Address line two" name="address-line2"/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputGroup required id="locality" label="City" name="locality"/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputGroup required id="postcode" label="Postcode" name="postal_code"/>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Dropdown required id="country" label="Country" name="country"/>
                        </Grid>
                    </Grid>
                </Form>
            </Card>
        </Page>
    );
}

function PlacementList() {
    const [popupActive, setPopupActive] = useState(false);
    const [viewPlacementPopup, setViewPlacementPopup] = useState<PlacementListing|false>(false);
    const [viewPlacementContent, setViewPlacementContent] = useState<PlacementListing>();
    const [deletePlacementPopup, setDeletePlacementPopup] = useState<PlacementListing|false>(false);
    const user = useContext(UserContext);
    const {uploadPlacements, alert} = useInstitutePlacementListingHandler({...{user}});

    useEffect(() => {
        if (viewPlacementPopup) {
            setViewPlacementContent(viewPlacementPopup);
            return;
        }
    }, [viewPlacementPopup]);

    const placementListingQuery: QueryObject[] = [{
        path: ["placementListings"],
        where: [["uploadedBy", "==", "admin"]],
        orderBy: "documentId",
    },
    {
        path: ["placementListings"],
        where: [["uploadedBy", "==", "provider"]],
        orderBy: "documentId",
    }];

    const approvePlacement = async () => {
        console.log("Approve function not built yet.");
    };

    const rejectPlacement = async () => {
        console.log("Reject function not built yet.");
    };

    return (
        <Page title="Student Users" grid>
            <Card grid xs={12} md={4} title={""}>
            </Card>
            <Grid item xs={12}></Grid>
            <Grid xs={12} item>
                {false && <FilterTable
                    columns={["name", "title", "email", "uploadedBy", "status"]}
                    columnTemplate={{name: (name: string) => <strong>{name}</strong>, uploadedBy: (name: string) => <Chip label={capitaliseWords(name)}/>, status: (name: string) => <Chip variant="filled" color={name === "active" ? "success" : name === "public" ? "primary" : name === "requiresApproval" ? "warning" : undefined} label={capitaliseWords(camelCaseToNormal(name))}/>}}
                    viewRows
                    onItemClick={(_, row) => setViewPlacementPopup(row as PlacementListing)}
                    hook={(data: any) => usePlacementListingPaginator({data: data as QueryObject[], user: user})}
                    data={placementListingQuery}
                    actionButtonText="Add placements"
                    onActionButtonClick={() => setPopupActive(true)}/>}
            </Grid>
            <Popup maxWidth={"lg"} open={popupActive} onClose={() => setPopupActive(false)} title={"Add placement"}>
                <SpreadsheetUploader
                    cellTypes={{country: ["dropdown", countryList, "unitedKingdom"]}}
                    alert={alert}
                    noNewColumns
                    onSubmit={(e) => uploadPlacements(e as InstitutePlacementListingUpload[]).then((e) => e && setPopupActive(false))}
                    defaultCols={["provider", "jobTitle", "email", "phone", "addressOne", "addressTwo", "city", "postcode", "country"]}/>
            </Popup>
            <DeletePopup open={Boolean(deletePlacementPopup)} onClose={() => setDeletePlacementPopup(false)} title={"placement"} onDelete={async () => await rejectPlacement()}/>
            {false && <PlacementListingDetailsPopup open={Boolean(viewPlacementPopup)} onClose={() => setViewPlacementPopup(false)} placement={viewPlacementContent} onApprove={async () => await approvePlacement()} onDeletePopupToggled={() => setDeletePlacementPopup(viewPlacementContent || false)}/>}
        </Page>
    );
}

function PlacementsMap() {
    return (
        <Page fullHeight noGutters>
            <PlacementMap fullscreen={false} title="Search placements" type="internal"/>
        </Page>);
}
