import {CohortData, PlacementExportObject, executeCallable, storage} from "placementt-core";
import {Popup} from "../../../../../Components/Popup";
import {Switch, Table, TableCell, TableRow} from "@mui/material";
import {useState} from "react";
import DateRangePicker from "../../../../../Components/FormComponents/DateRangePicker";
import {getDownloadURL, ref} from "firebase/storage";
import Form from "../../../../../Components/Form";


export default function DataExportPopup({type, cohort, open, onClose}:{type: "placements"|"users", cohort: "all"|CohortData, open: boolean, onClose: () => void}) {
    const [dataExportConfig, setDataExportConfig] = useState<PlacementExportObject>({cohortId: cohort === "all" ? "all" : cohort.id || ""});

    const submitDataExportRequest = async () => {
        const res = await executeCallable("exportPlacementData", dataExportConfig);
        const url = await getDownloadURL(ref(storage, res.data));
        window.open(url, "_blank");
    };

    const handleChange = (name: keyof PlacementExportObject, value: any) => {
        setDataExportConfig((d) => ({...d, [name]: value}));
    };

    return (
        <Popup title={`Export ${type} data`} {...{open, onClose}} fullWidth>
            <Form onSubmit={submitDataExportRequest}>
                <Table>
                    <TableRow>
                        <TableCell>Cohort</TableCell>
                        <TableCell>{cohort === "all" ? "All cohorts" : cohort.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>All time</TableCell>
                        <TableCell><Switch checked={dataExportConfig.allTime} onChange={(e) => handleChange("allTime", e.target.checked)}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Dates</TableCell>
                        <TableCell><DateRangePicker name={"date"} start={dataExportConfig.startDate} end={dataExportConfig.endDate} onChange={(date, type) => handleChange(`${type}Date`, date)}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Include student data (slower)</TableCell>
                        <TableCell><Switch checked={dataExportConfig.includeStudentData} onChange={(e) => handleChange("includeStudentData", e.target.checked)}/></TableCell>
                    </TableRow>
                </Table>
            </Form>
        </Popup>
    );
}
