import {Divider, Grid, Icon, Stack, Typography} from "@mui/material";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {useEffect, useState} from "react";
import LinearProgressbar from "../../Components/LinearProgressBar";
import {ArrowDropDownRounded, ArrowDropUpRounded, RemoveRounded} from "@mui/icons-material";
import {ERRORTEXTCOLOR, SUCCESSTEXTCOLOR} from "placementt-core";


export default function SkillsViewer({type, id, skillsTargetsValues}:{type: "placements"|"users", id: string, skillsTargetsValues?: {[key: string]: {provider: number[], student: number[]}}}) {
    const [addSkillsCounter, setAddSkillsCounter] = useState(0);
    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        if (addSkillsCounter !== 7 || !id) return;
        setAddSkillsCounter(0);

        const targets: {[skill: string]: {
            student: number[],
            provider: number[]
        }} = {};

        function getUniqueSkill() {
            let randomSkill;
            const skills = ["Animal Handling", "Inpatient Care", "Communication", "Surgical Preparation", "Medication Administration", "Diagnostic Imaging", "Laboratory Techniques", "Anaesthesia Support", "Record Keeping", "Surgical Assistance", "Client Education", "Triage Skills", "Emergency Care", "Animal Nutrition", "Equipment Sterilization", "Animal Behavior", "Venipuncture", "Teamwork"];

            do {
                // Get a random index from the skills array
                const randomIndex = Math.floor(Math.random() * skills.length);
                randomSkill = skills[randomIndex];
            } while (randomSkill in targets); // Check if skill exists as a key in targets

            return randomSkill; // Return the unique skill
        }

        function generateNewNumber(inputNumber: number) {
            // Check the input number to ensure it is within valid bounds
            let newInputNumber = inputNumber;
            if (inputNumber > 10) {
                newInputNumber = 10;
            }
            if (inputNumber < 1) {
                newInputNumber = 1;
            }

            // Generate a random number to decide if we go up to 3 or down 1
            const randomChoice = Math.random() < 0.5; // 50% chance for each choice

            if (randomChoice) {
                // Generate a number up to 3 above the input number
                const maxIncrease = Math.min(3, 10 - newInputNumber); // Ensure it doesn't exceed 10
                return newInputNumber + Math.floor(Math.random() * (maxIncrease + 1));
            } else {
                // Generate a number that is 1 below the input number
                return newInputNumber - 1;
            }
        }
        const limit = (Math.floor(Math.random() * 4) + 3);
        console.log("LIMIT", limit);
        for (let i = 0; i <= limit; i++) {
            const providerVal = Math.floor(Math.random() * 5) + 4;
            const studentVal = generateNewNumber(providerVal);

            targets[getUniqueSkill()] = {
                provider: [providerVal, generateNewNumber(providerVal + 1)],
                student: [studentVal, generateNewNumber(studentVal + 1)],
            };
            console.log("TAR", targets);
        }

        console.log("targets", targets);

        firebaseQuery.update([type, id], {skillsTargetsValues: targets});
    }, [addSkillsCounter]);

    return (
        <Stack mt={4}>
            <Stack direction={"row"} justifyContent={"space-between"} pr={2}>
                <Typography variant="h5" onClick={() => setAddSkillsCounter((c) => c+1)}>Skills development</Typography>
                {type === "placements" && <Stack spacing={0} sx={{opacity: 0.7}}>
                    <Typography variant="caption">Provider rating: How the provider has rated student progress.</Typography>
                    <Typography variant="caption">Student rating: The student's own rating of their progress</Typography>
                </Stack>}
                <LinearProgressbar primary={40} secondary={85} primaryText="Initial rating" secondaryText="Progress"/>
            </Stack>
            <Grid container>
                {Object.entries(skillsTargetsValues || {}).map((skill, index) => {
                    const providerChange = skill[1].provider[1] - skill[1].provider[0];
                    const studentChange = skill[1].student[1] - skill[1].student[0];

                    return (<Grid item xs={12} sm={12} md={6}>
                        <Stack ml={-2} pl={2} mb={-2} pb={2} pt={1} borderBottom={Object.entries(skillsTargetsValues || {}).length - index > (2 - (Object.entries(skillsTargetsValues || {}).length % 2)) ? "1px dashed #e1e1e1" : undefined} borderLeft={index % 2 === 1 ? "1px dashed #e1e1e1" : undefined} spacing={0}>
                            <Typography variant="h6">{skill[0]}</Typography>
                            <Stack spacing={1}>
                                <Stack flex={1} direction={"row"} spacing={4} alignItems={"center"}>
                                    <LinearProgressbar label="Provider rating" primary={skill[1].provider[0]*10} secondary={skill[1].provider[1]*10}/>
                                    <Stack direction={"row"} alignItems={"center"} spacing={0}>
                                        <Icon fontSize="large">
                                            {providerChange > 0 ? <ArrowDropUpRounded fontSize="large" color="success"/> : providerChange < 0 ? <ArrowDropDownRounded fontSize="large" color="error"/> : <RemoveRounded sx={{width: "20px"}} fontSize="large" color="disabled"/>}
                                        </Icon>
                                        <Typography fontWeight={"bold"} color={providerChange > 0 ? SUCCESSTEXTCOLOR : providerChange < 0 ? ERRORTEXTCOLOR : "lightgrey"}>{Math.abs(providerChange)}</Typography>
                                    </Stack>
                                </Stack>
                                <Stack flex={1} direction={"row"} spacing={4} alignItems={"center"}>
                                    <LinearProgressbar label="Student rating" primary={skill[1].student[0]*10} secondary={skill[1].student[1]*10}/>
                                    <Stack direction={"row"} alignItems={"center"} spacing={0}>
                                        <Icon fontSize="large">
                                            {studentChange > 0 ? <ArrowDropUpRounded fontSize="large" color="success"/> : studentChange < 0 ? <ArrowDropDownRounded fontSize="large" color="error"/> : <RemoveRounded sx={{width: "20px"}} fontSize="large" color="disabled"/>}
                                        </Icon>
                                        <Typography fontWeight={"bold"} color={studentChange > 0 ? SUCCESSTEXTCOLOR : studentChange < 0 ? ERRORTEXTCOLOR : "lightgrey"}>{Math.abs(studentChange)}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>);
                })}
            </Grid>
            <Divider/>
        </Stack>
    );
}
