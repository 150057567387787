import {ReactNode, useState} from "react";
import Alert from "./Alert";
import {Button, Grid, Stack} from "@mui/material";
import {Popup} from "./Popup";

export type AlertObject = {title: string, description?: string, actions?: ReactNode, severity: "primary"|"success"|"info"|"warning"|"error"|"critical"};

export default function CriticalAlertPopup({items, grid}:{items: AlertObject|AlertObject[], grid?: boolean}) {
    const [popup, setPopup] = useState(false);

    const AlertItems = Array.isArray(items) ? <>
        <Alert severity="error" variant="filled" action={<Button color="inherit" onClick={() => setPopup(true)}>View reports</Button>}><strong>Multiple reported placements</strong> | {items.length} students have reported their placements</Alert>
        <Popup open={popup} fullWidth title={"Review student reports"} onClose={() => setPopup(false)}>
            <Stack>
                {items.map((item) => <Alert severity={item.severity === "critical" ? "error" : item.severity} variant={item.severity === "critical" ? "filled" : undefined} action={item.actions}><strong>{item.title}</strong> | {item.description}</Alert>)}
            </Stack>
        </Popup>
    </> : <Alert severity={items.severity === "critical" ? "error" : items.severity} variant={items.severity === "critical" ? "filled" : undefined} action={items.actions}><strong>{items.title}</strong> | {items.description}</Alert>;

    return grid ? <Grid item xs={12}>{AlertItems}</Grid> : AlertItems;
}
