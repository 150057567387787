import {Button, List, ListItem, Typography} from "@mui/material";
import Card from "../../../../../Components/Card";
import {Popup} from "../../../../../Components/Popup";
import ListMaker from "../../../../../Components/FormComponents/ListMaker";
import {useState} from "react";
import Form from "../../../../../Components/Form";
import InputGroup from "../../../../../Components/FormComponents/InputGroup";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";


export default function SetSkillsTargets({type, id, skillsTargets}:{type: "cohorts"|"users", id: string, skillsTargets?: string[]}) {
    const [skillsTargetsPopup, setSkillsTargetsPopup] = useState(false);
    const [skills, setSkills] = useState<string[]>();

    const firebaseQuery = new FirebaseQuery();

    const setSkillsTargets = async () => {
        await firebaseQuery.update([type, id], {skillsTargets: skills});
    };

    return (
        <Card grid xs={12} sm={6} md={4} title={"Skills targets"} secondaryTitle={<Button onClick={() => setSkillsTargetsPopup(true)}>{skillsTargets ? "Edit" : "Add"}</Button>} subtitle="Specify specific skills for your students and their employers to assess during placements.">
            {skillsTargets ? <List sx={{maxHeight: "200px"}}>{skillsTargets.map((target) => <ListItem>{target}</ListItem>)}<ListItem><Typography variant="caption">Note: Skills targets can be set for individual students by clicking their profile on the 'Students' tab</Typography></ListItem></List> : <Typography variant="caption">Adding skills targets allows you to assess students' strengths adn weaknesses, and what areas specific areas different businesses support. This allows you to improve your matchmaking between students and employers.</Typography>}
            <Popup fullWidth maxWidth={"sm"} title={`${skillsTargets ? "Edit" : "Add"} skills targets`} open={skillsTargetsPopup} onClose={() => setSkillsTargetsPopup(false)}>
                <Form onSubmit={async () => await setSkillsTargets()}>
                    <ListMaker items={skillsTargets} onChange={(e) => setSkills(e as string[])} label="Skills" name="skills">
                        <InputGroup placeholder={"E.g. Punctuality"}/>
                    </ListMaker>
                </Form>
            </Popup>
        </Card>
    );
}
