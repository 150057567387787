import {Fullscreen, FullscreenExit, KeyboardArrowLeftRounded, KeyboardArrowRightRounded, SortByAlpha} from "@mui/icons-material";
import {Checkbox, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText, Popover, Radio, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {Children, ReactNode, cloneElement, createRef, isValidElement, useEffect, useState} from "react";
import IconButtonPop from "./IconButtonPop";
import {RenderFlags} from "../Util/visualUtil";
import {camelCase, camelCaseToNormal, DataViewerPaginater, FilterObject, FlagCodes, QueryObject, Sorts, useDataViewerPaginator} from "placementt-core";
import FilterSelector from "./FilterSelector";
import {useSearchParams} from "react-router-dom";
import {SearchBox} from "./SearchBox";
import useWindowDimensions from "../Util/util";
import Card from "./Card";
import FilterList from "./FilterList";

// editCols must be a link to the collection where to alter docs

type FilterTableParams = {
    title?: ReactNode,
    id?: string,
    data?: {[key:string]: {[key:string]: unknown}}|QueryObject[],
    columns?: string[],
    columnTemplate?: {[key:string]: unknown}, // Allow you to edit the value displayed. Can be a callback function.
    columnHeaders?: {[key: string]: ReactNode} // Allow you to change the header of a column from one value to another. Useful if data key is not user friendly.
    children?: React.ReactNode,
    setSelectedRows?: (e:unknown) => void,
    setTableFilters?: (e:{[key: string]: unknown; }) => void,
    formatQueryData?: (e:unknown) => void,
    onItemClick?: (id:string, row?: {[key:string]: unknown}) => void,
    filterNames?: FilterObject,
    access?: boolean,
    hook?: (e: DataViewerPaginater) => ReturnType<typeof useDataViewerPaginator>,
    viewRows?: boolean,
    disableSelection?: boolean,
    onSearch?: boolean | ((search?: string, sort?: [string, {value: string, direction: "asc"|"desc"}], page?: number, filters?:FilterObject, limit?: number) => Promise<{ [key: string]: any }>);
    listViewRenderer?: (k: string, v: any) => Promise<(false | JSX.Element)>|JSX.Element,
    noResultsText?: ReactNode,
    actions?: ReactNode,
    viewType?: "list"|"table",
    limit?: number,
    snapshot?: boolean,
    additionalEntryProcessing?: (k: string, v: any) => Promise<any> | any,
    grid?: boolean,
    xs?: number,
    sm?: number,
    md?: number,
    lg?: number,
    card?: boolean,
    sx?: {[key: string]: unknown}
}

// NEED TO ADD DISABLESELECTION!!!!!!
// NEED TO ADD HIDENORESULTSTEXT
// NEED TO ADD SETSELECTEDROWS

export default function DataViewer({
    title, onSearch, viewType = "list", snapshot, noResultsText, actions, listViewRenderer,
    data = {}, hook = (e) => useDataViewerPaginator(e), disableSelection, /* setTableFilters, */columnHeaders,
    columns, columnTemplate = {}, additionalEntryProcessing, children, setSelectedRows,
    formatQueryData, filterNames, id, grid, sx, xs, sm, md, lg, /* viewRows, */onItemClick,
    access = true, limit,
}: FilterTableParams) {
    const [selected, setSelected] = useState<{ [key: string]: { [key: string]: unknown } }>();
    const [allSelected, setAllSelected] = useState(false);
    const [renderedItems, setRenderedItems] = useState<(false | JSX.Element)[]>();
    const [fullscreen, setFullscreen] = useState(false);

    const [view, setView] = useState<"list" | "table">((columns && listViewRenderer) ? viewType : (!columns ? "list" : !listViewRenderer ? "table" : "list"));
    const {width} = useWindowDimensions();

    const {filters} = useGetFilters({urlRef: id || camelCase((title && typeof title === "string") ? title : "dataViewer"), filterNames});

    const {
        tableData,
        loading,
        page,
        pageUp,
        pageDown,
        setFilters,
        updateSearch,
        updateSort,
        setView: setHookView,
        sorts,
        sort,
    } = hook({data, view, queryLimit: limit, snapshot, additionalEntryProcessing, onSearch});


    useEffect(() => {
        setHookView(view);
    }, [view]);

    useEffect(() => {
        setFilters(filters);
    }, [filters]);

    const tableContainerScrollRef = createRef<HTMLDivElement>();
    /*
    useEffect(() => {
        setSelected(undefined);
        setAllSelected(false);
        setSelectedRows && setSelectedRows({});
        reset();
    }, [updateFilters]);
*/
    useEffect(() => {
        if (!tableContainerScrollRef.current) return;
        tableContainerScrollRef.current.scrollTo(0, 0);
    }, [tableData]);

    useEffect(() => {
        if (allSelected) {
            setSelectedRows && setSelectedRows("All");
            return;
        }
        if (setSelectedRows && selected) {
            setSelectedRows(selected);
        }
    }, [selected, setAllSelected]);

    const setAllSelectedHandler = () => {
        if (allSelected) {
            setSelected({});
            setAllSelected(false);
        } else {
            setSelected(tableData);
            setAllSelected(true);
        }
    };

    useEffect(() => {
        const renderPlacements = async () => {
            if (!tableData || !listViewRenderer) return;
            setRenderedItems(
                (await Promise.all(Object.entries(tableData).map(async ([key, item]) => await listViewRenderer(key, item)))).filter((x) => x)
            );
        };
        renderPlacements();
    }, [tableData, width]);


    const dataTable = (view === "table" && columns) ? (
        <Box sx={{maxHeight: "100%", display: "flex", flexFlow: "column", position: "relative"}}>
            <TableContainer ref={tableContainerScrollRef} style={{flex: "1 1 auto", marginBottom: "24px"}}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {disableSelection || <TableCell style={{padding: 0}}><Checkbox checked={allSelected} onClick={() => setAllSelectedHandler()}/></TableCell>}
                            {columns.map((value) => (
                                <TableCell style={{padding: 0}} key={value}>
                                    <ListItem disablePadding style={{paddingLeft: 0}}>
                                        <ListItemText primary={columnHeaders?.[value] || camelCaseToNormal(value)}/>
                                    </ListItem>
                                </TableCell>
                            ))}
                            {Object.entries(tableData).find(([, v]) => (v as {[key:string]:unknown}).flags) && <TableCell style={{padding: 0}}></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries((formatQueryData ? formatQueryData(tableData) : tableData) as {[key: string]: { [key: string]: unknown }}).map(([key, row]) => (
                            <TableRow key={key} onClick={() => onItemClick && onItemClick(key, row)} sx={onItemClick ? {":hover": {background: "#00000012"}, "cursor": "pointer"} : {}}>
                                {disableSelection || <TableCell style={{padding: 0}}>
                                    <Checkbox checked={Boolean(allSelected || (selected && Object.prototype.hasOwnProperty.call(selected, key)))}
                                        onClick={(e) => {
                                            selected && Object.prototype.hasOwnProperty.call(selected, key) ? setSelected((s) => s ? Object.fromEntries(Object.entries(s).filter(([k]) => k !== key)) : undefined) : setSelected({...selected, [key]: row});
                                            e.stopPropagation();
                                        }}/>
                                </TableCell>}
                                {columns.map((value) => {
                                    const colValue = (columnTemplate[value] && (columnTemplate[value] as (e:unknown, row: unknown) => string)(row[value], row)) || row[value] as string;

                                    return <TableCell>{colValue as string}</TableCell>;
                                })}
                                {(row?.flags as Array<unknown>)?.length > 0 && <TableCell style={{padding: 0}}>
                                    <RenderFlags flags={row.flags as FlagCodes[]} icon/>
                                </TableCell>}
                            </TableRow>
                        ))}
                        {Object.keys(tableData).length === 0 ?
                            <TableRow>
                                <TableCell colSpan={columns.length + 2} sx={{textAlign: "center", opacity: 0.7}}>
                                    {access ? loading === "loaded" ? "End of results" : "No data to display." : "You don't have access to this. Contact your administrator to update access in your user group."}
                                </TableCell>
                            </TableRow> : loading === "loaded" && <TableRow>
                                <TableCell colSpan={columns.length + 2} sx={{textAlign: "center", opacity: 0.7}}>
                                    End of results
                                </TableCell>
                            </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={0}>
                <Typography flex={1}>Page {page}</Typography>
                <IconButtonPop title="Page down" responsive={false} onClick={pageDown} disabled={page === 1}><KeyboardArrowLeftRounded/></IconButtonPop>
                <IconButtonPop title="Page up" responsive={false} onClick={pageUp} disabled={loading === "loaded"}><KeyboardArrowRightRounded/></IconButtonPop>
            </Stack>
        </Box>
    ) : (listViewRenderer ?
        <FilterList
            loadMoreIcon={Object.entries(tableData).length === 0 ? "loaded" : loading}
            card={false}
            noResultsText={noResultsText}
            data={tableData}
            noSearch
            onScrollBottom={pageUp}>
            {renderedItems}
        </FilterList> : "No view defined.");

    return (
        <Card
            title={<Box minWidth={"max-content"}>{title}</Box>}
            sx={{height: fullscreen ? "calc(100vh - 80px)" : "100%", position: fullscreen ? "fixed" : undefined, left: fullscreen ? 40 : undefined, top: fullscreen ? 40 : undefined, width: fullscreen ? "calc(100% - 80px)" : "100%", zIndex: fullscreen ? 1202 : undefined, ...(fullscreen ? {} : (sx || {}))}}
            contentSx={{display: "flex"}}
            {...{grid, xs, sm, md, lg}}
            secondaryTitle={
                <Stack key={"search"} direction={"row"} alignItems={"center"} spacing={1}>
                    {sorts && <SortSelector sorts={sorts} onSort={updateSort} sort={sort?.[0]}/>}
                    {filterNames && <FilterSelector filters={filterNames} urlRef={id}/>}
                    {onSearch && updateSearch && <SearchBox placeholder={"Search"} onSearch={updateSearch}/>}
                    {actions}
                    <Box flex={1}><IconButtonPop onClick={() => setFullscreen((f) => !f)} sx={{alignSelf: "center", float: "right"}} title={fullscreen ? "Close fullscreen" : "Open in fullscreen"} responsive={false}>{fullscreen ? <FullscreenExit/> : <Fullscreen/>}</IconButtonPop></Box>
                </Stack>}>
            <Stack flex={1}>
                {(columns && listViewRenderer) && <Tabs value={view} onChange={(e, v) => setView(v)}>
                    <Tab value={"list"} label="List view"/>
                    <Tab value={"table"} label="Table view"/>
                </Tabs>}
                <Stack direction={"row"} alignItems={"center"}>
                    {Children.count(children) > 0 && (
                        <Box sx={{display: "inline", position: "relative", marginLeft: "8px"}}>
                            <Typography
                                position={"absolute"}
                                top={"50%"}
                                sx={{translate: "0 -50%", width: "max-content", opacity: selected && Object.keys(selected).length ? 0 : 1, transition: "opacity 100ms ease-in-out"}}
                                variant="subtitle2"
                                color={"#0000006d"}
                                display={"inline-block"}
                            >
                        Select an item to view options
                            </Typography>
                            {Children.map(children, (child) => {
                                if (!isValidElement(child)) {
                                    return child;
                                }
                                const props = {sx: {opacity: (selected && Object.keys(selected).length) ? 1 : 0, transition: "opacity 100ms ease-in-out"}};
                                return cloneElement(child, props);
                            })}
                        </Box>
                    )}</Stack>
                <Stack mt={"0px !important"} overflow={"auto"}>
                    {dataTable}
                </Stack>
            </Stack>
        </Card>);
}


const useGetFilters = ({urlRef, filterNames}:{urlRef?: string, filterNames?: FilterObject}) => {
    const [filters, setFilters] = useState<FilterObject>();

    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!urlRef || !filterNames) return;
        const f = Object.fromEntries([...searchParams]);
        const filterElementId = f.id;
        delete f.id;

        if (filterElementId !== urlRef) {
            setFilters({});
            return;
        }

        const filtersWithValues = {...filterNames};

        Object.entries(f).map(([k, v]) => {
            const filterType = filtersWithValues[k].type;
            switch (filterType) {
            case "string":
                filtersWithValues[k].value = v;
                break;
            case "number":
                filtersWithValues[k].value = parseInt(v);
                break;
            case "dropdown":
                filtersWithValues[k].value = v === "true" ? true : v === "false" ? false : v;
                break;
            default:
                filtersWithValues[k].value = v;
                break;
            }
        });

        setFilters(filtersWithValues);
    }, [searchParams]);

    return {filters};
};

type SortSelectorParams = {
    sorts: Sorts,
    sort?: string,
    onSort: (sort: string) => void
}

function SortSelector({sorts, sort, onSort}: SortSelectorParams) {
    const [sortOpen, setSortOpen] = useState<Element>();

    const handleSortButtonClick = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (event.detail === 0) return;
        setSortOpen(event.currentTarget);
    };

    if (!sorts) return null;

    return (
        <Stack direction={"row"} alignItems={"center"} height={"min-content"}>
            <IconButtonPop aria-describedby={"filterPopup"} title={`Sort${sort ? `: ${sort}` : ""}`} onClick={(e) => handleSortButtonClick(e)}><SortByAlpha/></IconButtonPop>
            <Popover
                open={Boolean(sortOpen)}
                onClose={() => setSortOpen(undefined)}
                id={"filterPopup"}
                anchorEl={sortOpen}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box sx={{minWidth: "300px"}}>
                    <Typography p={2} pb={0} fontWeight={"bold"} color={"primary"}>Sort results by</Typography>
                    <List>
                        {Object.keys(sorts).map((sortName) =>
                            <ListItemButton onClick={() => {
                                if (sort !== sortName) {
                                    onSort(sortName);
                                }
                                setSortOpen(undefined);
                            }}>
                                <ListItemText primary={sortName}/>
                                <ListItemSecondaryAction sx={{mr: 0}}>
                                    <Radio checked={sort === sortName}/>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        )}
                    </List>
                </Box>
            </Popover>
        </Stack>
    );
}
