import React from "react";
import styled, {keyframes} from "styled-components";
import loadingIcon from "../Images/loading.svg";


const preloaderPulsate = keyframes`
  0%   {transform: scale(0.7);}
  100% {transform: scale(0.8);}
`;

const PreloaderImage = styled("img")<{visible?: boolean, size?: string}>`
    width:${(props) => props.size ? props.size : "40px"};
    margin: auto !important;
    display: ${(props) => props.visible ? "flex" : "none"};
    animation: ${preloaderPulsate} 0.9s alternate infinite ease-in-out;
`;


export default function Preloader({visible, size}:{visible?: boolean, size?: string}) {
    return (
        <PreloaderImage src={loadingIcon as any} size={size} visible={visible} alt="Loading icon" />
    );
}
