import {Address, ApplicantWorkflow, Application, PlacementListing, ProviderData, camelCaseToNormal, capitalise, capitaliseWords, convertDate, storage} from "placementt-core";
import {ReactNode, useContext, useEffect, useState} from "react";
import Page from "../../Components/Page";
import FilterList from "../../Components/FilterList";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {UserContext} from "../../App";
import {where} from "firebase/firestore";
import {Box, Chip, ListItemButton, ListItemSecondaryAction, ListItemText, Stack, Tab, Tabs, Typography} from "@mui/material";
import {PlacementApplicationPopup} from "./PlacementApplicationPopup";
import {getDownloadURL, ref} from "firebase/storage";
import IconButtonPop from "../../Components/IconButtonPop";
import {ArrowForward, Delete, Edit} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";


export default function StudentApplications() {
    const [table, setTable] = useState<"open"|"closed">("open");
    return (
        <Page title="My applications">
            <Tabs value={table} onChange={(_, n) => setTable(n)}>
                <Tab value={"open"} label={"Open"}/>
                <Tab value={"closed"} label={"Closed"}/>
            </Tabs>
            <StudentApplicationTable key={"open"} visible={table === "open"} type="open"/>
            <StudentApplicationTable key={"closed"} visible={table === "closed"} type="closed"/>
        </Page>
    );
}


function StudentApplicationTable({type, visible}:{type: "open"|"closed", visible: boolean}) {
    const [applications, setApplications] = useState<{[key: string]: Partial<Application>}>();
    const navigate = useNavigate();
    const [renderedApplications, setRenderedApplications] = useState<ReactNode>();
    const [applicationPopupOpen, setApplicationPopupOpen] = useState<{provider: ProviderData|false, listing: PlacementListing|false, listingId: string, application: Partial<Application>, applicationId: string}|undefined>();

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);

    useEffect(() => {
        firebaseQuery.collectionSnapshot(setApplications, "applications", [where("uid", "==", user.id), where("status", "in", type === "open" ? ["draft", "submitted"] : ["approved", "declined"])]);
    }, []);

    useEffect(() => {
        if (!applications) return;

        Promise.all(Object.entries(applications).map(async ([key, application]) => {
            console.log("PROVIDERID", application.providerId);
            const providerDetails = await firebaseQuery.getDocData(["providers", application.providerId || ""]).catch(() => false) as ProviderData|false;
            const listingDetails = await firebaseQuery.getDocData(["placementListings", application.listingId || ""]).catch(() => false) as PlacementListing|false;
            const addressDetails = listingDetails && listingDetails.addressId ? await firebaseQuery.getDocData(["addresses", listingDetails.addressId || ""]) as Address : undefined;

            if (listingDetails && listingDetails.applicantWorkflowId) {
                const applicantWorkflow = (await firebaseQuery.getDocData(["applicantWorkflows", listingDetails.applicantWorkflowId]) as ApplicantWorkflow).workflow.filter((i) => i.id === 1)[0];
                const applicantFiles = applicantWorkflow.files ? Object.fromEntries(await Promise.all(applicantWorkflow.files?.map(async (fileId) => {
                    const file = await firebaseQuery.getDocData(["files", fileId]) || undefined;

                    if (file) {
                        console.log("FJLE", file.fileName);
                        file.url = await getDownloadURL(ref(storage, `providers/${application.providerId}/${file.fileName}`));
                    }

                    return [fileId, file];
                }))) : [];
                const applicantForms = applicantWorkflow.forms ? Object.fromEntries(await Promise.all(applicantWorkflow.forms?.map(async (formId) => {
                    return [formId, await firebaseQuery.getDocData(["forms", formId])];
                }))) : [];

                applicantWorkflow.viewableFiles = applicantFiles;
                applicantWorkflow.formDetails = applicantForms;
                listingDetails.applicantWorkflow = [applicantWorkflow];
            }
            console.log(listingDetails);

            const chipColor = application.status === "submitted" ? "primary" : application.status === "approved" ? "success" : application.status === "declined" ? "error" : undefined;

            return (
                <ListItemButton sx={{height: "100px"}} divider key={key} id={key} onClick={() => setApplicationPopupOpen({provider: providerDetails, listing: listingDetails, listingId: application.listingId as string, application: application, applicationId: key})}>
                    <ListItemText
                        primary={`${providerDetails ? providerDetails.name : <Typography color={"error"}>Provider deleted</Typography>}${listingDetails ? " - "+listingDetails?.title : ""} | ${convertDate(application.startDate, "visual") as string} - ${convertDate(application.endDate, "visual") as string}`}
                        secondary={addressDetails ? `${addressDetails["address-line1"]} • ${addressDetails.postal_code} • ${capitaliseWords(camelCaseToNormal(addressDetails.country))}` : ""}
                    />
                    <ListItemSecondaryAction>
                        <Stack spacing={1} alignItems={"flex-end"}>
                            <Chip color={chipColor} sx={{width: "max-content"}} label={application.status !== "submitted" ? capitalise(application.status) : application.reqUserType === "Students" ? "Action Required" : "Submitted"}/>
                            {application.status === "approved" && application.placementId ? <Chip label="Go to placement" size="medium" color="primary" onClick={() => navigate(`/${user.product}/placements/${application.placementId}`)} onDelete={() => navigate(`/${user.product}/placements/${application.placementId}`)} deleteIcon={<ArrowForward/>}/> : <Stack direction={"row"}>
                                <IconButtonPop responsive={false} title="Edit"><Edit/></IconButtonPop>
                                <IconButtonPop responsive={false} title="Delete"><Delete/></IconButtonPop>
                            </Stack>}
                        </Stack>
                    </ListItemSecondaryAction>
                </ListItemButton>
            );
        })).then(setRenderedApplications);
    }, [applications]);

    return (
        <Box display={visible ? "flex" : "none"} width={"100%"}>
            <FilterList sx={{width: "100%"}} title={`${capitalise(type)} applications`} data={applications}>
                {renderedApplications}
            </FilterList>
            <PlacementApplicationPopup
                open={Boolean(applicationPopupOpen)}
                onComplete={() => setApplicationPopupOpen(undefined)}
                onClose={() => setApplicationPopupOpen(undefined)}
                provider={{details: applicationPopupOpen?.provider || undefined, id: applicationPopupOpen?.application.providerId}}
                listing={applicationPopupOpen?.listing || undefined}
                listingId={applicationPopupOpen?.listingId}
                application={applicationPopupOpen?.application}
                applicationId={applicationPopupOpen?.applicationId}/>
        </Box>
    );
}
