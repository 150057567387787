import {UserForm} from "../Pages/Institutes/Staff/Forms/UserForm";
import Form from "./Form";
import {Popup} from "./Popup";

export default function EditUserPopup({open, fields=[], initialData = {}, onSubmit, onClose}) {
    return (
        <Popup {...{open, onClose}} title={"Edit user"} cardSx={{width: "500px", maxWidth: "100%"}}>
            <Form onSubmit={onSubmit}>
                <UserForm existingFields={fields} data={initialData}/>
            </Form>
        </Popup>
    );
}
