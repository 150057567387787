import {CardContent, CardHeader, Grid, Stack} from "@mui/material";
import Page from "../../Components/Page";
import InfoPageHeader from "../../Components/Public/InfoPageHeader";
import {ClickableCard} from "../../Util/styledComponents";
import {useNavigate} from "react-router-dom";


export default function Help() {
    const navigate = useNavigate();

    return (
        <Page metaTitle="Placementt - Help" metaDesc="Get the support you need with our help center">
            <InfoPageHeader title="Help center" subtext="Get the help you need wth our help center. Can't find what you're looking for? Get in touch at admin@placementt.co.uk" background={"#7f55b9"}/>
            <Stack p={2} pt={9}>
                <Grid container>
                    <Grid item xs={12} sm={6} md={3}>
                        <ClickableCard onClick={() => navigate("/help/delete-account")}>
                            <CardHeader title="Delete your account"/>
                            <CardContent>
                                We would love to chat to you about any concerns or issues before you think about leaving, but if you'd still like to leave, click here.
                            </CardContent>
                        </ClickableCard>
                    </Grid>
                </Grid>
            </Stack>
        </Page>
    );
}
