import Page from "../../../Components/Page";


export default function AllUsers() {
    return (
        <Page title="All Users">
            ABC
        </Page>
    );
}
