import {Box, ListItem, ListItemText, Typography} from "@mui/material";
import ListContentViewer from "../../Components/ListContentViewer";
import {useState, useEffect, useContext} from "react";
import {UserContext} from "../../App";
import {arrayUnion} from "firebase/firestore";
import {getNotifications} from "placementt-core";
import ReactHtmlParser from "react-html-parser";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";

export default function Notifications() {
    const [notifications, setNotifications] = useState({});

    const user = useContext(UserContext);

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        getNotifications(user, setNotifications);
    }, []);

    const markAsRead = (id, notifType) => {
        let path = [user.product, user.oId, "notifications", id];
        if (notifType === "user") {
            path = ["users", user.id, "notifications", id];
        }
        firebaseQuery.update(path, {
            viewedBy: arrayUnion(user.id),
        });
    };

    const renderContent = (notification) => {
        console.log("notification", notification);
        let created = new Date(notification.created.toDate()).toDateString().split(" ");
        created = `${created[0]} ${created[2]}-${created[1]}-${created[3]}`;

        return (
            <ListItem key={notification.id} onClick={() => markAsRead(notification.id, notification.notifType)}
                sx={{flexDirection: "column", alignItems: "flex-start"}}
            >
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                    <Typography variant='overline'>{notification.author}</Typography>
                    <Typography variant='overline'>{created}</Typography>
                </Box>
                <ListItemText
                    sx={{marginTop: "-5px"}}
                    primary={notification.title}
                    // eslint-disable-next-line new-cap
                    secondary={ReactHtmlParser(notification.description)}
                />
            </ListItem>
        );
    };

    return (
        <ListContentViewer title={"Notifications"} listItems={notifications} contentTemplate={renderContent} standalone/>
    );
}
