import {Box, Stack, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {ReactNode, useState} from "react";


type TabButtonGroupItem = {
    label: string,
    value: string,
    item: ReactNode,
}[];

export default function TabButtonGroup({items, onChange, value}:{value?: string, items: TabButtonGroupItem, onChange: (selected: string) => void}) {
    const [selected, setSelected] = useState<string>(value || items[0].value);

    return (
        <Stack alignItems={"center"} spacing={0}>
            <ToggleButtonGroup sx={{alignSelf: "center"}} value={selected} exclusive onChange={(_, v) => {
                v && setSelected(v);
                onChange(v);
            }} color="primary">
                {items.map((item, i) => <ToggleButton sx={{borderBottomLeftRadius: 0}} value={item.value || i}>{item.label}</ToggleButton>)}
            </ToggleButtonGroup>
            <Box width={"100%"} borderRadius={"4px"} border={"1px solid rgba(0, 0, 0, 0.12)"} mt={"-1px"} p={3}>
                {items.find((o) => o.value === selected)?.item}
            </Box>
        </Stack>
    );
}
