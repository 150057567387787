import {useContext, useEffect, useState} from "react";
import Page from "../../Components/Page";
import {OrganisationAddress, PRIMARY_COLOUR, PlacementListing, ProviderData, camelCaseToNormal, capitaliseWords, decodeGeoHash, executeCallable, storage, useLazyLoadQueryList} from "placementt-core";
import {Box, Button, Divider, Grid, Link, ListItemButton, ListItemText, MenuItem, Stack, Table, TableRow, Typography} from "@mui/material";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {getDownloadURL, ref} from "firebase/storage";
import {useNavigate, useParams} from "react-router-dom";
import Card from "../../Components/Card";
import QRCodeGenerator from "../../Components/QRCode";
import {UserContext} from "../../App";
import {useCheckMobileScreen} from "../../Util/util";
import {InfoTableCell} from "../../Util/styledComponents";
import IconButtonPop from "../../Components/IconButtonPop";
import {AccessibleForward, ArrowBackRounded, ArrowForward, DirectionsBike, DirectionsCar, Edit, Kitchen, Upload} from "@mui/icons-material";
import {Popup} from "../../Components/Popup";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";
import {SectorSelector} from "../../Components/SectorSelector";
import Dropdown from "../../Components/FormComponents/Dropdown";
import {where} from "firebase/firestore";
import FilterList from "../../Components/FilterList";
import Map from "../../Components/Map";
import MobileLinkBanner from "../../Components/MobileLinkBanner";
import {BannerPic, ProfilePic, useInsuranceUploadPopupBanner, usePublishProviderPopup} from "../Providers/Home";
import Alert from "../../Components/Alert";
import {PlacementApplicationPopup} from "../Shared/PlacementApplicationPopup";

export default function ProviderPage() {
    const [provider, setProvider] = useState<ProviderData|undefined|false>();
    const [address, setAddress] = useState<OrganisationAddress|undefined|false>();
    const [listing, setListing] = useState<PlacementListing|undefined|false>();

    const {providerId, viewType, itemId, apply}:{providerId?: string, viewType?: "addresses"|"placementListings", itemId?: string, apply?: boolean} = useParams();

    const [applicationPopupOpen, setApplicationPopupOpen] = useState(apply || false);
    const navigate = useNavigate();

    const [primaryUserData, setPrimaryUserData] = useState<{forename: string, surname: string, pronouns?: string}>();

    useEffect(() => {
        if (viewType !== "placementListings") return;
        executeCallable("placementListing-getAssociatedStaffDetails", {placementId: itemId}).then((d) => setPrimaryUserData(d.data as {forename: string, surname: string, pronouns?: string}));
    }, [itemId, viewType]);

    const mobile = useCheckMobileScreen();

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);

    useEffect(() => {
        if (!providerId) return;
        firebaseQuery.documentSnapshot(["providers", providerId], setProvider);

        if (!viewType || !itemId) return;
        firebaseQuery.getDocData([viewType, itemId]).then((i) => viewType === "addresses" ? setAddress(i as OrganisationAddress) : viewType === "placementListings" ? setListing(i as PlacementListing) : undefined);
    }, [providerId, viewType, itemId]);

    useEffect(() => {
        if (!listing || !listing.addressId) return;

        firebaseQuery.getDocData(["addresses", listing.addressId]).then((i) => setAddress(i as OrganisationAddress));
    }, [listing]);

    const {eliUploadPopup, setProviderInsurancePopup} = user.oId === providerId ? useInsuranceUploadPopupBanner() : {eliUploadPopup: <></> as JSX.Element, setProviderInsurancePopup: (() => null) as React.Dispatch<React.SetStateAction<boolean>>};
    const {publishOrganisationPopup, setActivateProfilePopup} = user.oId === providerId ? usePublishProviderPopup({provider: provider}) : {publishOrganisationPopup: <></> as JSX.Element, setActivateProfilePopup: (() => null) as React.Dispatch<React.SetStateAction<boolean>>};

    useEffect(() => {
        if (location.hash.includes("publish")) {
            setActivateProfilePopup(true);
        }
    }, [location.hash]);


    if (provider === undefined) return null;

    // or if insurance expired. If date today after insuranceExpiry date
    if (!provider || (user.oId !== providerId && (!provider.mapConsent || provider.insurance !== true))) {
        return (
            <Page>
                <Typography>We can't find that provider.</Typography>
            </Page>
        );
    }

    const addressLocation = address && address?.geoHash ? decodeGeoHash(address.geoHash) : undefined;

    return (
        <Page noGutters>
            <MobileLinkBanner link={window.location.pathname}/>
            {(user.product === "providers" && user.oId === providerId && user.userGroup !== "readOnly") ? <BannerPic publicPage>
                <ProfilePic publicPage/>
            </BannerPic> : <PublicProfileBannerAndProfileImage providerId={providerId}/>}
            <Stack pl={"max(20vw, calc(120px + 5%))"} pr={"max(20vw, calc(120px + 5%))"} pt={mobile ? 0 : 2}>
                <Stack direction={"row"}>
                    <IconButtonPop responsive={false} title="Back" onClick={() => navigate(-1)}><ArrowBackRounded sx={{width: "40px", height: "40px"}}/></IconButtonPop>
                    <Typography variant={mobile ? "h4" :"h2"}>{provider.name}</Typography>
                </Stack>
                {viewType && <Typography variant={mobile ? "h6" :"h4"}>{(viewType === "addresses" && address) ? `${address["address-line1"]} · ${address.postal_code.toUpperCase()}` : (viewType === "placementListings" && listing) ? listing.title : undefined}</Typography>}
                {viewType === "placementListings" && listing && <Button variant="contained" onClick={() => setApplicationPopupOpen(true)}>Apply now</Button>}
            </Stack>
            <Grid container>
                <Grid item xs={0} sm={0} md={user.product === "providers" && user.oId === providerId ? 0 : 3} lg={user.product === "providers" && user.oId === providerId ? 0 : 3} xl={3}></Grid>
                <Grid item xs={12} md={user.product === "providers" && user.oId === providerId ? 8 : 6} lg={user.product === "providers" && user.oId === providerId ? 8 : 6} xl={6}>
                    {user.oId === providerId ?
                        <Box mb={2}>{eliUploadPopup}{publishOrganisationPopup}{!provider.insurance ? <Alert severity="error" sx={{alignItems: "center"}} action={<IconButtonPop onClick={() => setProviderInsurancePopup(true)} responsive={false} title="Upload"><Upload/></IconButtonPop>}>Upload your employers liability insurance to get your listings published.</Alert> :
                            provider.insurance === "awaitingReview" ? <Alert severity="info">We are currently reviewing your insurance. Your listings will not be public during this time.</Alert> :
                                provider.mapConsent ? <Alert action={<Button color="success" onClick={() => setActivateProfilePopup(true)}>Edit</Button>}>Your provider page is {provider.mapConsent === "unlisted" ? "published, but not listed on our map" : "listed on our public database"}.</Alert> : <Alert severity="info" sx={{alignItems: "center"}} action={<IconButtonPop onClick={() => setActivateProfilePopup(true)} responsive={false} title="Publish"><Upload/></IconButtonPop>}>Pages are not currently live. Click to publish.</Alert>}</Box> : undefined
                    }
                    {providerId && !viewType && <Stack spacing={4}>
                        <ProviderAboutInfo provider={provider} providerId={providerId}/>
                        <ProviderAddressList providerId={providerId}/>
                        <ProviderPlacementList providerId={providerId}/>
                    </Stack>}
                    {providerId && viewType === "placementListings" && listing && <Stack>
                        <Card title={"Overview"} expandable="200px">
                            <Typography>{listing?.questions?.description}</Typography>
                            <br/>
                            <Typography variant='h6' color={"primary"}>Experience given</Typography>
                            <Stack direction={"row"} flexWrap={"wrap"}>
                                {listing.questions.experiencesGiven ? (Array.isArray(listing.questions.experiencesGiven) ? listing.questions.experiencesGiven : listing.questions.experiencesGiven.split(", ")).sort((a, b) => b.length - a.length).map((exp) =>
                                    <Typography minWidth={"calc(50% - 20px)"} ml={"0 !important"}>• {exp}</Typography>
                                ) : <Typography>No information given.</Typography>}
                            </Stack>
                            <br/>
                            <Typography variant='h6' color={"primary"}>Experience required</Typography>
                            <Stack direction={"row"} flexWrap={"wrap"}>
                                {listing.questions.experienceDesired ? (Array.isArray(listing.questions.experienceDesired) ? listing.questions.experienceDesired : listing.questions.experienceDesired.split(", ")).sort((a, b) => b.length - a.length).map((exp) =>
                                    <Typography minWidth={"calc(50% - 20px)"} ml={"0 !important"}>• {exp}</Typography>
                                ) : <Typography>No information given.</Typography>}
                            </Stack>
                            <br/>
                            <Table size="small">
                                <TableRow>
                                    <InfoTableCell>Primary contact</InfoTableCell>
                                    <InfoTableCell>{primaryUserData?.forename} {primaryUserData?.surname} {primaryUserData?.pronouns ? <span style={{opacity: 0.7}}>({primaryUserData.pronouns})</span> : undefined}</InfoTableCell>
                                </TableRow>
                                <TableRow>
                                    <InfoTableCell width={"200px"}>Dress code</InfoTableCell>
                                    <InfoTableCell>{camelCaseToNormal(listing.questions.dressCode)}</InfoTableCell>
                                </TableRow>
                                <TableRow>
                                    <InfoTableCell>Working location</InfoTableCell>
                                    <InfoTableCell>{camelCaseToNormal(listing.questions.workingLocation)}</InfoTableCell>
                                </TableRow>
                                <TableRow>
                                    <InfoTableCell>Responsibilities</InfoTableCell>
                                    <InfoTableCell>{listing?.questions?.responsibilities}</InfoTableCell>
                                </TableRow>
                                <TableRow>
                                    <InfoTableCell>Required equipment</InfoTableCell>
                                    <InfoTableCell>{listing?.questions?.equipment}</InfoTableCell>
                                </TableRow>
                                <TableRow>
                                    <InfoTableCell>Other information</InfoTableCell>
                                    <InfoTableCell>{listing?.questions?.otherAdvice}</InfoTableCell>
                                </TableRow>
                            </Table>

                            <Stack direction={"row"}>
                                <strong>Amenities: </strong>
                                {listing.questions.carParking && <IconButtonPop responsive={false} title='Car parking' sx={{color: "gray"}}><DirectionsCar/></IconButtonPop>}
                                {listing.questions.bikeStorage && <IconButtonPop responsive={false} title='Bike storage' sx={{color: "gray"}}><DirectionsBike/></IconButtonPop>}
                                {listing.questions.wheelchairFriendly && <IconButtonPop responsive={false} title='Wheelchair friendly' sx={{color: "gray"}}><AccessibleForward/></IconButtonPop>}
                                {listing.questions.kitchen && <IconButtonPop responsive={false} title='Kitchen' sx={{color: "gray"}}><Kitchen/></IconButtonPop>}
                            </Stack>
                        </Card>
                        {address && <Card title={"Address"}>
                            <Stack>
                                <Typography>{capitaliseWords(address["address-line1"])+" · "+capitaliseWords(address["address-line2"])} · {capitaliseWords(address.locality)+" · "+address.postal_code.toUpperCase()} · {capitaliseWords(camelCaseToNormal(address.country))}</Typography>
                                {addressLocation && <Box sx={{position: "relative", overflow: "hidden"}} height={"300px"} borderRadius={"10px"}>
                                    <Map lat={addressLocation.latitude} lng={addressLocation.longitude} zoom={15} points={[[addressLocation.latitude, addressLocation.longitude]]}/>
                                </Box>}
                            </Stack>
                        </Card>}
                        {listing && user.userType === "Students" && <PlacementApplicationPopup open={applicationPopupOpen} onClose={() => setApplicationPopupOpen(false)} provider={{details: provider, id: providerId}} listing={listing} listingId={itemId}/>}
                    </Stack>}
                </Grid>
                <Grid item xs={12} sm={12} md={user.product === "providers" && user.oId === providerId ? 3.5 : 3} lg={user.product === "providers" && user.oId === providerId ? 3.5 : 3} xl={3}>
                    {user.product === "providers" && user.oId === providerId && <ProviderSocialsCard type={viewType || "page"} addressId={viewType === "addresses" ? itemId : undefined} placementId={viewType === "placementListings" ? itemId : undefined}/>}
                </Grid>
            </Grid>
        </Page>
    );
}

export function PublicProfileBannerAndProfileImage({providerId, publicPage}: {providerId?: string, publicPage?: boolean}) {
    const [profileUrl, setProfileUrl] = useState<string>();
    const [bannerUrl, setBannerUrl] = useState<string>();


    useEffect(() => {
        if (!providerId) return;

        getDownloadURL(ref(storage, `providers/${providerId}/bannerPic.png`)).then(setBannerUrl).catch(() => null);
        getDownloadURL(ref(storage, `providers/${providerId}/profilePic.png`)).then(setProfileUrl).catch(() => null);
    }, [providerId]);

    return (
        <Box height={publicPage ? "15vw" : "100px"} minHeight={"100px"} display={"grid"}>
            <Box height={publicPage ? "15vw" :"75px"} minHeight={publicPage ? "100px" : undefined} width={"100%"} borderBottom={`1px solid ${PRIMARY_COLOUR}`} component={"img"} sx={{objectFit: "contain"}} src={bannerUrl} />
            <Box width={publicPage ? "12vw" : "100px"} minWidth={"100px"} height={publicPage ? "12vw" : "100px"} minHeight={"100px"} top={publicPage ? "max(7vw, 50px)" : undefined} component={"img"} src={profileUrl} display={"flex"} sx={{objectFit: "cover"}} margin={publicPage ? "5%" : "20px"} position={"absolute"} borderRadius={"50%"} />
        </Box>
    );
}

function ProviderSocialsCard({type, placementId, addressId}: {type: "addresses"|"placementListings"|"page", placementId?: string, addressId?: string}) {
    const user = useContext(UserContext);
    const [linkCopied, setLinkCopied] = useState(false);

    let socialURLBase = `https://placementt.co.uk/provider/${user.oId}`;
    if (type === "placementListings" && placementId) {
        socialURLBase = socialURLBase + `/placementListings/${placementId}`;
    }
    if (type === "addresses" && addressId) {
        socialURLBase = socialURLBase + `/addresses/${addressId}`;
    }
    return (
        <Card title={`Share this ${type === "addresses" ? "address" : type === "placementListings" ? "listing" : "page"}`}>
            <Stack>
                <Stack direction={"row"}>
                    <Typography>Twitter</Typography>
                    <Typography>Facebook</Typography>
                    <Typography>Instagram</Typography>
                    <Typography>LinkedIn</Typography>
                </Stack>
                <Divider/>
                <Stack direction={"row"} alignItems={"center"}>
                    <Typography><strong>Share this QR code with anyone.</strong> Print it on flyers or posters, and anyone who scans it will be directed to this page.</Typography>
                    <QRCodeGenerator url={socialURLBase}/>
                </Stack>
                <Divider/>
                {/* <Stack direction={"row"} alignItems={"center"}>
                    <Typography><strong>Embed this page on your site.</strong> Seamlessly integrate this page within your website, to show off you offering.</Typography>
                    <Button>View tutorial</Button>
                </Stack>
                <Divider/> */}
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography><strong>Or simply copy and share a link</strong></Typography>
                    <Button onClick={() => {
                        navigator.clipboard.writeText(socialURLBase);
                        setLinkCopied(true);
                    }}>{linkCopied ? "Link copied!" : "Copy link"}</Button>
                </Stack>
            </Stack>
        </Card>
    );
}

function ProviderAboutInfo({provider, providerId}: {provider: ProviderData, providerId?: string}) {
    const [editPopupOpen, setEditPopupOpen] = useState(false);
    const user = useContext(UserContext);
    const [providerData, setProviderData] = useState<ProviderData>(provider);
    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        setProviderData(provider);
    }, [provider]);


    useEffect(() => {
        if (location.hash.includes("customise")) {
            setEditPopupOpen(true);
        }
    }, [location.hash]);

    const updateInfo = async (e: {description?: string, locations?: number, staff?: number, sector?: string, subsector?: string, website?: string}) => {
        if (!providerId || user.oId !== providerId || user.product !== "providers") throw new Error("Cannot update data.");

        const updateData = {
            sector: e.sector,
            subsector: e.subsector,
            website: e.website,
            ["information.staff"]: e.staff,
            ["information.locations"]: e.locations,
            ["information.description"]: e.description,
        };

        await firebaseQuery.update(["providers", providerId], updateData);
        setProviderData((p) => ({...p, sector: e.sector, subsector: e.subsector, website: e.website, information: {...p.information, staff: e.staff, locations: e.locations, description: e.description}} as ProviderData));
        setEditPopupOpen(false);
        return;
    };

    return (
        <Card title={"About Us"} secondaryTitle={user.product === "providers" && user.oId === providerId && <IconButtonPop responsive={false} title="Edit" onClick={() => setEditPopupOpen(true)}><Edit/></IconButtonPop>} expandable={user.product === "providers" ? undefined : "180px"}>
            <Typography>{providerData?.information?.description}</Typography>
            <br/><br/>
            <Table size="small">
                <TableRow>
                    <InfoTableCell width={"200px"}>Sector</InfoTableCell>
                    <InfoTableCell>{providerData.sector}</InfoTableCell>
                </TableRow>
                <TableRow>
                    <InfoTableCell>Subsector</InfoTableCell>
                    <InfoTableCell>{providerData.subsector}</InfoTableCell>
                </TableRow>
                <TableRow>
                    <InfoTableCell>Locations</InfoTableCell>
                    <InfoTableCell>{providerData?.information?.locations}</InfoTableCell>
                </TableRow>
                <TableRow>
                    <InfoTableCell>Staff</InfoTableCell>
                    <InfoTableCell>{providerData?.information?.staff}</InfoTableCell>
                </TableRow>
                <TableRow>
                    <InfoTableCell>Website</InfoTableCell>
                    <InfoTableCell><Link href={providerData.website}>{providerData.website}</Link></InfoTableCell>
                </TableRow>
            </Table>
            {user.product === "providers" && user.oId === providerId && <Popup fullWidth open={editPopupOpen} onClose={() => setEditPopupOpen(false)} title={"Add basic information"} subtitle="Information you add here will be publicly available on our placement database.">
                <Form onSubmit={(e) => updateInfo(e)}>
                    <Typography>General description</Typography>
                    <InputGroup multiline minRows={4} label={"General description"} type={"text"} name={"description"} value={providerData?.information?.description}/>
                    <Typography>Website</Typography>
                    <InputGroup label={"Website"} type={"text"} name={"website"} placeholder={"https://"} value={providerData.website}/>
                    <Typography>How many locations do you have?</Typography>
                    <InputGroup label="Locations" type="number" min={0} name={"locations"} value={providerData?.information?.locations}/>
                    <Typography>How many staff does your organisation employ?</Typography>
                    <Dropdown label="Staff" name={"staff"} value={providerData?.information?.staff?.toString()}>
                        <MenuItem value="0-5">0 - 5</MenuItem>
                        <MenuItem value="6-20">6 - 20</MenuItem>
                        <MenuItem value="21-50">21 - 50</MenuItem>
                        <MenuItem value="51-100">51 - 100</MenuItem>
                        <MenuItem value="101-500">101 - 500</MenuItem>
                        <MenuItem value="501-1000">501 - 1000</MenuItem>
                        <MenuItem value="1000+">1000+</MenuItem>
                    </Dropdown>
                    <SectorSelector sector={providerData.sector} subsector={providerData.subsector}/>
                </Form>
            </Popup>}
        </Card>
    );
}

function ProviderAddressList({providerId}: {providerId?: string}) {
    const constraints = [where("oId", "==", providerId), where("product", "==", "providers"), where("stage", "==", "complete")];

    const [addresses, setAddresses] = useState<{[key: string]: OrganisationAddress&{listings?: number}}>({});
    const navigate = useNavigate();
    const firebaseQuery = new FirebaseQuery();

    const {items, loadMore, loadMoreIcon} = useLazyLoadQueryList({
        path: "addresses",
        constraints: constraints,
        number: 5,
    });

    console.log("addresses", items);

    useEffect(() => {
        const newAddresses:{[key: string]: OrganisationAddress&{listings?: number}} = ({...(items as {[key: string]: OrganisationAddress}), ...(addresses || {})});

        Promise.all(Object.entries(newAddresses).map(async ([k, address]) => {
            if (address.listings !== undefined) return [k, address];

            const newAddress = {...address};
            newAddress.listings = await firebaseQuery.getCount("placementListings", [where("addressId", "==", k), where("providerId", "==", providerId)]);

            return [k, newAddress];
        })).then((items) => setAddresses(Object.fromEntries(items)));
    }, [items]);

    if (Object.keys(addresses).length === 1) {
        const address = Object.values(addresses)[0];
        const location = address?.geoHash ? decodeGeoHash(address?.geoHash) : undefined;

        return (<Card title={"Our Address"}>
            <Stack>
                <Typography>{capitaliseWords(address["address-line1"])+" · "+capitaliseWords(address["address-line2"])} · {capitaliseWords(address.locality)+" · "+address.postal_code.toUpperCase()} · {capitaliseWords(camelCaseToNormal(address.country))}</Typography>
                {location && <Box sx={{position: "relative", overflow: "hidden"}} height={"300px"} borderRadius={"10px"}>
                    <Map lat={location.latitude} lng={location.longitude} zoom={15} points={[[location.latitude, location.longitude]]}/>
                </Box>}
            </Stack>
        </Card>);
    }

    return (
        <FilterList title={"Our Addresses"} data={items} sx={{height: "300px"}} onScrollBottom={() => {
            console.log("LOAD MORE");
            loadMore();
        }} loadMoreIcon={loadMoreIcon}>
            {Object.entries(addresses).map(([key, address]) => {
                return (<ListItemButton id={key} key={key} divider onClick={() => navigate(`/provider/${providerId}/addresses/${key}`)}>
                    <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                        <ListItemText primary={<Typography><strong>{address?.["address-line1"]} | {address?.postal_code.toUpperCase()} | {capitaliseWords(camelCaseToNormal(address?.country))}</strong></Typography>}
                            secondary={
                                <Stack pt={2} spacing={1}>
                                    <Typography>Placement listings: {address.listings}</Typography>
                                </Stack>}/>
                        <Stack spacing={1}>
                            <Stack direction={"row"} sx={{justifyContent: "flex-end"}}>
                                <IconButtonPop responsive={false} onClick={() => navigate(`/provider/${providerId}/addresses/${key}`)} title="View address details"><ArrowForward/></IconButtonPop>
                            </Stack>
                        </Stack>
                    </Stack>
                </ListItemButton>);
            })}
        </FilterList>
    );
}

function ProviderPlacementList({providerId, addressId}: {providerId?: string, addressId?: string}) {
    const constraints = [where("oId", "==", providerId), where("product", "==", "providers"), where("status", "==", "listed")];
    if (addressId) {
        constraints.push(where("addressId", "==", addressId));
    }

    const [placementListings, setPlacementListings] = useState<{[key: string]: PlacementListing}>({});
    const [addresses, setAddresses] = useState<{[key: string]: OrganisationAddress}>();

    const navigate = useNavigate();
    const firebaseQuery = new FirebaseQuery();

    const {items, loadMore, loadMoreIcon} = useLazyLoadQueryList({
        path: "placementListings",
        constraints: constraints,
        number: 5,
    });

    useEffect(() => {
        const newListings:{[key: string]: PlacementListing} = ({...(items as {[key: string]: PlacementListing}), ...(placementListings || {})});
        setPlacementListings(newListings);

        const getAddresses = async () => {
            const listingsArray = Object.values(newListings);

            const addressesFromListings = await listingsArray.reduce(async (acc, listing) => {
                if (!listing.addressId) throw new Error("Placement with no address");
                if (Object.keys(await acc).includes(listing.addressId)) return acc;

                if ((await acc)[listing.addressId]) return acc;

                (await acc)[listing.addressId as string] = await firebaseQuery.getDocData(["addresses", listing.addressId as string]) as OrganisationAddress;

                return acc;
            }, Promise.resolve<{[key:string]: OrganisationAddress}>(addresses || {}));

            console.log("Addresses in useeffect", addressesFromListings);
            return addressesFromListings;
        };

        getAddresses().then((i) => {
            console.log("PROCESSED ADD", i);
            if (Object.keys(i).length === 0) return;
            setAddresses(i);
        });
    }, [items]);

    console.log("ADDRESSES", addresses);

    return (
        <FilterList title={"Our Listings"} data={items} onScrollBottom={loadMore} loadMoreIcon={loadMoreIcon}>
            {Object.entries(placementListings).map(([key, listing]) => {
                return (<ListItemButton id={key} key={key} divider onClick={() => navigate(`/provider/${providerId}/placementListings/${key}`)}>
                    <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                        <ListItemText primary={<Typography><strong style={{fontSize: "1.1em"}}>{listing.title}</strong><span style={{opacity: 0.75}}> - {addresses?.[listing.addressId || ""]?.["address-line1"]} | {addresses?.[listing.addressId || ""]?.postal_code?.toUpperCase()} | {capitaliseWords(camelCaseToNormal(addresses?.[listing.addressId || ""]?.country))}</span></Typography>}
                            secondary={listing.questions.description}/>
                        <Stack spacing={1}>
                            <Stack direction={"row"} sx={{justifyContent: "flex-end"}}>
                                <IconButtonPop responsive={false} onClick={() => navigate(`/provider/${providerId}/placementListings/${key}`)} title="View listing"><ArrowForward/></IconButtonPop>
                            </Stack>
                        </Stack>
                    </Stack>
                </ListItemButton>);
            })}
        </FilterList>
    );
}
