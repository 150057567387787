import {isAndroid, isIOS, MobileView} from "react-device-detect";
import Card from "./Card";
import {Button, Stack, Typography} from "@mui/material";
import {PRIMARY_COLOUR} from "placementt-core";
import Cookies from "universal-cookie";
import {useState} from "react";


export default function MobileLinkBanner({link}:{link: string}) {
    const cookies = new Cookies();

    const [appPopupDismissed, setAppPopupDismissed] = useState<boolean>(cookies.get("appPopupDismissed") === 1);

    if (appPopupDismissed) return null;
    return (<MobileView>
        <Card sx={{position: "fixed", zIndex: 10000, width: "96vw", marginLeft: "2vw", bottom: "2vw"}}>
            <Stack>
                <Typography color={PRIMARY_COLOUR}>Check out our mobile app!</Typography>
                <Stack direction={"row"} width={"100%"}>
                    <Button sx={{flex: 1}} variant="contained" onClick={() => {
                        if (isIOS) {
                            window.location.href = "placementt://"+link;
                            setTimeout(() => {
                                window.location.href = "https://apps.apple.com/us/app/placementt/id6464076066";
                            }, 1000);
                        }
                        if (isAndroid) {
                            return;
                        }
                    }}>Open app</Button>
                    <Button sx={{flex: 1}} variant="text" onClick={() => {
                        cookies.set("appPopupDismissed", 1);
                        setAppPopupDismissed(true);
                    }}>Continue on web</Button>
                </Stack>
            </Stack>
        </Card>
    </MobileView>);
}
