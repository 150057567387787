import {Grid} from "@mui/material";
import {useContext} from "react";
import {UserContext} from "../../App";
import Analytics from "../Shared/Analytics";
import NextOrActivePlacementCard from "../../Components/NextOrActivePlacementCard";
import Page from "../../Components/Page";
import UpdatesPopup from "./UpdatesPopup";
import NotesNew from "../Shared/NotesNew";
import NotificationBanner from "../../Components/NotificationBanner";
import StudentPlacementList from "../../Components/StudentPlacementList";
import Card from "../../Components/Card";

export default function Home() {
    const user = useContext(UserContext);

    return (
        <Page title={`Welcome back, ${user.details.forename}!`} metaTitle="Placementt | Home" grid>
            <Grid item container xs={12} lg={8}>
                <NotificationBanner grid/>
                <NextOrActivePlacementCard/>
                {user.product === "students" && <Card grid xs={12} md={6} sx={{
                    color: "white !important",
                    height: "100%",
                    background: "linear-gradient(114deg, rgba(201,0,214,1) 0%, rgba(148,24,255,1) 39%, rgba(35,58,224,1) 77%, rgba(0,138,255,1) 100%)",
                    filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#c900d6',endColorstr='#008aff',GradientType=1)"}}
                title={"Download our mobile app!"}>
                    For a better experience, download our mobile app, available for iOS and Android.
                </Card>}
                <Grid item xs={12}>
                    <Analytics type="card"/>
                </Grid>
                <Grid item xs={12}>
                    <StudentPlacementList
                        sx={{minHeight: "450px", maxHeight: "450px"}}
                        title={"Placements"}
                    />
                </Grid>
            </Grid>
            <Grid item md={0} lg={4}>
                <NotesNew/>
            </Grid>
            <UpdatesPopup/>
        </Page>
    );
}
