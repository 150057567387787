import {IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from "@mui/material";
import Card from "./Card";
import {ArrowForward, BusinessCenter, Campaign, CheckBox, CheckCircleOutline, DocumentScanner, People, School, Upload} from "@mui/icons-material";
import IconButtonPop from "./IconButtonPop";
import React, {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../App";
import {useNavigate} from "react-router-dom";
import {ProviderData} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {where} from "firebase/firestore";
import {useInsuranceUploadPopupBanner, usePublishProviderPopup} from "../Pages/Providers/Home";


export default function OnboardingChecklist() {
    const [staff, setStaff] = useState<number>();
    const [listings, setListings] = useState<number>();

    const user = useContext(UserContext);
    const provider = useContext(OrganisationContext).details as ProviderData;
    const navigate = useNavigate();
    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        firebaseQuery.getCount("users", [where("product", "==", user.product), where("oId", "==", user.oId)]).then((number) => setStaff(number - 1));
        firebaseQuery.getCount("placementListings", [where("providerId", "==", user.oId)]).then(setListings);
    }, []);


    const {publishOrganisationPopup, setActivateProfilePopup} = usePublishProviderPopup({provider: provider});

    const {eliUploadPopup, setProviderInsurancePopup} = useInsuranceUploadPopupBanner();


    return (
        <>
            <Checklist title={"First steps"} grid xs={12} md={7} items={[
                {
                    title: "Upload your employer's liability insurance",
                    icon: <DocumentScanner/>,
                    onClick: () => setProviderInsurancePopup(true),
                    completed: Boolean(provider.insurance),
                    iconButton: <Upload color="primary"/>,
                },
                {
                    title: "Add your staff members",
                    icon: <People/>,
                    onClick: () => navigate("/providers/organisation/staff"),
                    completed: Boolean(staff),
                },
                {
                    title: "Create your first placement",
                    icon: <School/>,
                    onClick: () => navigate("/providers/addListing"),
                    completed: Boolean(listings),
                },
                {
                    title: "Customise your profile",
                    icon: <BusinessCenter/>,
                    onClick: () => navigate(`/provider/${user.oId}#customise`),
                    completed: Boolean(provider.information?.description),
                },
                {
                    title: "Publish your profile!",
                    icon: <Campaign/>,
                    onClick: () => setActivateProfilePopup(true),
                    completed: Boolean(provider.mapConsent),
                },
            ]}/>
            {eliUploadPopup}
            {publishOrganisationPopup}
        </>);
}

type ChecklistParams = {
    title: string,
    grid?: boolean,
    xs?: number,
    sm?: number,
    md?: number,
    lg?: number,
    items: {
        title: string,
        completed?: boolean,
        icon?: React.ReactNode,
        iconButton?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
        onClick?: () => void
    }[],
}

function Checklist({title, items, grid, xs, sm, md, lg}: ChecklistParams) {
    return (
        <Card {...{title, grid, xs, sm, md, lg}}>
            <List>
                {items.map((item) =>
                    <ListItem sx={item.completed ? {textDecoration: "line-through", opacity: 0.6} : {}}>
                        <ListItemIcon>{item.icon || <CheckBox/>}</ListItemIcon>
                        <ListItemText primary={item.title}/>
                        <ListItemSecondaryAction>
                            {item.completed ? <IconButton><CheckCircleOutline color="success"/></IconButton> : item.onClick &&
                                <IconButtonPop title="Complete" responsive={false} onClick={() => item.onClick && item.onClick()}>{item.iconButton || <ArrowForward color="primary"/>}</IconButtonPop>
                            }
                        </ListItemSecondaryAction>
                    </ListItem>)}
            </List>
        </Card>
    );
}
