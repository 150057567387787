import {Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {MouseEventHandler, MutableRefObject, ReactNode} from "react";
import IconButtonPop from "./IconButtonPop";
import {Close} from "@mui/icons-material";
import {PRIMARY_COLOUR} from "placementt-core";


type Params = {
    open: boolean,
    onClose: (event?: object, reason?: string) => void,
    title?: string|ReactNode,
    children: ReactNode,
    actions?: ReactNode,
    maxWidth?: false | Breakpoint,
    fullWidth?: boolean,
    fullScreen?: boolean,
    sx?: {[key:string]: unknown},
    cardSx?: {[key:string]:unknown},
    onMouseMove?: MouseEventHandler<HTMLDivElement>,
    id?: string,
    noCloseButton?: boolean,
    cardRef?: MutableRefObject<unknown>,
    subtitle?: string
}

export function Popup({open, onClose, title, subtitle, children, actions, maxWidth, fullWidth, fullScreen, sx, cardSx, onMouseMove, id, noCloseButton, cardRef}:Params) {
    return (
        <Dialog {...{open, onClose, maxWidth, fullWidth, onMouseMove, id, sx}}>
            <DialogTitle sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>{typeof title === "string" ? <Typography variant={"h5"} textOverflow={"ellipsis"} overflow={"hidden"} maxWidth={"calc(100% - 40px)"}>{title}</Typography> : title}{noCloseButton || <IconButtonPop responsive={false} title={"Close"} onClick={onClose} sx={{float: "right"}}><Close/></IconButtonPop>}</DialogTitle>
            {subtitle && <Typography variant="subtitle2" ml={2} mr={2} mt={0} sx={{opacity: 0.7}}>{subtitle}</Typography>}
            <DialogContent sx={{overflowX: "hidden", ...cardSx, ...(fullScreen ? {width: fullScreen ? "90vw" : undefined} : {})}} ref={cardRef}>
                {children}
            </DialogContent>
            {actions && <DialogActions sx={{boxShadow: `0 0 15px -5px ${PRIMARY_COLOUR}`, borderTop: `2.5px solid ${PRIMARY_COLOUR}`}}>{actions}</DialogActions>}
        </Dialog>
    );
}
