import {useContext} from "react";
import {Card, CardContent, CardHeader, Grid, Table, TableBody, TableRow} from "@mui/material";
import {UserContext} from "../../App";
import {InfoTableCell} from "../../Util/styledComponents";
import {LoadingButton} from "../../Components/LoadingButton";
import {UserData, UserGroupData, executeCallable} from "placementt-core";
import Page from "../../Components/Page";


export default function Profile() {
    const user = useContext(UserContext) as UserData;

    const userGroup = (user.groupData as UserGroupData)?.name || "Admin";

    const sendResetEmail = async () => {
        return await executeCallable("userManagement-sendPasswordResetEmail", {email: user.email}).catch((e) => {
            throw e;
        }).then(() => {
            return {severity: "success", message: "Password reset email sent"};
        });
    };

    return (
        <Page title="Profile">
            <Grid container mt={0} direction={"column"} sx={{width: "600px", maxWidth: "100% !important"}}>
                <Grid item xs={12} md={4} sx={{maxWidth: "100% !important"}}>
                    <Card>
                        <CardHeader title={"Basic details"}/>
                        <CardContent>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <InfoTableCell>Name</InfoTableCell>
                                        <InfoTableCell>{user.details.forename} {user.details.surname}</InfoTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <InfoTableCell>Email</InfoTableCell>
                                        <InfoTableCell>{user.email}</InfoTableCell>
                                    </TableRow>
                                    {user.product !== "students" && <TableRow>
                                        <InfoTableCell>User type</InfoTableCell>
                                        <InfoTableCell>{user.userType}</InfoTableCell>
                                    </TableRow>}
                                    {user.product !== "students" && <TableRow>
                                        <InfoTableCell>User group</InfoTableCell>
                                        <InfoTableCell>{userGroup}</InfoTableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                            <LoadingButton onClick={sendResetEmail} text={"Change password"}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Page>
    );
}
