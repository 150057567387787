import {useEffect, useState} from "react";
import Page from "../../../Components/Page";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {where} from "firebase/firestore";
import {Grid, Typography} from "@mui/material";
import Card from "../../../Components/Card";
import FilterTable from "../../../Components/FilterTable";
import {PRIMARY_COLOUR, SECONDARY_COLOUR, UserData} from "placementt-core";
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from "chart.js";
import {Scatter} from "react-chartjs-2";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

export default function StudentUsers() {
    const [users, setUsers] = useState(0);
    const [userData, setUserData] = useState<{[key:string]: {placements: number, contacts: number, forename: string, surname: string, email: string}}>();
    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        firebaseQuery.getCount("users", where("product", "==", "students")).then(setUsers);

        firebaseQuery.getDocsWhere("users", where("product", "==", "students")).then(async (docs) => {
            setUserData(Object.fromEntries(await Promise.all(Object.entries(docs as {[key:string]: UserData}).map(async ([key, userData]) => {
                const placements = await firebaseQuery.getCount("placements", where("uid", "==", key));
                const contacts = await firebaseQuery.getCount("contacts", where("uid", "==", key));

                return [key, {
                    forename: userData.details.forename,
                    surname: userData.details.surname,
                    email: userData.email,
                    placements: placements, contacts: contacts}];
            }))));
        });
    }, []);

    const filters = {
        forename: {
            label: "Forename",
            type: "string",
        },
        surname: {
            label: "Surname",
            type: "string",
        },
        email: {
            label: "Email",
            type: "string",
        },
    };


    const placementData = {
        datasets: [
            {
                label: "Placements",
                data: Object.values(userData || {}).map((item) => [0, item.placements]),
                backgroundColor: PRIMARY_COLOUR,
            },
            {
                label: "Contacts",
                data: Object.values(userData || {}).map((item) => [10, item.contacts]),
                backgroundColor: SECONDARY_COLOUR,
            },
        ],
    };

    return (
        <Page title="Student Users" grid>
            <Card grid xs={12} md={4}>
                <Typography>Total users: {users}</Typography>
            </Card>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6}>
                <Scatter data={placementData}/>
            </Grid>
            <Grid item xs={12}>
                {Object.keys(userData || {}).length && <FilterTable
                    viewRows
                    title={"All students"}
                    filters={filters}
                    columns={["forename", "surname", "email", "placements", "contacts"]}
                    data={userData}/>}
            </Grid>
        </Page>
    );
}
