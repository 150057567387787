
import {Stack, Typography} from "@mui/material";
import {PRIMARY_COLOUR, SECONDARY_COLOUR} from "placementt-core";
import {ReactNode} from "react";


const strokeWidth = 12;

export default function CircularProgressbar({size=100, primary, secondary, text, label}:{size?: number, primary?:number, secondary?: number, text?:string, label?:ReactNode}) {
    // Ratio of path length to trail length, as a value between 0 and 1
    const getPathRatio = (value:number) => {
        return Math.min(Math.max(value, 0), 1);
    };

    return (
        <Stack spacing={0} alignItems={"center"}>
            <svg
                height={`${size}px`}
                viewBox={`0 0 ${100} ${100}`}
                data-test-id="CircularProgressbar"
                overflow={"visible"}
            >

                <Path
                    dashRatio={0.75}
                    pathRadius={50 - strokeWidth / 2}
                    strokeWidth={strokeWidth}
                    style={{stroke: "#eee"}}
                />

                {secondary && <Path
                    dashRatio={getPathRatio(secondary) * 0.75}
                    pathRadius={50 - strokeWidth / 2}
                    strokeWidth={strokeWidth}
                    style={{stroke: SECONDARY_COLOUR}}
                />}

                {primary && <Path
                    dashRatio={getPathRatio(primary) * 0.75}
                    pathRadius={50 - strokeWidth / 2}
                    strokeWidth={strokeWidth}
                    style={{stroke: PRIMARY_COLOUR}}
                />}

                {text ? (
                    <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fontSize={"1.2em"}>
                        {text}
                    </text>
                ) : null}
            </svg>
            {label ? typeof label === "string" ? (
                <Typography textAlign={"center"}>
                    {label}
                </Typography>
            ) : <Stack direction={"row"} spacing={0} alignItems={"center"} justifyContent={"center"}>{label}</Stack> : null}
        </Stack>
    );
}


function Path({
    dashRatio,
    pathRadius,
    strokeWidth,
    style,
}: {
  dashRatio: number;
  pathRadius: number;
  strokeWidth: number;
  style?: object;
}) {
    return (
        <path
            style={Object.assign({}, style, {
                transformOrigin: "center center",
                transform: `rotate(${1 / 2 + 1 / 8}turn)`,
                strokeDasharray: `${Math.PI * 2 * pathRadius}px ${Math.PI * 2 * pathRadius}px`,
                strokeDashoffset: `${(1 - dashRatio) * Math.PI * 2 * pathRadius}px`,
                transition: "stroke-dashoffset 0.5s ease 0s"})}
            d={`M 50,50
            m 0,-${pathRadius}
            a ${pathRadius},${pathRadius} 0 1 1 0,${2 * pathRadius}
            a ${pathRadius},${pathRadius} 0 1 1 0,-${2 * pathRadius}`}
            strokeWidth={strokeWidth}
            fillOpacity={0}
            strokeLinecap={"round"}
        />
    );
}
