import {useContext} from "react";
import Page from "../../Components/Page";
import PlacementCalendar from "../../Components/PlacementCalendar";
import {StudentContext} from "./Students";
import Card from "../../Components/Card";


export default function Calendar() {
    const placements = useContext(StudentContext).placements;
    return (
        <Page title="Calendar" metaTitle="Placementt | Calendar">
            <Card>
                <PlacementCalendar placements={placements}/>
            </Card>
        </Page>
    );
}
