import {Box, Button, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography} from "@mui/material";
import Page from "../../Components/Page";
import instituteHome from "../../Images/platformImages/instituteHome.webp";
import instituteAnalytics from "../../Images/platformImages/instituteAnalytics.png";
import instituteAnalyticsSectors from "../../Images/platformImages/instituteAnalyticsSectors.png";
import instituteStudentApp from "../../Images/platformImages/instituteStudentApp.png";
import instituteMap from "../../Images/platformImages/instituteMap.png";

import InstitutePriceCalculator from "../../Components/PriceCalculator";
import PublicTemplate from "../../Components/Public/PublicTemplate";
import {AirplanemodeActiveRounded, Analytics, Article, Assessment, Business, CalendarMonth, Campaign, Download, KeyOutlined, Language, LockOutlined, Map, NotificationImportant, Policy, Radar, School, StorageRounded, ThumbUp, TimerOutlined, TipsAndUpdates} from "@mui/icons-material";
import {PRIMARY_COLOUR} from "placementt-core";
import {Testimonials} from "./InstituteInfo";
import {RedDarkBlueGradientText} from "../../Util/visualUtil";
import {useCheckMobileScreen} from "../../Util/util";

export default function SchoolInfo() {
    const mobile = useCheckMobileScreen();
    return (
        <Page
            metaTitle="Placementt | Secondary Schools"
            metaDesc=""
        >
            <PublicTemplate>
                <Stack width={"100%"} mb={"-64px !important"}>
                    <Typography color={"primary"} fontWeight={"bold"}>Placementt for Secondary Schools</Typography>
                    <Divider/>
                </Stack>
                <Stack spacing={5} borderBottom={"1px solid lightgrey"} pt={mobile ? 8 : 20} pb={10} textAlign={"center"} maxWidth={"1000px"} alignSelf={"center"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant="h2" fontWeight={500} maxWidth={"800px"}><RedDarkBlueGradientText>Seamlessly Manage</RedDarkBlueGradientText> Your Work Experience</Typography>
                    <Typography variant="h5">We empower students by supporting educators, employers, parents and students through the work experience process.</Typography>
                    <Button href="mailto:tom@placementt.co.uk"><Typography variant="h5"><RedDarkBlueGradientText>Get in touch</RedDarkBlueGradientText></Typography></Button>
                </Stack>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Divider/>
                            <Typography variant="h4">Simple, By Design</Typography>
                            <Typography>We know you have no time. Our aim is to take up as little of it as possible.</Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon><School/></ListItemIcon>
                                    <ListItemText primary={"Upload your students, through a one-off upload."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Analytics/></ListItemIcon>
                                    <ListItemText primary={"Customise placement processing, including requiring risk assessments, parental sign off and DBS checks."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Business/></ListItemIcon>
                                    <ListItemText primary={"Your students will add placements and we will collect all the required documents and information for you."}/>
                                </ListItem>
                            </List>
                            <Typography>We will let you know when we need you, whether to check placements or sign off on insurance. Nothing more.</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"}>
                        <Box component={"img"} borderRadius={1} boxShadow={3} width={"100%"} alt={"Placementt for Educators home page"} src={instituteHome}/>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"}>
                        <Box component={"img"} display={"flex"} borderRadius={1} boxShadow={3} width={"100%"} alt={"Placementt for Educators placement database"} src={instituteMap}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Typography color={"primary"}>Your Own Placement Database</Typography>
                            <Divider/>
                            <Typography variant="h4">Build a Lasting Database of Employers</Typography>
                            <Typography>We help you build a comprehensive database of placements, with in-depth information on student experiences for your students to use year-on-year.</Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon><Language/></ListItemIcon>
                                    <ListItemText primary={"We help you build a private database of employers, for your students to utilise year-on-year."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><TipsAndUpdates/></ListItemIcon>
                                    <ListItemText primary={"Students view in-depth placement information, such as experiences given, responsibilities, travel and amenities."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Assessment/></ListItemIcon>
                                    <ListItemText primary={"Analytics on employer engagement, feedback, and a breakdown of sectors students enter most."}/>
                                </ListItem>
                            </List>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Divider/>
                            <Typography variant="h4">Comprehensive Analytics</Typography>
                            <Typography>Elevate your careers provision with advanced monitoring of placements, and insight into uptake of different sectors.</Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon><Analytics/></ListItemIcon>
                                    <ListItemText primary={"In-depth analytics on placements processing, to easily identify issues."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><ThumbUp/></ListItemIcon>
                                    <ListItemText primary={"Analyse placement logs and feedback, gaining deeper insight into student engagement."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Download/></ListItemIcon>
                                    <ListItemText primary={"Easily export custom data reports, for you to analyse off-platform."}/>
                                </ListItem>
                            </List>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} position={"relative"}>
                        <Box component={"img"} borderRadius={1} boxShadow={3} width={"100%"} alt={"Placementt for Educators analytics"} src={instituteAnalytics}/>
                        <Box position={"absolute"} p={1} bgcolor={"#fff"} right={-40} bottom={-40} component={"img"} borderRadius={1} boxShadow={5} height={"50%"} alt={"Placementt for Educators analytics sectors pie chart"} src={instituteAnalyticsSectors}/>
                    </Grid>
                </Grid>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"}>
                        <Box component={"img"} display={"flex"} m={"auto"} borderRadius={2} boxShadow={3} height={"500px"} alt={"Placementt for Educators student app"} src={instituteStudentApp}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Divider/>
                            <Typography variant="h4">Engage Your Students</Typography>
                            <Typography>We'll pester your students so you don't have to, with Placementt right in their pocket.</Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon><NotificationImportant/></ListItemIcon>
                                    <ListItemText primary={"Notifications direct to their phone."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Article/></ListItemIcon>
                                    <ListItemText primary={"Customisable daily logs for employers, students and staff."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Campaign/></ListItemIcon>
                                    <ListItemText primary={"Feedback forms for businesses and students, helping you tailor your careers programme."}/>
                                </ListItem>
                            </List>
                        </Stack>
                    </Grid>
                </Grid>
                <InstitutePriceCalculator type="school"/>
            </PublicTemplate>
            <Testimonials/>
            <PublicTemplate>
                <GDPRPrivacyInfo/>
            </PublicTemplate>
        </Page>
    );
}

export function GDPRPrivacyInfo() {
    return (
        <Stack>
            <Typography variant="h4">We Take Your Privacy Seriously</Typography>
            <Typography variant="h6">Data security is top priority. Throughout every area of our business, we work to ensure your data is safe and secure, and we only ever keep data absolutely necessary to providing you top-class service.</Typography>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"} color={PRIMARY_COLOUR}>Encryption at All Stages</Typography>
                    <Divider/>
                    <List>
                        <ListItem>
                            <ListItemIcon><AirplanemodeActiveRounded sx={{fontSize: 40, color: PRIMARY_COLOUR}}/></ListItemIcon>
                            <ListItemText
                                primary={"We secure your data in transit with TLS 1.2+ to protect it during transfer between users, services, and data centers."}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><StorageRounded sx={{fontSize: 40, color: PRIMARY_COLOUR}}/></ListItemIcon>
                            <ListItemText
                                primary={"We encrypt stored data using Google Cloud with AES-256, using multiple encryption layers to protect your data."}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"} color={PRIMARY_COLOUR}>Data Maintained Within the UK and Europe</Typography>
                    <Divider/>
                    <List>
                        <ListItem>
                            <ListItemIcon><Map sx={{fontSize: 40, color: PRIMARY_COLOUR}}/></ListItemIcon>
                            <ListItemText
                                primary={"We use Google Cloud to store your data in ISO/IEC 27001:2022 certified data centres. That data is stored in Google data centres in London, Belgium and The Netherlands, ensuring we have redundancy."}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"} color={PRIMARY_COLOUR}>Your Data is <i>Your</i> Data</Typography>
                    <Divider/>
                    <List>
                        <ListItem>
                            <ListItemIcon><KeyOutlined sx={{fontSize: 40, color: PRIMARY_COLOUR}}/></ListItemIcon>
                            <ListItemText
                                primary={"We keep you in full control of your data. We do not share your data with any third party, and we only ask for data that is strictly necessary, including users names and email addresses. Once you delete data, we remove it from our system."}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"} color={PRIMARY_COLOUR}>Backing Up Your Data</Typography>
                    <Divider/>
                    <List>
                        <ListItem>
                            <ListItemIcon><TimerOutlined sx={{fontSize: 40, color: PRIMARY_COLOUR}}/></ListItemIcon>
                            <ListItemText
                                primary={"We use point-in-time recovery, allowing us to recover data to the minute, up to seven days in the past."}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><CalendarMonth sx={{fontSize: 40, color: PRIMARY_COLOUR}}/></ListItemIcon>
                            <ListItemText
                                primary={"Further than 7 days into the past, we take daily backups of our data."}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"} color={PRIMARY_COLOUR}>Database Security</Typography>
                    <Divider/>
                    <List>
                        <ListItem>
                            <ListItemIcon><LockOutlined sx={{fontSize: 40, color: PRIMARY_COLOUR}}/></ListItemIcon>
                            <ListItemText
                                primary={"Our databases are secured with role-based access controls, encryption, and advanced firewalls. They undergo regular penetration testing and vulnerability assessments to mitigate risks."}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"} color={PRIMARY_COLOUR}>Monitoring and Threat Detection</Typography>
                    <Divider/>
                    <List>
                        <ListItem>
                            <ListItemIcon><Radar sx={{fontSize: 40, color: PRIMARY_COLOUR}}/></ListItemIcon>
                            <ListItemText
                                primary={"We continuously monitor our systems with automated tools to detect suspicious activities, and our team responds to potential threats in real time to mitigate risks before they impact your data."}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"} color={PRIMARY_COLOUR}>Access Control</Typography>
                    <Divider/>
                    <List>
                        <ListItem>
                            <ListItemIcon><Radar sx={{fontSize: 40, color: PRIMARY_COLOUR}}/></ListItemIcon>
                            <ListItemText
                                primary={"Only authorized team members with a legitimate need can access your data, and all access is secured by multi-factor authentication and logged for auditing."}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography fontWeight={"bold"} color={PRIMARY_COLOUR}>Compliance with GDPR</Typography>
                    <Divider/>
                    <List>
                        <ListItem>
                            <ListItemIcon><Policy sx={{fontSize: 40, color: PRIMARY_COLOUR}}/></ListItemIcon>
                            <ListItemText
                                primary={"We fully comply with GDPR by ensuring your data is processed lawfully, transparently, and for specific purposes. As part of this, we are registered with the Information Commissioner’s Office (ICO) in the UK, with reference ZB621892."}
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Stack>
    );
}
