import {ArrowBack, ArrowForward} from "@mui/icons-material";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import {PRIMARY_COLOUR, StudentPlacementData, UserData, getDateDiff} from "placementt-core";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {OrganisationContext, UserContext} from "../App";

export default function PlacementCalendar({date, placements}:{date?: Date, placements?: {[key: string]: StudentPlacementData&{student?: UserData}}}) {
    const [selectedMonth, setSelectedMonth] = useState<Date>(date || new Date());
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [hoveredDate, setHoveredDate] = useState<Date>();

    const navigate = useNavigate();
    const user = useContext(UserContext);
    const addresses = Object.keys(useContext(OrganisationContext)?.addresses || [])?.length;

    useEffect(() => {
        setSelectedMonth(date || new Date());
    }, [date]);

    // const testDates = {
    //     test: {
    //         title: "Placement one",
    //         startDate: "2024-07-06",
    //         endDate: "2024-07-15",
    //     },
    //     test2: {
    //         title: "Start date",
    //         startDate: "2024-07-22",
    //         endDate: "2024-08-08",
    //     },
    //     test3: {
    //         title: "Start date",
    //         startDate: "2024-06-22",
    //         endDate: "2024-07-02",
    //     },
    //     test4: {
    //         title: "September",
    //         startDate: "2024-08-22",
    //         endDate: "2024-10-05",
    //     },
    // };

    console.log("PLACEMENTSSSS", placements);

    const changeSelectedMonth = (direction: "forward"|"backward") => {
        setSelectedMonth((d) => {
            const newDate = new Date(d);
            newDate.setMonth(newDate.getMonth() + (direction === "forward" ? 1 : -1));
            return newDate;
        });
    };

    const month = selectedMonth.toLocaleString("default", {month: "long"});
    const year = selectedMonth.toLocaleString("default", {year: "numeric"});

    const daysInMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).getDate();
    const startDayOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1).getDay();
    const endDayOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).getDay();

    const renderedDates = [];

    for (let i = 0; i < startDayOfMonth; i++) {
        renderedDates.push(<Filler/>);
    }


    // Add the actual days of the month
    for (let i = 1; i <= daysInMonth; i++) {
        const currentDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), i);
        const isToday = getDateDiff(currentDate, new Date()) === 0;

        renderedDates.push(<DateItem
            isToday={isToday}
            date={i}
            disabled
            selected={selectedDate && getDateDiff(currentDate, selectedDate) === 0}
            hovered={hoveredDate && getDateDiff(currentDate, hoveredDate) === 0 ? "only" : undefined}
            onSelected={() => setSelectedDate(currentDate)}
            onHover={() => setHoveredDate(currentDate)}/>);
    }

    // Fill the rest of the array to make it a complete week
    while (renderedDates.length % 7 !== 0) {
        renderedDates.push(<Filler/>);
    }

    const renderedDatesInWeeks = [];
    for (let i = 0; i < renderedDates.length; i += 7) {
        renderedDatesInWeeks.push(renderedDates.slice(i, i + 7));
    }

    // List of rendered placements with 5 rows
    // Each row has a list of elements with a start date on each.

    // Create an array of weeks with placement and when it starts.

    const getWeekIndex = (date: number, startDayOfMonth: number): number => {
        return Math.floor((startDayOfMonth + date - 1) / 7);
    };

    const parsePlacementDates = (placement: StudentPlacementData&{student?: UserData}, startDayOfMonth: number, daysInMonth: number) => {
        const startDate = new Date(placement.startDate);
        const endDate = new Date(placement.endDate);

        const startDay = startDate.getDate();
        const endDay = endDate.getDate();

        const currentMonthStart = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);
        const currentMonthEnd = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), daysInMonth);

        const startsInCurrentMonth = startDate >= currentMonthStart;
        const endsInCurrentMonth = endDate <= currentMonthEnd;

        const displayStartDay = startsInCurrentMonth ? startDay : 1;
        const displayEndDay = endsInCurrentMonth ? endDay : daysInMonth;

        const startWeekIndex = getWeekIndex(displayStartDay, startDayOfMonth);
        const endWeekIndex = getWeekIndex(displayEndDay, startDayOfMonth);

        return {
            ...placement,
            startDay: displayStartDay,
            endDay: displayEndDay,
            startWeekIndex: startWeekIndex,
            endWeekIndex: endWeekIndex,
            startsInCurrentMonth: startsInCurrentMonth,
            endsInCurrentMonth: endsInCurrentMonth,
        };
    };

    const splitPlacementsByWeeks = (startDayOfMonth: number, daysInMonth: number, placements: (StudentPlacementData&{student?: UserData})[]) => {
        const weeks: (StudentPlacementData&{ student?: UserData, title: string, start?: number, end?: number, id?: string, startsInCurrentMonth?: boolean, endsInCurrentMonth?: boolean })[][] = Array.from({length: Math.ceil((startDayOfMonth + daysInMonth) / 7)}, () => []);
        const parsedPlacements = placements.map((placement) => parsePlacementDates(placement, startDayOfMonth, daysInMonth));

        parsedPlacements.forEach((placement) => {
            const {startWeekIndex, endWeekIndex, startDay, endDay, startsInCurrentMonth, endsInCurrentMonth} = placement;

            for (let weekIndex = startWeekIndex; weekIndex <= endWeekIndex; weekIndex++) {
                const start = weekIndex === startWeekIndex ? startsInCurrentMonth ? startDay - (startWeekIndex * 7) + startDayOfMonth - 1 : undefined : undefined;
                const end = weekIndex === endWeekIndex ? endsInCurrentMonth ? endDay - (endWeekIndex * 7) - 2 + endDayOfMonth : undefined : undefined;
                weeks[weekIndex].push({...placement, start, end});
            }
        });

        return weeks;
    };

    const isPlacementInCurrentMonth = (placement: {startDate: string, endDate: string}, selectedMonth: Date) => {
        const startDate = new Date(placement.startDate);
        const endDate = new Date(placement.endDate);

        const startOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);
        const endOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0);

        return (startDate <= endOfMonth && endDate >= startOfMonth);
    };

    const filteredPlacements = Object.values(placements || {}).filter((placement) => isPlacementInCurrentMonth(placement, selectedMonth));

    const renderedPlacements = splitPlacementsByWeeks(startDayOfMonth, daysInMonth, filteredPlacements);

    console.log("RENDEPLACEMNENTS", renderedPlacements);
    return (
        <Stack spacing={0} onMouseLeave={() => setHoveredDate(undefined)}>
            <Stack direction={"row"} justifyContent={"center"} position={"relative"} width={"100%"} spacing={0} mb={2}>
                <IconButton sx={{position: "absolute", left: 10, top: "50%", transform: "translateY(-50%)"}} onClick={() => changeSelectedMonth("backward")}><ArrowBack/></IconButton>
                <Typography>{month} {year}</Typography>
                <IconButton sx={{position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)"}} onClick={() => changeSelectedMonth("forward")}><ArrowForward/></IconButton>
            </Stack>
            <Stack direction={"row"} spacing={0} justifyContent={"space-around"} position={"relative"} width={"100%"} mb={2}>
                {["S", "M", "T", "W", "T", "F", "S"].map((day) => <Typography variant="subtitle1" textAlign={"center"} color={"#0000007c"}>{day}</Typography>)}
            </Stack>
            {renderedDatesInWeeks.map((dates, i) => (
                <Stack direction={"row"} spacing={0} justifyContent={"space-around"} position={"relative"} width={"100%"} key={i}>
                    <Stack spacing={1} position={"absolute"} top={"24px"} width={"100%"} overflow={"hidden"}>
                        {renderedPlacements[i].map((placement, idx) => {
                            const startRadius = placement?.start && placement.startsInCurrentMonth ? "20px" : "5px";
                            const endRadius = placement?.end && placement.endsInCurrentMonth ? "20px" : "5px";

                            const isLastRow = i === (renderedPlacements.length - 1);

                            return (
                                <Box
                                    pl={`${(100 / 7) * ((placement.start || (i === 0 ? startDayOfMonth : 0)))}%`}
                                    pr={`${(100 / 7) * (placement.end ? (7 - (placement.end || 0)) : (isLastRow ? 7-endDayOfMonth-1 : 0))}%`}
                                    position={"relative"} height={"25px"} key={idx}
                                    zIndex={1000}
                                    sx={{cursor: "pointer"}}
                                    onClick={() => navigate(`/${user.product}/placements/${placement.id}`)}
                                >
                                    <Box
                                        borderRadius={`${startRadius} ${endRadius} ${endRadius} ${startRadius}`}
                                        bgcolor={PRIMARY_COLOUR}
                                        flex={1}
                                        pl={1}
                                        pr={1}
                                    >
                                        {Boolean(idx > 1 && renderedPlacements[i].length > 2) || <Typography fontSize={12} noWrap textOverflow={"ellipsis"} lineHeight={"25px"} color={"white !important"}>
                                            {idx === 1 && renderedPlacements[i].length > 2 ? <strong>+{renderedPlacements[i].length - 1} placement{renderedPlacements[i].length > 2 ? "s" : ""}</strong> : <><strong>{placement.title}</strong>{placement.student && ` - ${placement.student.details.forename} ${placement.student.details.surname}`}{addresses > 1 && ` - ${placement["address-line1"]}, ${placement.postal_code}`}</>}
                                        </Typography>}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Stack>
                    {dates}
                </Stack>
            ))}
        </Stack>
    );
}

function Filler() {
    return (<Box
        flex={1}
        height={"60px"}
        lineHeight={"40px"}
        sx={{position: "relative"}}/>);
}

function DateItem({date, disabled, isToday, selected, hovered, onHover, onSelected}:{date: number, disabled?: boolean, hovered?: boolean|"start"|"end"|"only", selected?: boolean|"start"|"end"|"only", onHover: () => void, onSelected: () => void, isToday: boolean}) {
    return (<Box
        flex={1}
        height={"85px"}
        borderRadius={2}
        boxShadow={hovered ? "0px 2px 5px 0px#00000035" : undefined}
        sx={{position: "relative", cursor: disabled ? undefined : "pointer", transition: "all 150ms ease-in-out"}}
        color={selected ? "primary" : undefined}
        boxSizing={"border-box"}
        textAlign={"center"}
        onMouseEnter={() => disabled || onHover()}
        onClick={() => disabled || onSelected()}>
        <Typography p={1} sx={{display: "inline-flex", lineHeight: "16px", justifyContent: "center", width: "22px", background: isToday ? `${PRIMARY_COLOUR}bb` : undefined, borderRadius: "50%", padding: "3px"}} variant="caption" fontWeight={selected ? "bold" : undefined} color={selected ? PRIMARY_COLOUR : isToday ? "white !important" : undefined}>{date}</Typography>
        <Stack spacing={1}>
        </Stack>
    </Box>);
}
