import {Send} from "@mui/icons-material";
import {Box, Card, IconButton, LinearProgress, linearProgressClasses, TableCell, Typography} from "@mui/material";
import InputGroup from "../Components/FormComponents/InputGroup";
import IconButtonPop from "../Components/IconButtonPop";
import {useState} from "react";
import {PRIMARY_COLOUR} from "placementt-core";
import styled from "styled-components";

export const GradientText = styled(Typography)`
    background-color: var(--purple-one);
    background-image: linear-gradient(45deg, ${PRIMARY_COLOUR}, #d11aff, #d16eff);    
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
`;

export const InnerPage = styled(Box)`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    padding: 16px;
    padding-bottom: 16px;
`;

export const DecorativeText = styled("span")`
    text-decoration: underline;
    font-weight: bold;
    color: ${(props) => props.color};
`;

export const FlexBox = styled(Box)`
    position: relative;
    display: flex;
`;

export const InfoTableCell = styled(TableCell)`
    border-bottom: 0;
    font-size: 16px;

    :first-child {
        color: #777;
    }
`;

export const FormSection = styled(Box)`
    margin-bottom: 50px;
    position: relative;

    :not(:last-of-type):after {
        content: '';
        position: absolute;
        left: 10%;
        width: 80%;
        margin: 25px 0;
        border-bottom: solid rgba(0, 0, 0, 0.5) 1px;
    }
`;

export const DeleteCardButton = styled(IconButtonPop)<{visible: boolean}>`
    right:0;
    top:0;
    transform: translate(50%, -50%);
    background-color: white;
    position: absolute;
    color: grey;
    
    min-height: 22px !important;
    max-height: 22px !important;
    width: 22px !important;
    font-weight: bold;
    box-shadow: 1px 1px 5px 1px #838383;

    opacity: ${(props) => props.visible ? 1 : 0};
    pointer-events: ${(props) => props.visible ? "all" : "none"};;
    transition: all 250ms ease-in-out;

    &:hover {
        background-color: lightgrey;
    }
`;

type SlideCardProps = {
    $active: boolean|string
}

export const SlideCard = styled(Card)`
    top: 100px;
    position: absolute;
    transition: all 0.5s ease-in-out 0.1s;
    width: 500px;
    max-width: 96%;
    left: 50%;
    max-height: 85vh;
    text-align: center;
    transform: ${(props:SlideCardProps) => props.$active === true ? "translateX(-50%)" : props.$active === "pre" ? "translateX(300px)" : "translateX(-300px)"};
    opacity: ${(props:SlideCardProps) => props.$active === true ? 1 : 0};
    pointer-events: ${(props:SlideCardProps) => props.$active === true ? "all" : "none"};
`;

type MessageBubbleProps = {
    $sender: string
}

export const MessageBubble = styled(Box)`
    background-color: ${(props:MessageBubbleProps) => props.$sender === "You" ? "#d9f9ff" : "#ededed"};
    padding: 5px;
    border-radius: 4px;
`;


type MessageInputProps = {
    onSend: (message:string) => void;
  };
export function MessageInput({onSend}:MessageInputProps) {
    const [input, setInput] = useState("");
    return (
        <Box>
            <InputGroup placeholder={"Start typing..."} value={input} onChange={(e:React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value)} sx={{paddingRight: "40px"}}/>
            <IconButton sx={{position: "absolute", right: "15px"}} onClick={() => {
                onSend(input); setInput("");
            }}><Send/></IconButton>
        </Box>
    );
}


export const ProgressBar = styled(LinearProgress)(({theme}) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
    },
}));


type FeedbackBoxProps = {
    $open: boolean
}
export const FeedbackBox = styled(Card)`
    height: ${(props:FeedbackBoxProps) => props.$open ? "350px" : 0};
    width: ${(props:FeedbackBoxProps) => props.$open ? "400px" : 0};
    max-width: 90vw;
    background: white;
    opacity: ${(props:FeedbackBoxProps) => props.$open ? 1 : 0};
    margin-bottom: 10px;
    transition: all 250ms ease-in-out;
    margin-left: 20px;
    position: absolute;
    bottom: 100px;
`;

export const IconCircleContainer = styled(Card)`
    border-radius: 50%;
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px !important;
`;

export const FileDropzoneContainer = styled(Box)`
  height: 100px;
  width: 300px;
  border: 2px grey dashed;
  border-radius: 4px;
  position: relative;
  display: flex ;
  align-items: center ;
  justify-content: center;
  transition: background-color 250ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background-color: #f6f6f6;
  }
`;

export const FileDropzoneInput = styled("input")`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  z-index: 9;
  top: 0;
  left: 0;
`;

export const SearchBar = styled("input")`
    max-width: 400px;
    width: 100%;
    height: max-content;
    background: white;
    border-radius: 30px;
    height: 40px;
    font-size: 17px;
    outline: none;
    border: none;
    margin: 15px;
    padding-left: 20px;
    -webkit-box-shadow: 0 1px 2px rgb(202, 202, 202);
    -moz-box-shadow: 0 1px 2px rgb(202, 202, 202);
    box-shadow: 0 1px 2px rgb(202, 202, 202);
`;

export const ToggleFormItem = styled("label")`
    display: flex;
    flex-direction: row ;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
    cursor: pointer;
`;

export const TitleText = styled("span")`
    font-weight: bold;
    font-size: 1.2em;

    background-color: var(--purple-one);
    background-image: linear-gradient(45deg, ${PRIMARY_COLOUR}, #d11aff, #d16eff);    
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
`;

export const TitleLogo = styled("img")`
    height: 30px;
    vertical-align: top;
`;
export const FadeInBox = styled(Box)`
    pointer-events: ${(props:{$visible: boolean}) => props.$visible ? "all" : "none"};
    opacity: ${(props:{$visible: boolean}) => props.$visible ? 1 : 0};
    transition: all 250ms ease-in-out;
`;

export const PurpleCard = styled(Card)`
    background: rgb(121,32,245);
    background: linear-gradient(145deg, rgCba(121,32,245) 0%, rgba(192,75,255) 50%, rgba(179,128,249) 100%);
    color: white;
    cursor: ${(props:{onClick: unknown}) => props.onClick ? "pointer" : "auto"};
    transition: all 150ms ease-in-out;

    :hover {
        scale: ${(props:{onClick: unknown}) => props.onClick ? 1.025 : 1};;
    }
`;

export const ClickableCard = styled(Card)`
    cursor: pointer;
    transition: all 125ms ease-in-out;
    
    :hover {
        transform: scale(1.025);
    }
`;
