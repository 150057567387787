import {ArrowBack} from "@mui/icons-material";
import {Box, Card, CardContent, CardHeader, List, ListItem, ListItemText, Stack, Typography} from "@mui/material";
import {useState, useEffect, useRef, useCallback, ReactNode} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import IconButtonPop from "./IconButtonPop";
import {PageTitle} from "./Page";
import styled from "styled-components";

type ListCardProps = {
    $standalone?: boolean,
    $containerWidth: number,
    $open?: boolean
}

const ContentStack = styled(Stack)`
    position: relative;
    overflow-y: clip;
    padding: 5px;
    margin: -5px;
    flex: 1;
`;


const MessageCard = styled(Card)<ListCardProps>`
    flex: 1;
    max-width: 100%;
    box-shadow: ${(props) => props.$standalone ? undefined : "none"};

    ${(props) => props.$containerWidth < 600 && `
        position: absolute;
        display: ${props.$open ? "flex": "none"};
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        margin-left: 0 !important;
        z-index:10;

        ::after {
            display: none;
        }
    `}

    ::after {
        content: '';
        position: absolute;
        height: 75%;
        width: 1px;
        background: rgb(224,224,224);
        top: 50%;
        transform: translateY(-50%);
    }
`;

const ListCard = styled(Card)<ListCardProps>`
    width: 100%;
    max-width: 100%;
    box-shadow: ${(props) => props.$standalone ? undefined : "none"};
    overflow-y: auto;

    ${(props) => props.$containerWidth < 700 && props.$containerWidth > 600 && `
        width: 250px;
    `}

    ${(props) => props.$containerWidth > 700 && `        
        width: 350px;
    `}
`;

const BackButton = styled(IconButtonPop)<ListCardProps>`
    display: none;

    ${(props) => props.$containerWidth < 600 && `        
        display: flex;
    `}
`;

type Params = {
    title?: string,
    subtitle?: string,
    listItems?: {[key:string]: {[key:string]: unknown}},
    contentTemplate: (id:string, item:{[key:string]: unknown}) => ReactNode,
    standalone: boolean,
    children: ReactNode,
    height?: string,
    onChange?: () => void,
    url: string
}

export default function ListContentViewer({title ="Placeholder", listItems={}, subtitle, contentTemplate, standalone=false, children, onChange, height}: Params) {
    const [open, setOpen] = useState<string>();
    const [path, setPath] = useState<string>();
    const [width, setWidth] = useState(0);
    const [firstRender, setFirstRender] = useState(true);
    // To get width and determine whether mobile or desktop
    const containerRef = useRef<HTMLDivElement | null>(null);

    const navigate = useNavigate();
    const location = useLocation();

    const {id} = useParams();

    const updateWidth = useCallback(() => {
        if (containerRef.current) {
            setWidth(parseInt(window.getComputedStyle(containerRef.current).width, 10));
        }
    }, [containerRef]);

    useEffect(() => {
        updateWidth();
        window.addEventListener("resize", updateWidth);
        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    }, [updateWidth]);

    useEffect(() => {
        if (!standalone) {
            return;
        }

        let pathnameList = location.pathname.split("/");
        if (!isNaN(parseInt(pathnameList[pathnameList.length-1])) || pathnameList[pathnameList.length-1].toLowerCase() !== pathnameList[pathnameList.length-1]) {
            pathnameList = pathnameList.slice(0, -1);
        }
        setPath(pathnameList.join("/"));

        if (!id) {
            setOpen(undefined);
            return;
        }
        setOpen(Object.keys(listItems)?.find((itemId) => itemId === id.toString()) || undefined);
    }, [listItems, id]);

    useEffect(() => {
        if (!open) {
            onChange && onChange();
        }
    }, [open]);


    useEffect(() => {
        // Default to opening most recent
        if (firstRender && Object.keys(listItems).length > 0) {
            setOpen(Object.keys(listItems)[0] || undefined);
            setFirstRender(false);
            return;
        }
        if (open && !listItems[open]) {
            setOpen(undefined);
            return;
        }
    }, [listItems]);

    return (
        <Stack flex={1} height={height || "100%"} ref={containerRef} boxShadow={standalone ? "unset" : "rgba(0, 0, 0, 0.3) 0px 0px 5px 0px"} border={standalone ? "unset" : "lightgray 1px solid"} borderRadius={"10px"} bgcolor={standalone ? "unset" : "white"}>
            {standalone && <PageTitle>{title}</PageTitle>}
            {standalone || <CardHeader title={
                <Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant={"h5"}>{title}</Typography>
                </Stack>}/>}
            {subtitle && <Typography variant="subtitle2" sx={{opacity: 0.7, paddingLeft: 2, paddingRight: 2, marginTop: -0.5}}>{subtitle}</Typography>}
            <ContentStack direction={"row"} spacing={2}>
                <ListCard $containerWidth={width} $standalone={standalone}>
                    <List sx={{overflowY: "auto", height: "100%"}}>
                        {Object.keys(listItems).length > 0 ? Object.entries(listItems).map(([id, item]) => {
                            return (
                                <ListItem key={id} divider button onClick={() => standalone ? navigate(`${path}/${id.toString()}`) : setOpen(id)}>
                                    <Box sx={{width: "5px", height: "100%", position: "absolute", left: 0}} bgcolor={id === open ? "primary.main" : "unset"}/>
                                    <ListItemText primary={item?.title as string || "Untitled"}/>
                                </ListItem>
                            );
                        }) : <ListItem key={"noItems"} divider>
                            <ListItemText sx={{color: "rgb(180, 180, 180)"}} primary={"No items to view"}/>
                        </ListItem>}
                    </List>
                    {children}
                </ListCard>
                <MessageCard $open={Boolean(open)} $containerWidth={width} $standalone={standalone}>
                    <CardContent sx={{height: "100%"}}>
                        <Stack spacing={0} direction={"row"} height={"100%"}>
                            <Box ml={-2}>
                                <BackButton title="Back" responsive={false} $containerWidth={width} onClick={() => standalone ? navigate(`${path}`) : setOpen(undefined)}><ArrowBack/></BackButton>
                            </Box>
                            <Stack width={"100%"} sx={{overflowY: "hidden"}}>
                                {open ? contentTemplate(open, listItems[open]) : <Typography sx={{color: "rgb(180,180,180)", marginTop: "20px"}}>Click on an item to view it here.</Typography>}
                            </Stack>
                        </Stack>
                    </CardContent>
                </MessageCard>
            </ContentStack>
        </Stack>
    );
}
