import {Box, Card, CardContent, CardHeader} from "@mui/material";
import {ReactNode, useEffect, useState} from "react";
import styled from "styled-components";


type Params = {
    children: ReactNode,
    visible: boolean,
    sx?: {[key:string]: unknown},
    width?: string,
    mt?: string,
    card?: boolean,
    cardTitle?: string|ReactNode,
    margin?: string,
    cardSx?: {[key:string]: unknown},
    centerTitle?: true;
}

const FadingBox = styled(Box)`
    pointer-events: ${(props:{$visible: boolean, $margin?: string}) => props.$visible ? "all" : "none"};
    opacity: ${(props:{$visible: boolean, $margin?: string}) => props.$visible ? 1 : 0};
    transition: all 250ms ease-in-out;
    width: 500px;
    max-width: 95%; 
    margin: 64px auto !important;
    margin: ${(props:{$visible: boolean, $margin?: string}) => `${props.$margin} !important` || "64px auto !important"};
`;

export default function FadeInBox({children, visible, sx, width, mt, card, cardTitle, margin, cardSx, centerTitle}: Params) {
    const [active, setActive] = useState<{display: "flex"|"none", visible: boolean}>({display: visible ? "flex" : "none", visible: visible});

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                setActive((a) => ({...a, display: "flex"}));
            }, 200);
            setTimeout(() => {
                setActive((a) => ({...a, visible: true}));
            }, 250);
            return;
        }
        setActive((a) => ({...a, visible: false}));
        setTimeout(() => {
            setActive((a) => ({...a, display: "none"}));
        }, 200);
    }, [visible]);

    return (
        <FadingBox display={active.display} $visible={active.visible} {...{sx}} $margin={margin} width={`${width} !important`} mt={`${mt} !important`}>
            {active.display == "flex" ? (card || cardTitle ?
                <Card sx={{width: "100%", ...cardSx}}>
                    {Boolean(cardTitle) && <CardHeader title={cardTitle} sx={{margin: centerTitle ? "auto" : undefined}}/>}
                    <CardContent sx={{height: "100%"}}>
                        {children}
                    </CardContent>
                </Card> :
                children) : null}
        </FadingBox>
    );
}
