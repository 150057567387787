import {Typography, Alert, Stack, Popover, IconButton, Box, Button, FormControl, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import {PRIMARY_COLOUR, capitalise, convertDate, getDateDiff} from "placementt-core";
import {useState, useEffect, ChangeEvent, useRef, useCallback} from "react";
import InputGroup from "./InputGroup";
import {ArrowBack, ArrowForward} from "@mui/icons-material";
import Form from "../Form";
import {Popup} from "../Popup";
import useWindowDimensions from "../../Util/util";

type Params = {
    label?: string,
    required?: boolean,
    name: string,
    startLabel?: string,
    endLabel?: string,
    start?: string,
    end?: string,
    startMin?: string,
    startMax?: string,
    endMin?: string,
    endMax?: string,
    disabled?: boolean,
    noPast?: boolean,
    sx?: {[key: string]: unknown},
    onChange?: (v: {start: Date, end: Date}|undefined) => void,
    allowedDates?: {type: "all"|"within-range"|"specific", dates?: ([Date, Date]|Date)[]},
    restrictedDates?: ([Date, Date]|Date)[]
}

export default function DateRangePickerNew({label, required, noPast, name, startLabel="Start date", start, end, endLabel="End date", allowedDates, restrictedDates, disabled=false, sx={}, onChange=() => null}:Params) {
    const [error, setError] = useState<boolean>();
    const [pickerOpen, setPickerOpen] = useState(false);
    const [yearSelectorPopup, setYearSelectorPopup] = useState(false);

    const today = new Date();
    const {width} = useWindowDimensions();

    const [firstDate, setFirstDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));

    const [selectedDates, setSelectedDates] = useState<[Date, Date]|[Date]|[]>();
    const [hoveredDate, setHoveredDate] = useState<Date>();

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!start) return;
        const date = [convertDate(start, "date") as Date];
        if (end) date.push(convertDate(end, "date") as Date);
        setSelectedDates(date as [Date]|[Date, Date]);
    }, [start, end]);


    useEffect(() => {
        setError(undefined);
    }, [selectedDates]);

    console.log(setError);

    const secondDate = new Date(firstDate);
    secondDate.setMonth(secondDate.getMonth() + 1);

    const onSelectDate = useCallback((date: Date) => {
        setSelectedDates((currentDates) => {
            // If no dates selected, add it to array on its own.
            if (!currentDates || !currentDates.length) {
                return [date];
            }

            // If already one date selected, if this date is before, put it first. Otherwise, put it after.
            if (currentDates.length === 1) {
                if (getDateDiff(date, currentDates[0]) === 0) {
                    return [];
                }
                if (date < currentDates[0]) {
                    return [date, currentDates[0]];
                } else {
                    return [currentDates[0], date];
                }
            }

            // If date equals either already inputted, deselect.
            if (getDateDiff(date, currentDates[0]) === 0) {
                return [currentDates[1]];
            }
            if (getDateDiff(date, currentDates[1]) === 0) {
                return [currentDates[0]];
            }

            // If two dates already exist:

            //      If above second date, replace second
            if (date > currentDates[1]) {
                return [currentDates[0], date];
            }

            //      If above first date, replace first
            if (date < currentDates[0]) {
                return [date, currentDates[1]];
            }

            //      If selected date is between two existing dates, calculate proximity to each, and replace whichever is closest with this number.
            const proximityToFirst = Math.abs(getDateDiff(date, currentDates[0]));
            const proximityToSecond = Math.abs(getDateDiff(date, currentDates[1]));

            return proximityToFirst < proximityToSecond ? [date, currentDates[1]] : [currentDates[0], date];
        });
    }, [selectedDates]);

    const submitDates = () => {
        onChange(selectedDates && selectedDates.length ? {
            start: selectedDates?.[0],
            end: selectedDates?.[1] || selectedDates?.[0],
        } : undefined);
        setPickerOpen(false);
    };

    const Calendar = <>
        <Stack direction={"row"} onMouseLeave={() => setHoveredDate(undefined)}>
            <CalendarHeader date={firstDate} onError={() => setError(true)} changeMonthDirection={width > 710 ? "backward" : undefined} {...{noPast, allowedDates, error, restrictedDates, selectedDates, hoveredDate}} changeHoveredDate={setHoveredDate} changeSelectedDate={onSelectDate} onChangeMonth={(direction) => setFirstDate((d) => {
                const newDate = new Date(d);
                newDate.setMonth(newDate.getMonth() + (direction === "forward" ? 1 : -1));
                return newDate;
            })}/>
            {width < 710 || <><Box height={"300px"} alignSelf={"center"} flex={1} width={"1px"} bgcolor={"rgba(0, 0, 0, 0.301)"}/>
                <CalendarHeader date={secondDate} onError={() => setError(true)} changeMonthDirection="forward" {...{allowedDates, error, restrictedDates, selectedDates, hoveredDate}} changeHoveredDate={setHoveredDate} changeSelectedDate={onSelectDate} onChangeMonth={(direction) => setFirstDate((d) => {
                    const newDate = new Date(d);
                    newDate.setMonth(newDate.getMonth() + (direction === "forward" ? 1 : -1));
                    return newDate;
                })}/></>}
        </Stack>
        <Alert sx={{opacity: error ? 1 : 0, transition: "opacity ease-in-out 150ms"}} severity="error">You have selected dates that are unavailable. Please amend to continue.</Alert>
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"}>
                <Button variant="text" onClick={() => setSelectedDates(undefined)}>
            Clear
                </Button>
                <Button variant="text" onClick={() => setYearSelectorPopup(true)}>
            Select month / year
                </Button>
            </Stack>
            <Button variant="contained" disabled={Boolean(error)} onClick={submitDates}>Submit</Button>
        </Stack></>;

    return (
        <Stack spacing={2} {...sx}>
            <Typography variant={"subtitle1"}>{label}</Typography>
            <Stack direction={"row"} ref={ref} id={"datePickerHolder"} alignItems={"center"} sx={{cursor: disabled ? undefined : "pointer"}} onClick={() => disabled || setPickerOpen(true)}>
                <InputGroup {...{disabled, required}} readonly onClick={(e: ChangeEvent<HTMLInputElement>) => e.preventDefault()} value={convertDate(selectedDates?.[0], "dbstring")} type='date' name={`start${capitalise(name)}`} label={startLabel}/>
                <Typography>-</Typography>
                <InputGroup {...{disabled, required}} readonly onClick={(e: ChangeEvent<HTMLInputElement>) => e.preventDefault()} value={convertDate(selectedDates?.[1] || selectedDates?.[0], "dbstring")} type='date' name={`end${capitalise(name)}`} label={endLabel}/>
            </Stack>
            <Popover anchorEl={ref.current} anchorOrigin={{horizontal: "center", vertical: "bottom"}} transformOrigin={{horizontal: "center", vertical: "top"}} title={"Select your dates"} open={pickerOpen} onClose={() => setPickerOpen(false)}>
                <Stack p={3}>
                    {allowedDates?.type === "specific" ? <SpecificDatePicker {...{noPast, selectedDates, allowedDates, error, setSelectedDates, submitDates}} onError={() => setError(true)}/> : Calendar}
                </Stack>
            </Popover>
            <Popup title="Year" open={yearSelectorPopup} onClose={() => setYearSelectorPopup(false)}>
                <Form onSubmit={(e) => {
                    setFirstDate(new Date((e as {year:number}).year, 0, 1));
                    setYearSelectorPopup(false);
                }} submitText="Select" functionType="sync">
                    <InputGroup type="number" name={"year"} label={"Select year"} min="2000" value={firstDate.getFullYear()}/>
                </Form>
            </Popup>
        </Stack>
    );
}

function SpecificDatePicker({allowedDates, noPast, selectedDates, error, setSelectedDates, submitDates}: {selectedDates?: [Date, Date]|[Date]|[], noPast?: boolean, submitDates: () => void, allowedDates?: {type: "all"|"within-range"|"specific", dates?: ([Date, Date]|Date)[]}, onError: () => void, error?: boolean, setSelectedDates: (e: React.SetStateAction<[Date, Date] | [Date] | [] | undefined>) => void}) {
    const today = new Date();
    return (
        <>
            <Typography>Select dates</Typography>
            <FormControl>
                <RadioGroup
                    name="dateList"
                    value={allowedDates?.dates?.findIndex((dates) => {
                        if (!selectedDates || !selectedDates.length) return false;

                        if (!Array.isArray(dates)) {
                            if (getDateDiff(dates, selectedDates[0]) === 0) return true;
                        }
                        if (Array.isArray(dates) && selectedDates.length === 2) {
                            return (getDateDiff(dates[0], selectedDates[0]) === 0) && (getDateDiff(dates[1], selectedDates[1]) === 0);
                        }
                        return false;
                    })}
                    onChange={(_, a) => setSelectedDates(() => {
                        const selectedAllowedDate = allowedDates?.dates?.[parseInt(a)];
                        if (!selectedAllowedDate) return undefined;
                        return Array.isArray(selectedAllowedDate) ? selectedAllowedDate : [selectedAllowedDate];
                    })}
                >
                    {allowedDates?.dates?.filter((dates) => noPast ? !(Array.isArray(dates) ? dates.some((d) => d < today) : (dates < today)) : true)?.map((dates, index) => <FormControlLabel value={index} control={<Radio />} label={Array.isArray(dates) ? `${convertDate(dates[0], "visual")} - ${convertDate(dates[1], "visual")} (${getDateDiff(dates[0], dates[1])} day${getDateDiff(dates[0], dates[1]) > 1 ? "s" : ""})` : convertDate(dates, "visual") + " (1 day)"} />)}
                </RadioGroup>
            </FormControl>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                    <Button variant="text" onClick={() => setSelectedDates(undefined)}>
                        Clear
                    </Button>
                </Stack>
                <Button variant="contained" disabled={Boolean(error) || !selectedDates} onClick={submitDates}>Submit</Button>
            </Stack>
        </>
    );
}

function CalendarHeader({date, onChangeMonth, noPast, restrictedDates, allowedDates, changeMonthDirection, selectedDates, hoveredDate, changeSelectedDate, changeHoveredDate, onError, error}:{date: Date, noPast?: boolean, onChangeMonth: (direction: "forward"|"backward") => void, changeMonthDirection?: "forward"|"backward", hoveredDate?: Date, changeSelectedDate: (date: Date) => void, changeHoveredDate: (date: Date) => void, selectedDates?: [Date, Date]|[Date]|[], allowedDates?: {type: "all"|"within-range"|"specific", dates?: ([Date, Date]|Date)[]}, restrictedDates?: ([Date, Date]|Date)[], onError: () => void, error?: boolean}) {
    const month = date.toLocaleString("default", {month: "long"});
    const year = date.toLocaleString("default", {year: "numeric"});

    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const startDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1).getDay();

    const renderedDates = [];

    for (let i = 0; i < startDayOfMonth; i++) {
        renderedDates.push(<Filler/>);
    }

    const getIsHovered = (currentDate: Date, isSelected?: true | "end" | "start" | "only") => {
        if (isSelected || !hoveredDate) return false;
        if (noPast && currentDate <= new Date()) return false;

        const thisDateSameAsHovered = getDateDiff(currentDate, hoveredDate) === 0;

        if (!selectedDates?.length && thisDateSameAsHovered) return "only";

        if (!selectedDates?.length) return false;

        if (selectedDates.length === 1 && thisDateSameAsHovered) {
            const selectedDate = selectedDates[0];

            if (currentDate > selectedDate) return "end";
            if (currentDate < selectedDate) return "start";
        }
        if (selectedDates.length === 2 && thisDateSameAsHovered) {
            if (currentDate > selectedDates[1]) return "end";
            if (currentDate < selectedDates[0]) return "start";
        }
        if (selectedDates.length === 1) {
            if (currentDate < hoveredDate && currentDate > selectedDates[0]) return true;
            if (currentDate > hoveredDate && currentDate < selectedDates[0]) return true;
        }
        if (selectedDates.length === 2) {
            if (currentDate < hoveredDate && currentDate > selectedDates[0]) return true;
            if (currentDate > hoveredDate && currentDate < selectedDates[1]) return true;
        }
    };

    const getIsDisabled = (currentDate: Date) => {
        if (noPast && currentDate <= new Date()) return true;

        if ((restrictedDates?.filter((d) => !Array.isArray(d)) as Date[])?.find((date) => getDateDiff(date, currentDate) === 0)) return true;

        // Check restricted dates first.
        const restrictedRanges = restrictedDates?.filter((date) => Array.isArray(date)) as [Date, Date][];
        if (restrictedRanges) {
            for (const range of restrictedRanges) {
                const [start, end] = range;
                if (getDateDiff(currentDate, start) <= 0 && getDateDiff(currentDate, end) >= 0 ) {
                    return true;
                }
            }
        }

        // Check allowed dates.
        if (!allowedDates) return false;

        if (allowedDates.type === "all") return false;

        if ((allowedDates.dates?.filter((d) => !Array.isArray(d)) as Date[])?.some((date) => getDateDiff(date, currentDate) === 0)) return false;

        const allowedDateRanges = allowedDates.dates?.filter((date) => Array.isArray(date)) as [Date, Date][];

        for (const range of allowedDateRanges) {
            const [start, end] = range;
            if (currentDate >= start && currentDate <= end) {
                return false;
            }
        }
        return true;
    };

    // Add the actual days of the month
    for (let i = 1; i <= daysInMonth; i++) {
        const currentDate = new Date(date.getFullYear(), date.getMonth(), i);
        const isSelected =
            selectedDates?.length === 1 && getDateDiff(currentDate, selectedDates?.[0]) === 0 ? "only" :
                selectedDates?.length === 2 && getDateDiff(currentDate, selectedDates?.[0]) === 0 ? "start" :
                    selectedDates?.length === 2 && getDateDiff(currentDate, selectedDates?.[1]) === 0 ? "end" :
                        selectedDates?.length == 2 && currentDate > selectedDates?.[0] && currentDate < selectedDates?.[1] ? true : undefined;

        const isHovered = getIsHovered(currentDate, isSelected);

        const isDisabled = getIsDisabled(currentDate);

        renderedDates.push(<DateItem
            date={i}
            onSelected={() => changeSelectedDate(currentDate)}
            onHover={() => changeHoveredDate(currentDate)}
            selected={isSelected}
            hovered={isHovered}
            disabled={isDisabled}
            {...{error, onError}}/>);
    }

    // Fill the rest of the array to make it a complete week
    while (renderedDates.length % 7 !== 0) {
        renderedDates.push(<Filler/>);
    }

    const renderedDatesInWeeks = [];
    for (let i = 0; i < renderedDates.length; i += 7) {
        renderedDatesInWeeks.push(renderedDates.slice(i, i + 7));
    }

    return (
        <Stack>
            <Stack direction={"row"} justifyContent={"center"} position={"relative"} width={"300px"} spacing={0}>
                {(changeMonthDirection === "backward" || !changeMonthDirection) && <IconButton sx={{position: "absolute", left: 10, top: "50%", transform: "translateY(-50%)"}} onClick={() => onChangeMonth("backward")}><ArrowBack/></IconButton>}
                <Typography>{month} {year}</Typography>
                {(changeMonthDirection === "forward" || !changeMonthDirection) && <IconButton sx={{position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)"}} onClick={() => onChangeMonth("forward")}><ArrowForward/></IconButton>}
            </Stack>
            <Stack direction={"row"} spacing={0} justifyContent={"space-around"} position={"relative"} width={"300px"}>
                {["S", "M", "T", "W", "T", "F", "S"].map((day) => <Typography variant="subtitle1" textAlign={"center"} color={"#0000007c"}>{day}</Typography>)}
            </Stack>
            {renderedDatesInWeeks.map((dates) =>
                <Stack direction={"row"} spacing={0} justifyContent={"space-around"} position={"relative"} width={"300px"}>
                    {dates}
                </Stack>)}
        </Stack>
    );
}

function Filler() {
    return (<Box
        flex={1}
        height={"40px"}
        lineHeight={"40px"}
        border={"2px solid transparent"}
        sx={{position: "relative", margin: "1px"}}/>);
}

function DateItem({date, disabled, selected, hovered, onHover, onSelected, onError, error}:{date: number, disabled?: boolean, hovered?: boolean|"start"|"end"|"only", selected?: boolean|"start"|"end"|"only", onHover: () => void, onSelected: () => void, onError: () => void, error?: boolean}) {
    if (selected && disabled && !error) onError();

    const fError = selected && disabled;

    return (<Typography
        flex={1}
        height={"40px"}
        lineHeight={"40px"}
        border={(!selected && !hovered) ? "2px solid transparent" : undefined}
        borderRadius={`${selected === "start" || hovered === "start" || selected == "only" || hovered == "only" ? "20px" : 0} ${selected === "end" || hovered === "end" || selected == "only" || hovered == "only" ? "20px 20px" : "0 0"} ${selected === "start" || hovered === "start" || selected == "only" || hovered == "only" ? "20px" : 0}`}
        borderLeft={selected === "start" || selected == "only" ? `2px ${PRIMARY_COLOUR} solid` : hovered === "start" || hovered == "only" ? "2px lightgrey dashed" : undefined}
        borderRight={selected === "end" || selected == "only" ? `2px ${PRIMARY_COLOUR} solid` : hovered === "end" || hovered == "only" ? "2px lightgrey dashed" : undefined}
        sx={{"position": "relative", "opacity": disabled && !fError ? 0.5 : undefined, "cursor": disabled ? undefined : "pointer", "margin": selected ? undefined : "0 1px", "paddingLeft": (selected === "end") ? "3px" : (selected === true) ? "3px" : (selected === "start" || selected === "only") ? "1px" : (hovered === "end") ? "2px" : (hovered === true) ? "2px" : (hovered === "start") ? "0px" : undefined, "paddingRight": (selected === "start") ? "3px" : (selected === true) ? "3px" : (selected === "end" || selected === "only") ? "1px" : (hovered === "start") ? "2px" : (hovered === true) ? "2px" : (hovered === "end") ? "0px" : undefined}}
        bgcolor={fError ? "#ff00007e" : selected ? `${PRIMARY_COLOUR}20` : undefined}
        color={fError ? "#9a0000" : selected ? "primary" : undefined}
        boxSizing={"border-box"}
        borderTop={selected ? `2px ${PRIMARY_COLOUR} solid` : hovered ? "2px lightgrey dashed" : "2px solid transparent"}
        borderBottom={selected ? `2px ${PRIMARY_COLOUR} solid`: hovered ? "2px lightgrey dashed" : "2px solid transparent"}
        textAlign={"center"}
        onMouseEnter={() => disabled || onHover()}
        onClick={() => disabled || onSelected()}>{date}</Typography>);
}
