import {Person, Dashboard, BusinessCenter, School} from "@mui/icons-material";
import Home from "./Home";
import Profile from "../Shared/Profile";
import Notes from "../Shared/Notes";
import Notifications from "../Shared/Notifications";
import {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../../App";
import {ApplicantWorkflow, PlacementTemplate, ProviderData, StudentPlacementData, UserData, UserGroupData, getAccess, getOrganisation, useLoadAddresses} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import SideNavBar, {SideNavBarItems} from "../../Components/Navigation/SideNavBar";
import ViewPlacement from "../Shared/ViewPlacement";
import {where} from "firebase/firestore";
import FormPage from "../Shared/FormPage";
import CustomFormBuilder from "../../Components/CustomFormBuilder";
import CustomFormPage from "../../Components/CustomFormPage";
import AddApplicantWorkflowPage from "./AddItemForms/AddApplicantWorkflowPage";
import AddPlacementListing, {PlacementListingPage} from "./AddItemForms/AddPlacementListing";
import Organisation from "./Organisation";
import AddressPage from "./AddressPage";
import Placements from "./Placements";
import RequestItemPage from "./AddItemForms/RequestItemLists";
import UserProfile from "../Shared/UserProfile";
import AddAddressPage from "../Shared/AddAddressPage";
import ProposePlacement from "../Shared/ProposePlacement";


export default function Providers() {
    const [provider, setProvider] = useState<ProviderData&{adminData: UserData}>();
    const [userGroups, setUserGroups] = useState<{[key:string]: UserGroupData}>(); // {} NEEDS CHANGING TO PROVIDER GROUPS
    const [forms, setForms] = useState<{[key:string]: unknown}>();
    const [applicantWorkflows, setApplicantWorkflows] = useState<{[key:string]: ApplicantWorkflow}>({});
    const [placementTemplates, setPlacementTemplates] = useState<{[key:string]: Partial<PlacementTemplate>}>({});
    const [listingGroups, setListingGroups] = useState<{[key:string]: {[key:string]: unknown}}>({});
    const [placements, setPlacements] = useState<{[key:string]: StudentPlacementData&{student: UserData|false}}>();

    const firebaseQuery = new FirebaseQuery();

    const user = useContext(UserContext);

    const {addresses} = useLoadAddresses(user);

    const editForms = getAccess(user, "editForms");

    useEffect(() => {
        getOrganisation(user, (data) => firebaseQuery.getDocData(["users", (data as ProviderData).admin]).then((admin) => setProvider({...(data as ProviderData), adminData: admin as UserData})));
        firebaseQuery.collectionSnapshot(setUserGroups, "userGroups", [where("oId", "==", user.oId), where("product", "==", user.product)]);
        firebaseQuery.collectionSnapshot(setForms, "forms", [where("oId", "==", user.oId), where("product", "==", user.product)]);
        firebaseQuery.collectionSnapshot(setApplicantWorkflows, "applicantWorkflows", [where("oId", "==", user.oId), where("product", "==", user.product)]);
        firebaseQuery.collectionSnapshot(setPlacementTemplates, "placementTemplates", [where("providerId", "==", user.oId)]);
        firebaseQuery.collectionSnapshot(setListingGroups, "listingGroups", [where("providerId", "==", user.oId)]);
        firebaseQuery.collectionSnapshot(async (p: {[key: string]: StudentPlacementData}) => {
            const withStudent:{[key: string]: StudentPlacementData&{student: UserData}} = Object.fromEntries(await Promise.all(Object.entries(p).map(async ([k, placement]) => {
                const student = placement.uid ? await firebaseQuery.getDocData(["users", placement.uid]).catch(() => false) as UserData|false : {
                    details: {
                        forename: placement.studentForename,
                        surname: placement.studentSurname,
                    },
                    email: placement.studentEmail,
                };
                return [k, {...placement, student: student}];
            })));
            setPlacements(withStudent);
        }, "placements", [where("providerId", "==", user.oId)]);

        (firebaseQuery.getDocData(["userGroups", "readOnly"]) as Promise<UserGroupData>).then((readOnlyGroup) => setUserGroups((g) => ({...g, readOnly: readOnlyGroup})));
    }, []);


    if (!provider) {
        return null;
    }

    if ((user.viewAddresses === "request" && !user.visibleAddresses && !user.requestedVisibleAddresses) || (user.viewPlacementListings === "request" && !user.visibleListings && !user.requestedVisibleListings)) {
        return <RequestItemPage/>;
    }

    const routes = [
        {
            label: "Profile",
            route: "profile",
            element: <Profile/>,
            icon: <Person/>,
        },
        {
            label: "Home",
            route: "home",
            element: <Home/>,
            icon: <Dashboard/>,
        },
        {
            label: "Placements",
            route: "placementListings",
            element: <Placements/>,
            icon: <School/>,
        },
        user.userGroup !== "readOnly" && {
            label: "Organisation",
            route: "organisation",
            element: <Organisation/>,
            icon: <BusinessCenter/>,
        },
        {
            route: "notes",
            element: <Notes standalone/>,
        },
        {
            route: "notes/:id",
            element: <Notes standalone/>,
        },
        {
            route: "notifications",
            element: <Notifications/>,
        },
        {
            route: "notifications/:id",
            element: <Notifications/>,
        },
        {
            route: "activePlacements/:pid",
            element: <ViewPlacement/>,
        },
        {
            route: "addAddress",
            element: <AddAddressPage/>,
        },
        {
            route: "addAddress/:addressId",
            element: <AddAddressPage/>,
        },
        {
            route: "viewAddress/:addressId",
            element: <AddressPage/>,
        },
        {
            route: "addApplicantWorkflow/",
            element: <AddApplicantWorkflowPage/>,
        },
        {
            route: "addApplicantWorkflow/:id",
            element: <AddApplicantWorkflowPage/>,
        },
        {
            route: "addListing/",
            element: <AddPlacementListing/>,
        },
        {
            route: "addListing/:id",
            element: <AddPlacementListing/>,
        },
        {
            route: "viewListing/:id",
            element: <PlacementListingPage/>,
        },
        {
            route: "addApplicantWorkflow/:id/:custom",
            element: <AddApplicantWorkflowPage/>,
        },
        {
            route: "organisation/resources/f",
            element: <FormPage title='Create custom form' path='forms' editable={editForms}><CustomFormBuilder noSections/></FormPage>,
        },
        {
            route: "organisation/resources/f/:id",
            element: <FormPage title='Edit custom form' path='forms' editable={editForms}><CustomFormBuilder noSections/></FormPage>,
        },
        {
            route: "form",
            element: <CustomFormPage/>,
        },
        {
            route: "placements/:pid",
            element: <ViewPlacement/>,
        },
        {
            route: "addStudentPlacement",
            element: <ProposePlacement/>,
        },
        {
            route: "users/:id",
            element: <UserProfile/>,
        },
    ].filter((a) => a) as SideNavBarItems;

    return (
        <OrganisationContext.Provider value={{details: provider, ...{addresses, userGroups, forms, applicantWorkflows, placementTemplates, listingGroups, placements}}}>
            <SideNavBar items={routes}/>
        </OrganisationContext.Provider>);
}
