import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {UIEvent, useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {UserContext} from "../App";
import {NotificationObject, UserData, useLazyLoadQueryList} from "placementt-core";
import {Badge, Box, CardContent, List, ListItemButton, ListItemText, Popover, Typography, useTheme} from "@mui/material";
import {arrayUnion, orderBy, where} from "firebase/firestore";
import IconButtonPop from "./IconButtonPop";
import {Notifications} from "@mui/icons-material";
import ReactHtmlParser from "react-html-parser";


export function NavbarNotifications() {
    const [open, setOpen] = useState<Element>();
    const [unread, setUnread] = useState(0);
    const [bottom, setBottom] = useState(false);
    const [notifications, setNotifications] = useState<{[key:string]: NotificationObject}>({});

    const navigate = useNavigate();
    const firebaseQuery = new FirebaseQuery();

    const user = useContext(UserContext) as UserData;

    const {items, loadMore} = useLazyLoadQueryList({
        path: "notifications",
        constraints: [where("viewableBy", "array-contains-any", [user.id]), user.product === "students" ? where("uid", "==", user.id) : where("oId", "==", user.oId), orderBy("created", "desc")],
        number: 5,
    });

    useEffect(() => {
        setNotifications((n) => ({...items as {[key:string]: NotificationObject}, ...n}));
    }, [items]);

    const theme = useTheme();

    const handleScroll = (e:UIEvent<HTMLElement>) => {
        const scrolledToBottom = Math.floor((e.currentTarget.scrollHeight - e.currentTarget.scrollTop)/10) === Math.floor(e.currentTarget.clientHeight/10);

        if (scrolledToBottom && !bottom) {
            setBottom(true);
            loadMore();
        } else if (!scrolledToBottom) {
            setBottom(false);
        }
    };
    const handleClick = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(undefined);
    };

    useEffect(() => {
        const getUnread = async () => {
            const total = await firebaseQuery.getCount(
                "notifications",
                [where("viewableBy", "array-contains-any", [user.id]), user.product === "students" ? where("uid", "==", user.id) : where("oId", "==", user.oId), orderBy("created", "desc")]);

            const read = await firebaseQuery.getCount(
                "notifications",
                [where("viewedBy", "array-contains-any", [user.id]), user.product === "students" ? where("uid", "==", user.id) : where("oId", "==", user.oId), orderBy("created", "desc")]);
            return total - read;
        };

        getUnread().then(setUnread);
    }, []);

    const markAsRead = (id:string, notification:NotificationObject) => {
        setUnread((u) => u-1);
        setNotifications((n) => ({...n, [id]: {...n[id], viewedBy: [...n[id].viewedBy, user.id]}}));
        firebaseQuery.update(notification.docPath.path.split("/"), {
            viewedBy: arrayUnion(user.id),
        });
    };

    const markAllAsRead = () => {
        if (!notifications) return;
        firebaseQuery.collectionUpdate({viewedBy: arrayUnion(user.id)},
            "notifications",
            [where("viewableBy", "array-contains-any", [user.id]), user.product === "students" ? where("uid", "==", user.id) : where("oId", "==", user.oId), orderBy("created", "desc")],
        );
        setUnread(0);
        setNotifications((n) => (Object.fromEntries(Object.entries(n).map(([id, n]) => [id, {...n, viewedBy: [user.id]}]))));
    };

    const handleItemClick = (id:string, n:NotificationObject) => {
        n.viewedBy.includes(user.id) || markAsRead(id, n);
        if (n.buttonURL) {
            navigate(n.buttonURL.slice(25));
            setOpen(undefined);
        }
    };

    return (
        <>
            <IconButtonPop responsive={false} aria-describedby={"notification-button"} title={"Notifications"} onClick={handleClick}>
                <Badge badgeContent={unread} color="primary">
                    <Notifications />
                </Badge>
            </IconButtonPop>
            <Popover
                open={Boolean(open)}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{top: 40, left: window.innerWidth}}
                id={"notification-popup"}
                anchorEl={open}
            >
                <CardContent sx={{padding: "10px", width: "500px", maxWidth: "100%"}}>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <Typography variant='h6' sx={{width: "max-content"}}>Notifications</Typography>
                        {false && <Link to={`/${user.product}/notifications`}>View all</Link>}
                    </Box>
                    {Object.keys(notifications).length === 0 && <Typography sx={{opacity: 0.7}}>You don't currently have any notifications.</Typography>}
                    {Object.keys(notifications).length > 0 && <List sx={{maxHeight: "500px", overflow: "auto"}} onScroll={handleScroll}>
                        {Object.entries(notifications).map(([id, n]) => {
                            const read = n.viewedBy.includes(user.id);
                            const created = new Date(n.created.toDate()).toDateString().split(" ");
                            const createdString = `${created[0]} ${created[2]}-${created[1]}-${created[3]}`;

                            return (

                                <ListItemButton key={id} divider onClick={() => {
                                    handleItemClick(id, n);
                                }} style={{flexDirection: "column", alignItems: "flex-start", borderLeft: read ? "none" : `4px solid ${theme.palette.primary.main}`}}
                                >
                                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                        <Typography variant="body1">{n.title}</Typography>
                                        <Typography variant='overline'>{createdString}</Typography>
                                    </Box>
                                    <ListItemText
                                        sx={{marginTop: "-5px"}}
                                        // eslint-disable-next-line new-cap
                                        secondary={ReactHtmlParser(n.description)}
                                    />
                                </ListItemButton>

                            );
                        })}
                    </List>}
                    <Typography onClick={markAllAsRead} color={"primary"} sx={{textDecoration: "underline", cursor: "pointer"}} textAlign={"right"}>Mark all as read</Typography>
                </CardContent>
            </Popover>
        </>
    );
}
