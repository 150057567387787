import {Box, CardHeader} from "@mui/material";
import {useState} from "react";
import Drawer from "../../Components/Drawer";
import Map from "../../Components/Map";

export default function SearchPlacements() {
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);

    return (
        <Box sx={{minHeight: "calc(100vh - 60px)", position: "relative", margin: "-20px"}}>
            <Map/>
            <Drawer label={"Search placements"} active={isDrawerOpen} onToggle={(open) => setIsDrawerOpen(open)}>
                <CardHeader title={"Search placements"}/>
            </Drawer>
        </Box>
    );
}
