import {ProviderData, executeCallable} from "placementt-core";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {GradientText} from "../../Util/styledComponents";
import FadeInBox from "../../Components/FadeInBox";
import Page from "../../Components/Page";
import {Grid, Typography} from "@mui/material";
import Preloader from "../../Components/Preloader";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";


export default function RequestUserAcces() {
    const {oId, key}:{oId?: string, key?: string} = useParams();

    const [organisation, setOrganisation] = useState<ProviderData>();
    const [loading, setLoading] = useState<"loading"|"invalid"|"loaded"|"expired">("loading");

    useEffect(() => {
        const checkAccess = async () => {
            const orgAccess = await executeCallable("userManagement-getProviderRequests", {oId: oId, key: key});
            const returnData = orgAccess.data as ProviderData&{code: number};
            console.log("ACCESS", orgAccess);
            if (!returnData) {
                setLoading("invalid");
                return;
            }

            if (returnData?.code === 0) {
                setLoading("expired");
                return;
            }

            setLoading("loaded");
            setOrganisation(returnData);
        };

        checkAccess();
    }, []);

    const submitRegistrationRequest = async (e: {[key: string]: unknown}) => {
        await executeCallable("userManagement-submitRegistrationRequest", {oId: oId, key: key, userData: e}).catch((e) => {
            throw e;
        });
        return {severity: "success", message: "Request sent. We will email you with the administrator's response"};
    };

    const renderPage = () => {
        if (!oId || loading === "invalid") {
            return (
                <>
                    <Typography variant="h6">Incorrect link provided</Typography>
                    <Typography variant="h6">If this is a mistake, please contact your administrator and request a new link.</Typography>
                </>
            );
        }
        if (loading === "expired") {
            return (
                <Typography variant="h6">Link expired, request a fresh link from your administrator.</Typography>
            );
        }
        if (loading === "loading") {
            return (
                <>
                    <Typography variant="h5">Fetching organisation details</Typography>
                    <Preloader visible/>
                </>
            );
        }

        return (<>
            <Typography>Request access to {organisation?.name} through this link, to begin managing your placement students.</Typography>
            <Form onSubmit={submitRegistrationRequest} submitText={"Send access request"}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <InputGroup required id="forename" label="Forename" name="first_name" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputGroup required id="surname" label="Surname" name="family_name" />
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup required id="email" label="Email Address" type="email" name="email"/>
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup multiline minRows={4} name="message" label="Message" placeholder="Send any information relevant to the administrator."/>
                    </Grid>
                </Grid>
            </Form>
        </>);
    };

    return (
        <Page
            metaTitle="Placementt | Request access"
            metaDesc={`Request access to ${organisation?.name || "organisation"}`}
            fullHeight>
            <FadeInBox visible card cardTitle={<GradientText variant={"h4"} sx={{width: "100%", textAlign: "center"}}>Request access</GradientText>}>
                {renderPage()}
            </FadeInBox>
        </Page>
    );
}
