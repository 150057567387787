/* eslint-disable import/no-named-as-default */
import {Stack} from "@mui/material";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {GradientText} from "../../Util/styledComponents";
import {login} from "placementt-core";
import Page from "../../Components/Page";
import FadeInBox from "../../Components/FadeInBox";

export default function Login() {
    const location = useLocation();
    const navigate = useNavigate();
    const redirect = location.state?.redirect;

    const fLogin = async (e:any) => {
        await login(e).catch((error) => {
            console.log("Error!");
            throw error;
        }).then(() => {
            if (redirect) {
                navigate(redirect);
            }
        });
    };

    return (
        <Page
            metaTitle="Placementt | Login"
            metaDesc="Sign in to Placementt"
            fullHeight>
            <FadeInBox visible card mt="60px" cardTitle={<GradientText variant={"h4"} sx={{marginBottom: "20px", width: "100%", textAlign: "center"}}>Sign in</GradientText>}>
                <Form onSubmit={fLogin}>
                    <InputGroup name='email' type='email' label='Email' autoComplete='email' required/>
                    <InputGroup name='password' type='password' label='Password' autoComplete='password' required/>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} spacing={0}>
                        <Link to="/register">Register</Link>
                        <Link style={{textAlign: "right"}} to={"/reset/password"}>Reset password</Link>
                    </Stack>
                </Form>
            </FadeInBox>
        </Page>

    );
}

