import SubNavBar, {SubNavBarItems} from "../../../Components/Navigation/SubNavBar";
import InstituteAnalytics from "./Analytics";
import InstituteSetup from "./Setup";
import InstituteUsers from "./Users";

export default function Institute() {
    const items:SubNavBarItems = [
        {
            route: "users",
            label: "Users",
            element: <InstituteUsers/>,
        },
        {
            route: "analytics",
            label: "Analytics",
            element: <InstituteAnalytics/>,
        },
        {
            route: "setup",
            label: "Setup",
            element: <InstituteSetup/>,
        },
    ];

    return (<SubNavBar items={items} pathLevel={3}/>);
}
