import {Box, Chip, Icon, List, ListItemButton, ListItemSecondaryAction, MenuItem, Popover, Stack, Typography} from "@mui/material";
import IconButtonPop from "./IconButtonPop";
import {ArrowForward, Check, Close, Tune} from "@mui/icons-material";
import {ChangeEvent, RefObject, createRef, useEffect, useState} from "react";
import Dropdown from "./FormComponents/Dropdown";
import InputGroup from "./FormComponents/InputGroup";
import {useNavigate, useSearchParams} from "react-router-dom";
import {FilterObject} from "./FilterTable";
import {QueryFieldFilterConstraint, QueryOrderByConstraint} from "firebase/firestore";
import {capitaliseWords} from "placementt-core";


type Params = {
    filters: FilterObject,
    urlRef?: string
}

const getFilterLabelsFromObject = (values?: {
    [key: string|number]: string | {
        label: string;
        test: QueryFieldFilterConstraint|(QueryFieldFilterConstraint|QueryOrderByConstraint)[];
    }}) => values ? Object.fromEntries(Object.entries(values).map(([name, value]) => ([name, typeof value === "string" ? value : value.label]))) : undefined;

export default function FilterSelector({filters, urlRef}: Params) {
    const [filtersOpen, setFiltersOpen] = useState<Element>();
    const [selectedFilters, setSelectedFilters] = useState<{[key: string]: {label: string, open: boolean, disabled?: boolean, value?: unknown, type?: string, values?: {[key: string|number]: string}, ref: RefObject<HTMLDivElement>}}>();
    const [filterOpen, setFilterOpen] = useState<string>();
    const [filterRefs, setFilterRefs] = useState<{[key: string]: RefObject<HTMLDivElement>}>();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const fFilters = Object.fromEntries([...searchParams]);
        const filterElementId = fFilters.id;
        delete fFilters.id;

        const providedFilters = Object.fromEntries(Object.entries(fFilters || {}).map((([name, item]) => {
            const value = filters?.[name]?.value || filterElementId === urlRef ? item : undefined;
            const providedItem = filters[name];
            if (!value) return [];

            return [name, {
                label: providedItem?.label || capitaliseWords(name),
                open: false,
                disabled: !providedItem,
                value: value,
                type: providedItem?.type,
                values: providedItem?.values ? getFilterLabelsFromObject(providedItem.values) : undefined,
                ref: createRef(),
            }];
        })).filter((x) => x.length));

        if (!providedFilters) return;
        setSelectedFilters(providedFilters);
    }, [filters]);

    useEffect(() => {
        if (!selectedFilters) return;
        setFilterRefs(() => {
            // all current refs
            const finalRefs:{[key:string]: RefObject<HTMLDivElement>} = {};
            Object.entries(selectedFilters).forEach(([k, v]) => {
                finalRefs[k] = v.ref;
            });

            return finalRefs;
        });
    }, [selectedFilters]);

    const handleFilterButtonClick = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (event.detail === 0) return;
        setFiltersOpen(event.currentTarget);
    };

    const submitFilters = (e: {[name: string]: {value?: unknown}}) => {
        // onFilterChange && onFilterChange(Object.fromEntries(Object.entries(e || {}).map(([k, v]) => [k, v.value])));

        const query = Object.entries(e).reduce((acc, [name, item]) => {
            acc.push(`${name}=${item.value}`);
            return acc;
        }, [] as Array<string>);

        urlRef && query.unshift(`id=${urlRef}`);

        navigate(`?${query.join("&")}`);
        setFilterOpen(undefined);
    };

    return (
        <Stack direction={"row"} alignItems={"center"} height={"min-content"}>
            {selectedFilters && Object.entries(selectedFilters).map(([key, filter]) =>
                <>
                    <Chip ref={filter.ref} key={key} onClick={() => setFilterOpen(key)} label={filter.label} variant={filterOpen === key ? "filled" : "outlined"} color="primary" aria-describedby={"filterPopup"+key}/>
                    <Popover
                        open={Boolean(filterOpen === key && filterRefs?.[key]?.current)}
                        onClose={() => setFilterOpen(undefined)}
                        id={"filterPopup"+key}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        sx={{marginTop: 1}}
                        anchorEl={filter.ref.current}>
                        <Stack direction={"row"} spacing={0} p={1}>
                            {filter.type === "dropdown" ?
                                <Dropdown label={"Filter"} sx={{minWidth: "150px"}} value={selectedFilters[key].value as string} onChange={(e) => setSelectedFilters((s) => ({...s, [key]: {...(s || {})[key], value: e.target.value}}))}>
                                    {filter.values && Object.entries(filter.values).map(([name, label]) => <MenuItem value={name}>{label}</MenuItem>)}
                                </Dropdown> :
                                filter.disabled || <InputGroup placeholder={"Filter"} value={selectedFilters[key].value} onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectedFilters((s) => ({...s, [key]: {...(s || {})[key], value: e.target.value}}))}/>}
                            {filter.disabled || <IconButtonPop responsive={false} title="Go" onClick={() => submitFilters(selectedFilters)}><Check/></IconButtonPop>}
                            <IconButtonPop responsive={false} title="Remove" onClick={() => {
                                submitFilters(Object.fromEntries(Object.entries(selectedFilters || {}).filter(([k]) => k !== key)));
                            }}><Close/></IconButtonPop>
                        </Stack>
                    </Popover>
                </>)}
            {filters && <IconButtonPop aria-describedby={"filterPopup"} title={`Filters ${Object.keys(selectedFilters || {}).length ? `(${Object.keys(selectedFilters || {}).length})` : ""}`} onClick={(e) => handleFilterButtonClick(e)}><Tune/></IconButtonPop>}
            {filters && <Popover
                open={Boolean(filtersOpen)}
                onClose={() => setFiltersOpen(undefined)}
                id={"filterPopup"}
                anchorEl={filtersOpen}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box sx={{minWidth: "200px"}}>
                    <Typography p={2} pb={0}>Filter items</Typography>
                    <List>
                        {Object.entries(filters).map(([filterName, obj]) =>
                            <ListItemButton onClick={() => {
                                setSelectedFilters((f) => ({...f, [filterName]: {label: obj.label, open: true, type: obj.type, values: getFilterLabelsFromObject(obj.values), ref: createRef()}}));
                                setFilterOpen(filterName);
                                setFiltersOpen(undefined);
                            }}>
                                <Chip label={obj.label} color={(selectedFilters && selectedFilters[filterName]) && "primary"}/>
                                <ListItemSecondaryAction>
                                    {selectedFilters && selectedFilters[filterName] ?
                                        <IconButtonPop responsive={false} title="Remove filter" onClick={(e) => {
                                            setFiltersOpen(undefined);
                                            submitFilters(Object.fromEntries(Object.entries(selectedFilters || {}).filter(([k]) => k !== filterName)));
                                            e.stopPropagation();
                                        }}>
                                            <Close/>
                                        </IconButtonPop> :
                                        <Icon sx={{verticalAlign: "middle", opacity: 0.5}}>
                                            <ArrowForward/>
                                        </Icon>}
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        )}
                    </List>
                </Box>
            </Popover>}
        </Stack>
    );
}
