import {Box, Button, Divider, Grid, keyframes, List, ListItem, ListItemIcon, ListItemText, Stack, Typography} from "@mui/material";
import Page from "../../Components/Page";
import styled from "styled-components";
import PublicTemplate from "../../Components/Public/PublicTemplate";
import {Analytics} from "@mui/icons-material";

const gradient = keyframes`
    to {
        background-position: 400% 0;
    }
`;


const GradientText = styled("span")`
background: linear-gradient(205deg,
    hsl(240deg 100% 20%) 0%,
    hsl(301deg 100% 19%) 11%,
    hsl(329deg 100% 30%) 20%,
    hsl(344deg 100% 37%) 29%,
    hsl(3deg 98% 41%) 38%,
    hsl(344deg 100% 37%) 48%,
    hsl(329deg 100% 30%) 60%,
    hsl(301deg 100% 19%) 74%,
    hsl(240deg 100% 20%) 100%) 0 0 / 400% 100%;
background-clip: text;
color: transparent;
@media (prefers-reduced-motion: no-preference) {
    animation: ${gradient} 30s linear infinite;
}
`;

export default function UniversityInfo() {
    return (
        <Page
            metaTitle="Placementt | Universities"
            metaDesc=""
        >
            <PublicTemplate>
                <Stack width={"100%"} mb={"-64px !important"} mt={"-32px !important"}>
                    <Typography color={"primary"} fontWeight={"bold"}>Placementt for Universities</Typography>
                    <Divider/>
                </Stack>
                <Stack spacing={5} borderBottom={"1px solid lightgrey"} pt={20} pb={10} textAlign={"center"} maxWidth={"1000px"} alignSelf={"center"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant="h2" fontWeight={500} maxWidth={"800px"}><GradientText>Engage and Inspire</GradientText> Students Across Your Course.</Typography>
                    <Typography variant="h5">Deploy a suite of communication tools supporting students on long term placements, motivating and engaging your students, and streamlining your placements for university staff.</Typography>
                    <Button><Typography variant="h5"><GradientText>Get in touch</GradientText></Typography></Button>
                </Stack>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Divider/>
                            <Typography variant="h4">Engaging your Students</Typography>
                            <Typography>Intuitive Communication Tools, Tailored To You</Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Set up student skills assessments for before, during and after placements to view live feedback from employers and students."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Set student targets alongside employers and monitor progression of your students through year-in-industry placements."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Assign tutors to specific students, automatically schedule check-ins and visits and set student reminders through our mobile app."}/>
                                </ListItem>
                            </List>
                            <Typography></Typography>
                            <Button onClick={() => null} color="institutes">Get in Touch</Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"}>
                        <Box component={"img"} borderRadius={1} boxShadow={3} width={"100%"} alt={""} src={""}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"}>
                        <Box component={"img"} display={"flex"} borderRadius={1} boxShadow={3} width={"100%"} alt={""} src={"instituteMap"}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Typography color={"primary"}>Enhance Employer Communication</Typography>
                            <Divider/>
                            <Typography variant="h4">Foster stronger connections with employers.</Typography>
                            <Typography>Develop relationships with employers with comprehensive data on student feedback and engagement with the university.</Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon><Analytics/></ListItemIcon>
                                    <ListItemText primary={"View detailed analytics on previous placements, including feedback from students and employers."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Engage employers outside of placements, using Placementt to facilitate events management."}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon></ListItemIcon>
                                    <ListItemText primary={"Develop customised assessments for employers to deepen your understanding of their impact on the student experience."}/>
                                </ListItem>
                            </List>
                            <Typography></Typography>
                            <Button onClick={() => null} color="institutes">Get in Touch</Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography textAlign={"center"} variant="h5" mt={3}>Placementt for Universities is currently in development. We are excited to work with universities to tailor our platform to your needs. If you think we could benefit you, <a href={"mailto:tom@placementt.co.uk"}>get in touch</a>.</Typography>
                    </Grid>
                </Grid>
            </PublicTemplate>
        </Page>
    );
}
