import {Button, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Page from "../../../Components/Page";
import {Popup} from "../../../Components/Popup";
import {useContext, useEffect, useState} from "react";
import Form from "../../../Components/Form";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import {CohortData, InstituteData, UserData, auth, executeCallable} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import IconButtonPop from "../../../Components/IconButtonPop";
import {ArrowForward} from "@mui/icons-material";
import {where} from "firebase/firestore";
import {signInWithCustomToken} from "firebase/auth";
import {LoadingButton} from "../../../Components/LoadingButton";
import {UserContext} from "../../../App";

export default function InstituteUsers() {
    const firebaseQuery = new FirebaseQuery();

    const [addInstitutePopup, setAddInstitutePopup] = useState(false);
    const [viewInstitutePopup, setViewInstitutePopup] = useState<[string, {institute: InstituteData, admin: UserData}]>();

    const [institutes, setInstitutes] = useState<[string, {institute: InstituteData, admin: UserData}][]>([]);
    const user = useContext(UserContext);

    const sendInvite = async (e: {[key:string]: unknown}) => {
        return await executeCallable("registration-inviteInstitute", e);
    };

    const signInAsUser = async (id: string) => {
        if (user.product !== "admin") return;
        const userToken = await executeCallable("signInAsUser", {uid: id});
        signInWithCustomToken(auth, userToken.data as string);
    };

    useEffect(() => {
        firebaseQuery.getDocsWhere(["institutes"]).then(async (data) => {
            const newData = await Promise.all(Object.entries(data as {[oId: string]: InstituteData}).map(async ([oId, institute]) => {
                const adminUser = await firebaseQuery.getDocData(["users", institute.admin]) as UserData;

                return [oId, {institute: institute, admin: adminUser}] as [string, {institute: InstituteData, admin: UserData}];
            }));

            setInstitutes(newData);
            return;
        });
    }, []);

    console.log("in", institutes);

    return (
        <Page title="Institute Users" titleSecondary={<Stack direction={"row"} alignItems={"center"}><Form onSubmit={(e) => signInAsUser(e.uid as string)} button={false}><InputGroup name={"uid"} label={"Sign in with uid"}/></Form><Button variant="contained" onClick={() => setAddInstitutePopup(true)}>Invite institute</Button></Stack>}>
            <Table>
                <TableHead>
                    <TableCell>Name</TableCell>
                    <TableCell>Admin name</TableCell>
                    <TableCell>Admin email</TableCell>
                    <TableCell></TableCell>
                </TableHead>
                {institutes.map((data) =>
                    <TableRow>
                        <TableCell>{data[1].institute.name}</TableCell>
                        <TableCell>{data[1].admin.details?.forename} {data[1].admin.details?.surname}</TableCell>
                        <TableCell>{data[1].admin.email}</TableCell>
                        <TableCell>
                            <IconButtonPop title="View" onClick={() => setViewInstitutePopup(data)}>
                                <ArrowForward/>
                            </IconButtonPop>
                        </TableCell>
                    </TableRow>
                )}
            </Table>
            <Popup open={addInstitutePopup} title={"Invite institute"} onClose={() => setAddInstitutePopup(false)}>
                <Form onSubmit={sendInvite}>
                    <InputGroup name={"forename"} label={"Forename"} required/>
                    <InputGroup name={"surname"} label={"Surname"} required/>
                    <InputGroup name={"email"} label={"Email"} required/>
                </Form>
            </Popup>
            <Popup maxWidth={"lg"} fullWidth title={viewInstitutePopup ? viewInstitutePopup[1].institute.name : ""} open={Boolean(viewInstitutePopup)} onClose={() => setViewInstitutePopup(undefined)}>
                {viewInstitutePopup && <ViewInstitute oId={viewInstitutePopup[0]} institute={viewInstitutePopup[1].institute} admin={viewInstitutePopup[1].admin}/>}
            </Popup>
        </Page>
    );
}

function ViewInstitute({oId, institute, admin}: {oId: string, institute: InstituteData, admin: UserData}) {
    const [cohorts, setCohorts] = useState<{[cohortId: string]: CohortData&{count: number}}>();
    const [staffNum, setStaffNum] = useState<number>();

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);

    const signInAsUser = async (id: string) => {
        if (user.product !== "admin") return;
        const userToken = await executeCallable("signInAsUser", {uid: id});
        signInWithCustomToken(auth, userToken.data as string);
    };

    useEffect(() => {
        firebaseQuery.getCount("users", [where("product", "==", "institutes"), where("oId", "==", oId), where("userType", "==", "Staff")]).then(setStaffNum);
        firebaseQuery.getDocsWhere(["cohorts"], [where("product", "==", "institutes"), where("oId", "==", oId)]).then(async (cohorts) => {
            const newData = Object.fromEntries(await Promise.all(Object.entries(cohorts as {[cohortId: string]: CohortData}).map(async ([cohortId, cohort]) => {
                const studentCount = await firebaseQuery.getCount(["users"], [where("product", "==", "institutes"), where("oId", "==", oId), where("cohort", "==", cohortId)]);

                return [cohortId, {...cohort, count: studentCount}];
            })));
            setCohorts(newData as {[cohortId: string]: CohortData&{count: number}});
        });
    }, [institute]);

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <LoadingButton text="Sign in as admin" onClick={() => signInAsUser(admin.id)}/>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Created</TableCell>
                            <TableCell>{admin.created}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>{institute["address-line1"]}, {institute.locality}, {institute.postal_code}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Admin name</TableCell>
                            <TableCell>{admin.details.forename} {admin.details.surname}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Admin email</TableCell>
                            <TableCell>{admin.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Cohorts</TableCell>
                            <TableCell>{cohorts ? Object.keys(cohorts).length : "Loading"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Staff</TableCell>
                            <TableCell>{staffNum !== undefined ? staffNum : "Loading"}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
}
