import {Alert, Box, Button, Stack} from "@mui/material";
import {ButtonPropsColorOverrides} from "@mui/material/Button/Button";
import {OverridableStringUnion} from "@mui/types";
import {StudentPlacementData, executeCallable} from "placementt-core";
import {ChangeEvent, useState} from "react";
import {Popup} from "./Popup";
import InputGroup from "./FormComponents/InputGroup";
import Form from "./Form";
import {LoadingButton} from "./LoadingButton";

export default function ReportPlacementPopup({placement, buttonColor}: {placement: StudentPlacementData, buttonColor?: OverridableStringUnion<"inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning", ButtonPropsColorOverrides>}) {
    const [popupActive, setPopupActive] = useState(false);
    const [confirmPopupActive, setConfirmPopupActive] = useState(false);

    const [reason, setReason] = useState<string>();

    const submitReportedPlacement = async () => {
        await executeCallable("placement-report", {id: placement.id, reason: reason});
    };

    return (
        <Box onClick={(e) => e.stopPropagation()} width={"max-content"}>
            <Button color={buttonColor} onClick={() => setPopupActive(true)}>Report placement</Button>
            <Popup title={"Report this placement"} open={popupActive} onClose={() => setPopupActive(false)}>
                <Alert severity="warning">Warning: Reporting this placement will contact your institute and open an investigation into this employer.</Alert>
                <Form onSubmit={() => setConfirmPopupActive(true)} functionType="sync" submitText="Submit report">
                    <InputGroup minRows={4} label={"Reason"} required placeholder={"Add as much detail as possible."} value={reason} onChange={(e: ChangeEvent<HTMLInputElement>) => setReason(e.target.value)}/>
                </Form>
            </Popup>
            <Popup title={"Confirm report submission"} open={confirmPopupActive} onClose={() => setConfirmPopupActive(false)}>
                <Stack>
                    <Alert severity="error">Only submit a report for serious issues relating to your physical or mental safety.</Alert>
                    <LoadingButton color="error" onClick={async () => await submitReportedPlacement().then(() => {
                        setConfirmPopupActive(false);
                        setPopupActive(false);
                    })} text="Submit report"/>
                </Stack>
            </Popup>
        </Box>);
}
