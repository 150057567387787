import {useContext, useEffect, useState} from "react";
import FilterList from "./FilterList";
import {OrganisationContext, UserContext} from "../App";
import {CohortData, TaskQueryReturnObject, getTasks} from "placementt-core";
import {AlertTitle, Button, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {NavigateFunction, useNavigate} from "react-router-dom";
import IconButtonPop from "./IconButtonPop";
import {ArrowForwardOutlined, CancelOutlined, CheckCircleOutline, ErrorOutline, InfoOutlined, WarningOutlined} from "@mui/icons-material";
import Alert from "./Alert";

type Params = {
    cohort?: CohortData,
    showAlert?: boolean,
    grid?: boolean,
    xs?: number,
    sm?: number,
    md?: number,
    lg?: number
}

export default function Tasks({cohort, showAlert=true, grid, xs, sm, md, lg}:Params) {
    const [tasks, setTasks] = useState<TaskQueryReturnObject[]>();
    const user = useContext(UserContext);
    const institute = useContext(OrganisationContext);

    const navigate = useNavigate();

    useEffect(() => {
        getTasks(user, institute.details, cohort).then(setTasks);
    }, []);

    console.log("TASKS", tasks);

    return (
        <FilterList sx={{height: "500px"}} loadMoreIcon={"loaded"} data={Object.fromEntries((tasks || []).map((t) => [t?.itemName, t]))} title="Tasks" {...{grid, xs, sm, md, lg}}>
            {renderTipsAndTasksList(navigate, showAlert, tasks)}
        </FilterList>
    );
}


export const renderTipsAndTasksList = (navigate: NavigateFunction, showAlert: boolean, items?: TaskQueryReturnObject[]) => {
    const renderLeadingIcon = (severity: "error"|"success"|"warning"|"primary"|"info") => {
        switch (severity) {
        case "error":
            return <ErrorOutline color="error"/>;
        case "info":
            return <InfoOutlined color="info"/>;
        case "primary":
            return <ArrowForwardOutlined color="primary"/>;
        case "success":
            return <CheckCircleOutline color="success"/>;
        case "warning":
            return <WarningOutlined color="warning"/>;
        }
    };
    if (!items) return null;

    const renderAlert = (task: TaskQueryReturnObject) => (
        <ListItem id={task?.itemName} key={task?.itemName}>
            <Alert
                sx={{width: "100%"}}
                severity={task?.severity}
                action={task?.link && (task.buttonTitle ? <Button color={"inherit"} onClick={() => navigate(task.link as string)}>{task.buttonTitle}</Button> : <IconButtonPop responsive={false} title="Go to page" onClick={() => navigate(task.link as string)}><ArrowForwardOutlined color={task.severity}/></IconButtonPop>)}>
                <AlertTitle>{task?.title}</AlertTitle>
                {task?.message}
            </Alert>
        </ListItem>);

    const renderListItem = (task: TaskQueryReturnObject) => (<ListItem id={task?.itemName} divider>
        <ListItemIcon>
            {renderLeadingIcon(task?.severity || "primary")}
        </ListItemIcon>
        <ListItemText secondaryTypographyProps={{whiteSpace: "normal", paddingRight: 18}} primaryTypographyProps={{whiteSpace: "normal", paddingRight: 18}} primary={task?.title} secondary={task?.message}/>
        <ListItemSecondaryAction>
            {task?.link && (task.buttonTitle ? <Button color={"inherit"} onClick={() => navigate(task.link as string)}>{task.buttonTitle}</Button> : <IconButtonPop responsive={false} title="Go to page" onClick={() => navigate(task.link as string)}><ArrowForwardOutlined color={task.severity}/></IconButtonPop>)}
            {task?.dismissible && <IconButtonPop title="Dismiss"><CancelOutlined/></IconButtonPop>}
        </ListItemSecondaryAction>
    </ListItem>);

    return items.map((task) => showAlert ? renderAlert(task) : renderListItem(task));
};
