import {Stack, Tab, TabProps, Tabs} from "@mui/material";
import {ReactNode, useEffect, useState} from "react";
import {Link, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";

export type SubNavBarItems = ({
    label ?: string|ReactNode,
    route: string,
    element: ReactNode,
    divider?: boolean,
})[]

type Params = {
    items: SubNavBarItems,
    pathLevel?: number,
    leadingItem?: ReactNode,
    trailingItem?: ReactNode
}

const TabStack = styled(Tab)<TabProps&{to: string, divider?: boolean}>`
    ::after {
        content: "";
        position: absolute;
        height: 60%;
        width: 1px;
        background-color: #00000040;
        right: 0;
        display: ${(props) => props.divider ? "block" : "none"};
    }
`;

export default function SubNavBar({items, pathLevel=3, leadingItem, trailingItem}:Params) {
    const location = useLocation();
    const navigate = useNavigate();

    const route = location.pathname.split("/")[pathLevel];

    const activeItem = items.filter((i) => i.label).findIndex((item) => item.route === route);

    const [value, setValue] = useState(activeItem > -1 ? activeItem : 0);

    const handleChange = (_e:any, newValue:number) => {
        setValue(newValue);
    };

    useEffect(() => {
        const route = location.pathname.split("/")[pathLevel];
        console.log("ROUTE CHANGE", `(${pathLevel})`, route);
        const activeItem = items.filter((i) => i.label).findIndex((item) => item.route === route);
        console.log("ACTIVE ITEM", `(${pathLevel})`, route);

        setValue(activeItem);
        if (route) return;

        if (value) {
            navigate(items[value]?.route || items[0]?.route);
            return;
        }
        console.log("NAVIGATE TO FIRST ITEM", `(${pathLevel})`, items);
        navigate(items[0].route);
        setValue(0);
    }, [location]);

    return (
        <Stack overflow={"auto"} flex={1} spacing={0} height={"max-content"} minHeight={"100%"}>
            <Stack direction={"row"} width={"100%"} alignItems={"center"} sx={{borderBottom: "1px solid rgba(0, 0, 0, 0.3)", pl: leadingItem ? 2 : 0, pr: trailingItem ? 2 : 0}}>
                {leadingItem}
                <Tabs
                    value={value}
                    sx={{flex: 1, borderBottom: "none"}}
                    onChange={handleChange}
                    aria-label="Navigation">
                    {items.filter((item) => item.label).map((item) => <TabStack label={item.label} component={Link} to={item.route} divider={item.divider}/> )}
                </Tabs>
                {trailingItem}
            </Stack>
            <Stack flex={1} height={"100%"}>
                <Routes>
                    {items.map((item) => <Route path={item.route} element={item.element}><Route path={"*"} element={item.element}/></Route>)}
                </Routes>
            </Stack>
        </Stack>
    );
}
