/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {Box, CardContent, Collapse, Divider, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import Form from "../../../Components/Form";
import ControlledSwitch from "../../../Components/FormComponents/ControlledSwitch/ControlledSwitch";
import {ToggleFormItem} from "../../../Util/styledComponents";
import {ProviderUserGroup, StaffUserGroup, UserGroupData} from "placementt-core";
import Card from "../../../Components/Card";
import Alert from "../../../Components/Alert";
import OptionalSection from "../../../Components/FormComponents/OptionalSection";


type Params = {
    formData?: ProviderUserGroup,
    onSubmit?: (e: any|undefined) => void,
    access?: boolean,
    id?: string
}

export default function ProviderUserGroupForm({formData, onSubmit, access, id}: Params) {
    const [name, setName] = useState(formData?.name || "");
    const [nameDisabled, setNameDisabled] = useState(false);
    const [fFormData, setFFormData] = useState<Partial<ProviderUserGroup>>(formData || {});

    useEffect(() => {
        if (!formData || Object.keys(formData).length === 0) {
            return;
        }
        setGroupData(formData);
    }, [formData]);

    const setGroupData = (group: UserGroupData) => {
        setName(group.name);
        if (group.name === group.template) {
            setNameDisabled(true);
        }
    };

    if (!formData) return null;

    return (
        <Form onSubmit={() => onSubmit && onSubmit(fFormData)} sx={{alignSelf: "center", width: "600px", maxWidth: "100%"}}>
            <Card key={"name-card"}>
                <CardContent>
                    <Stack spacing={2}>
                        {nameDisabled ? <Typography variant="h4">{name}</Typography> : <InputGroup label="Group name" value={name} name={"name"} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)} required disabled={nameDisabled || !access}/>}
                    </Stack>
                </CardContent>
            </Card>
            <Stack key={"form-card"} style={{alignSelf: "center", width: "600px", maxWidth: "100%"}}>
                <StaffForm setFormData={setFFormData} formData={fFormData as StaffUserGroup} access={access || false}/>
            </Stack>
        </Form>
    );
}

type StaffFormParams = {
    formData: ProviderUserGroup,
    access: boolean,
    setFormData: Dispatch<SetStateAction<Partial<ProviderUserGroup>>>
}
function StaffForm({formData, access, setFormData}: StaffFormParams) {
    return (
        <Stack spacing={2}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Box>
                    <Typography>Create placement listings</Typography>
                    {formData.createPlacementListing === "template" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can create placements from a template, associated with their addresses.</Typography>}
                    {formData.createPlacementListing === "any" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can create placements from scratch, associated with their addresses.</Typography>}
                    {formData.createPlacementListing === "none" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff cannot create placement listings</Typography>}
                </Box>
                <ToggleButtonGroup value={formData?.createPlacementListing} onChange={(_, v) => v && setFormData((u) => ({...u, createPlacementListing: v}))} exclusive color="primary">
                    <ToggleButton value={"any"}>Yes</ToggleButton>
                    <ToggleButton sx={{minWidth: "max-content"}} value={"template"}>From template</ToggleButton>
                    <ToggleButton value={"none"}>No</ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Box>
                    <Typography>View placement listings</Typography>
                    {formData.viewPlacementListings === "all" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can view all placements listings.</Typography>}
                    {formData.viewPlacementListings === "request" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can request to view any placement listings.</Typography>}
                    {formData.viewPlacementListings === "none" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff cannot view any placement listings.</Typography>}
                </Box>
                <ToggleButtonGroup value={formData?.viewPlacementListings} onChange={(_, v) => v && setFormData((u) => ({...u, viewPlacementListings: v}))} exclusive color="primary">
                    <ToggleButton value={"all"}>All</ToggleButton>
                    <ToggleButton value={"request"}>Request access</ToggleButton>
                    <ToggleButton value={"none"}>None</ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Collapse in={formData.viewPlacementListings === "request" && formData.viewAddresses === "all"}>
                <Alert severity="primary">Staff will request specific access to view placement listings. Once granted by a Super Admin, they can view all associated applicants and placements.</Alert>
            </Collapse>
            <Collapse in={formData.viewPlacementListings === "none"}>
                <Alert severity="warning">Staff will not be able to view any listings or associated placements students are attending.</Alert>
            </Collapse>
            <Divider/>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Box>
                    <Typography>View addresses</Typography>
                    {formData.viewAddresses === "all" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can view all addresses.</Typography>}
                    {formData.viewAddresses === "request" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can request to view any addresses.</Typography>}
                    {formData.viewAddresses === "none" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff cannot view any addresses, or associated placement listings.</Typography>}
                </Box>
                <ToggleButtonGroup value={formData?.viewAddresses} onChange={(_, v) => v && setFormData((u) => ({...u, viewAddresses: v}))} exclusive color="primary">
                    <ToggleButton value={"all"}>All</ToggleButton>
                    <ToggleButton value={"request"}>Request access</ToggleButton>
                    <ToggleButton value={"none"}>None</ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Collapse in={formData.viewAddresses === "none" && formData.viewPlacementListings !== "none"}>
                <Alert severity="error">Setting "View addresses" to none will prevent users from seeing any placement listings. If they would like access, they will have to request it directly.</Alert>
            </Collapse>
            <Collapse in={formData.viewAddresses === "request" && formData.viewPlacementListings !== "none"}>
                <Alert severity="primary">When a staff member first logs in, we will allow them to select addresses {formData.viewPlacementListings === "request" ? "and placement listings " : ""} they would like to view. They can then view associated applications and placements.</Alert>
            </Collapse>
            {false && <ToggleFormItem>
                <Box>
                    <Typography>Create placement groups</Typography>
                    <Typography variant="subtitle2" sx={{opacity: 0.75}}>Allow staff to create groups of placements.</Typography>
                </Box>
                <ControlledSwitch id={"processApplications"} name={"processApplications"} onCheck={(_, c) => setFormData((u) => ({...u, processApplicants: c}))} defaultChecked={false}/>
            </ToggleFormItem>}
            {false && <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Box>
                    <Typography>View placement groups</Typography>
                    {formData.viewPlacementGroups === "all" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can view all placements groups.</Typography>}
                    {formData.viewPlacementGroups === "request" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can request to view any placement groups.</Typography>}
                    {formData.viewPlacementGroups === "none" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff cannot view any placement groups.</Typography>}
                </Box>
                <ToggleButtonGroup value={formData?.viewPlacementGroups} onChange={(_, v) => v && setFormData((u) => ({...u, viewPlacementGroups: v}))} exclusive color="primary">
                    <ToggleButton value={"any"}>All</ToggleButton>
                    <ToggleButton value={"request"}>Request access</ToggleButton>
                    <ToggleButton value={"none"}>None</ToggleButton>
                </ToggleButtonGroup>
            </Stack>}
            <Divider/>
            <ToggleFormItem>
                <Box>
                    <Typography>Process student applications</Typography>
                    <Typography variant="subtitle2" sx={{opacity: 0.75}}>Allow staff to process applications on their placement listings.</Typography>
                </Box>
                <ControlledSwitch id={"processApplications"} name={"processApplications"} onCheck={(_, c) => setFormData((u) => ({...u, processApplicants: c}))} checked={formData.processApplicants}/>
            </ToggleFormItem>
            <Divider/>
            <OptionalSection label={"Manage staff"} formData={formData} disabled={!access}>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Add staff</Typography>
                        <ControlledSwitch id={"addStaff"} name={"addStaff"} onCheck={(_, c) => setFormData((u) => ({...u, addStaff: c}))} checked={formData["addStaff"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Activate staff</Typography>
                        <ControlledSwitch id={"activateStaff"} name={"activateStaff"} onCheck={(_, c) => setFormData((u) => ({...u, activateStaff: c}))} checked={formData["activateStaff"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Edit staff</Typography>
                        <ControlledSwitch id={"editStaff"} name={"editStaff"} onCheck={(_, c) => setFormData((u) => ({...u, editStaff: c}))} checked={formData["editStaff"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
                <Grid item xs={12}>
                    <ToggleFormItem>
                        <Typography>Delete staff</Typography>
                        <ControlledSwitch id={"deleteStaff"} name={"deleteStaff"} onCheck={(_, c) => setFormData((u) => ({...u, deleteStaff: c}))} checked={formData["deleteStaff"] || false} disabled={!access}/>
                    </ToggleFormItem>
                </Grid>
            </OptionalSection>
            <Grid item xs={12}>
                <ToggleFormItem>
                    <Typography>View billing</Typography>
                    <ControlledSwitch id={"viewBilling"} name={"viewBilling"} onCheck={(_, c) => setFormData((u) => ({...u, viewBilling: c}))} checked={formData["viewBilling"] || false} disabled={!access}/>
                </ToggleFormItem>
            </Grid>
            <Grid item xs={12}>
                <ToggleFormItem>
                    <Typography>Manage billing</Typography>
                    <ControlledSwitch id={"manageBilling"} name={"manageBilling"} onCheck={(_, c) => setFormData((u) => ({...u, manageBilling: c}))} checked={formData["manageBilling"] || false} disabled={!access}/>
                </ToggleFormItem>
            </Grid>
        </Stack>
    );
}
