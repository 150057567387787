import ListContentViewer from "../../Components/ListContentViewer";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import {UserContext} from "../../App";
import SlateEditor from "../../Components/Slate";
import {Button, Stack} from "@mui/material";
import InputGroup from "../../Components/FormComponents/InputGroup";
import {Delete} from "@mui/icons-material";
import IconButtonPop from "../../Components/IconButtonPop";
import {Note, UserData, editNestedObject} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {where} from "firebase/firestore";

export default function Notes({standalone=false}) {
    const [notes, setNotes] = useState<{[key:string]: Note}>();

    const user = useContext(UserContext) as UserData;

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        firebaseQuery.getDocsWhere(["notes"], [where("uid", "==", user.id)]).then((n) => n ? setNotes(Object.fromEntries(Object.entries(n as {[key:string]: Note}).sort(([, a], [, b]) => -a.updated.localeCompare(b.updated)))) : null);
    }, []);

    const updateNotes = (location:string[], value: any) => {
        setNotes((prev) => {
            if (!prev) return;
            const newNotes = editNestedObject(location, prev, value) as {[key:string]: Note};
            const withEditedDate = editNestedObject([location[0], "updated"], newNotes, (new Date()).toISOString()) as {[key:string]: Note};

            return (Object.fromEntries(Object.entries(withEditedDate).sort(([, a], [, b]) => -a.updated.localeCompare(b.updated))));
        });
    };

    const createNote = async () => {
        const data = {
            created: (new Date()).toISOString(),
            updated: (new Date()).toISOString(),
            uid: user.id,
        };
        await firebaseQuery.add(["notes"], data).then((ref) => {
            setNotes((n) => ({...(n ? n : {}), [ref.id]: data}));
        });
    };

    const uploadNote = async (id:string) => {
        if (!notes) return;
        await firebaseQuery.update(["notes", id], notes[id]);
    };

    const renderNote = (id:string, item:{[key:string]: unknown}) => {
        const note = item as Note;
        if (!note) return null;
        return (
            <Stack height={"100%"}>
                <Stack direction={"row"} sx={{marginTop: "2px"}}>
                    <InputGroup placeholder={"Untitled"} value={note.title} onChange={(e: ChangeEvent<HTMLInputElement>) => updateNotes([id, "title"], e.target.value)} onBlur={() => uploadNote(id)}/>
                    <IconButtonPop responsive={false} title={"Delete"} onClick={() => firebaseQuery.delete(["notes", id]).then(() => setNotes((notes) => editNestedObject([id], notes as {[key:string]: unknown}, undefined) as {[key:string]: Note}))}><Delete/></IconButtonPop>
                </Stack>
                <SlateEditor key={id} initialValue={note.body} onChange={(value) => updateNotes([id, "body"], value)} onSave={async () => await uploadNote(id)}/>
            </Stack>
        );
    };

    return (
        <ListContentViewer title={"Personal notes"} listItems={notes} url={"../notes"} contentTemplate={renderNote} {...{standalone}}>
            <Button sx={{position: "absolute", bottom: "10px", right: "20px", zIndex: 5}} onClick={createNote}>New</Button>
        </ListContentViewer>
    );
}
