import {Stack, Typography} from "@mui/material";
import FileDropzone from "./FileDropzone/FileDropzone";
import InputGroup from "./FormComponents/InputGroup";
import Form from "./Form";
import {Popup} from "./Popup";


type Params = {
    accept?: string,
    message?: string,
    onClose: () => void,
    open: boolean,
    name?: boolean,
    onSubmit: (e: any) => Promise<any>|any,
    multiple?: boolean,
    title?: string
}

export default function FileUploaderPopup({accept, onClose, title="Add file", open, onSubmit, name, message, multiple}:Params) {
    return (
        <Popup key={"orgFileUploader"} {...{open, onClose, title}}>
            <Form onSubmit={(files) => onSubmit(files)}>
                <Stack alignContent={"center"} textAlign={"center"} spacing={2}>
                    {message && <Typography variant={"body1"} maxWidth={"550px"}>
                        {message}
                    </Typography>}
                    {name && <InputGroup name={"name"} label="File name" required/>}
                    <FileDropzone name="file" accept={accept === "any" ? undefined : accept} multiple={multiple} key={"orgFileUploaderDropzone"}/>
                </Stack>
            </Form>
        </Popup>
    );
}
