import {AlertColor, Alert as MuiAlert} from "@mui/material";
import {ReactNode} from "react";
import styled from "styled-components";

const StyledAlert = styled(MuiAlert)<{$primary: boolean}>`
    .MuiAlert-icon {
        color: ${(props) => props.$primary ? "#6925c8" : undefined}
    }
`;

export default function Alert(props:{severity?: string, children?: ReactNode, sx?: {[key: string]: unknown}, action?: ReactNode, [key: string]: unknown}) {
    return (
        <StyledAlert
            $primary={props.severity === "primary"}
            {...props}
            severity={props.severity === "primary" ? "info" : props.severity as AlertColor}
            sx={{
                color: props.severity === "primary" ? "#6925c8" : undefined,
                backgroundColor: props.severity === "primary" ? "#7920f522" : undefined,
                ...props.sx}}>
            {props.children}
        </StyledAlert>);
}
