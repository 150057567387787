import {useContext, useEffect, useState} from "react";
import SubNavBar from "../../Components/Navigation/SubNavBar";
import Page from "../../Components/Page";
import {OrganisationContext, UserContext} from "../../App";
import {useNavigate} from "react-router-dom";
import {BillingPackage, OrganisationAddress, PRIMARY_COLOUR, PROVIDER_COLOUR, ProviderData, QueryObject, RegistrationRequest, UserData, UserGroupData, camelCaseToNormal, capitalise, capitaliseWords, convertDate, executeCallable, getAccess, useExecuteCallableJob} from "placementt-core";
import FilterList from "../../Components/FilterList";
import {Alert, Badge, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText, Radio, RadioGroup, Snackbar, Stack, Table, TableCell, TableRow, Typography} from "@mui/material";
import IconButtonPop from "../../Components/IconButtonPop";
import {CheckCircleOutline, Close, CopyAll, Delete, Edit, Group} from "@mui/icons-material";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import DeletePopup from "../../Components/DeletePopup";
import {FieldValue, deleteField, where} from "firebase/firestore";
import Card from "../../Components/Card";
import {Popup} from "../../Components/Popup";
import FilterTable, {FilterObject} from "../../Components/FilterTable";
import {LoadingButton} from "../../Components/LoadingButton";
import Resources from "../Shared/Resources";
import TutorialPage from "../Shared/TutorialPage";
import ControlledSwitch from "../../Components/FormComponents/ControlledSwitch/ControlledSwitch";
import InputGroup from "../../Components/FormComponents/InputGroup";
import ActivateProviderUsersPopup from "./Popups/ActivateProviderUsersPopup";
import UserUploadPopup from "../Institutes/Staff/Cohorts/Popups/UserUploadPopup";
import Form from "../../Components/Form";
import FormPage from "../Shared/FormPage";
import ProviderUserGroupForm from "./AddItemForms/UserGroupForm";
import {AddressSelector} from "./AddItemForms/RequestItemLists";
import BillingTable from "../../Components/BillingTable";
import {InfoTableCell} from "../../Util/styledComponents";
import emptyBox from "./../../Images/Empty box.png";


export default function Organisation() {
    const user = useContext(UserContext);
    const viewBilling = getAccess(user, "viewBilling");
    return (
        <Page
            title="Organisation"
            noGutters
            metaTitle="Placementt | Organisation"
            metaDesc="View your organisations's details.">
            <SubNavBar
                pathLevel={3}
                items={[
                    {
                        label: "Staff",
                        route: "staff",
                        element: <Staff/>,
                    },
                    {
                        label: "Addresses",
                        route: "addresses",
                        element: <Addresses/>,
                    },
                    /* {
                        label: "EDI",
                        route: "edi",
                        element: <EDI/>,
                    }, */
                    {
                        label: "Resources",
                        route: "resources",
                        element: <Resources/>,
                    },
                    viewBilling && {
                        label: "Billing",
                        route: "billing",
                        element: <Billing/>,
                    },
                ]}/>
        </Page>
    );
}

function Staff() {
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const [staff, setStaff] = useState<number>();
    const [active, setActive] = useState<number>();
    const [activeNotLoggedIn, setActiveNotLoggedIn] = useState<number>();
    const [activeNotLoggedInPopup, setActiveNotLoggedInPopup] = useState(false);
    const [requests, setRequests] = useState<number>();

    const staffQuery: QueryObject[] = [{
        path: ["users"],
        where: [["oId", "==", user.oId], ["product", "==", user.product], ["userType", "==", "Staff"]],
    }];

    const firebaseQuery = new FirebaseQuery();
    const {execute} = useExecuteCallableJob({user});
    const {viewStaff, editStaffGroups} = getAccess(user, "viewStaff", "editStaffGroups");

    useEffect(() => {
        if (user.viewCohorts === "all" || user.userGroup === "admin") {
            firebaseQuery.getCount("users", [where("oId", "==", user.oId), where("userType", "==", "Staff")]).then(setStaff);
            firebaseQuery.getCount("users", [where("oId", "==", user.oId), where("userType", "==", "Staff"), where("status", "==", "active")]).then(setActive);
            firebaseQuery.getCount("users", [where("oId", "==", user.oId), where("userType", "==", "Staff"), where("status", "==", "active"), where("active", "==", false)]).then(setActiveNotLoggedIn);
        }
        firebaseQuery.getCount("requests", [where("product", "==", user.product), where("oId", "==", user.oId)]).then(setRequests);
    }, []);

    const staffPage = (<Page
        metaTitle={"Placementt | Staff"}
        metaDesc="Desc" grid>
        <TutorialPage pageId={"providerStaff"}/>
        <Grid item xs={12} md={(viewStaff || user.userGroup === "admin") ? 8 : 12}>
            <ProviderUserTable key={"staffTable"} queries={staffQuery} userType="Staff" onItemClick={(key: string) => navigate(`/${user.product}/users/${key}`)}/>
        </Grid>
        <Grid container item xs={12} md={4} direction={"column"}>
            {(viewStaff || user.userGroup === "admin") && <Card grid title={"Overview"}>
                <List>
                    <ListItem style={{background: PRIMARY_COLOUR, borderRadius: "20px", marginBottom: "10px", color: "white"}}>
                        <ListItemText primaryTypographyProps={{fontWeight: "bold"}} primary="Total staff"/>
                        <ListItemSecondaryAction style={{fontWeight: "bold", color: "white"}}>
                            {staff || 1}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                        <ListItemText primary="Active staff"/>
                        <ListItemSecondaryAction>
                            {active || 1}
                        </ListItemSecondaryAction>
                    </ListItem>
                    {Boolean(activeNotLoggedIn && activeNotLoggedIn > 0) && <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                        <ListItemText primary="Active, not logged in"/>
                        <ListItemSecondaryAction>
                            <Stack direction={"row"} spacing={3} alignItems={"center"}>
                                <Typography>{activeNotLoggedIn}</Typography>
                                <Button onClick={() => setActiveNotLoggedInPopup(true)} size="small" color="inherit">View</Button>
                            </Stack>
                        </ListItemSecondaryAction>
                    </ListItem>}
                </List>
                <Popup title={"Staff activated and not logged in"} open={activeNotLoggedInPopup} onClose={() => setActiveNotLoggedInPopup(false)}>
                    <FilterTable
                        formatQueryData={(data) =>
                            Object.entries(data as {[key:string]: UserData&{userGroupId: string}}).filter(([, user]) =>
                                user.userGroup !== "admin").reduce((acc, [uid, user]) => {
                                return {...acc, [uid]: {...user, ...user.details}};
                            }, {} as {[key:string]: unknown})
                        }
                        disableSelection
                        columns={["forename", "surname", "email"]} data={[{path: ["users"], where: [["oId", "==", user.oId], ["status", "==", "active"], ["userType", "==", "Staff"], ["active", "==", false]]}] as QueryObject[]}/>
                    <DialogActions>
                        <LoadingButton onClick={async () => execute("userManagement-activateUsers", {users: [{path: ["users"], where: [["oId", "==", user.oId], ["status", "==", "active"], ["userType", "==", "Staff"], ["active", "==", false]]}], userType: "Staff"})} text="Resend activation email"/>
                    </DialogActions>
                </Popup>
            </Card>}
        </Grid>
    </Page>);

    return (
        <>
            <SubNavBar pathLevel={4} items={[
                {
                    label: "All staff",
                    element: staffPage,
                    route: "all",
                },
                {
                    label: requests ? <Badge badgeContent={requests} color="primary"><Typography variant="subtitle2" pr={1}>Registration requests</Typography></Badge> : "Registration requests",
                    element: <RegistrationRequests/>,
                    route: "requests",
                },
                {
                    label: "User groups",
                    element: <StaffGroups/>,
                    route: "userGroups",
                },
                {
                    element: <FormPage title='Edit user group' path='userGroups' editable={editStaffGroups}><ProviderUserGroupForm/></FormPage>,
                    route: "userGroups/:id",
                },
            ]}/>
        </>
    );
}

function RegistrationRequests() {
    const [requests, setRequests] = useState<number>();
    const [registerRequestPopup, setRegisterRequestPopup] = useState(false);
    const [registerRequestCopied, setRegisterRequestCopied] = useState(false);

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);
    const org = useContext(OrganisationContext).details as ProviderData;
    const [allowRegistrationRequests, setAllowRegistrationRequests] = useState(org.registrationRequests);
    const [allowRegistrationRequestsDate, setAllowRegistrationRequestsDate] = useState(org.registrationRequestsDate);

    const [viewRequestPopup, setViewRequestPopup] = useState<RegistrationRequest>();


    const staffQuery: QueryObject[] = [{
        path: ["requests"],
        where: [["oId", "==", user.oId], ["product", "==", user.product]],
    }];

    useEffect(() => {
        firebaseQuery.getCount("requests", [where("product", "==", user.product), where("oId", "==", user.oId)]).then(setRequests);
    }, []);

    const updateRegistrationRequests = async (reset?: boolean) => {
        const updateObject: {registrationRequests: string|false, registrationRequestsDate?: string|FieldValue} = {registrationRequests: allowRegistrationRequests && !reset ? false : Math.random().toString(36).slice(2)};

        const today = new Date();
        today.setDate(today.getDate() + 30);
        updateObject.registrationRequestsDate = updateObject.registrationRequests ? convertDate(today, "dbstring") as string : deleteField();

        await firebaseQuery.update([user.product, user.oId], updateObject);
        setAllowRegistrationRequests(updateObject.registrationRequests);
        setAllowRegistrationRequestsDate(typeof updateObject.registrationRequestsDate === "string" ? updateObject.registrationRequestsDate : undefined);
    };

    console.log(viewRequestPopup);

    const today = convertDate(new Date(), "dbstring") as string;

    const isExpired = allowRegistrationRequestsDate && (allowRegistrationRequestsDate < today);

    return (
        <Page grid metaTitle="Placementt | Staff requests">
            <TutorialPage pageId={"providerRegistrationRequests"}/>
            <Grid item xs={12} md={(user.userGroup === "admin") ? 8 : 12}>
                {!allowRegistrationRequests && requests === 0 ?
                    <Stack alignItems={"center"} justifyContent={"center"} flex={1} mt={4}>
                        <img src={emptyBox} style={{maxHeight: 150}}/>
                        <Typography variant="h5" fontWeight={"bold"} textAlign={"center"} mb={5}>Set up registration requests</Typography>
                        <Typography variant="subtitle1" textAlign={"center"} width={"500px"} maxWidth={"95%"}>To enable staff to request access, click the button below or the toggle button on the right.</Typography>
                        <Button onClick={() => setRegisterRequestPopup(true)} variant="contained">Enable registration requests</Button>
                    </Stack> : <ProviderUserTable userType="Requests" queries={staffQuery} onItemClick={(_, v) => setViewRequestPopup(v as RegistrationRequest)}/>}
            </Grid>
            <Card grid xs={12} sm={6} md={4}>
                <List>
                    <ListItem sx={{marginBottom: "20px"}}>
                        <ListItemText primaryTypographyProps={{fontWeight: "bold"}} primary="Allow requests"/>
                        <ListItemSecondaryAction>
                            {!allowRegistrationRequests ? <ControlledSwitch name="externalProviderConsent" onCheck={() => setRegisterRequestPopup(true)}/> : <Button color={isExpired ? "error" : undefined} onClick={() => setRegisterRequestPopup(true)}>{isExpired ? "Expired" : "View link"}</Button>}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem style={{background: PRIMARY_COLOUR, borderRadius: "20px", marginBottom: "10px", color: "white"}}>
                        <ListItemText primaryTypographyProps={{fontWeight: "bold"}} primary="Registration requests"/>
                        <ListItemSecondaryAction style={{fontWeight: "bold", color: "white"}}>
                            {requests}
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Card>
            <Popup open={registerRequestPopup} onClose={() => {
                setRegisterRequestPopup(false);
                setRegisterRequestCopied(false);
            }} title={"Allow staff registration requests"}>
                <Stack>
                    <Typography>Allow staff to request to join your organisation. This is best for larger businesses.</Typography>
                    <Typography>Share the below link with your staff members, allowing them to sign up to the platform themselves. You can then approve the requests here and assign their roles.</Typography>
                    {allowRegistrationRequests && allowRegistrationRequestsDate && (allowRegistrationRequestsDate > today ? <><Stack direction={"row"} spacing={0} alignItems={"center"}>
                        <InputGroup sx={{color: `${PRIMARY_COLOUR} !important`, pointerEvents: "none"}} value={`https://placementt.co.uk/register/request/${user.oId}/${allowRegistrationRequests}`}/>
                        <Button sx={{height: "40px !important", borderRadius: "0px 4px 4px 0px", marginLeft: "-10px", background: "white !important"}} onClick={() => {
                            navigator.clipboard.writeText(`https://placementt.co.uk/register/request/${user.oId}/${allowRegistrationRequests}`);
                            setRegisterRequestCopied(true);
                        }}>{registerRequestCopied ? "Coped!" : "Copy"}</Button>
                    </Stack>
                    <Typography width={"100%"} variant="caption" textAlign={"center"}>Link will expire on {convertDate(allowRegistrationRequestsDate, "visual") as string}</Typography>
                    </> :
                        <Stack>
                            <Typography>Your sharable link has expired. Click to generate a new link.</Typography>
                            <LoadingButton color={"primary"} onClick={() => updateRegistrationRequests(true).then(() => {
                                setRegisterRequestCopied(false);
                            })} text={"Regenerate link"}/>
                        </Stack>)}
                    <LoadingButton color={allowRegistrationRequests ? "error" : "primary"} onClick={() => updateRegistrationRequests().then(() => {
                        setRegisterRequestPopup(!allowRegistrationRequests && true);
                        setRegisterRequestCopied(false);
                    })} text={allowRegistrationRequests ? "Disable registration requests" : "Enable registration requests"}/>
                </Stack>
            </Popup>
            <Popup fullWidth title={"View registration request"} open={Boolean(viewRequestPopup)} onClose={() => setViewRequestPopup(undefined)}>
                <Table>
                    <TableRow>
                        <TableCell>Forename</TableCell>
                        <TableCell>{viewRequestPopup?.forename}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Surname</TableCell>
                        <TableCell>{viewRequestPopup?.surname}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>{viewRequestPopup?.email}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Created</TableCell>
                        <TableCell>{convertDate(viewRequestPopup?.created, "visual") as string}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Message</TableCell>
                        <TableCell>{viewRequestPopup?.message}</TableCell>
                    </TableRow>
                </Table>
                <DialogActions>
                    <LoadingButton text="Approve request"/>
                    <LoadingButton color="error" text="Delete request"/>
                </DialogActions>
            </Popup>
        </Page>);
}

function StaffGroups() {
    const userGroups = Object.fromEntries(Object.entries(useContext(OrganisationContext).userGroups as {[key:string]: UserGroupData}).filter(([, group]) => group.template === "Staff"));
    const navigate = useNavigate();
    const [deletePopup, setDeletePopup] = useState<{name: string, id: string}>();

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);

    return (
        <Page metaTitle="Placementt | User groups">
            <FilterList card={false} data={userGroups} loadMoreIcon={"loaded"} actionButton={<Button onClick={() => navigate("/providers/organisation/staff/userGroups", {state: {formData: {template: "Staff"}}})}>New User Group</Button>} hideNoResultsText>
                {Object.entries(userGroups).map(([key, group]) =>
                    <ListItemButton key={key} id={key} divider onClick={() => navigate("/providers/organisation/staff/userGroups/"+group.id, {state: {group: group}})}>
                        <ListItemText
                            primary={<Stack direction={"row"} alignItems={"center"} mb={1}><Typography fontWeight={"bold"} fontSize={"1.2em"} color={"primary"}>{group.name}</Typography>{group.default && <Chip label={"Default"}/>}</Stack>}
                        />
                        <ListItemSecondaryAction>
                            <Stack direction={"row"}>
                                <IconButtonPop responsive={false} title="Copy" onClick={(e) => {
                                    e.stopPropagation(); firebaseQuery.copy(["userGroups"], group.id, user.oId, user.product);
                                }}><CopyAll/></IconButtonPop>
                                <IconButtonPop responsive={false} title="Edit" onClick={() => navigate("/providers/organisation/staff/userGroups/"+group.id, {state: {group: group}})}><Edit/></IconButtonPop>
                                {group.default || <IconButtonPop responsive={false} title="Delete" onClick={() => setDeletePopup({name: group.name, id: group.id})}><Delete/></IconButtonPop>}
                            </Stack>
                        </ListItemSecondaryAction>
                    </ListItemButton>)}
            </FilterList>
            <DeletePopup key={"delete"} open={typeof deletePopup === "object"} onClose={() => setDeletePopup(undefined)} title="staff group" itemName={deletePopup?.name} onDelete={async () => deletePopup?.id ? firebaseQuery.delete(["userGroups", deletePopup?.id]) : undefined}/>
        </Page>
    );
}


function Billing() {
    const [updatePlanPopup, setUpdatePlanPopup] = useState(false);
    const [currentPackage, setCurrentPackage] = useState<BillingPackage>();
    const orgDetails = useContext(OrganisationContext).details as ProviderData;

    const [selectedPackage, setSelectedPackage] = useState(orgDetails.package);
    const [selectedAddOnPackages, setSelectedAddOnPackages] = useState(orgDetails.addOnPackages);

    const firebaseQuery = new FirebaseQuery();

    const [addOnPopup, setAddOnPopup] = useState(false);
    const user = useContext(UserContext);

    const manageBilling = getAccess(user, "manageBilling");


    useEffect(() => {
        (firebaseQuery.getDocData(["billing", orgDetails.package]) as Promise<BillingPackage>).then(setCurrentPackage);
    }, [orgDetails.package]);


    return (
        <Page
            metaTitle="Organisation | Billing"
            grid>
            <Card grid sm={12} md={6} title={"Your Placementt plan"} secondaryTitle={manageBilling && <Button onClick={() => setUpdatePlanPopup(true)}>Update plan</Button>}>
                <Divider sx={{borderColor: PROVIDER_COLOUR, mb: 2}}/>
                <Typography variant="h6" color={PROVIDER_COLOUR}>{currentPackage?.name}</Typography>
                <Typography variant="subtitle2" sx={{opacity: 0.7}}>{currentPackage?.description}</Typography>
                <Table size="small">
                    <TableRow>
                        <InfoTableCell>Price / staff / month</InfoTableCell>
                        <InfoTableCell>£{currentPackage?.staffUnitCost}</InfoTableCell>
                    </TableRow>
                    <TableRow>
                        <InfoTableCell>Price / placement / month</InfoTableCell>
                        <InfoTableCell>£{currentPackage?.placementUnitCost}</InfoTableCell>
                    </TableRow>
                </Table>
            </Card>
            {false && <Card grid sm={12} md={6} title={"Add-Ons"} secondaryTitle={<Button onClick={() => setAddOnPopup(true)}>Update add-ons</Button>}>
                <Typography>Add-on packages to help you support your students.</Typography>
                <List>
                    {orgDetails.addOnPackages?.map((addOn) =>
                        <ListItem divider>
                            <ListItemText primary={addOn.toUpperCase()} secondary={"Manage work experience evidencing as part of your work experience process and automate collection of placement details and signoff from employers."}/>
                        </ListItem>)}
                </List>
            </Card>}
            <Popup title={"Update your plan"} fullWidth open={updatePlanPopup} onClose={() => setUpdatePlanPopup(false)}>
                <Stack>
                    <BillingTable product={user.product} {...{selectedPackage, setSelectedPackage}}/>
                    <LoadingButton variant="contained" onClick={async () => user.oId ? firebaseQuery.update(["providers", user.oId], {package: selectedPackage}) : new Error("NO ID")} text="Update package"/>
                </Stack>
            </Popup>
            <Popup title={"Configure add-ons"} fullWidth open={addOnPopup} onClose={() => setAddOnPopup(false)}>
                <Stack>
                    <List>
                        <ListItem divider>
                            <ListItemText primary={<Stack direction={"row"} alignItems={"center"}>
                                <Checkbox
                                    checked={selectedAddOnPackages?.includes("admissions")}
                                    onChange={() => setSelectedAddOnPackages((oldPackages) => {
                                        if (!oldPackages) return ["admissions"];
                                        if (!oldPackages?.includes("admissions")) {
                                            return [...oldPackages, "admissions"];
                                        }
                                        return oldPackages.filter((i) => i !== "admissions");
                                    })}
                                    name="radio-buttons"
                                    inputProps={{"aria-label": "admissions"}}
                                />
                                <Typography>Admissions add-on</Typography>
                            </Stack>} secondary={"Manage work experience evidencing as part of your work experience process and automate collection of placement details and signoff from employers."}/>
                        </ListItem>
                    </List>
                    <LoadingButton variant="contained" onClick={async () => user.oId ? firebaseQuery.update(["providers", user.oId], {addOnPackages: selectedAddOnPackages}) : new Error("NO ID")} text="Update add-ons"/>
                </Stack>
            </Popup>
        </Page>);
}
/*
function EDI() {
    return (
        <Page metaTitle={"Placementt | EDI"} grid>
            <Grid></Grid>
        </Page>
    );
}
*/

type UserTableParams = {
    userType: "Staff"|"Requests",
    queries: QueryObject[],
    onItemClick?: (key: string, value?: {[key: string]: unknown}) => void,
    noButtons?: boolean
}

export function ProviderUserTable({userType, noButtons, queries, onItemClick}: UserTableParams) {
    const [selectedUsers, setSelectedUsers] = useState<"All"| {[key: string]: UserData}>({});
    const [addUsersPopup, setAddUsersPopup] = useState(false);
    const [activateAccountPopup, setActivateAccountPopup] = useState(false);
    const [userGroupPopup, setUserGroupPopup] = useState(false);
    const [deleteUsersPopup, setDeleteUsersPopup] = useState(false);
    const [selectedUserGroup, setSelectedUserGroup] = useState<string>();

    const [snackbarText, setSnackbarText] = useState("");
    const [tableFilters, setTableFilters] = useState<{[key:string]: unknown}>();

    const org = useContext(OrganisationContext);

    const user = useContext(UserContext);
    const {execute} = useExecuteCallableJob({user: user});

    const access = userType === "Requests" ? getAccess(user, `edit${userType}`, `delete${userType}`) : getAccess(user, `add${userType}`, `edit${userType}`, `delete${userType}`);

    if (user.product !== "providers") {
        throw new Error("Providers onlt have access.");
    }


    const editUserGroup = () => {
        executeCallable("userManagement-editUserGroup", {users: selectedUsers === "All" ? queries : selectedUsers, filters: tableFilters, userGroup: selectedUserGroup, userType: userType});
        setUserGroupPopup(false);
        setSelectedUserGroup(undefined);
        setSnackbarText("Processing user group change.");
    };

    const columns = userType === "Requests" ? ["forename", "surname", "email", "message", "created"] :
        ["forename", "surname", "email", "status", "userGroup", "addresses", "listings", "groups"];

    const columnTemplate = {
        "userGroup": (groups: string) => groups?.split(", ").map((group) => <Chip color={"primary"} label={group}/>),
        "addresses": (c: string, row: UserData) => <Chip color={"primary"} label={row.viewAddresses ? row.viewAddresses === "all" ? "All" : row.viewAddresses === "none" ? "None" : row?.visibleAddresses?.length + " addresses" : "None"}/>,
        "groups": (c: string, row: UserData) => <Chip color={"primary"} label={row.viewPlacementGroups ? row.viewPlacementGroups === "all" ? "All" : (row.viewPlacementGroups === "none" ? "None" : "Requested") : "None"}/>,
        "listings": (c: string, row: UserData) => <Chip color={"primary"} label={row.viewPlacementListings ? row.viewPlacementListings === "all" ? "All" : row.viewPlacementListings === "none" ? "None" : row?.visibleListings?.length + " placements" : "None"}/>,
        "status": (s: string) => capitalise(s),
        "created": (d: string) => <Typography width={"max-content"} variant="subtitle2">{convertDate(d, "visual") as string}</Typography>,
        "message": (m: string) => <Typography
            variant="subtitle2"
            sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
            }}>{m}</Typography>,
    };
    const filters:FilterObject = {
        [userType === "Requests" ? "forename" : "details.forename"]: {
            label: "Forename",
            type: "string",
        },
        [userType === "Requests" ? "surname" : "details.surname"]: {
            label: "Surname",
            type: "string",
        },
        email: {
            label: "Email",
            type: "string",
        },
    };


    if (userType === "Staff") {
        filters.status = {
            label: "Status",
            values: {active: "Active", inactive: "Inactive"},
            type: "dropdown",
        };
        filters.userGroup = {
            label: "User group",
            values: Object.fromEntries(Object.entries(org.userGroups as {[key:string]: UserGroupData}).filter(([, group]) => group.template === userType).map(([id, group]) => [id, group.name])),
            type: "dropdown",
        };
    }

    if (!userType) {
        return null;
    }


    return (
        <Box style={{minHeight: "400px", padding: "16px", width: "100%"}}>
            <FilterTable
                viewRows
                key={"user table"}
                title={"All "+userType.toLowerCase()}
                filters={filters}
                {...{columns, columnTemplate, onItemClick}}
                setTableFilters={setTableFilters}
                access={Boolean(queries.length)}
                data={queries}
                setSelectedRows={(e) => setSelectedUsers(e as "All"|{[key:string]: UserData})}
                formatQueryData={(data) =>
                    Object.entries(data as {[key:string]: UserData&{userGroupId: string}}).reduce((acc, [uid, user]) => {
                        if (acc[uid]) return acc;

                        if (user.userGroupId) {
                            acc[uid] = user;
                            return acc;
                        }

                        return {...acc, [uid]: {...user, ...user.details, "userGroup": user.userGroup === "admin" ? "Super Admin" : org.userGroups[user.userGroup || ""]?.name, "userGroupId": user.userGroup}};
                    }, {} as {[key:string]: unknown})
                }
                actionButtonText={userType === "Staff" && !noButtons && access[`add${userType}`] && `Add ${userType.toLowerCase()}`}
                onActionButtonClick={() => setAddUsersPopup(true)}>
                {!noButtons && access[`edit${userType}`] && <IconButtonPop variant="contained" title={`Activate ${userType.toLowerCase()}`} onClick={() => setActivateAccountPopup(true)}><CheckCircleOutline/></IconButtonPop>}
                {!noButtons && userType === "Staff" && access[`edit${userType}`] && <IconButtonPop title={"Set user group"} onClick={() => {
                    setUserGroupPopup(true);
                }}><Group/></IconButtonPop>}
                {!noButtons && access[`delete${userType}`] && <IconButtonPop color="error" title={`Delete ${userType === "Requests" ? "requests" : "users"}`} onClick={() => {
                    setDeleteUsersPopup(true);
                }}><Delete/></IconButtonPop>}
            </FilterTable>
            {userType === "Staff" && <UserUploadPopup key={`${userType}UploadPopup`} userType={userType} active={addUsersPopup} onToggle={setAddUsersPopup}/>}
            <ActivateProviderUsersPopup filters={tableFilters} users={selectedUsers === "All" ? queries : selectedUsers} userType={userType} open={activateAccountPopup} onClose={() => setActivateAccountPopup(false)}/>

            <Dialog
                open={deleteUsersPopup}
                onClose={() => setDeleteUsersPopup(false)}>
                <DialogTitle>Delete {userType.toLowerCase()}?</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography variant='body1'>You can permanently delete all user data or disable the user accounts.</Typography>
                        <Typography variant='body1'>User data will be unretrievable once deleted. Users may be disabled to preserve data.</Typography>
                        <Stack direction={"row"} justifyContent="space-evenly">
                            <Button color="error" onClick={() => {
                                execute("userManagement-deleteUsers", {filters: tableFilters, users: selectedUsers === "All" ? queries : selectedUsers, userType: userType}); setDeleteUsersPopup(false);
                            }}>Delete</Button>
                            {userType === "Staff" && <Button onClick={() => {
                                execute("userManagement-deactivateUsers", {filters: tableFilters, users: selectedUsers === "All" ? queries : selectedUsers, userType: userType}); setDeleteUsersPopup(false);
                            }}>Deactivate</Button>}
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>

            <Popup key="changeUserGroup" open={userGroupPopup} onClose={() => setUserGroupPopup(false)} title={"Set User groups for selected users"}>
                <Form onSubmit={editUserGroup}>
                    <RadioGroup
                        aria-labelledby="Select user radio buttons"
                        name="userGroup"
                        sx={{margin: "10px"}}
                        onChange={(e) => setSelectedUserGroup(e.target.value)}
                    >
                        {Object.entries(org.userGroups as {[key:string]: UserGroupData}).map(([id, group]) => {
                            if (group.template !== userType) {
                                return null;
                            }
                            return (<FormControlLabel value={id} control={<Radio />} label={group.name} />);
                        })}
                    </RadioGroup>
                </Form>
            </Popup>
            <Snackbar
                open={Boolean(snackbarText)}
                autoHideDuration={6000}
                onClose={() => setSnackbarText("")}
                message={snackbarText}
                action={
                    <IconButton
                        size="small"
                        title='Close notification'
                        aria-label="close"
                        color="inherit"
                        onClick={() => setSnackbarText("")}>
                        <Close fontSize="small" />
                    </IconButton>
                }/>
        </Box>
    );
}


function Addresses() {
    const [deleteAddress, setDeleteAddress] = useState<string>();
    const navigate = useNavigate();
    const org = useContext(OrganisationContext);
    const user = useContext(UserContext);
    const addresses = org.addresses as {[key: string]: OrganisationAddress&{listings: number}};
    const firebaseQuery = new FirebaseQuery();

    const [requestAddressAccessPopup, setRequestAddressAccessPopup] = useState(false);

    if (!addresses) return null;

    console.log("Addresses", addresses);

    return (
        <Page metaTitle={"Placementt | Addresses"} grid>
            <FilterList title="Addresses" data={addresses} loadMoreIcon={"loaded"} grid xs={12} md={8} actionButton={(user.viewAddresses === "all" || user.userGroup === "admin") ? <Button onClick={() => navigate("/providers/addAddress")}>Add address</Button> : user?.viewAddresses === "request" ? <Button onClick={() => setRequestAddressAccessPopup(true)}>Request access</Button> : undefined}>
                {Object.entries(addresses).map(([id, address]) =>
                    <ListItemButton key={id} divider id={id} onClick={() => navigate(`/providers/${address.stage === "complete" ? "viewAddress" : "addAddress"}/${id}`)}>
                        <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                            <ListItemText
                                primary={<Stack spacing={0}>
                                    <Typography fontSize={"1.2em"} pb={1}>{address.name}</Typography>
                                    <Typography color={"grey"}>{address["address-line1"]} | {address.postal_code} | {capitaliseWords(camelCaseToNormal(address.country))}</Typography>
                                    <Typography>Associated listings: {address?.listings}</Typography>
                                </Stack>}/>
                            <Stack spacing={1}>
                                <Stack direction={"row"}>
                                    <Chip sx={{width: "max-content", alignSelf: "end"}} color={address.stage !== "complete" ? "warning" : "primary"} label={address.stage !== "complete" ? "Complete set up" : "Active"}/>
                                    {Boolean(address?.flags?.length) && address.flags?.map((flag) => <Chip sx={{width: "max-content", alignSelf: "end"}} color="warning" label={capitaliseWords(camelCaseToNormal(flag))}/>)}
                                    {address.default && <Chip sx={{width: "max-content", alignSelf: "end"}} color="primary" label={"Default"}/>}
                                </Stack>
                                <Stack direction={"row"} sx={{justifyContent: "flex-end"}}>
                                    <IconButtonPop responsive={false} onClick={() => navigate(`/providers/addAddress/${id}`)} title="Edit address"><Edit/></IconButtonPop>
                                    {address.default || <IconButtonPop responsive={false} onClick={async (e) => {
                                        e.stopPropagation();
                                        setDeleteAddress(id);
                                    }} title="Delete address"><Delete/></IconButtonPop>}
                                </Stack>
                            </Stack>
                        </Stack>
                    </ListItemButton>)}
            </FilterList>
            <AddressSelector visible={requestAddressAccessPopup} onClose={() => setRequestAddressAccessPopup(false)} type="popup" onForward={() => setRequestAddressAccessPopup(false)} request/>
            <Popup title="Delete address" open={Boolean(deleteAddress)} onClose={() => setDeleteAddress(undefined)}>
                <Stack>
                    <Typography>Permanently delete "{deleteAddress && addresses[deleteAddress]?.name}"?</Typography>
                    {addresses[deleteAddress as string]?.listings === 0 ?
                        <LoadingButton text="Delete" onClick={async () => {
                            await firebaseQuery.delete(["addresses", deleteAddress || ""]).then(() => setDeleteAddress(undefined));
                        }}/> :
                        <>
                            <Alert severity="warning">Cannot delete address associated with {addresses[deleteAddress as string]?.listings} active listings.</Alert>
                            <LoadingButton>
                                <Button onClick={() => {
                                    setDeleteAddress(undefined);
                                }}>Exit</Button>
                                <Button color="error" onClick={async () => {
                                    await firebaseQuery.delete(["addresses", deleteAddress || ""]).then(() => setDeleteAddress(undefined));
                                }}>Delete and delist associated listings.</Button>
                            </LoadingButton>
                        </>}
                </Stack>
            </Popup>
        </Page>
    );
}
