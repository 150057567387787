import {Button, Link, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@mui/material";
import Page from "../../../Components/Page";
import {useEffect, useState} from "react";
import {ProviderData, executeCallable, storage} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {orderBy, where} from "firebase/firestore";
import {getDownloadURL, ref} from "firebase/storage";
import {LoadingButton} from "../../../Components/LoadingButton";
import Card from "../../../Components/Card";
import Preloader from "../../../Components/Preloader";
import Form from "../../../Components/Form";
import {Popup} from "../../../Components/Popup";
import InputGroup from "../../../Components/FormComponents/InputGroup";


export default function BusinessUsers() {
    const [checkInsurance, setCheckInsurance] = useState<{[key: string]: ProviderData&{downloadURL: string}}>();
    const [rejectELIPopup, setRejectELIPopup] = useState<string>();

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        firebaseQuery.getDocsWhere("providers", [where("insurance", "==", "awaitingReview"), orderBy("admin")]).then(async (docs) => {
            const finalDocs = Object.fromEntries(await Promise.all(
                Object.entries(docs as {[key: string]: ProviderData}).map(async ([key, data]) => {
                    const downloadURL = await getDownloadURL(ref(storage, `insurance/${key}.pdf`));
                    return [key, {...data, downloadURL: downloadURL}];
                })
            ));

            setCheckInsurance(finalDocs);
        });
    }, []);

    const submitInsuranceReview = async (providerId: string, outcome: "accept"|"reject", reason?: string) => {
        if (outcome === "accept") {
            await executeCallable("insurance-approve", {providerId: providerId});
            return;
        }

        if (outcome === "reject") {
            await executeCallable("insurance-reject", {reason: reason, providerId: providerId});
            setRejectELIPopup(undefined);
        }
    };

    return (
        <Page title="Business Users" grid>
            <Card title={"Insurance to review"} grid xs={12} md={4}>
                <List>
                    {checkInsurance ? Object.entries(checkInsurance).map(([providerId, provider]) =>
                        <ListItem id={providerId}>
                            <ListItemText
                                primary={provider.name}
                                secondary={<Link target={"_blank"} href={provider.downloadURL}>View insurance doc</Link>}
                            />
                            <ListItemSecondaryAction>
                                <LoadingButton>
                                    <Button onClick={() => submitInsuranceReview(providerId, "accept")} variant="contained">Accept</Button>
                                    <Button onClick={() => setRejectELIPopup(providerId)} color="error">Reject</Button>
                                </LoadingButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ) : <Preloader visible/>}
                </List>
            </Card>
            <Popup title={"Reject insurance"} open={Boolean(rejectELIPopup)} onClose={() => setRejectELIPopup(undefined)}>
                <Typography>Rejecting this employers liability insurance will require them to reupload. You can check back here once it is checked.</Typography>
                <Form onSubmit={async (e) => submitInsuranceReview(rejectELIPopup as string, "reject", (e as {reason:string}).reason)}>
                    <InputGroup label="Rejection reason" name={"reason"} required/>
                </Form>
            </Popup>
        </Page>
    );
}
