import {OnboardingDocs, PRIMARY_COLOUR, StudentPlacementData, convertDate, editNestedObject} from "placementt-core";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import {Popup} from "../../../Components/Popup";
import Form from "../../../Components/Form";
import {Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, MenuItem, Stack, Typography} from "@mui/material";
import Dropdown from "../../../Components/FormComponents/Dropdown";
import ListMaker from "../../../Components/FormComponents/ListMaker";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import {Link} from "react-router-dom";
import Files from "../../Shared/Files";
import {OrganisationContext} from "../../../App";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {LoadingButton} from "../../../Components/LoadingButton";


export default function AddOnboardingDocsPopup({dismiss, placement, onClose}:{onComplete: () => void, dismiss?: boolean, placement?: StudentPlacementData, onClose: () => void}) {
    const [onboardingDocs, setOnboardingDocs] = useState<Partial<(OnboardingDocs&{deadline: number})>>({});
    const [popupOpen, setPopupOpen] = useState<boolean>(Boolean(placement));
    const [filesPopupOpen, setFilesPopupOpen] = useState<boolean>(false);

    const [dismissPopup, setDismissPopup] = useState(false);

    useEffect(() => {
        if (dismiss === dismissPopup) return;
        if (dismiss) {
            setDismissPopup(true);
            return;
        }

        if (!dismiss) {
            setTimeout(() => setDismissPopup(false), 500);
        }
    }, [dismiss]);

    useEffect(() => {
        setPopupOpen(Boolean(placement));
    }, [placement]);

    const firebaseQuery = new FirebaseQuery();
    const forms = useContext(OrganisationContext)?.forms as {[key:string]: {name: string}};

    const submitOnboarding = async (e: {addToListing?: boolean}) => {
        // Update listing.
        if (!onboardingDocs) throw new Error("No onboarding documents to update.");
        if (!placement) throw new Error("No placement to update.");

        const onboardingDate = new Date(placement.startDate);
        if (onboardingDocs.deadline) {
            onboardingDate.setDate(onboardingDate.getDate() - onboardingDocs.deadline);
        }

        await firebaseQuery.update(["placements", placement.id], {onboarding: {...onboardingDocs, completed: {submitted: false, accepted: false}, deadline: convertDate(onboardingDate, "dbstring")}});

        if (e.addToListing && placement?.placementId) {
            await firebaseQuery.update(["placementListings", placement.placementId], {onboarding: onboardingDocs});
        }
    };

    const dismissOnboarding = async (applyToListing?: boolean) => {
        if (!placement) throw new Error("No placement to update.");

        await firebaseQuery.update(["placements", placement?.id], {onboarding: false});
        if (applyToListing && placement?.placementId) {
            await firebaseQuery.update(["placementListings", placement.placementId], {onboarding: false});
        }
    };

    if (dismissPopup) {
        return (
            <Popup open={dismiss || false} onClose={onClose} title="Dismiss onboarding popup">
                <Stack>
                    <Typography sx={{opacity: 0.8}}>Sending onboarding documents to the student can allow them to orient themselves with key documents, provide more information to you, or complete key training documents necessary to start work.</Typography>
                    <Typography>Continue if you do not want to send onboarding to this student.</Typography>
                    <LoadingButton>
                        <Stack alignItems={"center"} spacing={1}>
                            <Button variant="outlined" sx={{width: "100%", maxWidth: "300px"}} onClick={async () => await dismissOnboarding()}>Don't send to this student</Button>
                            <Button variant="outlined" sx={{width: "100%", maxWidth: "300px"}} onClick={async () => await dismissOnboarding(true)}>Don't send to any student on this placement</Button>
                            <Button variant="outlined" sx={{width: "100%", maxWidth: "300px"}} color="inherit" onClick={onClose}>Close</Button>
                        </Stack>
                    </LoadingButton>
                </Stack>
            </Popup>
        );
    }

    return (
        <Popup open={popupOpen} onClose={onClose} title="Add onboarding docs for placement">
            <Form onSubmit={(e) => submitOnboarding(e as {addToListing: boolean})}>
                <Typography>When should we send out your onboarding documents?</Typography>
                <InputGroup sx={{flex: 1}} required onChange={(e: ChangeEvent<HTMLInputElement>) => setOnboardingDocs((o) => editNestedObject(["deadline"], o || {}, parseInt(e.target.value)) as OnboardingDocs)} label={"Days before placement"} type={"number"} min={0}/>
                <Divider/>
                <InputGroup name={"message"} label={"Message"} multiline rows={3} placeholder={"Generic message sent to candidate. To send more information, upload a custom file."} value={onboardingDocs?.message} onChange={(e: ChangeEvent<HTMLInputElement>) => setOnboardingDocs((o) => editNestedObject(["message"], o || {}, e.target.value) as OnboardingDocs)}/>
                <Box>
                    <Typography mt={1}>Select custom forms for the student to complete</Typography>
                    <Dropdown
                        label='Forms'
                        multiple
                        key={"submissionForms"}
                        value={onboardingDocs?.forms}
                        onChange={(e) => setOnboardingDocs((o) => editNestedObject(["forms"], o || {}, e.target.value) as OnboardingDocs)}>
                        {forms && Object.entries(forms).map(([key, e]) => {
                            return (<MenuItem value={key}>{e.name}</MenuItem>);
                        })}
                        <Divider/>
                        <MenuItem onClick={()=>window.open("/providers/organisation/resources/f", "_blank")}>
                            {"Create form"}
                        </MenuItem>
                    </Dropdown>
                </Box>
                <Box>
                    <Typography mt={1}>Select files for the student to review.</Typography>
                    <Stack direction={"row"} alignItems={"stretch"}>
                        <Box border={`1px solid ${PRIMARY_COLOUR}`} borderRadius={1} display="flex" alignItems="center" pl={1} flex={1}>
                            {onboardingDocs?.files ? `${onboardingDocs?.files?.length} file${(onboardingDocs?.files as string[])?.length > 1 ? "s" : ""} selected` : "No files selected"}
                        </Box>
                        <Button onClick={() => setFilesPopupOpen(true)}>Select files</Button>
                    </Stack>
                </Box>
                <Divider/>
                <ListMaker noDefault name="requiredFiles" onChange={(items) => setOnboardingDocs((o) => editNestedObject(["requiredFiles"], o || {}, items) as OnboardingDocs)} items={onboardingDocs?.requiredFiles} label="Required documents for students to upload" multipleInputs>
                    <Grid container mt={"-16px !important"}>
                        <Grid item xs={6}>
                            <InputGroup label={"Document name"} name={"fileName"} placeholder={"One file, such as CV"} required/>
                        </Grid>
                        <Grid item xs={6}>
                            <Dropdown label="Document type" required name="fileType">
                                <MenuItem value={"application/pdf"}>PDF</MenuItem>
                                <MenuItem value={"image/png, image/jpeg"}>Image</MenuItem>
                                <MenuItem value={"video/mp4, video/ogg"}>Video</MenuItem>
                                <MenuItem value={"audio/ogg, audio/mpeg"}>Voice note</MenuItem>
                                <Typography p={2} variant="caption">Need another? <Link to={"mailto:admin@placementt.co.uk"}>Contact us</Link></Typography>
                            </Dropdown>
                        </Grid>
                    </Grid>
                </ListMaker>
                {placement?.placementId && <FormGroup>
                    <FormControlLabel control={<Checkbox key={"applyToListingCheckbox"} name={"addToListing"}/>} label={
                        <>
                            <Typography>Add onboarding docs to placement listing?</Typography>
                            <Typography variant="subtitle2" sx={{opacity: 0.7}}>We will automatically send these to all students on this placement.</Typography>
                        </>} />
                </FormGroup>}
            </Form>
            <Files type="org" popup selectable={"multiple"} open={filesPopupOpen} defaultSelected={filesPopupOpen ? onboardingDocs?.files : undefined} onClose={() => setFilesPopupOpen(false)} onSubmitSelection={(files) => {
                filesPopupOpen && setOnboardingDocs((o) => editNestedObject(["files"], o || {}, files) as OnboardingDocs);
                setFilesPopupOpen(false);
            }}/>
        </Popup>
    );
}
