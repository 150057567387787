import "react-app-polyfill/stable";

import React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {createTheme, ThemeProvider} from "@mui/material";
import {BrowserRouter} from "react-router-dom";
import {INSTITUTE_COLOUR, PRIMARY_COLOUR, PROVIDER_COLOUR, SECONDARY_COLOUR, store, STUDENT_COLOUR} from "placementt-core";
import {Provider} from "react-redux";

declare module "@mui/material/Alert" {
    interface AlertPropsColorOverrides {
      primary: true;
    }
}

export const theme = createTheme({
    palette: {
        primary: {
            main: PRIMARY_COLOUR,
        },
        secondary: {
            main: SECONDARY_COLOUR,
            dark: SECONDARY_COLOUR,
        },
        institutes: {
            main: INSTITUTE_COLOUR,
            contrastText: INSTITUTE_COLOUR,
        },
        providers: {
            main: PROVIDER_COLOUR,
            contrastText: PROVIDER_COLOUR,
        },
        students: {
            main: STUDENT_COLOUR,
            contrastText: STUDENT_COLOUR,
        },
        white: {
            main: "#ffffff",
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                size: "small",
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    width: "100%",
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: "outlined",
            },
            styleOverrides: {
                root: {
                    "width": "max-content",
                    "padding": "5px 10px",
                    "&:not(:last-child)": {
                        marginRight: "7px",
                    },
                    "height": "max-content",
                },
                contained: {
                    "color": "#fff",
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        cursor: "pointer",
                    },
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: "15px",
                    background: "rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                },
                bar: {
                    borderRadius: "10px",
                },
            },
        },
        MuiListItemSecondaryAction: {
            styleOverrides: {
                root: {
                    padding: "10px",
                },
            },
        },
        MuiChip: {
            defaultProps: {
                size: "small",
                variant: "outlined",
            },
            styleOverrides: {
                root: {
                    maxWidth: 150,
                },
            },
        },
        MuiFab: {
            defaultProps: {
                color: "primary",
                size: "small",
                ["aria-label"]: "add",
            },
            styleOverrides: {
                root: {
                    position: "absolute",
                    bottom: 15,
                    right: 30,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingLeft: "13px",
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: "max-content",
                    paddingRight: "13px",
                },
            },
        },
        MuiGrid: {
            defaultProps: {
                spacing: 2,
            },
            styleOverrides: {
                container: {
                    "&.MuiGrid-container": {
                        height: "max-content",
                        marginLeft: "-16px",
                    },
                },
            },
        },
        MuiStack: {
            defaultProps: {
                spacing: 2,
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    borderRadius: 10,
                    boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 5px 0px",
                    border: "lightgray 1px solid",
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    overflow: "auto",
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    paddingBottom: 0,
                },
                title: {
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                },
            },
        },
        MuiTabs: {
            defaultProps: {
                variant: "scrollable",
                allowScrollButtonsMobile: true,
            },
            styleOverrides: {
                root: {
                    borderBottom: "1px rgba(0,0,0,0.25) solid",
                },
                scrollButtons: {
                    "&.Mui-disabled": {
                        opacity: 0.3,
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        "backgroundColor": PRIMARY_COLOUR,
                        "color": "white",
                        "borderRadius": 20,
                        ":hover": {
                            backgroundColor: "#5b18b8",
                        },
                    },
                },
            },
        },
        MuiToggleButtonGroup: {
            defaultProps: {
                size: "small",
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    fontSize: "1.1em",
                    lineHeight: "35px",
                },
            },
            defaultProps: {
                disableSticky: true,
                disableGutters: true,
                color: "primary",
            },
        },
    },
    typography: {
        fontFamily: "nunito",
        button: {
            textTransform: "none",
        },
    },
});

declare module "@mui/material/styles/createPalette" {
    interface Palette {
      white: {
        main: string,
    };
      institutes: {
        main: string,
        contrastText: string,
    };
      students: {
        main: string,
        contrastText: string,
    };
      providers: {
        main: string,
        contrastText: string,
    };
    }

    interface PaletteOptions {
        white?: {
            main: string,
        };
        institutes?: {
            main: string,
            contrastText: string,
        };
        students?: {
            main: string,
            contrastText: string,
        };
        providers?: {
            main: string,
            contrastText: string,
        };
    }
  }

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        white: true;
        institutes: true;
        providers: true;
        students: true;
    }
}

declare module "@mui/material/ToggleButtonGroup" {
    interface ToggleButtonGroupPropsColorOverrides {
        white: true;
        institutes: true;
        providers: true;
        students: true;
    }
}

declare module "@mui/material/Alert" {
    interface AlertPropsColorOverrides {
        primary: true;
        institutes: true;
        providers: true;
        students: true;
    }
}

declare module "@mui/material/Chip" {
    interface ChipPropsColorOverrides {
        white: true;
        institutes: true;
        providers: true;
        students: true;
    }
}

const el = document.getElementById("root");

console.log("Render index");

if (el) {
    const root = ReactDOM.createRoot(el);
    root.render(
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Provider store = {store}>
                    <App />
                </Provider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
