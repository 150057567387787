import {INSTITUTE_COLOUR} from "placementt-core";
import {List, ListItem, ListItemIcon, ListItemText, Typography, Box, Stack, Grid, Divider, keyframes} from "@mui/material";
import TwoColumnSection from "../../Components/Public/TwoColumnSection";
import IconGridList from "../../Components/Public/IconGridList";
import {AccountTreeRounded, Analytics, AnalyticsRounded, AssignmentTurnedInRounded, BadgeRounded, CalendarMonth, CheckCircleRounded, DocumentScannerRounded, GroupsRounded, LightbulbRounded, PeopleRounded, SchoolRounded, ShieldRounded, SmsRounded} from "@mui/icons-material";
import CardSection from "../../Components/Public/CardSection";
import PublicTemplate from "../../Components/Public/PublicTemplate";
import Page from "../../Components/Page";
import EpsomEwell from "../../Images/TestimonialsLogos/EpsomEwell.webp";
import HallCrossAcademy from "../../Images/TestimonialsLogos/HallCrossAcademy.webp";
import KestevenGrantham from "../../Images/TestimonialsLogos/KestevenGrantham.webp";
import InstitutePriceCalculator from "../../Components/PriceCalculator";
import animatedColouredBackground from "../../Images/animatedColouredBackground.svg";
import Card from "../../Components/Card";
import {GDPRPrivacyInfo} from "./SchoolInfo";
import {useEffect, useState} from "react";
import styled from "styled-components";


const springIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    90% {
        opacity: 1;
        transform: translateY(5%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
`;

const springOut = keyframes`
    0% {
        opacity: 1;
        transform: translateY(0%);
    }
    10% {
        opacity: 1;
        transform: translateY(-5%);
    }
    100% {
        opacity: 0;
        transform: translateY(100%);
    }
`;

// Wrapper to ensure the texts stack and remain centered
const TextContainer = styled.div`
    position: relative;
    height: 70px;
    overflow: hidden;
    display: "inline";
`;

// Styling for each text element
const Text = styled(Typography)<{ isVisible: boolean }>`
    position: absolute;
    width: 100%;
    text-align: center;
    animation: ${({isVisible}) => (isVisible ? springIn : springOut)} 0.6s ease-in-out;
    animation-fill-mode: forwards;
`;


export default function InstituteInfo() {
    const [index, setIndex] = useState(0);
    const texts = ["School", "Multi-Academy Trust", "University"];

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [texts.length]);

    return (
        <Page
            metaTitle="Placementt | Educator"
            metaDesc="A platform for schools, trusts
            and universities to seamlessly manage work experience. Manage
            students and applicant work experience through a customisable workflow, track progress and communicate easily with your students."
        >
            <PublicTemplate>
                <Stack width={"100%"} mb={"-64px !important"} mt={"-32px !important"}>
                    <Typography color={"primary"} fontWeight={"bold"}>Placementt for Educators</Typography>
                    <Divider/>
                </Stack>
                <Stack spacing={5} borderBottom={"1px solid lightgrey"} pt={20} pb={10} textAlign={"center"} maxWidth={"1000px"} alignSelf={"center"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant="h2" fontWeight={500} maxWidth={"800px"}>Take Control of Placements Across Your
                        <TextContainer>
                            {texts.map((text, i) => (
                                <Text
                                    color={"primary"}
                                    variant="h2"
                                    key={i}
                                    isVisible={i === index}
                                >
                                    {text}
                                </Text>
                            ))}
                        </TextContainer></Typography>
                    <Typography variant="h5">Build a placement programme that empowers students and buildsde employer relationships, with emphasis on communication, student experience and simplicity.</Typography>
                </Stack>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <IconGridList
                        title="Core Features to Streamline Your Placements, from Secondary School to University"
                        titleColour={INSTITUTE_COLOUR}
                        items={[
                            {
                                title: "Customisable placement workflow",
                                icon: <AccountTreeRounded/>,
                            },
                            {
                                title: "Comprehensive user analytics",
                                icon: <Analytics/>,
                            },
                            {
                                title: "Robust communication tools",
                                icon: <SmsRounded/>,
                            },
                            {
                                title: "Simple, secure user management",
                                icon: <PeopleRounded/>,
                            },
                        ]}
                    />
                </Box>
                <TwoColumnSection
                    title="Bring out the best in your students"
                    titleColor={INSTITUTE_COLOUR}
                    sectionOne="Our platform doesn't just reduce your administrative workload, it supports students directly by building up comprehensive profiles of work placements. This means that your students have access to placements that will maximise their career prospects."
                    sectionTwo="Placementt will provide you a strong foundation for your placement programme, allowing you to easily support students and maximise their development. "
                />
                <CardSection
                    title="Your Complete Placement Platform"
                    titleColour={INSTITUTE_COLOUR}
                    items={[
                        {
                            title: "For Schools",
                            primaryColor: INSTITUTE_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={INSTITUTE_COLOUR} display={"inline"}>Manage your work experience programme</Typography> with ease. Our placement management platform will reduce admin and give you more time to spend on your students.</>,
                            secondaryContent:
                                <List>
                                    <ListItem>
                                        <ListItemIcon><AccountTreeRounded/></ListItemIcon>
                                        <ListItemText primary="Customisable workflow with automated sign-off from employers, parents and students"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><DocumentScannerRounded/></ListItemIcon>
                                        <ListItemText primary="Automatic insurance, DBS and risk assessment acquisition"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><SmsRounded/></ListItemIcon>
                                        <ListItemText primary="Customisable daily logs and feedback from employers and students"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><AnalyticsRounded/></ListItemIcon>
                                        <ListItemText primary="Basic placement analytics"/>
                                    </ListItem>
                                </List>,
                        },
                        {
                            title: "For Multi-Academy Trusts",
                            primaryColor: INSTITUTE_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={INSTITUTE_COLOUR} display={"inline"}>Streamline your schools</Typography> with standardised placement workflows and advanced analytics.</>,
                            secondaryContent:
                            <List>
                                <ListItem>
                                    <ListItemIcon><CheckCircleRounded/></ListItemIcon>
                                    <ListItemText primary="All school features"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><LightbulbRounded/></ListItemIcon>
                                    <ListItemText primary="Centralised, standardised placement processing"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><AssignmentTurnedInRounded/></ListItemIcon>
                                    <ListItemText primary="Manage all school cohorts in one place"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><GroupsRounded/></ListItemIcon>
                                    <ListItemText primary="Advanced analytics and reporting"/>
                                </ListItem>
                            </List>,
                        },
                        {
                            title: "For Universities",
                            primaryColor: INSTITUTE_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={INSTITUTE_COLOUR} display={"inline"}>Develop exceptional young professionals</Typography> with an advanced, secure platform. Expose your students to vibrant opportunities in any sector.</>,
                            secondaryContent:
                            <List>
                                <ListItem>
                                    <ListItemIcon><CheckCircleRounded/></ListItemIcon>
                                    <ListItemText primary="All previous features"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CalendarMonth/></ListItemIcon>
                                    <ListItemText primary="Manage long-term placements"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><SchoolRounded/></ListItemIcon>
                                    <ListItemText primary="Customisable skills tracking"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><ShieldRounded/></ListItemIcon>
                                    <ListItemText primary="Automate organising placement visits"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><BadgeRounded/></ListItemIcon>
                                    <ListItemText primary="Placement assessments for students before, during and after placements."/>
                                </ListItem>
                            </List>,
                        },
                    ]}
                />
                <InstitutePriceCalculator/>
            </PublicTemplate>
            <Testimonials/>
            <PublicTemplate>
                <GDPRPrivacyInfo/>
            </PublicTemplate>
        </Page>
    );
}


export function Testimonials() {
    return (
        <Stack ml={"-16px !important"} alignItems={"center"} pt={3} pb={4} pl={4} pr={4} mr={"-16px !important"} sx={{backgroundImage: `url(${animatedColouredBackground})`}}>
            <Grid container maxWidth={"1000px"}>
                <Card grid xs={12} md={4} sx={{height: "100%"}}>
                    <Stack spacing={0} height={"100%"}>
                        <Stack direction={"row"} mb={2} alignItems={"center"} flex={1}>
                            <Box component={"img"} height={"80px"} src={HallCrossAcademy}/>
                            <Typography variant="h6" color={"#8b0000"}>Hall Cross Academy</Typography>
                        </Stack>
                        <Typography>
                            "The platform has really made communication between the placement, students and school so much more efficient, reducing time spent on administration. We look forward to continuing to work with Placementt in the future to further develop our work experience programme."
                        </Typography>
                        <Typography mt={2} textAlign={"end"} color={"#8b0000"} fontWeight={"bold"}>Mrs D Stokes</Typography>
                        <Typography textAlign={"end"} color={"#8b0000"} fontWeight={"bold"}>Sixth Form Year Manager</Typography>
                    </Stack>
                </Card>
                <Card grid xs={12} md={4} sx={{height: "100%"}}>
                    <Stack spacing={0} height={"100%"}>
                        <Stack direction={"row"} mb={2} alignItems={"center"} flex={1}>
                            <Box component={"img"} height={"80px"} src={EpsomEwell}/>
                            <Typography variant="h6" color={"#006400"}>Epsom and Ewell High School</Typography>
                        </Stack>
                        <Typography>
                        "Placementt… have been thorough in their approach to making the journey for recording work experience as smooth as possible. The best thing about this product is that the culture of the team behind it is all about making things as efficient as possible for us as users; we will certainly be working with them in the future!"
                        </Typography>
                        <Typography mt={2} textAlign={"end"} color={"#006400"} fontWeight={"bold"}>Miss H Cobbold</Typography>
                        <Typography textAlign={"end"} color={"#006400"} fontWeight={"bold"}>Assistant Headteacher</Typography>
                    </Stack>
                </Card>
                <Card grid xs={12} md={4} sx={{height: "100%"}}>
                    <Stack spacing={0} height={"100%"}>
                        <Stack direction={"row"} mb={2} alignItems={"center"} flex={1}>
                            <Box component={"img"} height={"80px"} src={KestevenGrantham}/>
                            <Typography variant="h6" color={"#0080FE"}>Kesteven and Grantham Girls School</Typography>
                        </Stack>
                        <Typography>
                        “Placementt has really simplified and streamlined the administration process related to work experience for us, so is an invaluable tool. We are also assured that it will help us develop a comprehensive database of employers who can accommodate our students in future. Most impressive was how reactive the team at Placementt were to our queries.”
                        </Typography>
                        <Typography mt={2} textAlign={"end"} color={"#0080FE"} fontWeight={"bold"}>Assistant Headteacher</Typography>
                    </Stack>
                </Card>
            </Grid>
        </Stack>
    );
}
