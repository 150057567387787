import {Button, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Stack, Typography} from "@mui/material";
import FadeInBox from "../../../Components/FadeInBox";
import Form from "../../../Components/Form";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import {SectorSelector} from "../../../Components/SectorSelector";
import IconButtonPop from "../../../Components/IconButtonPop";
import {executeCallable} from "placementt-core";
import {ChangeEvent, useState} from "react";
import {Popup} from "../../../Components/Popup";
import {LoadingButton} from "../../../Components/LoadingButton";
import {ArrowBack} from "@mui/icons-material";
import {GradientText} from "../../../Util/styledComponents";
import Dropdown from "../../../Components/FormComponents/Dropdown";

type Params = {
    visible: boolean,
    placement?: {[key:string]: unknown}
    onComplete?: (e: {[key:string]: unknown}) => any,
    back?: () => void,
    uid?: string,
    pId?: string,}

type ProviderMatch = {
    name: string,
    insurance?: boolean,
    placements: number
};

export default function UploadProviderDetails({visible, back, onComplete, uid, pId, placement}: Params) {
    const [providerMatches, setProviderMatches] = useState<[string, ProviderMatch][]>();
    const [providerMatchPopup, setProviderMatchPopup] = useState(false);
    const [inputtedDetails, setInputtedDetails] = useState<{[key:string]: unknown}>(placement || {});

    const onSubmit = async (additionalDetails?:{[key:string]: unknown}, skipSearch=false) => {
        if (!uid) {
            onComplete && await onComplete({...inputtedDetails, ...additionalDetails});
            return;
        }
        const res = await executeCallable("placement-uploadProviderDetails", {
            uid: uid,
            pId: pId,
            placement: {...placement, ...inputtedDetails, ...additionalDetails},
            stage: placement?.currentStage,
            skipSearch: skipSearch}).catch((e) => {
            throw e;
        });

        const resData = res.data as {match?: boolean, matches: [string, ProviderMatch][]};

        console.log("RESDATA", resData);

        if (resData?.match) {
            setProviderMatches(resData.matches);
            setProviderMatchPopup(true);
            return;
        }
        setProviderMatches(undefined);
        setProviderMatchPopup(false);
        onComplete && onComplete((res.data as {[key:string]: unknown}));
    };


    return (
        <>
            <FadeInBox card visible={visible} cardTitle={[<IconButtonPop responsive={false} onClick={back} title={"Back"}><ArrowBack/></IconButtonPop>, <GradientText variant={"h4"} sx={{margin: "auto"}}>{placement ? "Review details" : "Provider details"}</GradientText>]}>
                <Form onSubmit={onSubmit} submitText={"Next"}>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputGroup required name='name' onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, name: e.target.value}))} value={placement?.name} label='Provider name' />
                            </Grid>
                            <Grid item xs={12}>
                                <SectorSelector required sector={placement?.sector as string} subsector={placement?.subsector as string} onSectorChange={(v) => setInputtedDetails((i) => ({...i, sector: v}))} onSubsectorChange={(v) => setInputtedDetails((i) => ({...i, subsector: v}))}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup label="Website" name="website" onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, website: e.target.value}))} value={placement?.website} placeholder="myBusiness.com"/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Contact details</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputGroup required id="forename" value={placement?.contactForename} onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, contactForename: e.target.value}))} label="Contact forename" name="contactForename" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputGroup required id="surname" value={placement?.contactSurname} onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, contactSurname: e.target.value}))} label="Contact surname" name="contactSurname" />
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup required name='providerEmail' value={placement?.providerEmail} onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, providerEmail: e.target.value}))} type='email' label='Contact email' />
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup name='providerPhone' type='tel' pattern="^[0-9]*$" label='Provider phone' onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, providerPhone: e.target.value}))} value={placement?.providerPhone}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Your address</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputGroup required id="address-line1" value={placement?.["address-line1"]} label="Address line one" onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, ["address-line1"]: e.target.value}))} name="address-line1" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputGroup id="address-line2" value={placement?.["address-line2"]} label="Address line two" onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, ["address-line2"]: e.target.value}))} name="address-line2" />
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup required id="locality" value={placement?.locality} label="City" name="locality" onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, locality: e.target.value}))} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputGroup required id="postcode" value={placement?.postal_code} label="Postcode" name="postal_code" onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, postal_code: e.target.value}))} />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Dropdown required id="country" value={placement?.country as string} label="Country" name="country" onChange={(e) => setInputtedDetails((i) => ({...i, country: e.target.value}))} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </FadeInBox>
            <Popup open={providerMatchPopup} onClose={(event, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                setProviderMatchPopup(false);
                return;
            }}
            title={"Provider match found"}>
                <Typography>We've found these providers in our placementt database that match your email. Is this you?</Typography>
                <List>
                    {(providerMatches || []).map(([providerId, match]) =>
                        <ListItem divider>
                            <ListItemText
                                primary={<strong>{match?.name}</strong>}
                                secondary={`Placements: ${match.placements}`} />
                            <ListItemSecondaryAction>
                                <LoadingButton onClick={async () => {
                                    await onSubmit({providerId: providerId}).then(() => setProviderMatchPopup(false));
                                }} text="Select"/>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
                <Stack spacing={2} direction={"row"} sx={{float: "right"}}>
                    <LoadingButton>
                        <Button variant="text" onClick={() => {
                            onSubmit({}, true).then(() => {
                                setProviderMatches(undefined); setProviderMatchPopup(false);
                            });
                        }}>No matches here!</Button>
                    </LoadingButton>
                </Stack>
            </Popup>
        </>
    );
}
