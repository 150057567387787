
import {PRIMARY_COLOUR, SECONDARY_COLOUR} from "placementt-core";


const strokeWidth = 10;

export default function LinearProgressbar({size=200, primary, secondary, primaryText, secondaryText, totalText, label}:{size?: number, primary?:number, secondary?: number, primaryText?:string, secondaryText?:string, totalText?:string, label?:string}) {
    // Ratio of path length to trail length, as a value between 0 and 1
    const getPathRatio = (value:number) => {
        return Math.min(Math.max(value, 0), 100) / 100;
    };

    return (
        <svg
            width={`${size+20}px`}
            viewBox={`${strokeWidth*1.5} ${strokeWidth/2 + (label ? 0 : 5)} ${100} ${strokeWidth*2}`}
            data-test-id="CircularProgressbar"
            overflow={"visible"}
        >

            <Path
                dashRatio={1}
                pathLength={100}
                strokeWidth={strokeWidth}
                style={{stroke: "#eee"}}
            />

            {secondary && <Path
                dashRatio={getPathRatio(secondary)}
                pathLength={100}
                strokeWidth={strokeWidth}
                style={{stroke: SECONDARY_COLOUR}}
            />}

            {primary && <Path
                dashRatio={getPathRatio(primary)}
                pathLength={100}
                strokeWidth={strokeWidth}
                style={{stroke: PRIMARY_COLOUR}}
            />}

            {primaryText && primary &&
                <text y={21} x={primary+20} fill="#ffffff" dominant-baseline="middle" text-anchor="end" fontSize={`${strokeWidth*0.6}px`}>
                    {primaryText}
                </text>}

            {secondaryText && secondary &&
                <text y={21} x={secondary+20} fill="#ffffff" dominant-baseline="middle" text-anchor="end" fontSize={`${strokeWidth*0.6}px`}>
                    {secondaryText}
                </text>}

            {totalText &&
                <text y={21} x={120} fill="#00000050" dominant-baseline="middle" text-anchor="end" fontSize={`${strokeWidth*0.8}px`}>
                    {totalText}
                </text>}

            {label ? (
                <text x={20} y={20-(strokeWidth*0.7)} fontSize={`${strokeWidth*0.6}px`} opacity={0.7} dominant-baseline="start" text-anchor="start">
                    {label}
                </text>
            ) : null}
        </svg>
    );
}


function Path({
    dashRatio,
    pathLength,
    strokeWidth,
    style,
}: {
  dashRatio: number;
  pathLength: number;
  strokeWidth: number;
  style?: object;
}) {
    return (
        <path
            style={Object.assign({}, style, {
                strokeDasharray: `${pathLength}px ${pathLength}px`,
                strokeDashoffset: `${(1 - dashRatio) * pathLength}px`,
                transition: "stroke-dashoffset 0.5s ease 0s"})}
            d={"M 20,20 h 100 "}
            strokeWidth={strokeWidth}
            fillOpacity={0}
            strokeLinecap={"round"}
        />
    );
}
