import {ChangeEvent, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import Page from "../../../Components/Page";
import {Box, Button, CardContent, Chip, Divider, Grid, InputAdornment, MenuItem, Stack, Table, TableCell, TableRow, Typography} from "@mui/material";
import Card from "../../../Components/Card";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Address, ApplicantStage, ApplicantWorkflow, OrganisationAddress, PRIMARY_COLOUR, PlacementListing, PlacementListingStages, PlacementTemplate, camelCaseToNormal, capitaliseWords, decodeGeoHash, executeCallable, useApplicantWorkflowEditor} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {OrganisationContext, UserContext} from "../../../App";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import Dropdown from "../../../Components/FormComponents/Dropdown";
import AddAddressPopup from "../Popups/AddAddressPopup";
import {UserSearch} from "../../Shared/ProposePlacement";
import DateRangePicker from "../../../Components/FormComponents/DateRangePicker";
import ListMaker from "../../../Components/FormComponents/ListMaker";
import {Popup} from "../../../Components/Popup";
import IconButtonPop from "../../../Components/IconButtonPop";
import {AccessibleForward, Clear, ContentCopy, DirectionsBike, DirectionsCar, HelpOutline, Kitchen} from "@mui/icons-material";
import TabButtonGroup from "../../../Components/TabButtonGroup";
import {VerticalPaginatorForm, useVerticalPlacementForm} from "../../../Components/VerticalPaginator";
import {deleteField, where} from "firebase/firestore";
import SubNavBar, {SubNavBarItems} from "../../../Components/Navigation/SubNavBar";
import {Applicants, PlacementsTab} from "../Placements";
import {InfoTableCell} from "../../../Util/styledComponents";
import Map from "../../../Components/Map";
import Files from "../../Shared/Files";
import Alert from "../../../Components/Alert";

const listingStages: PlacementListingStages[] = ["basic", "address", "students", "responsibilities", "applications", "review", "complete"];

const checkPlacementValidity = (placement: PlacementListing) => {
    placement;
    return [];
};


export default function AddPlacementListing() {
    const applicantWorkflowCreatorRef = useRef();
    const [fromTemplate, setFromTemplate] = useState(false);

    const {id}:{id?: string} = useParams();

    const template = useLocation().state?.template || false;

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);
    const orgAddresses = useContext(OrganisationContext).addresses as {[key: string]: OrganisationAddress};
    const applicantWorkflows = useContext(OrganisationContext).applicantWorkflows as {[k: string]: ApplicantWorkflow};

    const [addresses, setAddresses] = useState<{[key: string]: OrganisationAddress}>(orgAddresses);

    const [addAddressPopup, setAddAddressPopup] = useState(false);

    const navigate = useNavigate();


    const onComplete = async (e: {id?: string, item?: Partial<PlacementListing>}) => {
        if (!e.id) throw new Error("No listing ID given");

        console.log("On complete list listing");

        if (template) {
            await firebaseQuery.update(["placementTemplates", e.id], {...e.item, status: "published"});
            navigate(-1);
            return;
        }

        // Verify title, address

        // Verify risk assessment
        const flags = checkPlacementValidity(e.item as PlacementListing);

        // Verify placement questions

        await firebaseQuery.update(["placementListings", e.id], {...e.item, flags: flags, status: "listed"});
        navigate(-1);
        return;
    };

    const {step, changeStep, exitBuilder, updateItem, item, setItem} = useVerticalPlacementForm({
        stages: listingStages,
        path: template ? "placementTemplates" : "placementListings",
        id: id,
        onComplete: onComplete,
        dbUploadData: {
            status: "draft",
            providerId: user.oId,
            applicationType: "simple",
            stage: "address",
        }});

    const deleteItem = async () => {
        if (!id) return false;
        await firebaseQuery.delete([template ? "placementTemplates" : "placementListings", id]);
        return true;
    };


    const templates = useContext(OrganisationContext).placementTemplates as {[k: string]: Partial<PlacementTemplate>};

    const listing = item as PlacementListing&PlacementTemplate;

    console.log("APPLICANT WORKFLOW", listing?.applicantWorkflow);
    const ApplicantWorkflowSelectedItem = (typeof listing?.applicantWorkflowId === "string") ?
        <Stack direction={"row"} p={1} justifyContent={"space-between"} alignItems={"center"} border="1px lightgrey solid" borderRadius={2} width={"100%"}>
            <Stack spacing={0} flex={1}>
                <Typography>{applicantWorkflows[listing?.applicantWorkflowId]?.name}</Typography>
                <Typography variant="caption">{applicantWorkflows[listing?.applicantWorkflowId]?.workflow.length} stages</Typography>
            </Stack>
            <Chip color="primary" label={"Selected"}/>
            <IconButtonPop responsive={false} title="Remove" onClick={async () => {
                updateItem("applicantWorkflowId", deleteField());
            }}><Clear/></IconButtonPop>
        </Stack> : null;

    if (step === undefined) return null;

    return (
        <Page title={`${id ? "Edit" : "Add"} placement ${template ? "template" : "listing"}`} metaTitle={`Placementt | ${id ? "Edit" : "Add"} Listing`} back={true} fullHeight>
            <VerticalPaginatorForm onDelete={deleteItem} deleteText="This will delete the item." onChangeStep={changeStep} saveText={template || listing?.status === "listed" ? "Save and exit" : "Save as draft"} completeText={template ? "Finish" : "List placement"} onComplete={exitBuilder} step={step} items={[
                template || {
                    title: "Let's begin",
                    description: "If you think you will be reusing placement details often, create a template first for your staff to reuse across their placements.",
                    fields:
                        <Stack>
                            <Stack direction={"row"}>
                                <Card sx={{flex: 1}} onClick={() => {
                                    changeStep("forward");
                                    setFromTemplate(false);
                                }}>
                                    Create listing from scratch
                                </Card>
                                {Object.keys(templates).length > 0 && <Card sx={{transition: "150ms all ease-in-out", flex: 1, background: fromTemplate ? PRIMARY_COLOUR : undefined, color: fromTemplate ? "white !important" : undefined}} onClick={() => setFromTemplate(true)}>
                                    Create listing from template
                                </Card>}
                                <Card sx={{flex: 1}} onClick={() => navigate("/providers/addListing", {state: {template: true}})}>
                                    Create new template
                                </Card>
                            </Stack>
                            {fromTemplate &&
                                <Dropdown required name={"template"} label="Select template" onChange={(e) => {
                                    const template = templates[e.target.value];
                                    setItem((l) => ({...l, ...template, template: e.target.value}));
                                }}>
                                    {Object.entries(templates).map(([k, v]) => <MenuItem value={k}>{v.templateName}</MenuItem>)}
                                </Dropdown>}
                        </Stack>,
                },
                {
                    title: "Basic details",
                    description: template ? "All fields in a template are optional. You can later decide what parts people can edit." : "Add some basic details to describe your placement, who can apply, and when the placements are open.",
                    fields: <Grid container>
                        {template && <Grid item xs={12}>
                            <InputGroup name={"templateName"} label="Template name (required)" required onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem("templateName", e.target.value)} value={listing?.templateName}/>
                        </Grid>}
                        <Grid item xs={12}>
                            <InputGroup name={"title"} label="Job title" placeholder={"E.g. Veterinary assistant"} required={!template} onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem("title", e.target.value)} value={listing?.title}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputGroup name={"decription"} placeholder={"Provide a basic description of the placement..."} multiline rows={2} label="Description" required={!template} onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem(["questions", "description"], e.target.value)} value={listing?.questions?.description}/>
                        </Grid>
                        <Grid item xs={12}>
                            <UserSearch onUserChange={(user) => updateItem("primaryContactId", user?.id)} defaultUid={listing?.primaryContactId || user.id} required label="Responsible person" userType="Staff"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>What dates can students book placements on?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TabButtonGroup items={[
                                {
                                    label: "Any dates",
                                    value: "any",
                                    item: <Typography color={"grey"}>Student can apply for placements on any dates.</Typography>,
                                },
                                {
                                    label: "Within a range of dates",
                                    value: "range",
                                    item:
                                            <ListMaker multipleInputs items={listing?.placementDates} label="Set one or multiple date ranges" onChange={(e) => updateItem("placementDates", e)} name="placementDates">
                                                <DateRangePicker required={!template} key={listing?.placementDateType+"-dateRangePicker"} name="date"/>
                                            </ListMaker>,
                                },
                                {
                                    label: "On defined dates",
                                    value: "defined",
                                    item:
                                            <ListMaker multipleInputs items={listing?.placementDates} label="Define one or multiple specific dates" onChange={(e) => {
                                                console.log("Dates", e);
                                                updateItem("placementDates", e);
                                            }} name="placementDates">
                                                <DateRangePicker required={!template} key={listing?.placementDateType+"-dateRangePicker"} name="date"/>
                                            </ListMaker>,
                                },
                            ]}
                            onChange={(val) => {
                                if (!val) return;
                                setItem((l) => ({...l, placementDates: undefined}));
                                updateItem("placementDateType", val);
                            }}
                            />
                        </Grid>
                        {listing?.placementDateType !== "defined" && <>
                            <Grid item xs={4}>
                                <InputGroup label={"Min length"} error={(listing?.questions?.desiredMaxLength || 111110) < (listing?.questions?.desiredMinLength || 0)} min={1} name={"desiredMinLength"} value={listing?.questions?.desiredMinLength} type={"number"} onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem(["questions", "desiredMinLength"], parseInt(e.target.value))}/>
                            </Grid>
                            <Grid item xs={4}>
                                <InputGroup label={"Max length"} error={(listing?.questions?.desiredMaxLength || 111110) < (listing?.questions?.desiredMinLength || 0)} min={1} name={"desiredMaxLength"} value={listing?.questions?.desiredMaxLength} type={"number"} onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem(["questions", "desiredMaxLength"], parseInt(e.target.value))}/>
                            </Grid>
                            <Grid item xs={4}>
                                <Dropdown label={"Units"} required={!template} name={"desiredLengthUnits"} value={listing?.questions?.desiredLengthUnits || "weeks"}>
                                    <MenuItem value={"days"}>Days</MenuItem>
                                    <MenuItem value={"weeks"}>Weeks</MenuItem>
                                    <MenuItem value={"months"}>Months</MenuItem>
                                </Dropdown>
                            </Grid>
                        </>}
                    </Grid>,
                },
                {
                    title: "Location and Hours",
                    description: "Where will your placement primarily be based?",
                    fields: <>
                        <Dropdown required={!template} name="address" label="Address" value={listing?.addressId} onChange={(e) => updateItem("addressId", e.target.value)}>
                            {Object.entries(addresses).map(([key, address]) => <MenuItem value={key}>
                                <Stack spacing={0}>
                                    <Typography>{address.name}</Typography>
                                    <Typography variant="caption">{address?.["address-line1"]} | {address?.postal_code}</Typography>
                                </Stack>
                            </MenuItem>)}
                        </Dropdown>
                        <Dropdown label={"Working location"} required={!template} name={"workingLocation"} onChange={(e) => updateItem(["questions", "workingLocation"], e.target.value)} value={listing?.questions?.workingLocation}>
                            <MenuItem value={"inPerson"}>In-person</MenuItem>
                            <MenuItem value={"hybrid"}>Hybrid</MenuItem>
                            <MenuItem value={"remote"}>Remote</MenuItem>
                        </Dropdown>
                        <Typography onClick={() => setAddAddressPopup(true)} fontSize={14} color={PRIMARY_COLOUR} sx={{textDecorationLine: "underline", cursor: "pointer"}} textAlign={"center"}>Add new address</Typography>
                        <Grid item xs={12}>
                            <InputGroup required={!template} value={listing?.questions?.hours} onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem(["questions", "hours"], e.target.value)} name={"hours"} label={"Working days and hours"} placeholder={"Mon-Fri 9-5"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Primary contact details</Typography>
                        </Grid>
                        <UserSearch onUserChange={(user) => updateItem("contactId", user?.id)} defaultUid={user.id} label="Select primary contact" userType="Staff"/>
                    </>,
                },
                {
                    title: "Students",
                    fields:
                        <Stack>
                            <Typography>What students will your placement take?</Typography>
                            <Dropdown label={"Student level"} required={!template} name={"studentLevel"} onChange={(e) => updateItem(["questions", "studentLevel"], e.target.value)} value={listing?.questions?.studentLevel}>
                                <MenuItem value={"gcse"}>14-16 years old (GCSE)</MenuItem>
                                <MenuItem value={"college"}>16-18 years old (Sixth form / college)</MenuItem>
                                <MenuItem value={"over18"}>18+ years old</MenuItem>
                                <MenuItem value={"undergraduate"}>Undergraduate</MenuItem>
                                <MenuItem value={"postgraduate"}>Postgraduate</MenuItem>
                            </Dropdown>
                            {listing?.questions?.studentLevel && <Dropdown multiple label={"Student level (Multi-select)"} required={!template} onChange={(e) => updateItem(["questions", "studentSublevel"], e.target.value)} value={listing?.questions?.studentSublevel}>
                                <MenuItem value={"1"}>Year 1</MenuItem>
                                <MenuItem value={"2"}>Year 2</MenuItem>
                                <MenuItem value={"3"}>Year 3</MenuItem>
                                <MenuItem value={"4"}>Year 4</MenuItem>
                                <MenuItem value={"5"}>Year 5</MenuItem>
                                <MenuItem value={"6"}>Year 6</MenuItem>
                            </Dropdown>}
                            <InputGroup label={"How many students can you take at once?"} name={"maxStudents"} value={listing?.maxStudents} onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem("maxStudents", e.target.value)} type={"number"} min={1} placeholder={"Leave blank if no limit."}/>
                            <Typography>If you have different placement details for different students, you can duplicate this placement. E.g. Different experiences given to first and second year students.</Typography>
                            <Grid container>
                                <Grid item xs={12} md={listing?.questions?.dressCode === "other" ? 6 : 12}>
                                    <Dropdown label={"Dress code"} required={!template} name={"dressCode"} value={listing?.questions?.dressCode} onChange={(e) => updateItem(["questions", "dressCode"], e.target.value)}>
                                        <MenuItem value={"businessFormal"}>Business Formal</MenuItem>
                                        <MenuItem value={"businessProfessional"}>Business Professional</MenuItem>
                                        <MenuItem value={"businessCasual"}>Business Casual</MenuItem>
                                        <MenuItem value={"casual"}>Casual</MenuItem>
                                        <MenuItem value={"other"}>Other</MenuItem>
                                    </Dropdown>
                                </Grid>
                                {listing?.questions?.dressCode === "other" && <Grid item xs={12} md={6}>
                                    <InputGroup value={listing?.questions?.dressCodeText} name={"dressCodeText"} onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem(["questions", "dressCodeText"], e.target.value)} label={"Dress code"} placeholder={"Please specify"} required={!template}/>
                                </Grid>}
                                <Grid item xs={6}>
                                    <InputGroup InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                    }} label={"Pay"} onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem("pay", e.target.value)} type={"number"} min={0}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <Dropdown label="Timeframe" onChange={(e) => updateItem("payFrequency", e.target.value)}>
                                        <MenuItem value={"total"}>Total</MenuItem>
                                        <MenuItem value={"hourly"}>per hour</MenuItem>
                                        <MenuItem value={"daily"}>per day</MenuItem>
                                        <MenuItem value={"weekly"}>per week</MenuItem>
                                        <MenuItem value={"monthly"}>per month</MenuItem>
                                        <MenuItem value={"yearly"}>per year</MenuItem>
                                    </Dropdown>
                                </Grid>
                            </Grid>
                        </Stack>,
                },
                {
                    title: "Listing Details",
                    description: "Describe the placement to get the best match with students.",
                    fields:
                        <Grid container>
                            <Grid item xs={12} mt={-3}>
                                <Alert severity="info">This is a lot if information! To save you time, you can copy this placement from the Listings page, by clicking the <ContentCopy fontSize="small"/> icon.</Alert>
                            </Grid>
                            <Grid item xs={12}>
                                <ListMaker required={!template} items={listing?.questions?.responsibilities} name={"responsibilities"} onChange={(value) => updateItem(["questions", "responsibilities"], value)} label={"Responsibilities"} subtitle='What will the student be responsible for?'>
                                    <InputGroup placeholder={"Start typing..."} />
                                </ListMaker>
                            </Grid>
                            <Grid item xs={12}>
                                <ListMaker items={listing?.questions?.equipment} name={"equipment"} onChange={(value) => updateItem(["questions", "equipment"], value)} label={"Required equipment"} subtitle='What should the student bring?'>
                                    <InputGroup placeholder={"Start typing..."} />
                                </ListMaker>
                            </Grid>
                            <Grid item xs={12}>
                                <ListMaker items={listing?.questions?.experiencesGiven} name={"experiencesGiven"} onChange={(value) => updateItem(["questions", "experiencesGiven"], value)} label={"Experience given"} subtitle='What experiences can the student expect to gain?' required={!template}>
                                    <InputGroup placeholder={"Start typing..."} />
                                </ListMaker>
                            </Grid>
                            <Grid item xs={12}>
                                <ListMaker items={listing?.questions?.experienceDesired} name={"experienceDesired"} onChange={(value) => updateItem(["questions", "experienceDesired"], value)} label={"Experience desired"} subtitle='What skills will the student need to be proficient in?'>
                                    <InputGroup placeholder={"Start typing..."} />
                                </ListMaker>
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup multiline rows={3} value={listing?.questions?.otherAdvice} name={"otherAdvice"} onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem(["questions", "otherAdvice"], e.target.value)} label={"Other relevant information"} placeholder={"E.g. Preparation, directions"}/>
                            </Grid>
                        </Grid>,
                },
                {
                    title: "Applicants",
                    description: "How would you like people to apply?",
                    onChangeStep: async () => {
                        console.log("application type:", listing.applicationType);
                        if (listing.applicationType === "simple" || !listing.applicationType) {
                            const workflowId = await (applicantWorkflowCreatorRef?.current as {submitForm: () => Promise<void>}|undefined)?.submitForm();
                            console.log("WORKFLOW ID", workflowId);
                            return {applicantWorkflowId: workflowId};
                        }
                    },
                    fields: <Grid container>
                        <Grid item xs={12} mt={-3}>
                            <Alert severity="info">You can set up <strong>onboarding</strong> and <strong>feedback</strong> later. For applications, only set up what students should complete when they apply.</Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <InputGroup label={"Limit number of applicants at one time?"} placeholder={"This field is optional"} name={"maxApplicants"} type={"number"} min={1} value={listing?.maxApplicants} onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem("maxApplicants", e.target.value)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>How do you want students to apply?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TabButtonGroup
                                value={listing?.applicationType}
                                items={[
                                    {
                                        label: "Basic set-up",
                                        value: "simple",
                                        item: <SimpleApplicantWorkflow ref={applicantWorkflowCreatorRef} workflowId={typeof listing?.applicantWorkflowId === "string" ? listing.applicantWorkflowId : undefined} onSubmit={async (e) => {
                                            console.log("SUBMIT WORKFLOW");
                                            if (typeof listing?.applicantWorkflowId === "string") {
                                                await firebaseQuery.update(["applicantWorkflows", listing.applicantWorkflowId], {
                                                    workflow: e.workflow,
                                                    files: e.includedFiles,
                                                    forms: e.includedForms,
                                                });
                                                return;
                                            }
                                            console.log("Creating applicant ID");
                                            const applicantWorkflowId = (await firebaseQuery.add(["applicantWorkflows"], {
                                                type: "simple",
                                                workflow: e.workflow,
                                                oId: user.oId,
                                                product: user.product,
                                                name: listing.name + " applicant workflow",
                                                files: e.includedFiles,
                                                forms: e.includedForms,
                                            } as ApplicantWorkflow)).id;
                                            return applicantWorkflowId;
                                        }}/>,
                                    },
                                    {
                                        label: "Select existing workflow",
                                        value: "complex",
                                        item:
                                        <>
                                            <Typography>Select an existing applicant workflow.</Typography>
                                            {(typeof listing?.applicantWorkflowId === "string") ? ApplicantWorkflowSelectedItem : <Dropdown required={!template} name="applicantWorkflow" label="Applicant workflow" value={typeof listing?.applicantWorkflowId === "string" ? listing.applicantWorkflowId : undefined} onChange={(e) => updateItem("applicantWorkflowId", e.target.value)}>
                                                {Object.entries(applicantWorkflows).map(([key, workflow]) => <MenuItem value={key}>
                                                    <Stack spacing={0}>
                                                        <Typography>{workflow.name}</Typography>
                                                        <Typography variant="caption">{workflow.workflow.length} stages</Typography>
                                                    </Stack>
                                                </MenuItem>)}
                                            </Dropdown>}
                                        </>,
                                    },
                                ]}
                                onChange={(val) => {
                                    if (!val) return;
                                    if (listing.applicationType === "simple") {
                                        const oldId = listing.applicantWorkflowId;
                                        if (typeof oldId === "string") {
                                            firebaseQuery.getCount(["placementListings"], [where("providerId", "==", user.oId), where("applicantWorkflowId", "==", oldId)]).then((count) => {
                                                console.log("COUNT", count);
                                                if (count > 1) return;
                                                firebaseQuery.delete(["applicantWorkflows", oldId]);
                                            });
                                        }
                                    }
                                    updateItem("applicantWorkflowId", deleteField());
                                    updateItem("applicationType", val);
                                }}
                            />
                        </Grid>
                    </Grid>,
                },
                {
                    title: "Review",
                    fields: <Table>
                        <TableRow>
                            <TableCell>Job title</TableCell>
                            <TableCell>{listing?.title}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>{listing?.questions?.description}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>{listing?.addressId ? `${addresses[listing?.addressId]?.["address-line1"]} | ${addresses[listing?.addressId]?.postal_code}` : "Not set"}</TableCell>
                        </TableRow>
                    </Table>,
                },
            ].filter((i) => typeof i === "object")}/>
            <AddAddressPopup active={addAddressPopup} onToggle={() => setAddAddressPopup(false)} onSubmit={(address) => {
                setAddresses((a) => ({...a, [address.id || ""]: address}));
                setAddAddressPopup(false);
            }}/>
        </Page>
    );
}

export function PlacementListingPage() {
    const {id} = useParams();
    const [listing, setListing] = useState<PlacementListing>();
    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        if (!id) return;
        firebaseQuery.getDocData(["placementListings", id]).then((data) => setListing(data as PlacementListing));
    }, [id]);

    const navigate = useNavigate();

    const orgAddresses = useContext(OrganisationContext).addresses as {[key: string]: OrganisationAddress};

    if (!listing) return null;
    const address = orgAddresses[listing.addressId || ""];

    console.log("LISTING", listing);

    return (
        <Page back titleSecondary={<Button onClick={() => navigate("../addListing/"+listing.id)}>Edit listing</Button>} title={listing.title} subtitle={`${address?.["address-line1"]} | ${address?.postal_code.toUpperCase()} | ${capitaliseWords(camelCaseToNormal(address?.country))}`} metaTitle={`Placementt | ${listing.title}`}>
            <SubNavBar pathLevel={4} items={[{
                label: "Overview",
                route: "overview",
                element: <PlacementListingPageOverview listing={listing} address={address}/>,
            },
            {
                label: "Applicants",
                route: "applicants",
                element: <Applicants listingId={listing.id}/>,
            },
            {
                label: "Placements",
                route: "placements",
                element: <PlacementsTab listingId={listing.id}/>,
            }] as SubNavBarItems}/>
        </Page>
    );
}

function PlacementListingPageOverview({listing, address}:{listing: PlacementListing, address: Address}) {
    const location = address.geoHash ? decodeGeoHash(address.geoHash) : undefined;
    const [primaryUserData, setPrimaryUserData] = useState<{forename: string, surname: string, pronouns?: string}>();

    useEffect(() => {
        executeCallable("placementListing-getAssociatedStaffDetails", {placementId: listing.id}).then((d) => setPrimaryUserData(d.data as {forename: string, surname: string, pronouns?: string}));
    }, []);
    return (
        <CardContent>
            <Stack>
                <Card title={"Overview"} expandable="200px">
                    <Typography>{listing?.questions?.description}</Typography>
                    <br/>
                    <Typography variant='h6' color={"primary"}>Experience given</Typography>
                    <Stack direction={"row"} flexWrap={"wrap"}>
                        {listing.questions.experiencesGiven ? (Array.isArray(listing.questions.experiencesGiven) ? listing.questions.experiencesGiven : listing.questions.experiencesGiven.split(", ")).sort((a, b) => b.length - a.length).map((exp) =>
                            <Typography minWidth={"calc(50% - 20px)"} ml={"0 !important"}>• {exp}</Typography>
                        ) : <Typography>No information given.</Typography>}
                    </Stack>
                    <br/>
                    <Typography variant='h6' color={"primary"}>Experience required</Typography>
                    <Stack direction={"row"} flexWrap={"wrap"}>
                        {listing.questions.experienceDesired ? (Array.isArray(listing.questions.experienceDesired) ? listing.questions.experienceDesired : listing.questions.experienceDesired.split(", ")).sort((a, b) => b.length - a.length).map((exp) =>
                            <Typography minWidth={"calc(50% - 20px)"} ml={"0 !important"}>• {exp}</Typography>
                        ) : <Typography>No information given.</Typography>}
                    </Stack>
                    <br/>
                    <Table size="small">
                        <TableRow>
                            <InfoTableCell width={"200px"}>Dress code</InfoTableCell>
                            <InfoTableCell>{camelCaseToNormal(listing.questions.dressCode)}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>Primary contact</InfoTableCell>
                            <InfoTableCell>{primaryUserData?.forename} {primaryUserData?.surname} {primaryUserData?.pronouns ? <span style={{opacity: 0.7}}>({primaryUserData.pronouns})</span> : undefined}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>Working location</InfoTableCell>
                            <InfoTableCell>{camelCaseToNormal(listing.questions.workingLocation)}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>Responsibilities</InfoTableCell>
                            <InfoTableCell>{listing?.questions?.responsibilities}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>Required equipment</InfoTableCell>
                            <InfoTableCell>{listing?.questions?.equipment}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>Other information</InfoTableCell>
                            <InfoTableCell>{listing?.questions?.otherAdvice}</InfoTableCell>
                        </TableRow>
                    </Table>

                    <Stack direction={"row"}>
                        <strong>Amenities: </strong>
                        {listing.questions.carParking && <IconButtonPop responsive={false} title='Car parking' sx={{color: "gray"}}><DirectionsCar/></IconButtonPop>}
                        {listing.questions.bikeStorage && <IconButtonPop responsive={false} title='Bike storage' sx={{color: "gray"}}><DirectionsBike/></IconButtonPop>}
                        {listing.questions.wheelchairFriendly && <IconButtonPop responsive={false} title='Wheelchair friendly' sx={{color: "gray"}}><AccessibleForward/></IconButtonPop>}
                        {listing.questions.kitchen && <IconButtonPop responsive={false} title='Kitchen' sx={{color: "gray"}}><Kitchen/></IconButtonPop>}
                    </Stack>
                </Card>
                {address && <Card title={"Address"}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <Typography>{capitaliseWords(address["address-line1"])+" · "+capitaliseWords(address["address-line2"])}</Typography>
                            <Typography>{capitaliseWords(address.locality)+" · "+address.postal_code.toUpperCase()}</Typography>
                            <Typography>{capitaliseWords(camelCaseToNormal(address.country))}</Typography>
                        </Grid>
                    </Grid>
                    {location && <Box sx={{position: "relative", overflow: "hidden"}} height={"300px"} borderRadius={"10px"}>
                        <Map lat={location.latitude} lng={location.longitude} zoom={15} points={[[location.latitude, location.longitude]]}/>
                    </Box>}
                </Card>}
            </Stack>
        </CardContent>
    );
}

const SimpleApplicantWorkflow = forwardRef(({workflowId, onSubmit}:{workflowId?: string, onSubmit: (e: {
    workflow: ApplicantStage[];
    includedFiles: string[];
    includedForms: string[];
}) => any}, ref) => {
    useImperativeHandle(ref, () => ({
        async submitForm() {
            return await submitWorkflow();
        },
    }));

    const user = useContext(UserContext);
    const org = useContext(OrganisationContext);
    const forms = org.forms as {[key:string]: {name: string}};

    const [formInfoPopup, setFormInfoPopup] = useState(false);
    const [fileInfoPopup, setFileInfoPopup] = useState(false);

    const {workflowNodes, onChange, filePopupActive, setFilePopupActive, submitWorkflow} = useApplicantWorkflowEditor({user: user, onSubmit: onSubmit, workflowId: workflowId});

    if (!workflowNodes.length) return null;

    return (
        <Stack spacing={1} width={"100%"}>
            <Files selectable="multiple" defaultSelected={workflowNodes.find((n) => n.id === 1)?.files} type="org" popup open={filePopupActive} onClose={() => setFilePopupActive(false)} onSubmitSelection={(s) => {
                onChange([1, "files"], s);
                setFilePopupActive(false);
            }}/>
            <Box>
                <Typography mt={1}>Select custom forms for the student to complete <IconButtonPop responsive={false} title="Click to learn more" onClick={() => setFormInfoPopup(true)}><HelpOutline/></IconButtonPop></Typography>
                <Dropdown
                    label='Forms to complete'
                    multiple
                    key={"submissionForms"}
                    value={workflowNodes.find((n) => n.id === 1)?.forms}
                    onChange={(e) => onChange([1, "forms"], (e.target.value as unknown as unknown[]).filter((x: unknown) => x !== undefined))}>
                    {forms && Object.entries(forms).map(([key, e]) => {
                        return (<MenuItem value={key}>{e.name}</MenuItem>);
                    })}
                    <Divider/>
                    <MenuItem onClick={()=>window.open(`/${user.product}/organisation/resources/f`, "_blank")}>
                        {"Create form"}
                    </MenuItem>
                </Dropdown>
            </Box>
            <Box>
                <Typography mt={1}>Select files for the student to review.</Typography>
                <Stack direction={"row"} alignItems={"stretch"}>
                    <Box border={`1px solid ${PRIMARY_COLOUR}`} borderRadius={1} display="flex" alignItems="center" pl={1} flex={1}>
                        {workflowNodes.find((n) => n.id === 1)?.files ? `${workflowNodes.find((n) => n.id === 1)?.files?.length} file${(workflowNodes.find((n) => n.id === 1)?.files as string[])?.length > 1 ? "s" : ""} selected` : "No files selected"}
                    </Box>
                    <Button onClick={() => setFilePopupActive(true)}>Select files</Button>
                </Stack>
            </Box>
            <Divider/>
            <ListMaker name="requiredFiles" noDefault onChange={(items) => onChange([1, "requiredFiles"], items)} items={workflowNodes.find((n) => n.id === 1)?.requiredFiles} label="Required documents for students to upload" multipleInputs>
                <Grid container mt={"-16px !important"}>
                    <Grid item xs={6}>
                        <InputGroup label={"Document name"} name={"fileName"} placeholder={"One file, such as CV"} required/>
                    </Grid>
                    <Grid item xs={6}>
                        <Dropdown label="Document type" required name="fileType">
                            <MenuItem value={"application/pdf"}>PDF</MenuItem>
                            <MenuItem value={"image/png, image/jpeg"}>Image</MenuItem>
                            <MenuItem value={"video/mp4, video/ogg"}>Video</MenuItem>
                            <MenuItem value={"audio/ogg, audio/mpeg"}>Voice note</MenuItem>
                            <Typography p={2} variant="caption">Need another? <Link to={"mailto:admin@placementt.co.uk"}>Contact us</Link></Typography>
                        </Dropdown>
                    </Grid>
                </Grid>
            </ListMaker>
            <Popup title={"Creating custom forms"} open={formInfoPopup} onClose={() => setFormInfoPopup(false)}>
                <Stack>
                    <Typography>If you require additional information that we do not already cover, you can generate customised forms through our form builder.</Typography>
                    <Typography>These forms can be assigned to staff, employers or parents, who will be required to complete them before submitting and progressing the placement.</Typography>
                    <Typography>When you first view the form builder, you can view a short tutorial on how to use it.</Typography>
                    <Typography>To create a form, click the dropdown and select to create a form. Otherwise, navigate to Organisation → Resources.</Typography>
                </Stack>
            </Popup>
            <Popup title={"Uploading custom files"} open={fileInfoPopup} onClose={() => setFileInfoPopup(false)}>
                <Stack>
                    <Typography>If you require your employers, staff or parents to view specific forms, you can upload them to the platform and select for your users to review them.</Typography>
                    <Typography>Users will be required to view any forms you select on their stage in order to progress their placement.</Typography>
                    <Typography>To upload a file, click the dropdown and select to upload. Otherwise, navigate to Organisation → Resources.</Typography>
                </Stack>
            </Popup>
        </Stack>
    );
});

