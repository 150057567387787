import {useContext, useEffect, useState} from "react";
import {Button, Stack, Typography, Radio, FormControlLabel, RadioGroup, Chip, Snackbar, IconButton, DialogContent, DialogTitle, Box, Dialog, Grid, ToggleButton, MenuItem, Accordion, AccordionSummary, AccordionDetails, ToggleButtonGroup, Divider, Link, Alert} from "@mui/material";
import UserUploadPopup from "./Cohorts/Popups/UserUploadPopup";
import FilterTable, {FilterObject} from "../../../Components/FilterTable";
import ActivateStudentsPopup from "./Cohorts/Popups/ActivateStudentsPopup";
import {CheckCircleOutline, Close, Delete, Email, Group, School} from "@mui/icons-material";
import Form from "../../../Components/Form";
import IconButtonPop from "../../../Components/IconButtonPop";
import {OrganisationContext, UserContext} from "../../../App";
import {CohortData, Job, OrganisationAddress, PRIMARY_COLOUR, QueryObject, UserData, UserGroupData, camelCaseToNormal, capitalise, executeCallable, getAccess, getRandomNumber, useAppSelector, useCohortUserPaginator, useExecuteCallableJob} from "placementt-core";
import {Popup} from "../../../Components/Popup";
import Dropdown from "../../../Components/FormComponents/Dropdown";
import {orderBy, where} from "firebase/firestore";
import ListMaker from "../../../Components/FormComponents/ListMaker";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import {LoadingButton} from "../../../Components/LoadingButton";
import SendParentWelcomeEmailPopup from "./Cohorts/Popups/SendParentWelcomeEmailPopup";

type UserTableParams = {
    userType: "Staff"|"Students",
    cohortId?: string|"all",
    queries: QueryObject[],
    onItemClick?: (key: string) => void,
    noButtons?: boolean,
    onUserCountChange?: () => void
}

export function UserTable({userType, noButtons, cohortId="", queries, onItemClick, onUserCountChange}: UserTableParams) {
    const [selectedUsers, setSelectedUsers] = useState<"All"| {[key: string]: UserData}>({});
    const [addUsersPopup, setAddUsersPopup] = useState(false);
    const [activateAccountPopup, setActivateAccountPopup] = useState(false);
    const [sendParentWelcomeEmailPopup, setSendParentWelcomeEmailPopup] = useState(false);

    const [userGroupPopup, setUserGroupPopup] = useState(false);
    const [cohortPopup, setCohortPopup] = useState(false);
    const [deleteUsersPopup, setDeleteUsersPopup] = useState(false);
    const [selectedUserGroup, setSelectedUserGroup] = useState<string>();

    const [snackbarText, setSnackbarText] = useState("");
    const [tableFilters, setTableFilters] = useState<{[key:string]: unknown}>();
    const [uploadJob, setUploadJob] = useState<string>();
    const [userTableKey, setUserTableKey] = useState(getRandomNumber(0, 100000));

    const jobs = useAppSelector((state) => state.jobs.values) as {[key: string]: Job}|undefined;
    const org = useContext(OrganisationContext);
    const cohorts = org.cohorts;
    const schools = org.schools;

    const user = useContext(UserContext);
    const {execute} = useExecuteCallableJob({user: user});

    const access = getAccess(user, `add${userType}`, `edit${userType}`, `delete${userType}`);

    useEffect(() => {
        if (!uploadJob || !jobs) return;

        const job = jobs[uploadJob];
        if (job.status !== "processing") {
            setUserTableKey(getRandomNumber(0, 100000));
            setUploadJob(undefined);
            onUserCountChange && onUserCountChange();
        }
    }, [jobs]);

    if (user.product === "providers" && (userType === "Students" || cohortId)) {
        throw new Error("Providers cannot upload students or cohorts.");
    }


    const editUserGroup = () => {
        executeCallable("userManagement-editUserGroup", {users: selectedUsers === "All" ? queries : selectedUsers, filters: cohortId ? {...tableFilters, cohort: cohortId} : tableFilters, userGroup: selectedUserGroup, userType: userType});
        setUserGroupPopup(false);
        setSelectedUserGroup(undefined);
        setSnackbarText("Processing user group change.");
    };

    const columns = user.product === "providers" ? ["forename", "surname", "email", "status", "userGroup", "visibleSchools", "visibleGroups"] :
        userType === "Staff" ? ["forename", "surname", "email", "status", "userGroup", "visibleSchools", "visibleCohorts", "viewStudents"] : ["forename", "surname", "email", "status", ...(cohortId === "all" ? ["cohort"] : [])];

    const columnTemplate = {
        "userGroup": (groups: string) => groups.split(", ").map((group) => <Chip color={"primary"} label={group}/>),
        "cohort": (c: string) => c.split(", ").map((cohort) => <Chip color={"primary"} label={cohorts[cohort]?.name || "Unknown cohort"}/>),
        "visibleCohorts": (c: string[], row: {[key:string]: unknown}) => <Chip color={"primary"} label={(row.viewCohorts || row.userGroup === "admin") ? (row.viewCohorts === "all" || row.userGroup === "admin") ? "All" : row.viewCohorts === "none" ? "None" : c?.length === 1 ? cohorts[c[0]].name : c?.length + " cohorts" : "None"}/>,
        "visibleSchools": (c: string[], row: {[key:string]: unknown}) => <Chip color={"primary"} label={(row.viewSchools || row.userGroup === "admin") ? (row.viewSchools === "all" || row.userGroup === "admin") ? "All" : row.viewSchools === "none" ? "None" : c?.length === 1 ? schools[c[0]].name : c?.length + " schools" : "None"}/>,
        "visibleGroups": (c: string, row: {[key:string]: unknown}) => <Chip color={"primary"} label={(row.viewGroups || row.userGroup === "admin") ? (row.viewGroups === "all" || row.userGroup === "admin") ? "All" : row.viewGroups === "none" ? "None" : c?.split(",").length === 1 ? cohorts[c.split(",")[0]].name : c?.split(",").length + " groups" : "None"}/>,
        "viewStudents": (c: string, row: {[key:string]: unknown}) => <Chip sx={{maxWidth: "100px"}} color={"primary"} label={(row.viewStudents !== "none" || row.userGroup === "admin") ? (row.viewStudents === "all" || row.userGroup === "admin") ? "All" : `${camelCaseToNormal(row.studentFilter as string)}: ${row.studentFilterValues}` : "None"}/>,
        "status": (s: string) => capitalise(s),
    };
    const filters:FilterObject = {
        ["details.forename"]: {
            label: "Forename",
            type: "string",
        },
        ["details.surname"]: {
            label: "Surname",
            type: "string",
        },
        ["details.email"]: {
            label: "Email",
            type: "string",
        },
        status: {
            label: "Status",
            values: {active: "Active", inactive: "Inactive"},
            type: "dropdown",
        },
        userGroup: {
            label: "User group",
            values: Object.fromEntries(Object.entries(org.userGroups as {[key:string]: UserGroupData}).filter(([, group]) => group.template === userType).map(([id, group]) => [id, group.name])),
            type: "dropdown",
        },
    };

    if (cohortId === "all") {
        filters.cohort = {
            label: "Cohort",
            values: Object.fromEntries(Object.entries(org.cohorts as {[key:string]: CohortData}).map(([id, group]) => [id, group.name])),
            type: "dropdown",
        };
    }

    if (userType === "Students") {
        filters.parentEmail = {
            label: "Parent email",
            values: {false: {label: "Doesn't exist", test: where("details.parentEmail", "==", "null")}},
            type: "dropdown",
        };
        filters.active = {
            label: "Activated",
            values: {true: {label: "True", test: where("active", "==", true)}, false: {label: "False", test: where("active", "==", false)}},
            type: "dropdown",
        };
        filters.placements = {
            label: "Has placements",
            values: {true: {label: "True", test: [where("placements", "!=", 0), orderBy("placements")]}, false: {label: "False", test: where("placements", "==", 0)}},
            type: "dropdown",
        };
    }

    if (!userType) {
        return null;
    }

    console.log("RENDER FGENAUIGIB", queries);

    return (
        <Box style={{minHeight: "400px", padding: "16px", width: "100%"}}>
            <FilterTable
                key={userTableKey}
                search
                viewRows
                filters={filters}
                {...{columns, columnTemplate, onItemClick}}
                setTableFilters={setTableFilters}
                access={Boolean(queries.length)}
                data={queries}
                setSelectedRows={(e) => setSelectedUsers(e as "All"|{[key:string]: UserData})}
                hook={(data, query) => useCohortUserPaginator({user: user, cohort: cohortId, data: data as QueryObject[], userType: userType, search: query})}
                formatQueryData={(data) =>
                    Object.entries(data as {[key:string]: UserData&{userGroupId: string}}).filter(([id]) =>
                        id !== user.id).reduce((acc, [uid, user]) => {
                        if (acc[uid]) return acc;

                        if (user.userGroupId) {
                            acc[uid] = user;
                            return acc;
                        }

                        return {...acc, [uid]: {...user, ...user.details, "userGroup": user.userGroup === "admin" ? "admin" : org.userGroups[user.userGroup || ""].name, "userGroupId": user.userGroup}};
                    }, {} as {[key:string]: unknown})
                }
                actionButtonText={!noButtons && cohortId !== "all" && access[`add${userType}`] && `Add ${userType.toLowerCase()}`}
                onActionButtonClick={() => setAddUsersPopup(true)}>
                {!noButtons && access[`edit${userType}`] && cohortId !== "all" && <IconButtonPop variant="contained" title={`Activate ${userType.toLowerCase()}`} onClick={() => setActivateAccountPopup(true)}><CheckCircleOutline/></IconButtonPop>}
                {!noButtons && access["editStudents"] && cohortId !== "all" && userType === "Students" && <IconButtonPop title={"Send parent welcome email"} onClick={() => setSendParentWelcomeEmailPopup(true)}><Email/></IconButtonPop>}
                {!noButtons && userType === "Staff" && access[`edit${userType}`] && <IconButtonPop title={"Set user group"} onClick={() => {
                    setUserGroupPopup(true);
                }}><Group/></IconButtonPop>}
                {!noButtons && userType === "Staff" && access[`edit${userType}`] && <IconButtonPop title={"Change student visibility"} onClick={() => {
                    setCohortPopup(true);
                }}><School/></IconButtonPop>}
                {!noButtons && access[`delete${userType}`] && <IconButtonPop color="error" title={"Delete users"} onClick={() => {
                    setDeleteUsersPopup(true);
                }}><Delete/></IconButtonPop>}
            </FilterTable>
            {cohortId !== "all" && (cohortId || userType === "Staff") && <UserUploadPopup onComplete={setUploadJob} key={`${userType}UploadPopup`} userType={userType} cohortId={cohortId} active={addUsersPopup} onToggle={setAddUsersPopup}/>}
            {cohortId !== "all" && userType === "Students" && <ActivateStudentsPopup cohortId={cohortId} active={activateAccountPopup} filters={tableFilters} users={selectedUsers === "All" ? queries : selectedUsers} onToggle={setActivateAccountPopup}/>}
            {cohortId !== "all" && userType === "Students" && <SendParentWelcomeEmailPopup cohortId={cohortId} active={sendParentWelcomeEmailPopup} filters={tableFilters} users={selectedUsers === "All" ? queries : selectedUsers} onToggle={setSendParentWelcomeEmailPopup}/>}
            {cohortId !== "all" && userType === "Staff" && <ActivateInstituteStaffPopup type={activateAccountPopup ? "activate" : cohortPopup ? "editVisibility" : undefined} open={activateAccountPopup || cohortPopup} filters={tableFilters} users={selectedUsers === "All" ? queries : selectedUsers} onClose={() => {
                setActivateAccountPopup(false);
                setCohortPopup(false);
            }}/>}
            <Dialog
                open={deleteUsersPopup}
                onClose={() => setDeleteUsersPopup(false)}>
                <DialogTitle>Delete {userType.toLowerCase()}?</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography variant='body1'>You can permanently delete all user data or disable the user accounts.</Typography>
                        <Typography variant='body1'>User data will be unretrievable once deleted. Users may be disabled to preserve data.</Typography>
                        <Stack direction={"row"} justifyContent="space-evenly">
                            <Button color="error" onClick={async () => {
                                const jobId = await execute("userManagement-deleteUsers", {filters: cohortId ? {...tableFilters, cohort: cohortId} : tableFilters, users: selectedUsers === "All" ? queries : selectedUsers, userType: userType}); setDeleteUsersPopup(false);
                                setUploadJob(jobId);
                            }}>Delete</Button>
                            <Button onClick={() => {
                                execute("userManagement-deactivateUsers", {filters: cohortId ? {...tableFilters, cohort: cohortId} : tableFilters, users: selectedUsers === "All" ? queries : selectedUsers, userType: userType}); setDeleteUsersPopup(false);
                            }}>Deactivate</Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>

            <Popup key="changeUserGroup" open={userGroupPopup} onClose={() => setUserGroupPopup(false)} title={"Set User groups for selected users"}>
                <Form onSubmit={editUserGroup}>
                    <RadioGroup
                        aria-labelledby="Select user radio buttons"
                        name="userGroup"
                        sx={{margin: "10px"}}
                        onChange={(e) => setSelectedUserGroup(e.target.value)}
                    >
                        {Object.entries(org.userGroups as {[key:string]: UserGroupData}).map(([id, group]) => {
                            if (group.template !== userType) {
                                return null;
                            }
                            return (<FormControlLabel value={id} control={<Radio />} label={group.name} />);
                        })}
                    </RadioGroup>
                </Form>
            </Popup>
            <Snackbar
                open={Boolean(snackbarText)}
                autoHideDuration={6000}
                onClose={() => setSnackbarText("")}
                message={snackbarText}
                action={
                    <IconButton
                        size="small"
                        title='Close notification'
                        aria-label="close"
                        color="inherit"
                        onClick={() => setSnackbarText("")}>
                        <Close fontSize="small" />
                    </IconButton>
                }/>
        </Box>
    );
}


type Params = {
    open: boolean,
    onClose: () => void,
    filters?: {[key:string]: unknown},
    users: QueryObject[] | {[key: string]: UserData},
    type?: "activate"|"editVisibility"
}

export default function ActivateInstituteStaffPopup({open, onClose, filters, users, type="activate"}: Params) {
    const org = useContext(OrganisationContext);

    const userGroups = Object.entries(org.userGroups as {[key: string]: UserGroupData}).filter(([, group]) => group.template === "Staff");

    const [step, setStep] = useState<"userGroup"|"visibility">("userGroup");
    const [accessDetails, setAccessDetails] = useState<{
        viewSchools: "all"|"some"|"none",
        viewCohorts: "all"|"some"|"none",
        viewStudents: "all"|"some"|"none",
        visibleSchools?: string[],
        visibleCohorts?: string[],
        studentFilterValues?: string[],
        studentFilter?: string
    }>({
        viewSchools: "none",
        viewCohorts: "none",
        viewStudents: "none",
    });
    const [selectedUserGroup, setSelectedUserGroup] = useState<string>();

    useEffect(() => {
        if (type === "editVisibility" && step !== "visibility") {
            setStep("visibility");
        }
        if (type === "activate" && step !== "userGroup") {
            setStep("userGroup");
        }
    }, [type]);

    const activateUsers = async () => {
        if (!selectedUserGroup && type === "activate") {
            throw new Error("Select a user group to assign to your users");
        }

        await executeCallable(`userManagement-${type === "activate" ? "activateUsers" : "editCohortVisibility"}`, {
            users: users, filters: filters, userType: "Staff", userGroupType: "select", userGroupData: selectedUserGroup, accessData: accessDetails,
        });
    };

    const organisation = useContext(OrganisationContext);
    const schools = organisation.schools as {[key: string]: OrganisationAddress};
    const cohorts = organisation.cohorts as {[key: string]: CohortData};

    useEffect(() => {
        setStep(selectedUserGroup ? "visibility" : "userGroup");
    }, [selectedUserGroup]);

    useEffect(() => {
        console.log("access details", accessDetails);
    }, [accessDetails]);

    const cohortsAvailableBasedOnSchoolVisibilitySelection = Object.entries(cohorts).filter(([, cohort]) => accessDetails.viewSchools === "all" || (accessDetails.viewSchools === "some" && cohort.schoolId && accessDetails.visibleSchools?.includes(cohort.schoolId)));

    return (
        <Popup title={type === "activate" ? "Activate user accounts" : "Edit user access"} {...{open, onClose}} fullWidth maxWidth={"lg"} actions={<LoadingButton onClick={activateUsers} variant="contained" noFlexGrow text={type === "activate" ? "Activate" : "Submit"} successText={type === "activate" ? "Users successfully activated." : "Users successfully edited."}/>}>
            <Grid container sx={{minHeight: "400px"}}>
                <Grid item sm={12} md={4}>
                    <Typography>{type === "activate" ? "Activating these users will enable them to access the platform." : "Edit user access to schools, cohorts or individual groups of students."}</Typography>
                    <br/>
                    {type === "activate" && <Typography>We will send each user an email requesting they create a password and sign in.</Typography>}
                    <Box border={`2px solid ${PRIMARY_COLOUR}`} borderRadius={3} p={2} pl={3} pr={3} m={2} mr={5}>
                        <Typography variant="h6" textAlign={"center"}>Activate {Array.isArray(users) ? (filters && Object.keys(filters).length) ? "all filtered" : "all" : Object.keys(users).length} staff member{(!Array.isArray(users) && Object.keys(users).length) === 1 ? "" : "s"}</Typography>
                    </Box>
                    {(filters && Object.keys(filters).length > 0) &&
                    <Box border={`2px solid ${PRIMARY_COLOUR}`} borderRadius={3} p={2} pl={3} pr={3} m={2} mr={5}>
                        <Typography variant="h6">Filters</Typography>
                        {Object.entries(filters).map(([k, f]) => <Typography><strong>{capitalise(camelCaseToNormal(k))}: </strong>{f as string}</Typography>)}
                    </Box>}
                </Grid>
                <Grid item sm={12} md={8} borderLeft={"1px solid lightgrey"}>
                    {type === "activate" && <Typography>Set staff permissions through user groups. You can either select a user group here, or create a new set of permissions for these users.</Typography>}
                    {type === "activate" && <Accordion sx={{boxShadow: "none", borderBottom: "lightgrey 1px solid"}} disableGutters expanded={step === "userGroup"} onChange={(_, open) => setStep((n) => open ? "userGroup" : n)}>
                        <AccordionSummary>
                            <Stack direction={"row"} alignItems={"center"}>
                                <Radio checked={step === "userGroup"}/>
                                <Box>
                                    <Typography variant="h6">Assign user to a user group.</Typography>
                                    <Typography variant="subtitle2" sx={{opacity: 0.75}}>Assign permissions associated with an existing user group.</Typography>
                                </Box>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Dropdown label="Select a group" onChange={(e) => setSelectedUserGroup(e.target.value)}>
                                <MenuItem value={"admin"}>Super admin</MenuItem>
                                <Divider/>
                                {userGroups.map(([k, v]) => <MenuItem value={k}>{v.name}</MenuItem>)}
                            </Dropdown>
                            <Link sx={{fontSize: "14px", textAlign: "center", display: "block", marginTop: 1}} target="_blank" href={location.origin+"/institutes/userGroups"}>Create new user group (Opens in new window)</Link>
                        </AccordionDetails>
                    </Accordion>}
                    <Accordion sx={{boxShadow: "none", borderBottom: "lightgrey 1px solid"}} disableGutters expanded={step === "visibility"} onChange={(_, open) => setStep((n) => open ? "visibility" : n)}>
                        <AccordionSummary>
                            <Stack direction={"row"} alignItems={"center"}>
                                <Radio checked={step === "visibility"}/>
                                <Box>
                                    <Typography variant="h6">Set student visibility</Typography>
                                    <Typography variant="subtitle2" sx={{opacity: 0.75}}>This will create a new user group.</Typography>
                                </Box>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box>
                                        <Typography>View schools</Typography>
                                        {accessDetails.viewSchools === "some" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can only view cohorts and students in the schools you specify.</Typography>}
                                        {accessDetails.viewSchools === "all" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can view all schools. Their access to cohorts and students can be altered separately.</Typography>}
                                        {accessDetails.viewSchools === "none" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff cannot view any schools, or their cohorts and students.</Typography>}
                                    </Box>
                                    <ToggleButtonGroup value={accessDetails?.viewSchools} onChange={(_, v) => v && setAccessDetails((u) => ({...u, viewSchools: v, visibleSchools: (v === "select" ? u.visibleSchools : undefined)}))} exclusive color="primary">
                                        <ToggleButton value={"all"}>All</ToggleButton>
                                        <ToggleButton sx={{minWidth: "max-content"}} value={"some"}>Select schools</ToggleButton>
                                        <ToggleButton value={"none"}>None</ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                                {accessDetails.viewSchools === "some" &&
                                    <Dropdown label={"Select schools"} divider value={accessDetails.visibleSchools} required multiple onChange={(e) => setAccessDetails((prev) => ({...prev, visibleSchools: e.target.value as unknown as string[]}))}>
                                        {Object.entries(schools).map(([k, school]) => <MenuItem value={k}>
                                            <Stack spacing={0}>
                                                <Typography>{school.name}</Typography>
                                                <Typography variant="caption">{school?.["address-line1"]} | {school?.postal_code}</Typography>
                                            </Stack>
                                        </MenuItem>)}
                                    </Dropdown>}
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box>
                                        <Typography>View cohorts</Typography>
                                        {accessDetails.viewCohorts === "some" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can only view students students in the cohorts you specify.</Typography>}
                                        {accessDetails.viewCohorts === "all" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can view all cohorts within the schools they can view.</Typography>}
                                        {accessDetails.viewCohorts === "none" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff cannot view any cohorts, or their students.</Typography>}
                                    </Box>
                                    <ToggleButtonGroup value={accessDetails?.viewCohorts} onChange={(_, v) => v && setAccessDetails((u) => ({...u, viewCohorts: v, visibleCohorts: v === "select" ? u.visibleCohorts : undefined}))} exclusive color="primary">
                                        <ToggleButton value={"all"}>All</ToggleButton>
                                        <ToggleButton sx={{minWidth: "max-content"}} value={"some"}>Select cohorts</ToggleButton>
                                        <ToggleButton value={"none"}>None</ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                                {accessDetails.viewCohorts === "some" &&
                                    (cohortsAvailableBasedOnSchoolVisibilitySelection.length > 0 ? <Dropdown label={"Select cohorts"} value={accessDetails.visibleCohorts} required multiple onChange={(e) => setAccessDetails((prev) => ({...prev, visibleCohorts: e.target.value as unknown as string[]}))}>
                                        {cohortsAvailableBasedOnSchoolVisibilitySelection.map(([k, cohort]) => <MenuItem value={k}>{
                                            <Stack spacing={0}>
                                                <Typography>{cohort.name}</Typography>
                                                <Typography variant="caption">{(cohort.schoolId && schools[cohort.schoolId]) ? `${schools[cohort.schoolId]?.["address-line1"]} | ${schools[cohort.schoolId]?.postal_code}` : "Unknown school"}</Typography>
                                            </Stack>
                                        }</MenuItem>)}
                                    </Dropdown> : <Alert severity="error">You have not selected any schools with cohorts assigned to them. Add other schools to tailor cohort visibility.</Alert>)}
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box>
                                        <Typography>View students</Typography>
                                        {accessDetails.viewStudents === "some" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can only view students you specify.</Typography>}
                                        {accessDetails.viewStudents === "all" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff can view all students within the cohorts and schools you specified.</Typography>}
                                        {accessDetails.viewStudents === "none" && <Typography variant="subtitle2" sx={{opacity: 0.75}}>Staff cannot view any students.</Typography>}
                                    </Box>
                                    <ToggleButtonGroup value={accessDetails?.viewStudents} onChange={(_, v) => v && setAccessDetails((u) => ({...u, viewStudents: v, studentFilter: v === "select" ? u.studentFilter : undefined, studentFilterValues: v === "select" ? u.studentFilterValues : undefined}))} exclusive color="primary">
                                        <ToggleButton value={"all"}>All</ToggleButton>
                                        <ToggleButton sx={{minWidth: "max-content"}} value={"some"}>Filter students</ToggleButton>
                                        <ToggleButton value={"none"}>None</ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                                {accessDetails.viewStudents === "some" && <Grid item xs={12}>
                                    <Dropdown label="Select fields" onChange={(e) => setAccessDetails((p) => ({...p, studentFilter: e.target.value}))} id={"Select field dropdown"} key={"Select field dropdown"} required name="studentFilter">
                                        {(org.details.studentsFields as string[]).map((field) => <MenuItem value={field}>{camelCaseToNormal(field)}</MenuItem>)}
                                    </Dropdown>
                                </Grid>}
                                {accessDetails.viewStudents === "some" && accessDetails.studentFilter && <Grid item xs={12}>
                                    <ListMaker label={`Select the text that '${camelCaseToNormal(accessDetails.studentFilter)}' should match`} name="studentFilterValues" onChange={(e) => setAccessDetails((p) => ({...p, studentFilterValues: e as string[]}))}>
                                        <InputGroup placeholder={"Start typing..."} />
                                    </ListMaker>
                                </Grid>}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Popup>
    );
}
