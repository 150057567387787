import React, {useState, useEffect} from "react";
import Page from "../../Components/Page";
import {useNavigate, useParams} from "react-router-dom";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {Blog, camelCaseToNormal, capitaliseWords, convertDate, readingTime, storage} from "placementt-core";
import {Box, Divider, Grid, Stack, Chip, Typography} from "@mui/material";
import {getDownloadURL, ref} from "firebase/storage";
import ProfileLogo from "../../Components/Public/ProfileLogo";
import {serialiseSlate} from "../../Util/util";
import IconButtonPop from "../../Components/IconButtonPop";
import {ArrowBack} from "@mui/icons-material";

export default function BlogPage() {
    const [blog, setBlog] = useState<Blog>();
    const [heroImg, setHeroImg] = useState<string>("");
    const [authorImg, setAuthorImg] = useState<string>("");

    const navigate = useNavigate();
    const {id} = useParams();
    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        if (!id) return;
        firebaseQuery.getDocData(["blogs", id]).then(async (doc) => {
            setBlog(doc as Blog);
            const authorImage = await getDownloadURL(ref(storage, `/profilePics/${doc?.author}.png`));
            setAuthorImg(authorImage);
            const heroImage = await getDownloadURL(ref(storage, `/blogs/${id}.png`));
            setHeroImg(heroImage);
        });
    }, []);

    return (
        <Page
            metaTitle={blog?.title}
            metaDesc={blog?.summary}
            metaKeywords={blog?.tags?.split(", ")}
            sx={{display: "flex"}}>
            <Stack sx={{alignItems: "center"}}>
                <Box
                    component = "img"
                    width={"100%"}
                    src={heroImg}
                    style={{maxWidth: "800px"}}
                />
                <Grid container>
                    <Grid item sm={12} md={3}></Grid>
                    <Grid item sm={12} md={6}>
                        <Grid item>
                            <Stack direction={"row"} spacing={0}>
                                <IconButtonPop responsive={false} title={"Back"} sx={{marginRight: "10px"}} onClick={() => navigate("../blogs")} key={"back-link"}><ArrowBack/></IconButtonPop>
                                <h1>{blog?.title}</h1>
                            </Stack>
                            <Stack direction = "row" alignItems={"center"} justifyContent={"space-between"}>
                                <Stack direction = "row" alignItems={"center"}>
                                    <ProfileLogo src={authorImg}/>
                                    <Stack spacing={0} sx={{opacity: 0.75}}>
                                        <Typography>By {capitaliseWords(camelCaseToNormal(blog?.author))}</Typography>
                                        <Typography>Published on {convertDate(blog?.uploaded, "visual") as string}</Typography>
                                    </Stack>
                                </Stack>
                                <Typography sx={{opacity: 0.75}}>Reading time: {readingTime(blog?.summary + JSON.stringify(blog?.body))}</Typography>
                            </Stack>
                        </Grid>

                        <Divider sx={{marginTop: 3}}/>

                        <Grid item mt={2}>
                            <Chip style={{marginBottom: 10}} label={capitaliseWords(blog?.category)} color={blog?.category === "placementt" ? "primary" : blog?.category}/>
                            <Typography fontWeight={600} fontSize={"1.1em"}>{blog?.summary}</Typography>
                            {serialiseSlate(blog?.body || [])}
                        </Grid>
                    </Grid>
                </Grid>
            </Stack>
        </Page>
    );
}
