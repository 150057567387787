/* eslint-disable max-len */
import {useEffect} from "react";
import Page from "../../Components/Page";


export default function Terms() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <Page metaTitle="Placementt | Terms of Service" metaDesc="Our terms of service">
            <div
                style={{minWidth: "100%", maxWidth: "1000px", margin: "auto", fontFamily: "Nunito !important"}}
                name="termly-embed"
                data-id="bb951d3e-723c-4e63-b4f1-512f99ecbeb7"
                data-type="iframe"
            ></div>
        </Page>
    );
}
