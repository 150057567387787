/* eslint-disable import/no-named-as-default */
/* eslint-disable no-undef */
import {ArrowDownwardRounded} from "@mui/icons-material";
import {Box, Button, Divider, Grid, Icon, keyframes, Stack, Typography} from "@mui/material";
import {PRIMARY_COLOUR} from "placementt-core";
import {useNavigate} from "react-router-dom";
import PublicTemplate from "../../Components/Public/PublicTemplate";
import Page from "../../Components/Page";
import {Testimonials} from "./InstituteInfo";
import Card from "../../Components/Card";
import instituteIcon from "../../Images/Institute_Icon.png";
import providerIcon from "../../Images/Business_Icon.png";
import studentIcon from "../../Images/Student_Icon.png";
import instituteStudentApp from "../../Images/platformImages/instituteStudentApp.png";
import multipleSchools from "../../Images/platformImages/multipleSchools.png";
import schoolPlacements from "../../Images/platformImages/schoolPlacements.png";
import instituteMap from "../../Images/platformImages/instituteMap.png";

import {ReactNode} from "react";
import styled from "styled-components";
import {RedDarkBlueGradientTextStatic, RedOrangePinkGradientText} from "../../Util/visualUtil";
import {useCheckMobileScreen} from "../../Util/util";

export default function Home() {
    const navigate = useNavigate();
    const mobile = useCheckMobileScreen();

    const bounce = keyframes`
        0% {
            transform: translateY(0);
        }
        35% {
            transform: translateY(40%);
        }
        50% {
            transform: translateY(30%);
        }
        65% {
            transform: translateY(40%);
        }
        100% {
            transform: translateY(0);
        }
    `;

    const BouncingArrow = styled(Icon)<{children?: ReactNode}>`
        animation: ${bounce} 2s linear infinite;
        align-self: center;
        opacity: 0.7;
        font-size: 40px;
    `;

    return (
        <Page
            metaTitle="Placementt | Find and Manage Work Experience."
            metaDesc="An ecosystem for students, institutes and businesses to find, organise and manage work experience placements."
            metaKeywords={["work experience tracker", "work experience database", "school work experience", "placement tracker", "placement map"]}>
            <PublicTemplate>

                <Stack spacing={5} borderBottom={"1px solid lightgrey"} pl={5} pr={5} pt={mobile ? 8 : 20} pb={10} textAlign={"center"} maxWidth={"1000px"} alignSelf={"center"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant="h2" fontWeight={500}><RedOrangePinkGradientText>Enhance Relationships</RedOrangePinkGradientText> Between Employers and Students</Typography>
                    <Typography variant="h5">We empower students by supporting educators, employers, parents and students in streamlining valuable work experience placements.</Typography>
                    <Button href="mailto:tom@placementt.co.uk"><Typography variant="h5"><RedOrangePinkGradientText>Get In Touch</RedOrangePinkGradientText></Typography></Button>
                </Stack>
                <Typography variant="h5" lineHeight={1.7} textAlign={"center"}>Placementt is a <RedDarkBlueGradientTextStatic>cloud-based</RedDarkBlueGradientTextStatic> service providing end-to-end support with <RedDarkBlueGradientTextStatic start={400}>work experience</RedDarkBlueGradientTextStatic> and <RedDarkBlueGradientTextStatic start={300}>employer relationships</RedDarkBlueGradientTextStatic>. We support schools, multi-academy trusts and employers in their work experience. You set up your requirement, we do the rest, from <RedDarkBlueGradientTextStatic start={200}>insurance and risk assessments</RedDarkBlueGradientTextStatic> to <RedDarkBlueGradientTextStatic start={100}>feedback and analytics</RedDarkBlueGradientTextStatic>.</Typography>
                <Divider/>
                <Stack textAlign={"center"} alignItems={"center"} width={"100%"} maxWidth={"1000px"} alignSelf={"center"}>
                    <Typography variant="h5">Learn how we can support you in <RedOrangePinkGradientText>levelling the playing field</RedOrangePinkGradientText> across work experience.</Typography>
                    <Grid container>
                        <Card grid xs={6} md={4} onClick={() => navigate("secondary-schools")} sx={{height: "100%"}}>
                            <Stack sx={{alignItems: "center"}}>
                                <img src={instituteIcon} style={{width: "80%", height: "100px", objectFit: "contain"}} alt={"Clickable secondary school icon"}/>
                                <Typography variant="h6">Secondary Schools</Typography>
                            </Stack>
                        </Card>
                        <Card grid xs={6} md={4} onClick={() => navigate("multi-academy-trusts")} sx={{height: "100%"}}>
                            <Stack sx={{alignItems: "center"}}>
                                <img src={providerIcon} style={{width: "80%", height: "100px", objectFit: "contain"}} alt={"Clickable multi academy trust icon"}/>
                                <Typography variant="h6">Multi-Academy Trusts</Typography>
                            </Stack>
                        </Card>
                        {/* <Card grid xs={6} md={2.4} onClick={() => navigate("universities")} sx={{height: "100%"}}>
                            <Stack sx={{alignItems: "center"}}>
                                <img src={universityIcon} style={{width: "80%", height: "100px", objectFit: "contain"}} alt={"Clickable university icon"}/>
                                <Typography variant="h6">Universities</Typography>
                            </Stack>
                        </Card> */}
                        {/* <Card grid xs={6} md={2.4} onClick={() => navigate("provider")} sx={{height: "100%"}}>
                            <Stack sx={{alignItems: "center"}}>
                                <img src={providerIcon} style={{width: "80%", height: "100px", objectFit: "contain"}} alt={"Clickable business icon"}/>
                                <Typography variant="h6">Businesses</Typography>
                            </Stack>
                        </Card> */}
                        <Grid item container xs={12} md={4} spacing={0}>
                            <Grid item xs={3} md={0}/>
                            <Card grid xs={6} md={12} onClick={() => navigate("student")} sx={{height: "100%", ml: 2, mr: -2}}>
                                <Stack sx={{alignItems: "center"}}>
                                    <img src={studentIcon} style={{width: "80%", height: "100px", objectFit: "contain"}} alt={"Clickable student icon"}/>
                                    <Typography variant="h6">Students</Typography>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                </Stack>
                <Divider/>
                <Stack mt={"-8px !important"}>
                    <Typography pt={7} textAlign={"center"} variant="h5" alignSelf={"center"} maxWidth={"1000px"}>Placementt Helps <strong style={{color: PRIMARY_COLOUR}}>Educators</strong>, <strong style={{color: PRIMARY_COLOUR}}>Businesses</strong>, and <strong style={{color: PRIMARY_COLOUR}}>Students</strong> Manage Their Work Placements</Typography>
                    <BouncingArrow><ArrowDownwardRounded sx={{fontSize: "40px"}}/></BouncingArrow>
                </Stack>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Stack mt={"5%"}>
                            <Typography color={"primary"}>For Secondary Schools</Typography>
                            <Divider/>
                            <Typography variant="h4">Streamlined, Simple, Comprehensive</Typography>
                            <Typography>Placementt streamlines your work experience through automated, personalised stages.</Typography>
                            <Typography>We collect detailed information about every placement your students attend, helping them prepare and inspiring future students.</Typography>
                            <Typography>Placementt takes a personalised approach to every placement while keeping things simple for staff, students, parents and employers.</Typography>
                            <Button onClick={() => navigate("/secondary-schools")}>Learn more</Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"}>
                        <Box component={"img"} borderRadius={1} boxShadow={3} width={"100%"} alt={"Placementt for Educators home page"} src={schoolPlacements}/>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"}>
                        <Box component={"img"} borderRadius={1} boxShadow={3} width={"100%"} alt={"Placementt for Educators home page"} src={multipleSchools}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack mt={"7.5%"}>
                            <Typography color={"primary"}>For Multi-Academy Trusts</Typography>
                            <Divider/>
                            <Typography variant="h4">Central Management Across Schools</Typography>
                            <Typography>Standardise work experience placements across your schools, easing the burden on your careers teams.</Typography>
                            <Typography>Share one placement processing workflow across schools, including custom forms and files for employers, guidance documents and branded email templates.</Typography>
                            <Typography>Analyse your schools progress to give an in-depth view of your work experience programme.</Typography>
                            <Button onClick={() => navigate("/multi-academy-trusts")}>Learn more</Button>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Stack mt={"7.5%"}>
                            <Typography color={"primary"}>Your Placement Database</Typography>
                            <Divider/>
                            <Typography variant="h4">Build a Vast Employer Database</Typography>
                            <Typography>We won't charge you an arm and a leg for a database of employers. Instead, we provide robust tools to seamlessly build your own database of employers.</Typography>
                            <Typography>We gather detailed information about placements, including student experiences, amenities, and responsibilities, helping students find their ideal fit.</Typography>
                            <Typography>View comprehensive analytics on employers, including student engagement and overall feedback on placements.</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"}>
                        <Box component={"img"} mt={"10%"} borderRadius={1} boxShadow={3} width={"100%"} alt={"Placementt for Educators home page"} src={instituteMap}/>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"}>
                        <Box component={"img"} display={"flex"} m={"auto"} borderRadius={2} boxShadow={3} height={"500px"} alt={"Placementt Student App"} src={instituteStudentApp}/>
                    </Grid>
                    <Grid item xs={12} md={6} minHeight={"100%"}>
                        <Stack mt={"12.5%"}>
                            <Typography color={"primary"}>For Students</Typography>
                            <Divider/>
                            <Typography variant="h4">Take Control of Your Placements</Typography>
                            <Typography>Track your placements and contacts through our mobile app, and gather feedback on your placement progress.</Typography>
                            <Typography>Browse an interactive map of placements across your region, or the entire UK, built up by your school.</Typography>
                            <Typography>Propel your development with custom analytics, ensuring you meet important skill and experience targets.</Typography>
                            <Button onClick={() => navigate("/student")}>Learn more</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </PublicTemplate>
            <Testimonials/>
            {/* <PublicTemplate>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Typography color={"primary"}>For Businesses</Typography>
                            <Divider/>
                            <Typography variant="h4">Streamlined, Simple, Comprehensive</Typography>
                            <Typography>Placementt streamlines your work experience through automated, personalised stages.</Typography>
                            <Typography>For every placement your students attend, we gather a wealth of information, to prepare those attending, and inspire future students.</Typography>
                            <Typography>Placementt takes a personalised approach to every placement, while keeping things simple for staff, students, parents and employers.</Typography>
                            <Button onClick={() => navigate("/secondary-schools")}>Learn more</Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} display={"flex"} alignItems={"center"}>
                        <Box component={"img"} borderRadius={1} boxShadow={3} width={"100%"} alt={"Placementt for Educators home page"} src={studentPlacement}/>
                    </Grid>
                </Grid>

            </PublicTemplate> */}
        </Page>
    );
}

/**
 *                 <CardSection
                    title="Your complete placement platform"
                    items={[
                        {
                            title: "For institutes",
                            primaryColor: INSTITUTE_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={INSTITUTE_COLOUR} display={"inline"}>Manage your work experience programme</Typography> with ease. Our automated placement management workflow will reduce admin and give you more time to spend on student support.</>,
                            button: {
                                label: "Learn more",
                                onClick: () => navigate("institute"),
                                color: "institutes",
                            },
                            secondaryContent:
                                <List>
                                    <ListItem>
                                        <ListItemIcon><AccountTreeRounded/></ListItemIcon>
                                        <ListItemText primary="Customisable workflow"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><DocumentScannerRounded/></ListItemIcon>
                                        <ListItemText primary="Automatic insurance and risk assessment acquisition"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><SmsRounded/></ListItemIcon>
                                        <ListItemText primary="Robust communication tools"/>
                                    </ListItem>
                                </List>,
                        },
                        {
                            title: "For students",
                            primaryColor: STUDENT_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={STUDENT_COLOUR} display={"inline"}>Maximise your potential</Typography> with a platform that lets you find the best placements, tracks your progress and helps you develop your skills.</>,
                            button: {
                                label: "Learn more",
                                onClick: () => navigate("student"),
                                color: "students",
                            },
                            secondaryContent:
                            <List>
                                <ListItem>
                                    <ListItemIcon><TimelineRounded/></ListItemIcon>
                                    <ListItemText primary="Simple placement tracking"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><AnalyticsRounded/></ListItemIcon>
                                    <ListItemText primary="Customisable analytics"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><ConstructionRounded/></ListItemIcon>
                                    <ListItemText primary="Skills development tools"/>
                                </ListItem>
                            </List>,
                        },
                        {
                            title: "For providers",
                            primaryColor: PROVIDER_COLOUR,
                            summary: <><Typography fontWeight={"bold"} color={PROVIDER_COLOUR} display={"inline"}>Secure top-tier students</Typography> for your placements. We support and enhance your placement adverts, allow you to track your students and take care of all needless admin.</>,
                            button: {
                                label: "Learn more",
                                onClick: () => navigate("provider"),
                                color: "providers",
                            },
                            secondaryContent:
                            <List>
                                <ListItem>
                                    <ListItemIcon><SchoolRounded/></ListItemIcon>
                                    <ListItemText primary="Manage placement students"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Campaign/></ListItemIcon>
                                    <ListItemText primary="Advertise your opportunities"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><Assignment/></ListItemIcon>
                                    <ListItemText primary="Automated school admin"/>
                                </ListItem>
                            </List>,
                        },
                    ]}/>
 */

