// When a business adds a placement manually, the student is emailed and asked to accept the placement and link it to their account. This is that page.

import {Link, useNavigate, useParams} from "react-router-dom";
import Page from "../../Components/Page";
import {Alert, Button, Link as MuiLink, List, ListItem, ListItemIcon, ListItemText, Stack, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {capitaliseWords, convertDate, executeCallable, PRIMARY_COLOUR, STUDENT_COLOUR} from "placementt-core";
import FadeInBox from "../../Components/FadeInBox";
import Preloader from "../../Components/Preloader";
import {Article, BusinessCenter, Lightbulb} from "@mui/icons-material";
import {UserContext} from "../../App";
import {LoadingButton} from "../../Components/LoadingButton";
import {Popup} from "../../Components/Popup";

type PlacementRequestDetails = {
    providerName: string,
    startDate: string,
    endDate: string,
}

export default function PlacementRequest() {
    const {pId} = useParams();
    const [cardPos, setCardPos] = useState<"loading"|"notFound"|"loaded"|"success"|"rejected">("loading");
    const [placement, setPlacement] = useState<PlacementRequestDetails>();
    const [confirmPopup, setConfirmPopup] = useState<"accept"|"reject">();

    const user = useContext(UserContext);

    const navigate = useNavigate();
    console.log(navigate); // REMOVE
    useEffect(() => {
        // Check if placement exists
        executeCallable("placement-getPlacement", {pId: pId, key: "request"}).then((res) => {
            const data = res.data as {code: 0|1|2|3, msg?: string, details?: PlacementRequestDetails};

            if (data.code === 1) {
                setPlacement(data.details);
                setCardPos("loaded");
                return;
            }

            setCardPos("notFound");
        });
    }, []);

    const sendResponse = async () => {
        if (!confirmPopup) throw new Error("You must select a response first.");
        await executeCallable("placement-respondToRequest", {pId: pId, response: confirmPopup === "accept"});
        setConfirmPopup(undefined);
        setCardPos(confirmPopup === "accept" ? "success" : confirmPopup === "reject" ? "rejected" : "loaded");
    };


    return (
        <Page fullHeight sx={{overflow: "hidden"}} metaTitle="Approve placement request | Placementt">
            <FadeInBox card visible={cardPos === "loading"}>
                <Typography variant="h4">Finding placement</Typography>
                <Typography variant='h6'>This won't take long.</Typography>
                <Preloader visible/>
            </FadeInBox>
            <FadeInBox card visible={cardPos === "notFound"}>
                <Stack spacing={2} alignItems={"center"}>
                    <Typography variant='h5'>Cannot find placement.</Typography>
                    <Typography>This placement cannot be found. This occurs if it does not exist, if you have a broken link, or if you previously accepted the placement. If you think there is an error, <MuiLink href="mailto:admin@placementt.co.uk">get in touch</MuiLink>.</Typography>
                </Stack>
            </FadeInBox>
            <FadeInBox card visible={cardPos === "loaded"}>
                <Stack spacing={2} alignItems={"center"} textAlign={"center"}>
                    <Typography variant='h5' color={PRIMARY_COLOUR}>Review placement request</Typography>
                    <Typography variant={"h6"}>{placement?.providerName} has created a placement for you from {convertDate(placement?.startDate, "visual") as string} to {convertDate(placement?.endDate, "visual") as string}.</Typography>
                    <Typography variant={"h6"} color={STUDENT_COLOUR}>Create a Placementt - Student account to track your progress, manage placements and find opportunities</Typography>
                    <List sx={{alignSelf: "start"}}>
                        <ListItem>
                            <ListItemIcon><Lightbulb sx={{color: STUDENT_COLOUR}}/></ListItemIcon>
                            <ListItemText primaryTypographyProps={{fontSize: "1.2em"}} primary={"Review comprehensive placement details."}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><Article sx={{color: STUDENT_COLOUR}}/></ListItemIcon>
                            <ListItemText primaryTypographyProps={{fontSize: "1.2em"}} primary={"View onboarding documents."}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><BusinessCenter sx={{color: STUDENT_COLOUR}}/></ListItemIcon>
                            <ListItemText primaryTypographyProps={{fontSize: "1.2em"}} primary={"Track your placements."}/>
                        </ListItem>
                    </List>
                    {user.id ? (user.userType === "Students" ?
                        <Stack direction={"row"} width={"100%"}>
                            <Button sx={{flex: 1}} onClick={() => setConfirmPopup("reject")}>Reject</Button>
                            <Button sx={{flex: 1}} variant="contained" onClick={() => setConfirmPopup("accept")}>Accept</Button>
                        </Stack> : <Alert severity="error">Only students can accept placements.</Alert>) :
                        <Stack alignItems={"center"}>
                            <Button onClick={() => navigate("/register/students", {state: {redirect: window.location.pathname}})} variant="contained"><Typography variant="h6">Create account to continue</Typography></Button>
                            <Link to={"/login"} state={{redirect: window.location.pathname}}>Or log in instead</Link>
                        </Stack>}
                </Stack>
            </FadeInBox>
            <FadeInBox card visible={cardPos === "success" || cardPos === "rejected"}>
                <Stack spacing={2} alignItems={"center"}>
                    <Typography variant='h5'>Thank you for responding.</Typography>
                    <Typography>{cardPos === "success" ? "This placement is now linked in your account. You can view it here on our web-platform or on our Android or iOS app." : "This placement has been rejected. You don't need to do anything else at this point."}</Typography>
                </Stack>
            </FadeInBox>
            <Popup title={capitaliseWords(confirmPopup)+" placement"} open={Boolean(confirmPopup)} onClose={() => setConfirmPopup(undefined)}>
                <Stack spacing={2} alignItems={"center"}>
                    <Typography>Are you sure you want to {confirmPopup} this placement?</Typography>
                    <LoadingButton color={confirmPopup === "reject" ? "error" : undefined} onClick={sendResponse} variant={confirmPopup === "reject" ? "outlined" : "contained"} text={capitaliseWords(confirmPopup)}/>
                </Stack>
            </Popup>
        </Page>
    );
}
