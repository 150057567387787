import {useParams} from "react-router-dom";
import Page from "../../Components/Page";
import {Button, Link, List, ListItem, ListItemIcon, ListItemText, Stack, Typography} from "@mui/material";
import {INSTITUTE_COLOUR, PlacementListing, executeCallable} from "placementt-core";
import {useEffect, useState} from "react";
import FadeInBox from "../../Components/FadeInBox";
import UploadProviderDetails from "./ProviderDetails/UploadProviderDetails";
import UploadPlacementQuestions from "./ProviderDetails/UploadPlacementQuestions";
import Preloader from "../../Components/Preloader";
import {Handshake, Lightbulb, Task} from "@mui/icons-material";
import UploadProviderInsurance from "./ProviderDetails/UploadProviderInsurance";
import Cookies from "universal-cookie";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";


export default function ExternalPlacementSubmission() {
    let {iId, placementId} = useParams();
    const cookies = new Cookies();

    const [cardPos, setCardPos] = useState<"loading"|"insurance"|"review"|"details"|"questions"|"complete"|"notAccepting">("loading");
    const [instituteName, setInstituteName] = useState("");
    const [placementDetails, setPlacementDetails] = useState<{[key:string]: unknown}>({});
    const [signedUp, setSignedUp] = useState<boolean>(cookies.get("businessInterest"));

    // If there is an institute, check they are accepting placements. If not, allow them to advertise publicly
    // If not institute, allow public submission

    useEffect(() => {
        // Check if placement exists
        if (!iId) return;
        executeCallable("placementListing-getDetailsForListingUpload", {iId: iId, placementId: placementId}).then((res) => {
            const data = res.data as {code: 0|1|2|3|4, msg?: string, details?: string | {instituteName: string, placement: PlacementListing}};
            console.log("data", data);
            if (data.code === 2) {
                setInstituteName(data.details as string || "");
                setCardPos("review");
            } else if (data.code === 3) {
                const details = data.details as {instituteName: string, placement: PlacementListing};
                setInstituteName(details.instituteName || "");
                setCardPos("review");
                setPlacementDetails(details.placement);
            } else if (data.code === 4) {
                const details = data.details as {instituteName: string, placement: PlacementListing};
                setPlacementDetails(details.placement);
                setInstituteName(details.instituteName || "");
                setCardPos("complete");
            } else {
                setCardPos("notAccepting");
                iId = undefined;
            }
        });
    }, []);

    const uploadPlacement = async (e: {[key: string]: unknown}) => {
        if (placementId || placementDetails.placementId) {
            await executeCallable("placementListing-update", {placement: e, placementId: placementId || placementDetails.placementId, instituteId: iId});
            return;
        }

        return await executeCallable("placementListing-add", {data: [e], instituteId: iId, providerSubmission: true});
    };


    const submitToWaitlist = async () => {
        const firebaseQuery = new FirebaseQuery();
        await firebaseQuery.add(["businessInterest"], {
            forename: placementDetails.contactForename,
            surname: placementDetails.contactSurname,
            email: placementDetails.providerEmail,
            provider: placementDetails.name,
            providerId: placementDetails.providerId,
            sector: placementDetails.sector,
            subsector: placementDetails.subsector,
        });
        cookies.set("businessInterest", true);
        setSignedUp(true);
    };

    return (
        <Page
            metaTitle="Placementt | Submit placement"
            metaDesc="Advertise your available placements to Placementt students"
            fullHeight>
            <FadeInBox card visible={cardPos === "loading"} cardTitle={"Loading placement"}>
                <Preloader visible/>
            </FadeInBox>
            <FadeInBox card visible={cardPos === "notAccepting"} cardTitle={"You cannot upload placements at this time."}>
                <Typography>If you think this is a mistake, contact the institute you received this link from, or contact <Link href="mailto:tom@placementt.co.uk">Tom@placementt.co.uk</Link></Typography>
            </FadeInBox>
            <FadeInBox card visible={cardPos === "review"} cardTitle={placementId && <Typography variant="h5" textAlign={"center"}><span style={{color: INSTITUTE_COLOUR, fontWeight: "bold", display: "inline"}}>{instituteName}</span> has added you to their database.</Typography>}>
                <Typography variant="h6" textAlign={"center"}>Advertise your work experience placements to students at {instituteName}.</Typography>
                <Typography textAlign={"center"} mt={2} mb={2}>Advertising your placement will provide students with a wealth of opportunity to develop their career interests.</Typography>
                <List sx={{alignSelf: "start"}}>
                    <ListItem>
                        <ListItemIcon><Lightbulb color="primary"/></ListItemIcon>
                        <ListItemText primary={"Students find placements that suit them."}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><Handshake color="primary"/></ListItemIcon>
                        <ListItemText primary={"You get more enthusiastic, motivated students."}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><Task color="primary"/></ListItemIcon>
                        <ListItemText primary={"We manage all admin for you, only notifying you when you are needed."}/>
                    </ListItem>
                </List>
                <Stack direction={"row"} justifyContent={"center"} pt={5} alignItems={"center"}>
                    <Button onClick={() => setCardPos("details")} variant="contained">{placementId ? "Review" : "Create"} placement</Button>
                </Stack>
                {placementId && <Typography color={"gray"} sx={{cursor: "pointer", float: "right"}} >Reject placement</Typography>}

            </FadeInBox>

            <UploadProviderDetails visible={cardPos === "details"} placement={placementDetails} back={() => setCardPos("review")} onComplete={async (details) => {
                const res = await uploadPlacement(details);
                const resData = res?.data as {
                    providerId: string;
                    placements: string[];
                }[];
                const providerId = Array.isArray(resData) && resData.length ? resData[0].providerId : placementDetails?.providerId;
                const placementId = Array.isArray(resData) && resData.length ? resData[0].placements[0] : placementDetails?.providerId;

                setPlacementDetails((prev) => ({...prev, ...details, providerId: providerId, placementId: placementId}));
                setCardPos("insurance");
            }}/>
            <UploadProviderInsurance uploaded={placementDetails.insurance as boolean|undefined} expiry={placementDetails.insuranceExpiry as string|undefined} onComplete={() => setCardPos("questions")} providerId={placementDetails.providerId as string} visible={cardPos === "insurance"} back={() => setCardPos("details")}/>
            <UploadPlacementQuestions visible={cardPos === "questions"} instituteName={instituteName} back={() => setCardPos("insurance")} onComplete={async (questions) => {
                await uploadPlacement({...placementDetails, ...questions});
                setCardPos("complete");
            }}/>
            <FadeInBox card visible={cardPos === "complete"} cardTitle={"Thanks for uploading your placement"} centerTitle>
                {signedUp ?
                    <Stack>
                        <Typography>Thanks for signing up to our waitlist. We'll be in touch soon!</Typography>
                    </Stack> :
                    <Stack>
                        <Typography variant="subtitle1" textAlign={"center"}>We are building a platform allowing you to manage your placements, advertise your opportunitites and interface directly with schools and students.</Typography>
                        <Typography textAlign={"center"}>We would love to chat to you about how we could help support your placements. If you're interested, sign up to our waitlist below.</Typography>
                        <Button variant="contained" sx={{alignSelf: "center"}} onClick={() => submitToWaitlist()}>Join our waitlist</Button>
                    </Stack>}
            </FadeInBox>
        </Page>
    );
}
