import {forwardRef, useContext, useEffect, useState} from "react";
import {Products, storage, useUserUploadHandler} from "placementt-core";
import {UserContext} from "../App";
import SpreadsheetUploader from "./SpreadsheetUploader";
import {Alert, Button} from "@mui/material";
import {getDownloadURL, ref as storageRef} from "firebase/storage";
import {Download} from "@mui/icons-material";


type Params = {
    defaultCols: string[],
    formatColLabels?: (label:string) => string,
    onChange?: () => void,
    noNewRows?: boolean,
    noNewColumns?: boolean,
    noSubmitButton?: boolean,
    colWidth?: number,
    userType: "Staff"|"Students",
    userGroupId?: string,
    cohortId?: string,
    onComplete?: (jobId: string) => void,
    onSubmit?: (users: {[key:string]: unknown}[]) => void,
    oId?: string,
    product?: Products,
    initialUsers?: {forename: string, surname: string, email: string, parentEmail?: string}[]
}

type UserUploadData = {
        [key: string]: unknown;
        email?: string | undefined;
        parentEmail?: string | undefined;
        year: number;
    }[]


const UserUploadSpreadsheet = forwardRef(({oId, product, onComplete, noSubmitButton, defaultCols, formatColLabels, noNewColumns, noNewRows, colWidth, userType, userGroupId, cohortId, initialUsers}:Params, ref) => {
    const user = useContext(UserContext);
    const [uploadSpreadsheet, setUploadSpreadsheet] = useState<string>();

    const {uploadUsers, onChange, alert} = useUserUploadHandler({oId: oId || user.oId, product: product || user.product, ...{user, userType, cohortId, onComplete}, userGroupId: userGroupId});
    console.log(userType);
    useEffect(() => {
        getDownloadURL(storageRef(storage, `public/${userType.toLowerCase()} upload template.xlsx`)).then(setUploadSpreadsheet);
    }, []);

    return (<SpreadsheetUploader initialDataHotUpdate initialData={initialUsers} utilityButtons={<><Button variant="contained" startIcon={<Download/>} onClick={() => window.open(uploadSpreadsheet, "_blank")}>Template</Button>{initialUsers && <Alert severity="info" sx={{padding: "0 16px"}}>Imported {initialUsers.length} users</Alert>}</>} ref={ref} {...{defaultCols, alert, onChange, noSubmitButton, noNewColumns, noNewRows, formatColLabels, colWidth}} onSubmit={(users) => uploadUsers(users as UserUploadData)}/>
    );
});

export default UserUploadSpreadsheet;
