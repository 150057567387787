import {Stack, Typography} from "@mui/material";
import InfoPageHeader from "../../../Components/Public/InfoPageHeader";
import Page from "../../../Components/Page";


export default function DeleteAccount() {
    return (
        <Page metaTitle="Placementt - Delete account" metaDesc="Information on how to delete your Placementt account">
            <InfoPageHeader title="Delete account" subtext="We would love to chat to you about any concerns or issues before you think about leaving, but if you'd still like to leave, this article shows you how." background={"#fff5ea"} textColor="#232323"/>
            <Stack p={"10%"} pt={9}>
                <Typography>At Placementt, we are continually improving our platform to best support you. If you are thinking of leaving, we would love to hear your concerns, so that we may improve your experience. When we say we are dedicated to improving Placementt, we mean it. Our team work tirelessly to roll out features in a matter of <i>days</i> to make our platform the best it can be for you. However, while we would love to keep supporting you, we understand that we cannot support everyone equally.</Typography>
                <Typography variant="h5">Placementt - Student</Typography>
                <Typography>If you are using Placementt - Student, you can delete your account in the "Profile" page. In the app, this is located in the "More" menu, and on the webapp, it is on the sidebar. Account deletion is instant.</Typography>
                <Typography variant="h5">Placementt - Educator</Typography>
                <Typography>If you are the admin of a Placementt - Educator account and would like to delete your account, please get in touch with us at admin@placementt.co.uk. Once your request has been received, we will remove your account within a week.</Typography>
                <Typography>If you are a staff member or student on a Placementt - Educator account, but are not the admin, you will need to talk to the account admin to request that they remove your account. On your account, navigate to the "Profile" page to view who your admin is, you can then get in touch with them via email.</Typography>
            </Stack>
        </Page>
    );
}
