import {CheckCircleOutline} from "@mui/icons-material";
import {Alert, Box, Button, Icon, Stack} from "@mui/material";
import Preloader from "./Preloader";
import React, {Children, ReactNode, useEffect, useState} from "react";

export type LoadingButtonParams = {
    id?: string,
    disabled?: boolean,
    onClick?: (...args:any) => Promise<any>,
    children?: ReactNode,
    text?: string,
    type?: "button" | "submit" | "reset" | undefined,
    sx?: object,
    variant?: "outlined"|"contained",
    state?: "normal"|"loading"|"success"|ResponseObject,
    buttonRef?: React.RefObject<HTMLButtonElement>,
    resetButton?: () => void,
    color?: "success" | "inherit" | "info" | "warning" | "error" | "primary" | "secondary",
    width?: string,
    noFlexGrow?: boolean,
    successText?: string,
    successDismissible?: boolean,
    display?: "none"
}

export type ResponseObject = undefined | Error | {severity:"info"|"warning"|"error"|"success", message:string, button?:{title:string, onClick:() => null}};

export function LoadingButton({id, children, onClick, display, sx, type, disabled, variant, state, buttonRef, resetButton, color, text="Submit", width="100%", noFlexGrow, successText, successDismissible}:LoadingButtonParams) {
    const [loading, setLoading] = useState<"normal"|"loading"|"success"|ResponseObject>("normal");

    // Allow for parent control
    useEffect(() => {
        if (!state) return;
        setLoading(state);
    }, [state]);


    const buttonClick = async (e:React.MouseEvent<HTMLButtonElement>, onButtonClick=onClick) => {
        setLoading("loading");

        if (!onButtonClick) return;

        await onButtonClick(e).then(() => {
            setLoading("success");
        }).catch((e) => {
            setLoading(e);
        });
    };

    const renderIcon = () => {
        switch (loading) {
        case "loading":
            return <Button
                sx={{...sx, padding: 0, flexGrow: noFlexGrow ? undefined : 1}}
                {...{type, variant, color}} disabled>
                <Preloader visible size="34.5px"/>
            </Button>;
        case "success":
            return (successText ? <Alert severity={"success"} onClose={successDismissible ? onClose : undefined}>{successText}</Alert> :
                <Icon fontSize="large"><CheckCircleOutline fontSize="large" color={"success"}/></Icon>);
        case "normal":
            return (children ?
                <Stack direction={"row"} width={"100%"} spacing={1} justifyContent={"space-around"}>
                    {Children.map(children, (child) => {
                        if (!React.isValidElement(child)) {
                            return child;
                        }
                        return React.cloneElement(child,
                                {disabled: child.props?.disabled !== undefined ? child.props?.disabled : disabled || loading !== "normal",
                                    sx: {...child.props?.sx, flexGrow: 1},
                                    onClick: (e:React.MouseEvent<HTMLButtonElement>) => buttonClick(e, child.props?.onClick)} as {[key: string]: unknown});
                    }) }
                </Stack> :
                <Button
                    ref={buttonRef}
                    disabled={disabled || loading !== "normal"}
                    onClick={type === "submit" ? undefined : buttonClick}
                    sx={{...sx, flexGrow: noFlexGrow ? undefined : 1}}
                    {...{type, variant, color, id}}>
                    {text}
                </Button>);
        default:
            return (
                <Alert severity={loading instanceof Error ? "error" : loading?.severity || "error"}
                    onClose={loading instanceof Error ? onClose : undefined}
                    action={loading instanceof Error || !loading?.button ? undefined : <Button color={"inherit"} size='small' onClick={loading.button.onClick}>{loading.button.title}</Button>}>
                    {loading?.message}
                </Alert>
            );
        }
    };

    const onClose = () => {
        setLoading("normal");
        resetButton && resetButton();
    };

    return (
        <Box sx={{display: display || "inline-flex", justifyContent: "center", width: noFlexGrow ? undefined : width}}>{renderIcon()}</Box>
    );
}
