import {SearchOutlined} from "@mui/icons-material";
import {ChangeEvent, useEffect, useState} from "react";
import InputGroup from "./FormComponents/InputGroup";
import IconButtonPop from "./IconButtonPop";


type Params = {
    placeholder: string,
    onSearch: (query:string) => void
}

export function SearchBox({placeholder, onSearch}:Params) {
    const [query, setQuery] = useState<string>();

    useEffect(() => {
        if (query === "") {
            onSearch("");
        }
    }, [query]);

    return (
        <form onSubmit={(e) => {
            e.preventDefault(); onSearch(query || "");
        }}>
            <div style={{position: "relative"}}>
                <InputGroup {...{placeholder}} value={query} onChange={(e:ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}/>
                <IconButtonPop responsive={false} onClick={() => query && onSearch(query)} sx={{position: "absolute", right: "0", top: "50%", transform: "translateY(-50%)"}} title={"Search"}><SearchOutlined/></IconButtonPop>
            </div>
        </form>
    );
}
