import React, {useState, useContext, useEffect} from "react";
import {Stack, Grid, Card, CardHeader, CardContent, Button, Dialog, MenuItem, Typography, Box, ListItem, ListItemSecondaryAction, ListItemText, List, Divider} from "@mui/material";
import IconButtonPop from "../../Components/IconButtonPop";
import InputGroup from "../../Components/FormComponents/InputGroup";
import Dropdown from "../../Components/FormComponents/Dropdown";
import DeletePopup from "../../Components/DeletePopup";
import {Delete, Download, StarRounded} from "@mui/icons-material";
import FileDropzone from "../../Components/FileDropzone/FileDropzone";
import Form from "../../Components/Form";
import {OrganisationContext, UserContext} from "../../App";
import {uploadFiles, UserData, deleteStorageItem, getUniqueId, PRIMARY_COLOUR} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {arrayRemove, arrayUnion, deleteField} from "firebase/firestore";
import {getDownloadURL, ref, getStorage} from "firebase/storage";
import {Worker, Viewer, Plugin, RenderViewer, SpecialZoomLevel} from "@react-pdf-viewer/core";
import {thumbnailPlugin} from "@react-pdf-viewer/thumbnail";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import Preloader from "../../Components/Preloader";
import {Popup} from "../../Components/Popup";
import Page from "../../Components/Page";
import styled from "styled-components";

export interface PageThumbnailPluginProps {
  PageThumbnail: React.ReactElement;
}

export const pageThumbnailPlugin = (props: PageThumbnailPluginProps): Plugin => {
    const {PageThumbnail} = props;

    return {
        renderViewer: (renderProps: RenderViewer) => {
            const {slot} = renderProps;

            slot.children = PageThumbnail;

            // Reset the sub slot
            if (slot.subSlot) {
                slot.subSlot.attrs = {};
                slot.subSlot.children = <></>;
            }

            return slot;
        },
    };
};


const Hoverable = styled(Card)`
  position: relative;
  cursor: pointer !important;
  height: 100%;
  transition: all ease-in-out 150ms;

  ::after {
      content:'';
      transition: opacity ease-in-out 150ms;
      background: rgb(121,32,245);
      background: linear-gradient(180deg, rgba(121,32,245,0.8) 0%, rgba(255,255,255,0) 100%);
      position: absolute;
      height: 40%;
      width: 100%;
      left: 0;
      opacity: 0;
    }

  #options {
    opacity: 0;
    transition: opacity ease-in-out 150ms;
    pointer-events: none;
  }

  &:hover {
    scale: 1.05;

    ::after {
      opacity: 1;
    }

    #options {
      pointer-events: all;
      opacity: 1;
    }
  }
`;

export default function Guidance() {
    const user = useContext(UserContext) as UserData;
    const organisation = useContext(OrganisationContext).details as {staffGuidance?: {[key: number]: string}, studentsGuidance?: {[key: number]: string}, admin: string};

    const [open, setOpen] = useState(false);
    const [openGuidance, setOpenGuidance] = useState({open: false, guidance: {id: "", title: "", url: ""}});
    const [openDelete, setOpenDelete] = useState({open: false, guidance: {id: "", title: "", visibility: ""}});
    const [guidances, setGuidances] = useState<{Staff?: {[id:string]: {title: string, url: string}}, Students?: {[id:string]: {title: string, url: string}}}>();
    const [favourites, setFavourites] = useState<string[]>([]);
    const storage = getStorage();

    // initialise FirebaseQuery class

    const firebaseQuery = new FirebaseQuery();


    async function handleSubmit(e:{title: string, doc: any[], visibility: string}) {
    // if user === student -> return
        if (user.userType === "Students") return;

        // common / src / firebase / firebaseQuery
        const id = getUniqueId(guidances || {});

        await uploadFiles(e.doc[0], `${user.product}/${user.oId}/guidance/${id}.pdf`);

        await firebaseQuery.update(
            [user.product, user.oId],
            e.visibility === "Staff" ? {[`staffGuidance.${id}`]: e.title} :
                e.visibility === "Students" ? {[`studentsGuidance.${id}`]: e.title} :

                    {
                        [`staffGuidance.${id}`]: e.title,
                        [`studentsGuidance.${id}`]: e.title,
                    },
        );
        // await update {user.product}/{user.oId} with {staffGuidance: arrayUnion([title, fileName])}

        // const res = await uploadFiles(files, `{user.product}/${user.oId}/guidance/${fileName}`)
        return;
    }

    async function handleToggleFavouriteGuidance(id: string) {
        if (!favourites.includes(id)) {
            console.log(user.id);
            setFavourites((favourites) => [...favourites, id]);
            await firebaseQuery.update(["users", user.id], {favGuidance: arrayUnion(id)});
            return;
        }
        setFavourites((favourites) => favourites.filter((name) => name !== id));
        await firebaseQuery.update(["users", user.id], {favGuidance: arrayRemove(id)});
    }

    async function handleDeleteGuidance() {
        const inStaff = guidances?.Staff && Object.keys(guidances.Staff).filter((guidance) => guidance === openDelete.guidance.id).length;
        const inStudents = guidances?.Students && Object.keys(guidances.Students).filter((guidance) => guidance === openDelete.guidance.id).length;

        if (!inStaff && !inStudents) {
            await deleteStorageItem(`${user.product}/${user.oId}/guidance/${openDelete.guidance.id}.pdf`);
        }
        await firebaseQuery.update([user.product, user.oId],
            openDelete.guidance.visibility === "Staff" ?
                {[`staffGuidance.${openDelete.guidance.id}`]: deleteField()} :
                {[`studentsGuidance.${openDelete.guidance.id}`]: deleteField()}
        );
        setOpenDelete({open: false, guidance: {id: "", title: "", visibility: ""}});
    }

    useEffect(() => {
    // get staffGuidance / studentGuidance list (organisation.staffGuidance) [[title, path], [title, path]]
        const fetchGuidanceDocs = async (userType:"Staff"|"Students") => {
            const docs:{[key:string]:{title:string, url:string}} = {};
            const guidance = organisation[`${userType.toLowerCase()}Guidance` as keyof typeof organisation];
            if (!guidance) return {};
            if (!Object.keys(guidance).length) return {};

            for (const [id, title] of Object.entries(guidance)) {
                const document = await getDownloadURL(ref(storage, `${user.product}/${user.oId}/guidance/${id}.pdf`));
                docs[id] = {title: title, url: document};
            }

            return docs;
        };


        const getGuidanceDocs = (userType:"Staff"|"Students") => {
            if ((user.userType === userType || user.id === organisation.admin)) {
                fetchGuidanceDocs(userType).then((data) => {
                    console.log(userType, "data", data);
                    setGuidances((state) => ({...state, [userType]: data}));
                });
            }
        };

        user.userType === "Staff" && getGuidanceDocs("Staff");
        getGuidanceDocs("Students");

        if (user.favGuidance?.length) {
            setFavourites(user.favGuidance);
        }
    }, [organisation]);

    return (
        <Page
            title="Guidance"
            metaTitle={"Placementt | Guidance"}
            metaDesc={user.userType === "Staff" ? "View and manage guidance documents for staff and students" : "View placement guidance documents"}
            titleSecondary={user.userType === "Staff" && <Button onClick = {() => setOpen(true)}>Add guidance</Button>}
        >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                {guidances && Object.entries(guidances).sort(([a], [b]) => (a < b) ? -1 : (a > b) ? 1 : 0).map(([userType, g]) => {
                    return (
                        <Grid container pl={2}>
                            {Boolean(user.userType === "Staff") && <Grid item xs={12}>
                                <Typography variant='h5' margin={"20px 0"}>{userType}</Typography>
                            </Grid>}
                            <Grid item container>
                                {Object.keys(g).length ? Object.entries(g).map(([id, guidance]:[string, {title: string, url:string}]) => {
                                    console.log("g", guidance);
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key = {id}>
                                            <Hoverable onClick = {() => setOpenGuidance((state) => ({...state, open: true, guidance: {id: id, title: guidance.title, url: guidance.url ? guidance.url : ""}}))}>
                                                <Stack id={"options"} sx={{position: "absolute", zIndex: 5, right: "10px", top: "10px"}} direction={"row"} spacing={0}>
                                                    <IconButtonPop responsive={false} title='Download' onClick={(e) => {
                                                        window.open(guidance.url); e.stopPropagation();
                                                    }} sx={{color: "white"}}><Download fontSize='medium' /></IconButtonPop>
                                                    {user.userType === "Staff" && <IconButtonPop responsive={false} title='Delete' sx={{color: "white"}} onClick={(e) => {
                                                        setOpenDelete((state) => ({...state, open: true, guidance: {...state.guidance, id: id, title: guidance.title, visibility: userType}})); e.stopPropagation();
                                                    }}><Delete/></IconButtonPop>}
                                                </Stack>
                                                <DisplayThumbnailMemo key={id} url={guidance.url}/>
                                                <Divider/>
                                                <List sx={{padding: 0}}>
                                                    <ListItem>
                                                        <ListItemText primary={<Typography variant='h6'>{guidance.title}</Typography>}/>
                                                        <ListItemSecondaryAction sx={{top: "28px", right: "5px"}}>
                                                            <IconButtonPop responsive={false} title={"Add guidance to favourites"} onClick={(e) => {
                                                                handleToggleFavouriteGuidance(id); e.stopPropagation();
                                                            }}><StarRounded style={{color: favourites.includes(id) ? PRIMARY_COLOUR : ""}} /></IconButtonPop>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </List>
                                            </Hoverable>
                                        </Grid>);
                                }) : <Grid item xs={12}><Typography>Your organisation has not uploaded any guidance documents yet.</Typography></Grid>}
                            </Grid>
                        </Grid>);
                })}
                <Popup title={openGuidance.guidance.title} key = {"ViewGuidance"} onClose = {() => setOpenGuidance((state) => ({...state, open: false}))} open = {openGuidance.open} fullWidth>
                    <Viewer fileUrl = {openGuidance.guidance.url}/>
                </Popup>
                <Dialog onClose = {() => setOpen(false)} open = {open}>
                    <CardHeader title="Add guidance"/>
                    <CardContent sx={{minWidth: "400px", maxWidth: "100%"}}>
                        <Form onSubmit = {(e) => handleSubmit(e as {title: string, doc: any[], visibility: string}).then(() => setOpen(false))}>
                            <Grid item xs={12} sm={6}>
                                <InputGroup required id="title" label="Title" name="title"/>
                            </Grid>
                            <FileDropzone accept={"application/pdf"} label = "Guidance document" name = "doc" defUploadText = "Upload PDF file" key={"GuidanceUploaderDropzone"}/>
                            <Dropdown id="visibility-dropdown" label={"Visibility"} name="visibility" size = "small" required>
                                <MenuItem value = "Students">Students</MenuItem>
                                <MenuItem value = "Staff">Staff</MenuItem>
                                <MenuItem value = "all">Both</MenuItem>
                            </Dropdown>
                        </Form>
                    </CardContent>
                </Dialog>
                <DeletePopup open = {openDelete.open} onClose = {() => setOpenDelete((state) => ({...state, open: false}))} title = {`'${openDelete.guidance.title}'`} onDelete = {handleDeleteGuidance} id = {String(openDelete.guidance.id)}/>
            </Worker>
        </Page>
    );
}

const DisplayThumbnailMemo = React.memo(DisplayThumbnail);


function DisplayThumbnail({url}:{url:string}) {
    const thumbnailPluginInstance = thumbnailPlugin({thumbnailWidth: 1000, renderSpinner: () => <Box sx={{marginTop: "80px"}}><Preloader visible/></Box>});
    const {Cover} = thumbnailPluginInstance;
    const pageThumbnailPluginInstance = pageThumbnailPlugin({
        PageThumbnail: <Cover getPageIndex={() => 0} width = {800}/>,
    });

    return (
        <Box sx={{height: "200px", overflow: "hidden"}}>
            <Box><Viewer renderLoader={() => <Box sx={{marginTop: "80px"}}><Preloader visible/></Box>} fileUrl = {url} plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]} defaultScale={SpecialZoomLevel.PageWidth} /></Box>
        </Box>
    );
}
