import {ChangeEvent, useContext, useEffect, useState} from "react";
import Page from "../../Components/Page";
import {VerticalPaginatorForm, useVerticalPlacementForm} from "../../Components/VerticalPaginator";
import {Checkbox, Divider, FormControlLabel, Grid, Stack, Table, TableRow, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {OrganisationAddress, UserData, camelCaseToNormal, capitalise, capitaliseWords, getGeoHash} from "placementt-core";
import InputGroup from "../../Components/FormComponents/InputGroup";
import Dropdown from "../../Components/FormComponents/Dropdown";
import ListMaker from "../../Components/FormComponents/ListMaker";
import {UserSearch} from "../Shared/ProposePlacement";
import {UserContext} from "../../App";
import {InfoTableCell} from "../../Util/styledComponents";
import IconButtonPop from "../../Components/IconButtonPop";
import {AccessibleForward, DirectionsBike, DirectionsCar, Kitchen} from "@mui/icons-material";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";

const addressSteps = ["address", "contacts", "misc", "review", "complete"];

const checkAddressValidity = (addressData: OrganisationAddress) => {
    const flags = [];

    if (!addressData["address-line1"] || !addressData.locality || !addressData.postal_code || !addressData.country) {
        flags.push("addressMissing");
    }

    if (!addressData.primaryContactId) {
        flags.push("noPrimaryContact");
    }

    return flags;
};

export default function AddAddressPage() {
    const {addressId}:{addressId?: string} = useParams();

    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();

    const [responsiblePerson, setResponsiblePerson] = useState<UserData>();

    const onUploadComplete = async (address: {
            id?: string;
            item?: {
                [key: string]: unknown;
            };
        }) => {
        const flags = checkAddressValidity(addressData);

        const update = {
            flags: flags,
            stage: "complete",
            geoHash: await getGeoHash(addressData),
        };

        if (!address.id) {
            alert("No ID");
            return;
        }
        await firebaseQuery.update(["addresses", address.id], update);

        navigate(-1);
    };

    const user = useContext(UserContext);

    const {step, changeStep, exitBuilder, updateItem, item} = useVerticalPlacementForm({stages: addressSteps, path: "addresses", id: addressId, onComplete: onUploadComplete});


    const addressData = item as OrganisationAddress;

    useEffect(() => {
        if (!addressData?.primaryContactId) return;
        if (addressData.primaryContactId === responsiblePerson?.id) return;
        firebaseQuery.getDocData(["users", addressData.primaryContactId || ""]).then((user) => setResponsiblePerson(user as UserData));
    }, [addressData]);


    console.log("STEP", step);

    return (
        <Page title={`${addressId ? "Edit" : "Add"} your address`} back={true} fullHeight>
            <VerticalPaginatorForm step={step} onChangeStep={changeStep} onComplete={exitBuilder} items={[
                {
                    title: "Address details",
                    description: "Add an address to your organisation and provide details to help your students.",
                    fields: <Grid container>
                        <Grid item xs={12}>
                            <InputGroup required id="name" label="Nickname (private)" name="name" onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem("name", e.target.value)} value={addressData?.name}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputGroup required id="address-line1" label="Address line one" name="address-line1" onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem("address-line1", e.target.value)} value={addressData?.["address-line1"]}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputGroup id="address-line2" label="Address line two" name="address-line2" onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem("address-line2", e.target.value)} value={addressData?.["address-line2"]}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputGroup required id="locality" label="City" name="locality" onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem("locality", e.target.value)} value={addressData?.locality}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputGroup id="what3Words" label="What3Words" name="what3Words" onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem("what3Words", e.target.value)} value={addressData?.what3Words}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputGroup required id="postcode" label="Postcode" name="postal_code" onChange={(e: ChangeEvent<HTMLInputElement>) => updateItem("postal_code", e.target.value)} value={addressData?.postal_code}/>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Dropdown required id="country" label="Country" name="country" onChange={(e) => updateItem("country", e.target.value)} value={addressData?.country}/>
                        </Grid>
                    </Grid>,
                },
                {
                    title: "Contact details",
                    fields: <Grid container>
                        <Grid item xs={12}>
                            <UserSearch onUserChange={(user) => updateItem("primaryContactId", user?.id)} defaultUid={user.id} required label="Responsible person" userType="Staff"/>
                        </Grid>
                        <Grid item xs={12}>
                            <ListMaker name={"reception"} noDefault header label="Reception contacts" multipleInputs onChange={(e) => updateItem(["contacts", "reception"], e)} items={addressData?.contacts?.reception}>
                                <Stack spacing={1}>
                                    <InputGroup name={"name"} label={"Name"} required/>
                                    <InputGroup name={"email"} label={"Email"} type={"email"}/>
                                    <InputGroup name={"phone"} label={"Phone"} type={"phone"}/>
                                </Stack>
                            </ListMaker>
                        </Grid>
                        <Grid item xs={12}>
                            <ListMaker name={"firstAid"} noDefault header label="First aiders" multipleInputs onChange={(e) => updateItem(["contacts", "firstAid"], e)} items={addressData?.contacts?.firstAid}>
                                <Stack spacing={1}>
                                    <InputGroup name={"name"} label={"Name"} required/>
                                    <InputGroup name={"email"} label={"Email"} type={"email"}/>
                                    <InputGroup name={"phone"} label={"Phone"} type={"phone"}/>
                                </Stack>
                            </ListMaker>
                        </Grid>
                        <Grid item xs={12}>
                            <ListMaker name={"security"} noDefault header label="Security / Emergency contacts" multipleInputs onChange={(e) => updateItem(["contacts", "security"], e)} items={addressData?.contacts?.security}>
                                <Stack spacing={1}>
                                    <InputGroup name={"name"} label={"Name"} required/>
                                    <InputGroup name={"email"} label={"Email"} type={"email"}/>
                                    <InputGroup name={"phone"} label={"Phone"} type={"phone"}/>
                                </Stack>
                            </ListMaker>
                        </Grid>
                    </Grid>,
                },
                {
                    title: "Additional questions",
                    description: "Let your students know if you have any of the following",
                    fields:
                            <Grid container>
                                <Grid item xs={6}>
                                    <FormControlLabel label={"On-site parking"} control={<Checkbox checked={addressData?.additionalQuestions?.parking} onChange={(e) => updateItem(["additionalQuestions", "parking"], e.target.checked)}/>}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel label={"Bike storage"} control={<Checkbox checked={addressData?.additionalQuestions?.bikeStorage} onChange={(e) => updateItem(["additionalQuestions", "bikeStorage"], e.target.checked)}/>}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel label={"Kitchen space"} control={<Checkbox checked={addressData?.additionalQuestions?.kitchen} onChange={(e) => updateItem(["additionalQuestions", "kitchen"], e.target.checked)}/>}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel label={"Disabled access"} control={<Checkbox checked={addressData?.additionalQuestions?.disabledAccess} onChange={(e) => updateItem(["additionalQuestions", "disabledAccess"], e.target.checked)}/>}/>
                                </Grid>
                            </Grid>,
                },
                {
                    title: "Review",
                    fields:
                        <Table size="small">
                            <TableRow>
                                <InfoTableCell>Nickname</InfoTableCell>
                                <InfoTableCell colSpan={2}>{addressData?.name}</InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell colSpan={3}>
                                    <Divider/>
                                </InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell><Typography variant="h6">Address</Typography></InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell>
                                    <Typography>{capitalise(addressData?.["address-line1"] || "")}</Typography>
                                </InfoTableCell>
                                <InfoTableCell>
                                    <Typography>{capitalise(addressData?.["address-line2"] || "")}</Typography>
                                </InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell>
                                    <Typography>{capitalise(addressData?.locality || "")}</Typography>
                                </InfoTableCell>
                                <InfoTableCell>
                                    <Typography>{(addressData?.postal_code || "").toUpperCase()}</Typography>
                                </InfoTableCell>
                                <InfoTableCell>
                                    <Typography>{capitaliseWords(camelCaseToNormal(addressData?.country))}</Typography>
                                </InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell colSpan={3}>
                                    <Divider/>
                                </InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell><Typography variant="h6">Contacts</Typography></InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell>Primary contact</InfoTableCell>
                                <InfoTableCell colSpan={2}>{responsiblePerson?.details.forename} {responsiblePerson?.details.surname} | {responsiblePerson?.email}</InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell>Reception</InfoTableCell>
                                <InfoTableCell colSpan={2}>{addressData?.contacts?.reception?.length} contacts</InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell>First aid</InfoTableCell>
                                <InfoTableCell colSpan={2}>{addressData?.contacts?.firstAid?.length} contacts</InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell>Security</InfoTableCell>
                                <InfoTableCell colSpan={2}>{addressData?.contacts?.security?.length} contacts</InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell colSpan={3}>
                                    <Divider/>
                                </InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell><Typography variant="h6">Amenities</Typography></InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell colSpan={3}>
                                    {Object.values(addressData?.additionalQuestions || {}).filter((v) => v).length === 0 && "No amenities selected."}
                                    {addressData?.additionalQuestions?.parking && <IconButtonPop responsive={false} title='Car parking' sx={{color: "gray"}}><DirectionsCar/></IconButtonPop>}
                                    {addressData?.additionalQuestions?.bikeStorage && <IconButtonPop responsive={false} title='Bike storage' sx={{color: "gray"}}><DirectionsBike/></IconButtonPop>}
                                    {addressData?.additionalQuestions?.disabledAccess && <IconButtonPop responsive={false} title='Disabled access' sx={{color: "gray"}}><AccessibleForward/></IconButtonPop>}
                                    {addressData?.additionalQuestions?.kitchen && <IconButtonPop responsive={false} title='Kitchen area' sx={{color: "gray"}}><Kitchen/></IconButtonPop>}
                                </InfoTableCell>
                            </TableRow>
                        </Table>,
                },
            ]}/>
        </Page>
    );
}
