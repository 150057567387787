import {Button, Chip, Grid, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText, Stack, Tab, Tabs, Typography} from "@mui/material";
import Page from "../../Components/Page";
import SubNavBar, {SubNavBarItems} from "../../Components/Navigation/SubNavBar";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {ReactNode, useContext, useEffect, useState} from "react";
import {Address, ApplicantStage, ApplicantWorkflow, Application, ERRORTEXTCOLOR, PRIMARY_COLOUR, PlacementListing, PlacementTemplate, StudentPlacementData, UserData, capitalise, convertDate, getAccess, useLoadApplications, useLoadProviderPlacements} from "placementt-core";
import {OrganisationContext, UserContext} from "../../App";
import {where} from "firebase/firestore";
import FilterList from "../../Components/FilterList";
import Card from "../../Components/Card";
import IconButtonPop from "../../Components/IconButtonPop";
import {ArrowForward, Delete, Edit, Upload} from "@mui/icons-material";
import {PlacementApplicationPopup} from "../Shared/PlacementApplicationPopup";
import {useNavigate, useSearchParams} from "react-router-dom";
import PlacementCalendar from "../../Components/PlacementCalendar";
import CircularProgressbar from "../../Components/CircularProgressbar";
import Tasks from "../../Components/Tasks";
import AddOnboardingDocsPopup from "./Popups/AddOnboardingDocsPopup";
import PlacementListingsList from "./Lists/PlacementListingList";
import DeletePopup from "../../Components/DeletePopup";
import {Popup} from "../../Components/Popup";
import {LoadingButton} from "../../Components/LoadingButton";
import Alert from "../../Components/Alert";
import {ListingSelector} from "./AddItemForms/RequestItemLists";


export default function Placements() {
    return (
        <Page
            title="Placements"
            noGutters
            metaTitle="Placementt | Placements"
            metaDesc="View your organisations's placements.">
            <SubNavBar
                pathLevel={3}
                items={[
                    {
                        label: "Overview",
                        route: "overview",
                        element: <Overview/>,
                    },
                    {
                        label: "Listings",
                        route: "listings",
                        element: <PlacementListings/>,
                    },
                    {
                        label: "Applicants",
                        route: "applicants",
                        element: <Applicants/>,
                    },
                    {
                        label: "Students",
                        route: "students",
                        element: <PlacementsTab/>,
                    },
                    false && {
                        label: "Setup",
                        route: "setup",
                        element: <Setup/>,
                    },
                ].filter((a) => a) as SubNavBarItems}/>
        </Page>);
}

function Overview() {
    const [openApplications, setOpenApplications] = useState<number>();
    const [scheduledPlacements, setScheduledPlacements] = useState<number>();
    const [activePlacements, setActivePlacements] = useState<number>();

    const navigate = useNavigate();
    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);
    const placements = useContext(OrganisationContext).placements as {[key: string]: StudentPlacementData};

    useEffect(() => {
        firebaseQuery.getCount("applications", [where("providerId", "==", user.oId), where("status", "==", "submitted")]).then(setOpenApplications);
        firebaseQuery.getCount("placements", [where("providerId", "==", user.oId), where("inProgress", "==", true)]).then(setScheduledPlacements);
        firebaseQuery.getCount("placements", [where("providerId", "==", user.oId), where("active", "==", true)]).then(setActivePlacements);
    }, []);

    return (
        <Page metaTitle="Placementt | Placements" grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h5" ml={2} mt={2}>Overview</Typography>
                <Stack direction={"row"} m={3} justifyContent={"space-around"}>
                    <CircularProgressbar text={openApplications?.toString()} label={[<Typography textAlign={"center"}>Open applications</Typography>, <IconButtonPop sx={{display: "inline"}} responsive={false} title="Go to open applications" onClick={() => navigate("/providers/placementListings/applicants")}><ArrowForward/></IconButtonPop>]}/>
                    <CircularProgressbar text={scheduledPlacements?.toString()} label={[<Typography textAlign={"center"}>Scheduled placements</Typography>, <IconButtonPop sx={{display: "inline"}} responsive={false} title="Go to scheduled placements" onClick={() => navigate("/providers/placementListings/students")}><ArrowForward/></IconButtonPop>]}/>
                    <CircularProgressbar text={activePlacements?.toString()} label={[<Typography textAlign={"center"}>Active placements</Typography>, <IconButtonPop sx={{display: "inline"}} responsive={false} title="Go to active placements" onClick={() => navigate("/providers/placementListings/students")}><ArrowForward/></IconButtonPop>]}/>
                </Stack>
                <Tasks/>
            </Grid>
            <Card grid xs={12} md={6} title={"Calendar"}>
                <PlacementCalendar placements={placements} date={new Date()}/>
            </Card>
        </Page>);
}

export function Applicants({listingId}:{listingId?: string}) {
    const [applicationCount, setApplicationCount] = useState<number>();
    const [applicationCountStaffRequired, setApplicationCountStaffRequired] = useState<number>();
    const [applicationCountAwaitingStudent, setApplicationCountAwaitingStudent] = useState<number>();
    const [applicationCountClosed, setApplicationCountClosed] = useState<number>();

    const [renderedApplications, setRenderedApplications] = useState<ReactNode>();
    const navigate = useNavigate();

    const [progressApplicantPopup, setProgressApplicantPopup] = useState<{application?: {application: Application, student: UserData, workflowStage: ApplicantStage, listing: PlacementListing}, open?: boolean}>();

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);
    const provider = useContext(OrganisationContext);

    const addresses = provider.addresses as {[key: string]: Address};

    const {applications, setType, onScrollBottom, type, loading} = useLoadApplications({user: user, listingId: listingId});

    useEffect(() => {
        firebaseQuery.getCount("applications", [where("providerId", "==", user.oId), where("status", "==", "submitted"), ...(listingId ? [where("listingId", "==", listingId)] : [])]).then(setApplicationCount);
        firebaseQuery.getCount("applications", [where("providerId", "==", user.oId), where("status", "==", "submitted"), ...(listingId ? [where("listingId", "==", listingId)] : []), where("reqUserType", "==", "Staff")]).then((c) => {
            setApplicationCountStaffRequired(c);
            if (c > 0) {
                setType("actionRequired");
            }
        });
        firebaseQuery.getCount("applications", [where("providerId", "==", user.oId), where("status", "==", "submitted"), ...(listingId ? [where("listingId", "==", listingId)] : []), where("reqUserType", "==", "Students")]).then(setApplicationCountAwaitingStudent);
        firebaseQuery.getCount("applications", [where("providerId", "==", user.oId), where("status", "in", ["approved", "declined"]), ...(listingId ? [where("listingId", "==", listingId)] : [])]).then(setApplicationCountClosed);
    }, []);

    console.log("APPLICATIONS", applications);


    const rejectPlacementWithoutListing = async (id: string) => {
        await firebaseQuery.update(["applications", id], {
            outcomeMessage: "Placement listing has been deleted.",
            status: "declined",
        } as Partial<Application>);
    };

    useEffect(() => {
        console.log("Applications", applications);
        if (!applications) return;

        Promise.all(applications.map(async ([key, application]) => {
            const student = await firebaseQuery.getDocData(["users", application.uid || ""]).catch(() => false) as UserData|false;
            const applicantWorkflow = (provider.applicantWorkflows[application.applicantWorkflowId] || await firebaseQuery.getDocData(["applicantWorkflows", application.applicantWorkflowId || ""])) as ApplicantWorkflow;
            const workflowStage = applicantWorkflow.workflow.find((s) => s.id === application.stage) as ApplicantStage;
            const listing = await firebaseQuery.getDocData(["placementListings", application.listingId || ""]).catch(() => false) as PlacementListing|false;
            const chipColor = application.status === "submitted" ? "primary" : application.status === "approved" ? "success" : application.status === "declined" ? "error" : undefined;
            const address = listing && addresses[listing.addressId as string];

            return (
                <ListItemButton key={key} id={key} divider sx={{pt: 2, pb: 2}} onClick={() => {
                    listing && student && setProgressApplicantPopup({
                        application: {application: application, student: student, workflowStage: workflowStage, listing: listing},
                        open: true,
                    });
                }}>
                    <ListItemText
                        primary={<Typography><strong style={{color: student ? undefined : ERRORTEXTCOLOR}}>{student ? `${student.details?.forename} ${student.details?.surname}` : "User deleted"}</strong> | {convertDate(application.startDate, "visual") as string} - {convertDate(application.endDate, "visual") as string}</Typography>}
                        secondary={
                            <Stack spacing={0}>
                                <Typography>{student ? student.email : "User deleted"}</Typography>
                                <Typography>{listing && listing?.title || "Listing not found"} | {address && address["address-line1"]} - {address && address?.postal_code}</Typography>
                            </Stack>
                        }
                    />
                    <ListItemSecondaryAction>
                        {listing ?
                            <Stack spacing={1} alignItems={"flex-end"}>
                                <Chip color={chipColor} sx={{width: "max-content"}} label={application.status !== "submitted" ? capitalise(application.status) : application.reqUserType === "Staff" ? "Action Required" : "Submitted"}/>
                                {application.status === "approved" && application.placementId ? <Chip label="Go to placement" size="medium" color="primary" onClick={() => navigate(`/${user.product}/placements/${application.placementId}`)} onDelete={() => navigate(`/${user.product}/placements/${application.placementId}`)} deleteIcon={<ArrowForward/>}/> : <Stack direction={"row"}>
                                    <IconButtonPop responsive={false} title="Edit"><Edit/></IconButtonPop>
                                    <IconButtonPop responsive={false} title="Delete"><Delete/></IconButtonPop>
                                </Stack>}
                            </Stack> :
                            <Stack spacing={1} alignItems={"flex-end"}>
                                {application.status === "submitted" && <Alert sx={{maxWidth: "75%"}} severity="error" action={<LoadingButton color="inherit" onClick={async () => await rejectPlacementWithoutListing(key)} text="Remove"/>}>Associated listing has been deleted. Unable to process application further. Please remove the application.</Alert>}
                            </Stack>}
                    </ListItemSecondaryAction>
                </ListItemButton>
            );
        })).then(setRenderedApplications);
    }, [applications]);

    if (applicationCount === undefined) return null;

    return (
        <Page grid>
            <Grid item xs={12} md={8}>
                <Stack>
                    <Tabs value={type} onChange={(_, v) => setType(v)}>
                        <Tab value={"all"} label={`All applications (${applicationCount})`}/>
                        <Tab value={"actionRequired"} label={`Action required (${applicationCountStaffRequired})`}/>
                        <Tab value={"awaitingStudent"} label={`Awaiting student (${applicationCountAwaitingStudent})`}/>
                        <Tab value={"closed"} label={`Closed (${applicationCountClosed})`}/>
                    </Tabs>
                    <FilterList title={" "} loadMoreIcon={loading} onScrollBottom={onScrollBottom} data={Object.fromEntries(applications)}>
                        {renderedApplications}
                    </FilterList>
                </Stack>
            </Grid>
            <Card grid xs={12} md={4} title={"Overview"}>
                <List>
                    <ListItem style={{background: applicationCount > 0 ? PRIMARY_COLOUR : "#00000010", borderRadius: "20px", marginBottom: "10px", color: applicationCount ? "white" : undefined}}>
                        <ListItemText primaryTypographyProps={{fontWeight: applicationCount && "bold"}} primary="Open applications"/>
                        <ListItemSecondaryAction>
                            <Stack direction={"row"} alignItems={"center"} color={applicationCount > 0 ? "white !important" : undefined}>
                                {applicationCount}
                                <IconButtonPop onClick={() => setType("all")} title="View" responsive={false} ><ArrowForward sx={{color: applicationCount > 0 ? "white !important" : undefined}}/></IconButtonPop>
                            </Stack>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                        <ListItemText primary="Action required"/>
                        <ListItemSecondaryAction>
                            <Stack direction={"row"} alignItems={"center"}>
                                {applicationCountStaffRequired}
                                <IconButtonPop onClick={() => setType("actionRequired")} title="View" responsive={false}><ArrowForward/></IconButtonPop>
                            </Stack>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem sx={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                        <ListItemText primary="Awaiting student"/>
                        <ListItemSecondaryAction>
                            <Stack direction={"row"} alignItems={"center"}>
                                {applicationCountAwaitingStudent}
                                <IconButtonPop onClick={() => setType("awaitingStudent")} title="View" responsive={false}><ArrowForward/></IconButtonPop>
                            </Stack>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Card>
            <PlacementApplicationPopup key={progressApplicantPopup?.application?.application.id} listing={progressApplicantPopup?.application?.listing} application={progressApplicantPopup?.application?.application} applicationId={progressApplicantPopup?.application?.application.id} open={Boolean(progressApplicantPopup?.open)} onClose={() => setProgressApplicantPopup((p) => ({application: p?.application, open: false}))}/>
        </Page>
    );
}
/*
function ViewApplicantion({open, onClose, application}:{open: boolean, onClose: () => void, application?: {application: Application, student: UserData, workflowStage: ApplicantStage, listing: PlacementListing}}) {
    const navigate = useNavigate();

    const [acceptPopup, setAcceptPopup] = useState(false);
    const [rejectPopup, setRejectPopup] = useState(false);

    const progressStage = async (type: "progress"|"accept"|"reject", e?: {feedback?: string}) => {
        return await executeCallable("applications-changeStage", {applicationId: application?.application.id, type: type, feedback: e?.feedback});
    };

    const applicationObj = application?.application;
    const student = application?.student;
    const workflowStage = application?.workflowStage;
    const listing = application?.listing;

    if (!applicationObj || !student || !workflowStage) return null;

    return (
        <Popup {...{open, onClose}} title={"View application"} fullWidth>
            <Typography>{student.details.forename} {student.details.surname}</Typography>
            <Divider/>
            <Stack direction={"row"} pt={1} pb={1} justifyContent={"space-between"} alignItems={"center"}>
                <Typography>{listing?.title}</Typography>
                <IconButtonPop title="View placement" responsive={false} onClick={() => navigate(`/providers/viewListing/${applicationObj.listingId}`)}><ArrowForward/></IconButtonPop>
            </Stack>
            <Divider/>

            <Stack direction={"row"}>
                {workflowStage.buttons?.find((b) => b.id === 11) && <Button variant={acceptPopup ? "contained" : "outlined"} color="success" onClick={() => {
                    setRejectPopup(false);
                    setAcceptPopup(true);
                }}>Accept</Button>}
                {workflowStage.buttons?.find((b) => b.id === 12) && <Button variant={rejectPopup ? "contained" : "outlined"} color="error" onClick={() => {
                    setAcceptPopup(false);
                    setRejectPopup(true);
                }}>Reject</Button>}
            </Stack>

            {workflowStage.buttons?.find((b) => b.id === 11) && <Collapse in={acceptPopup}>
                <Form submitText="Accept" onSubmit={(e) => progressStage("accept", e as {feedback?: string})} functionType="async">
                    <Stack>
                        <Typography>Accept application</Typography>
                        <InputGroup name="feedback" label={"Application feedback"} placeholder={"Type some constructive feedback or information to be shared with the student."} required minRows={4} multiline/>
                    </Stack>
                </Form>
            </Collapse>}
            {workflowStage.buttons?.find((b) => b.id === 12) && <Collapse in={rejectPopup}>
                <Form submitText="Reject" onSubmit={(e) => progressStage("reject", e as {feedback?: string})} functionType="async">
                    <Stack>
                        <Typography>Reject application</Typography>
                        <InputGroup name="feedback" label={"Application feedback"} placeholder={"Type some constructive feedback or information to be shared with the student."} required minRows={4} multiline/>
                    </Stack>
                </Form>
            </Collapse>}

        </Popup>);
}
*/

export function PlacementsTab({listingId}:{listingId?: string}) {
    const [activePlacementCount, setActivePlacementCount] = useState<number>(0);
    const [upcomingPlacementCount, setUpcomingPlacementCount] = useState<number>(0);
    const [completedPlacementCount, setCompletedPlacementCount] = useState<number>(0);
    const [placementCancelledCount, setPlacementCancelledCount] = useState<number>(0);

    const [addOnboardingDocsPopup, setAddOnboardingDocsPopup] = useState<StudentPlacementData>();

    const navigate = useNavigate();

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);

    const {placements: completedPlacements, loading: completedLoading, onScrollBottom: completedOnScrollBottom} = useLoadProviderPlacements(user, [where("inProgress", "==", false)], listingId);
    const {placements: activePlacements, loading: activeLoading, onScrollBottom: activeOnScrollBottom} = useLoadProviderPlacements(user, [where("inProgress", "==", true), where("active", "==", true)], listingId);
    const {placements: upcomingPlacements, loading: upcomingLoading, onScrollBottom: upcomingOnScrollBottom} = useLoadProviderPlacements(user, [where("inProgress", "==", true), where("active", "==", false)], listingId);

    useEffect(() => {
        const constraints = [where("providerId", "==", user.oId)];
        if (listingId) constraints.push(where("placementId", "==", listingId));

        firebaseQuery.getCount("placements", [...constraints, where("inProgress", "==", false)]).then(setCompletedPlacementCount);

        constraints.push(where("inProgress", "==", true));

        firebaseQuery.getCount("placements", [...constraints, where("active", "==", false)]).then(setUpcomingPlacementCount);
        firebaseQuery.getCount("placements", [...constraints, where("active", "==", true)]).then(setActivePlacementCount);


        setPlacementCancelledCount(0);
    }, []);

    const renderPlacements = (placements: {[key: string]: StudentPlacementData&{student: UserData|false, listing: PlacementListing}}) => Object.entries(placements).map(([key, placement]) => {
        const onboardingStatus:"Add onboarding documents"|"Onboarding sent"|"Onboarding docs completed"|"Onboarding docs approved"|undefined = placement.onboarding ? placement.onboarding.completed.submitted ? placement.onboarding.completed.accepted ? "Onboarding docs approved" : "Onboarding docs completed" : "Onboarding sent" : placement.onboarding === null ? "Add onboarding documents" : undefined;

        return (
            <ListItemButton key={key} id={key} divider onClick={() => navigate(`/${user.product}/placements/${key}`)}>
                <ListItemText
                    primary={<Typography><strong style={{color: placement.student ? undefined : ERRORTEXTCOLOR}}>{placement.student ? `${placement.student.details?.forename} ${placement.student.details?.surname}` : "User deleted"}</strong> | {convertDate(placement.startDate, "visual") as string} - {convertDate(placement.endDate, "visual") as string}</Typography>}
                    secondary={
                        <Stack spacing={0}>
                            <Typography>{placement.student ? placement.student.email : "User deleted"}</Typography>
                            <Typography>{placement.listing?.title || "Listing not found"} | {placement["address-line1"]} - {placement.postal_code}</Typography>
                        </Stack>
                    }
                />
                <ListItemSecondaryAction>
                    <Stack spacing={2} alignItems={"flex-end"}>
                        {placement.reqUserType === "Provider" && <Chip color={"primary"} sx={{width: "max-content"}} label={"Action required"}/>}
                        {placement.statusType === "requested" && <Chip color={"warning"} sx={{width: "max-content"}} label={"Awaiting student"}/>}
                        {placement.statusType === "withdrawn" && <Chip color={"error"} sx={{width: "max-content"}} label={"Student withdrawn"}/>}
                        <Stack direction={"row"}>
                            {placement.inProgress && !["requested", "withdrawn"].includes(placement.statusType) && onboardingStatus ? onboardingStatus !== "Onboarding docs approved" && (onboardingStatus === "Add onboarding documents" ? <Button onClick={(e) => {
                                e.stopPropagation();
                                setAddOnboardingDocsPopup(placement);
                            }}>Add onboarding documents</Button> : <Chip color="primary" label={onboardingStatus}/>) : undefined}
                            <IconButtonPop sx={{alignSelf: "end"}} responsive={false} title="View placement" onClick={() => navigate(`/${user.product}/placements/${key}`)} onDelete={() => navigate(`/${user.product}/placements/${key}`)}><ArrowForward/></IconButtonPop>
                        </Stack>
                    </Stack>
                </ListItemSecondaryAction>
            </ListItemButton>
        );
    });

    return (
        <Page title={""} noGutters={Boolean(listingId)} grid>
            <Grid item xs={12} md={8} direction={"column"}>
                <Stack>
                    <FilterList sx={{maxHeight: "500px"}} title={"Active placements"} data={activePlacements} loadMoreIcon={activeLoading} onScrollBottom={activeOnScrollBottom}>
                        {activePlacements && renderPlacements(activePlacements)}
                    </FilterList>
                    <FilterList sx={{maxHeight: "500px"}} title={"Scheduled placements"} data={upcomingPlacements} loadMoreIcon={upcomingLoading} onScrollBottom={upcomingOnScrollBottom}>
                        {upcomingPlacements && renderPlacements(upcomingPlacements)}
                    </FilterList>
                </Stack>
            </Grid>
            <Grid item xs={12} md={4} direction={"column"}>
                <Stack>
                    <Card grid title={"Overview"}>
                        <List>
                            <ListItem style={{background: activePlacementCount > 0 ? PRIMARY_COLOUR : "#00000010", borderRadius: "20px", marginBottom: "10px", color: activePlacementCount ? "white" : undefined}}>
                                <ListItemText primaryTypographyProps={{fontWeight: activePlacementCount && "bold"}} primary="Active placements"/>
                                <ListItemSecondaryAction style={{color: activePlacementCount ? "white" : undefined}}>
                                    <Stack direction={"row"} alignItems={"center"}>
                                        {activePlacementCount}
                                        <IconButtonPop title="View" responsive={false} ><ArrowForward style={{color: activePlacementCount ? "white" : undefined}}/></IconButtonPop>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem style={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                                <ListItemText primary="Upcoming placements"/>
                                <ListItemSecondaryAction>
                                    <Stack direction={"row"} alignItems={"center"}>
                                        {upcomingPlacementCount}
                                        <IconButtonPop title="View" responsive={false} ><ArrowForward/></IconButtonPop>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem style={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                                <ListItemText primary="Completed placements"/>
                                <ListItemSecondaryAction>
                                    <Stack direction={"row"} alignItems={"center"}>
                                        {completedPlacementCount}
                                        <IconButtonPop title="View" responsive={false} ><ArrowForward/></IconButtonPop>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItem>
                            {placementCancelledCount > 0 && <ListItem style={{background: "#00000010", borderRadius: "20px", marginBottom: "10px"}}>
                                <ListItemText primary="Cancelled placements"/>
                                <ListItemSecondaryAction>
                                    <Stack direction={"row"} alignItems={"center"}>
                                        {placementCancelledCount}
                                        <IconButtonPop title="View" responsive={false} ><ArrowForward/></IconButtonPop>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItem>}
                            <ListItemButton onClick={() => navigate("/providers/addStudentPlacement")} style={{borderRadius: "20px", border: `2px solid ${PRIMARY_COLOUR}`, marginBottom: "10px"}}>
                                <ListItemText primaryTypographyProps={{color: PRIMARY_COLOUR}} primary="Manually add placement"/>
                                <ListItemSecondaryAction>
                                    <Stack direction={"row"} alignItems={"center"}>
                                        <IconButtonPop title="View" responsive={false} ><ArrowForward color={"primary"}/></IconButtonPop>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        </List>
                    </Card>
                    <FilterList sx={{maxHeight: "500px"}} title={"Completed placements"} data={completedPlacements} loadMoreIcon={completedLoading} onScrollBottom={completedOnScrollBottom}>
                        {completedPlacements && renderPlacements(completedPlacements)}
                    </FilterList>
                </Stack>
            </Grid>

            <AddOnboardingDocsPopup onClose={() => setAddOnboardingDocsPopup(undefined)} placement={addOnboardingDocsPopup} onComplete={() => setAddOnboardingDocsPopup(undefined)}/>
        </Page>
    );
}


function ApplicantWorkflows() {
    const [deleteWorkflow, setDeleteWorkflow] = useState<string>();
    const navigate = useNavigate();
    const firebaseQuery = new FirebaseQuery();
    const org = useContext(OrganisationContext);

    const workflows = org.applicantWorkflows as {[k: string]: ApplicantWorkflow};
    const [cannotDeleteWorkflow, setCannotDeleteWorkflow] = useState<number>();
    const user = useContext(UserContext);

    return (
        <>
            <FilterList title="Applicant workflows" data={workflows} grid xs={12} md={4} actionButton={false && <Button onClick={() => navigate("/providers/addApplicantWorkflow")}>New</Button>}>
                {Object.entries(workflows).map(([id, workflow]) =>
                    <ListItemButton key={id} divider id={id} onClick={() => navigate(`/providers/addApplicantWorkflow/${id}`)}>
                        <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                            <ListItemText
                                primary={<Stack spacing={0}>
                                    <Typography fontSize={"1.1em"}>{workflow.name}</Typography>
                                    <Typography color={"grey"}>{workflow.workflow.length} stages</Typography>
                                </Stack>}/>
                            <Stack spacing={1}>
                                <Stack direction={"row"} sx={{justifyContent: "space-between"}}>
                                    <IconButtonPop responsive={false} onClick={() => navigate(`/providers/addApplicantWorkflow/${id}`)} title="Edit workflow"><Edit/></IconButtonPop>
                                    <IconButtonPop responsive={false} onClick={async (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();

                                        const listingOnWorkflow = await firebaseQuery.getCount("placementListings", [where("providerId", "==", user.oId), where("applicantWorkflowId", "==", id)]);
                                        if (listingOnWorkflow) {
                                            setCannotDeleteWorkflow(listingOnWorkflow);
                                        }
                                        setDeleteWorkflow(id);
                                    }} title="Delete workflow"><Delete/></IconButtonPop>
                                </Stack>
                            </Stack>
                        </Stack>
                    </ListItemButton>)}
            </FilterList>
            <Popup title="Delete workflow" open={Boolean(deleteWorkflow)} onClose={() => setDeleteWorkflow(undefined)}>
                <Stack>
                    <Typography>Permanently delete "{deleteWorkflow && workflows[deleteWorkflow].name}"?</Typography>
                    {!cannotDeleteWorkflow ?
                        <LoadingButton text="Delete" onClick={async () => {
                            await firebaseQuery.delete(["addresses", deleteWorkflow || ""]).then(() => setDeleteWorkflow(undefined));
                        }}/> :
                        <>
                            <Alert severity="warning">Cannot delete address associated with 2 active listings.</Alert>
                            <LoadingButton>
                                <Button onClick={() => {
                                    setCannotDeleteWorkflow(undefined);
                                    setDeleteWorkflow(undefined);
                                }}>Exit</Button>
                                <Button color="error" onClick={async () => {
                                    await firebaseQuery.delete(["applicantWorkflows", deleteWorkflow || ""]).then(() => setDeleteWorkflow(undefined));
                                }}>Delete and delist associated listings.</Button>
                            </LoadingButton>
                        </>}
                </Stack>
            </Popup>        </>
    );
}


function Setup() {
    return (
        <Page
            metaTitle="Placementt | Setup"
            grid
        >
            <ApplicantWorkflows/>
        </Page>);
}

function PlacementListings() {
    const navigate = useNavigate();
    const [deleteTemplate, setDeleteTemplate] = useState<string>();
    const [requestListingsPopup, setRequestListingsPopup] = useState(false);
    const firebaseQuery = new FirebaseQuery();
    const org = useContext(OrganisationContext);
    const user = useContext(UserContext);

    const [searchParams] = useSearchParams();
    const filters = Object.fromEntries([...searchParams]);
    const filterElementId = filters.id;
    delete filters.id;

    const templates = org.placementTemplates as {[k: string]: Partial<PlacementTemplate>};

    return (
        <Page grid>
            <Grid item xs={12} md={8}>
                <Stack>
                    {!org.details.mapConsent && <Alert severity="warning" sx={{alignItems: "center"}} action={<IconButtonPop onClick={() => navigate(`/provider/${user.oId}#publish`)} responsive={false} title="Publish"><Upload/></IconButtonPop>}>Pages are not currently live. Click to publish.</Alert>}
                    <Card>
                        <PlacementListingsList key={"publishedListings"} type="published" filters={filterElementId === "published" ? filters : undefined}/>
                    </Card>
                    <Card>
                        <PlacementListingsList key={"draftListings"} type="draft" filters={filterElementId === "draft" ? filters : undefined}/>
                    </Card>
                </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
                <Stack>
                    <Card>
                        <Stack>
                            {getAccess(user, "addListings") && <Button sx={{width: "100%"}} variant="contained" onClick={() => navigate("/providers/addListing")}>Create new listing</Button>}
                            {user.viewPlacementListings === "request" && <Button sx={{width: "100%"}} variant="contained" onClick={() => setRequestListingsPopup(true)}>Request access</Button>}
                            {getAccess(user, "addEmailTemplates") && <Button sx={{width: "100%"}} onClick={() => navigate("/providers/addListing", {state: {template: true}})}>Create new template</Button>}
                        </Stack>
                    </Card>
                    <Card>
                        <FilterList title="Templates" card={false} data={templates}>
                            {Object.entries(templates).map(([id, workflow]) =>
                                <ListItemButton key={id} divider id={id} onClick={() => navigate(`/providers/addListing/${id}`, {state: {template: true}})}>
                                    <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                                        <ListItemText
                                            primary={<Stack spacing={0}>
                                                <Typography fontSize={"1.1em"}>{workflow.templateName}</Typography>
                                            </Stack>}/>
                                        <Stack spacing={1}>
                                            <Stack direction={"row"} sx={{justifyContent: "space-between"}}>
                                                <IconButtonPop responsive={false} onClick={() => navigate(`/providers/addListing/${id}`, {state: {template: true}})} title="Edit template"><Edit/></IconButtonPop>
                                                <IconButtonPop responsive={false} onClick={(e) => {
                                                    setDeleteTemplate(workflow.templateName);
                                                    e.stopPropagation();
                                                }} title={"Delete template?"}><Delete/></IconButtonPop>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </ListItemButton>)}
                        </FilterList>
                    </Card>
                </Stack>
            </Grid>
            <DeletePopup title="template" onDelete={async () => await firebaseQuery.delete(["placementTemplates", deleteTemplate || ""])} itemName={deleteTemplate} open={Boolean(deleteTemplate)} onClose={() => setDeleteTemplate(undefined)}/>
            {user.viewPlacementListings === "request" && <ListingSelector type="popup" visible={requestListingsPopup} onClose={() => setRequestListingsPopup(false)} request/>}
        </Page>
    );
}

/*
function ListingGroups() {
    const navigate = useNavigate();
    return (
        <SubNavBar items={[
            {
                label: "All listings",
                route: "all",
                element: <PlacementListingsList/>,
            },
            {
                label: "By address",
                route: "byAddress",
                element: <PlacementListingsList/>,
                divider: true,
            },
        ]} pathLevel={4} trailingItem={
            <>
                <Button onClick={() => navigate("/providers/addListing")}>New group</Button>
            </>}/>);
}

*/
