import {Box, Card} from "@mui/material";
import {useEffect, useState} from "react";
import IconButtonPop from "../../Components/IconButtonPop";
import {CreateRounded} from "@mui/icons-material";
import Notes from "./Notes";
import styled from "styled-components";

type NotesPageProps = {
    $popout: boolean,
    $open?: boolean
}

const NotesPage = styled(Box)<NotesPageProps>`
  position: ${(props) => props.$popout ? "absolute" : "relative"};
  top: ${(props) => props.$popout ? "10vh" : "0"};
  height: ${(props) => props.$popout ? "80vh" : "100%"};
  width: ${(props) => props.$popout ? "80vw" : "100%"};
  max-width: ${(props) => props.$popout ? "400px" : "100%"};
  left: ${(props) => props.$popout ? "100%" : "0"};
  min-height: 400px;

  transform: ${(props) => props.$popout && props.$open ? "translateX(-100%)" : "translateX(0)"};

  transition: ${(props) => props.$popout ? "all 250ms ease-in-out" : "none"};
  overflow: visible;
  z-index: 5;
`;

const NotesIcon = styled(Box)`
    height: 50px;
    width: 50px;
    box-shadow: rgba(0,0,0,0.3) 0px 0px 5px 0px;
    position: absolute;
    bottom: 20px;
    left: -50px;
    border-radius: 10px 0 0 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default function NotesNew() {
    const [popout, setPopout] = useState(window.innerWidth < 1200);
    const [popoutOpen, setPopoutOpen] = useState(false);

    useEffect(() => {
        const handleWindowResize = () => {
            setPopout(window.innerWidth < 1200);
        };
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);


    return (
        <>
            <NotesPage $popout={popout} $open={popoutOpen}>
                {popout && <NotesIcon><IconButtonPop responsive={false} title="Edit notes" onClick={() => setPopoutOpen((o) => !o)}><CreateRounded/></IconButtonPop></NotesIcon>}
                <Card sx={{width: "100%", height: "100%"}}>
                    <Notes/>
                </Card>
            </NotesPage>
        </>

    );
}
