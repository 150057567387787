import {Box, Button, Grid, Stack, Typography, TypographyPropsVariantOverrides} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import {OverridableStringUnion} from "@mui/types";
import {useRefDimensions} from "placementt-core";
import {ReactNode} from "react";


type Params = {
    title: string,
    titleVariant?: OverridableStringUnion<"inherit" | Variant, TypographyPropsVariantOverrides>,
    subtext: string,
    subtextVariant?: OverridableStringUnion<"inherit" | Variant, TypographyPropsVariantOverrides>,
    button?: {
        label: string,
        onClick: () => void,
    },
    image?: ReactNode,
    background: string|ReactNode,
    scaleBackground?: boolean,
    textColor?: string
};

export default function InfoPageHeader({title, subtext, button, image, background, scaleBackground, titleVariant="h3", subtextVariant="h5", textColor="white"}:Params) {
    const {dimensions, refCallback} = useRefDimensions();

    return (
        <Stack alignItems={"center"} mb={5} ref={refCallback}>
            <Box position={"absolute"} bgcolor={typeof background === "string" ? background : "transparent"} width={"100vw"} left={0} height={scaleBackground ? dimensions.height : "700px"} overflow={"visible"}>
                {typeof background === "string" || background}
            </Box>
            <Box zIndex={10} width={"100%"}>
                <Grid container margin={"auto"} width={"100%"} pb={8} pt={4} zIndex={10}>
                    <Grid item xs={12} md={7} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Stack color={"white !important"} spacing={2} maxWidth={"600px"} width={"max-content"} m={"auto auto"}>
                            <Typography variant={titleVariant} color={textColor}>{title}</Typography>
                            <Typography variant={subtextVariant} color={textColor}>{subtext}</Typography>
                            {button && <Button color={textColor === "white" ? "white" : undefined} onClick={() => button.onClick()}>{button.label}</Button>}
                        </Stack>
                    </Grid>
                    {image && <Grid item xs={12} md={5} sx={{display: "flex", justifyContent: "center", alignItems: "center", overflow: "visible", height: "max-content"}}>
                        {image}
                    </Grid>}
                </Grid>
            </Box>
        </Stack>
    );
}
