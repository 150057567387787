import InfoPageHeader from "../../Components/Public/InfoPageHeader";
import {PROVIDER_COLOUR} from "placementt-core";
import PublicTemplate from "../../Components/Public/PublicTemplate";
import IconGridList from "../../Components/Public/IconGridList";
import {AssignmentTurnedInRounded, CampaignRounded, PersonSearchRounded, SchoolRounded} from "@mui/icons-material";
import TwoColumnSection from "../../Components/Public/TwoColumnSection";
import {Button, Stack, Typography} from "@mui/material";
import Page from "../../Components/Page";
import BillingTable from "../../Components/BillingTable";
import {useNavigate} from "react-router-dom";

export default function ProviderInfo() {
    const navigate = useNavigate();

    return (
        <Page
            metaTitle="Placementt | Business"
            metaDesc="Advertise placements, manage work experience and headhunt students. Placementt - Business integrates you seamlessly with the work experience process."
        >
            <InfoPageHeader
                title="Placementt - Business"
                subtext="Advertise placements, manage work experience and headhunt students. Placementt - Business integrates you seamlessly with the work experience process."
                background={PROVIDER_COLOUR}
                scaleBackground
            />
            <PublicTemplate>
                <IconGridList
                    title="Empower the brightest minds of the next generation by providing high quality placements."
                    titleColour={PROVIDER_COLOUR}
                    items={[
                        {
                            title: "Comprehensive student management",
                            icon: <SchoolRounded/>,
                        },
                        {
                            title: "Advertise your opportunities",
                            icon: <CampaignRounded/>,
                        },
                        {
                            title: "Headhunt top-tier students",
                            icon: <PersonSearchRounded/>,
                        },
                        {
                            title: "Integrate seamlessly with institutes",
                            icon: <AssignmentTurnedInRounded/>,
                        },
                    ]}
                />
                <TwoColumnSection
                    title="Empower your students and supercharge your workforce"
                    titleColor={PROVIDER_COLOUR}
                    sectionOne="Placementt is building a hub for student placements and recruitment. We support businesses in managing their placements, advertising their opportunities and headhunting the best student talent."
                    sectionTwo="Work experience is integral to career development. Our platform allows you to reach talented young professionals, and provide the level of support they need to enrich their career."
                />
                <Stack>
                    <Typography textAlign={"start"} color={PROVIDER_COLOUR} variant="h5">A system that suits your needs, from a small to large businesses.</Typography>
                    <Typography fontSize={"1.2em"}>We price our platform as fairly and openly as possible, charging you only for placements you organise through us. Join anytime, cancel anytime, and pay for only the features you need from Placementt.</Typography>
                    <Typography variant="caption">Psst... Read below for pricing information.</Typography>
                </Stack>

                <BillingTable product="providers"/>
                <Button onClick={() => navigate("/register")}>Register now</Button>
            </PublicTemplate>
        </Page>
    );
}
