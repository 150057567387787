import {useContext, useEffect, useState} from "react";
import {Popup} from "../../Components/Popup";
import {arrayUnion, limit, orderBy} from "firebase/firestore";
import {UserContext} from "../../App";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {PRIMARY_COLOUR, UserData} from "placementt-core";
import {Box, Button, Stack, Typography} from "@mui/material";
import homepageSVG from "../../Images/uk_homepage.svg";
import {LoadingButton} from "../../Components/LoadingButton";


export default function UpdatesPopup() {
    const [active, setActive] = useState<{title: string, body: string, id: string}>();
    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext) as UserData;

    useEffect(() => {
        setTimeout(() => {
            firebaseQuery.getDocsWhere("updates", [orderBy("created", "desc"), limit(1)]).then((result) => {
                const item = Object.values(result as {[key:string]: {title: string, body: string, id: string}})[0];
                if (!user.readUpdates?.includes(item.id)) {
                    setActive(item);
                }
            });
        }, 1000);
    }, []);

    const dismiss = () => {
        firebaseQuery.update(["users", user.id], {readUpdates: arrayUnion(active?.id)});
        setActive(undefined);
    };

    if (!user.contactProviderConsent) {
        return (<ContactProviderConsent/>);
    }

    return (
        <Popup open={Boolean(active)} title={active?.title} onClose={dismiss}>
            <Typography>{active?.body.split("<br/>").join("\n")}</Typography>
            <Button onClick={dismiss}>Dismiss</Button>
        </Popup>
    );
}


function ContactProviderConsent() {
    const [active, setActive] = useState<boolean>(false);
    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext) as UserData;

    const consent = async (consent: boolean) => {
        await firebaseQuery.update(["users", user.id], {
            contactProviderConsent: consent,
            contactProviderConsentDate: (new Date()).toISOString(),
        });
        setActive(false);
    };

    useEffect(() => {
        if (user.contactProviderConsent === undefined) {
            setActive(true);
        }
    }, []);


    return (
        <Popup open={active} title={"The Placementt database"} onClose={() => setActive(false)}>
            <Stack maxWidth={"500px"}>
                <Box component={"img"} src={homepageSVG as unknown as string} style={{alignItems: "center", maxHeight: "200px", margin: "auto", marginBottom: 10}}/>
                <Typography style={{marginBottom: 10}}>We are creating a database of placements across the UK, allowing you to find valuable opportunities through Placementt.</Typography>
                <Typography style={{marginBottom: 10}}>Consent to us emailing your work experience placements, asking them to advertise their opportunities on Placementt.</Typography>
                <Typography style={{marginBottom: 10, color: PRIMARY_COLOUR}}>With your help, we will build a database with thousands of placements across the UK.</Typography>
                <LoadingButton variant="contained" text="Consent" onClick={() => consent(true)}/>
                <LoadingButton variant="outlined" text="Do not consent" onClick={() => consent(false)}/>
                <Typography style={{marginTop: 10, fontSize: 12}}>By consenting, you are allowing us to email your work experience placements, share your name and placement dates with them, and request them to advertise on Placementt.</Typography>
            </Stack>
        </Popup>
    );
}
