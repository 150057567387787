import {Box, Grid, Typography} from "@mui/material";
import Page from "../../Components/Page";
import imageOne from "../../Images/studentAppImages/1.png";
import imageTwo from "../../Images/studentAppImages/2.png";
import imageThree from "../../Images/studentAppImages/3.png";
import imageFour from "../../Images/studentAppImages/4.png";
import googlePlayButton from "../../Images/Google Play Button.png";
import appStoreButton from "../../Images/App Store Button.png";

import Card from "../../Components/Card";
import {STUDENT_COLOUR} from "placementt-core";
import InfoPageHeader from "../../Components/Public/InfoPageHeader";

export default function StudentAppAdvert() {
    return (
        <Page
            metaTitle="Placementt | Student app"
            metaDesc="Download Placementt Student andn take control of your work experience.">
            <InfoPageHeader
                title="Placementt - Student"
                subtext=""
                background={STUDENT_COLOUR}
                scaleBackground
            />
            <Box width={"100%"} maxWidth={"1400px"} alignSelf={"center"}>
                <Grid container>
                    <Card grid xs={12} md={8}>
                        <Typography fontSize="20px">Take control of your placements with <strong style={{color: STUDENT_COLOUR}}>Placementt - Student</strong>, our all-in-one app helping you to take control of your work experience.</Typography>
                        <Typography fontSize="20px" mt={3}>- Log your work experience placements</Typography>
                        <Typography fontSize="20px">- Keep track of work experience contacts</Typography>
                        <Typography fontSize="20px">- View custom placement analytics based on your course</Typography>
                        <Typography fontSize="20px" mt={3}>Built for students at any level, Placementt allows you to organise and evidence your work experience with ease.</Typography>
                    </Card>
                    <Grid item xs={12} md={4} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"space-around"}>
                        <Box style={{cursor: "pointer"}} onClick={() => window.open("https://apps.apple.com/gb/app/placementt/id6464076066", "blank")} width={"300px"} component={"img"} src={appStoreButton}/>
                        <Box style={{cursor: "pointer"}} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.placementt.app", "blank")} width={"300px"} component={"img"} src={googlePlayButton}/>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12} md={3} sm={6}>
                            <Box component={"img"} borderRadius={4} width={"100%"} src={imageOne}/>
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                            <Box component={"img"} borderRadius={4} width={"100%"} src={imageTwo}/>
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                            <Box component={"img"} borderRadius={4} width={"100%"} src={imageThree}/>
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                            <Box component={"img"} borderRadius={4} width={"100%"} src={imageFour}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
}
