import {Grid, Icon, Stack, Typography} from "@mui/material";
import {ReactElement, cloneElement} from "react";

type Params = {
    title?: string,
    titleColour?: string,
    iconColour?: string,
    items: {
        icon: ReactElement,
        title: string
    }[]
    itemWidth?: number
}

export default function IconGridList({title, titleColour="primary", items, itemWidth=Math.min(Math.ceil(12 / (Object.keys(items)?.length || 1)), 3), iconColour=titleColour}:Params) {
    return (
        <Stack width={"100%"}>
            {title && <Typography textAlign={"center"} color={titleColour} variant="h5" pt={"40px"}>{title}</Typography>}
            <Grid alignSelf={"center"} container>
                {items?.map((item) => <IconItem {...{...item, itemWidth, iconColour}}/>)}
            </Grid>
        </Stack>
    );
}

type IconItemParams = {
    icon: ReactElement,
    title: string,
    itemWidth: number,
    iconColour: string
}

function IconItem({icon, title, itemWidth, iconColour}: IconItemParams) {
    return (
        <Grid item xs={12} md={itemWidth} mt={3}>
            <Stack alignItems={"center"} justifyContent={"center"} textAlign={"center"}>
                <Icon style={{color: iconColour, width: "max-content", height: "max-content"}}>{cloneElement(icon, {fontSize: "large"})}</Icon>
                <Typography fontSize={"1.2em"} sx={{opacity: 0.75}}>{title}</Typography>
            </Stack>
        </Grid>
    );
}
