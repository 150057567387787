import {Card, CardContent, Stack, Typography} from "@mui/material";
import {executeCallable, uploadFiles} from "placementt-core";
import {useState} from "react";
import {useParams} from "react-router-dom";
import FileDropzone from "../../Components/FileDropzone/FileDropzone";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";
import Page from "../../Components/Page";


export default function UploadInsurance() {
    const [complete, setComplete] = useState(false);

    const {providerId, placementId} = useParams();

    const uploadELI = async (e:{eli:any[], expiry:string}) => {
        await uploadFiles(e.eli[0], `insurance/${providerId}.pdf`);
        await executeCallable("insurance-add", {
            providerId: providerId,
            pId: placementId,
            expiry: e.expiry});
        setComplete(true);
    };

    if (!complete) {
        return (
            <Page fullHeight>
                <Card sx={{maxWidth: "450px", position: "absolute", left: "50%", top: "20%", transform: "translate(-50%)"}}>
                    <CardContent sx={{textAlign: "center"}}>
                        <Stack spacing={2}>
                            <Typography variant='h4'>Upload insurance</Typography>
                            <Typography>Reupload your employers liability insurance to progress your placements.</Typography>
                        </Stack>

                        <Form onSubmit={(e) => uploadELI(e as {eli:unknown[], expiry:string})} submitText={"Submit"}>
                            <FileDropzone name={"eli"} required accept={"application/pdf"} label={"Upload Employers liability insurance"} />
                            <InputGroup required id="expiry" type={"date"} label="Insurance expiry" name="expiry" />
                        </Form>
                    </CardContent>
                </Card>
            </Page>

        );
    }
    return (
        <Page fullHeight>
            <Card sx={{maxWidth: "450px", position: "absolute", left: "50%", top: "20%", transform: "translate(-50%)"}}>
                <CardContent sx={{textAlign: "center"}}>
                    <Stack spacing={2}>
                        <Typography variant='h4'>Thank you for reuploading your insurance.</Typography>
                        <Typography>We will be in touch with any placement changes.</Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Page>);
}
