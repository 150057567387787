import {Stack} from "@mui/material";
import {ReactNode} from "react";

type Params = {
    children?: ReactNode[] | ReactNode
}

export default function PublicTemplate({children}: Params) {
    return (
        <Stack alignItems={"center"} pt={8} p={2} spacing={8} width={"100%"} maxWidth={"1080px"} alignSelf={"center"}>
            {children}
        </Stack>
    );
}
